import React, { useState, useEffect } from "react";
import "./Admin.css";
// import data from '../Authentication/SignupData.json';
// import { postMutation } from "../../components/ApolloConnection/ApolloProviderComponent";
import { CLINIC_CREATION } from "../graphql/GraphQLmutation";
import { snackbar } from "@mobiscroll/react";
import {
  getQuery,
  postMutation,
} from "../ApolloConnection/ApolloProviderComponent";
// import {postMutation} from '../ApolloConnection/UnauthorizedConnection/ApolloProviderSignup';
import { LIST_CLINIC_REQUEST } from "../graphql/GraphQLquery";
import { CLINIC_APPROVAL } from "../graphql/GraphQLmutation";

const Admin = (props) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    // let response = ListAll();
    // setState(response)
    ListAll();
  }, []);

  async function ListAll() {
    let response = await getQuery(LIST_CLINIC_REQUEST);
    // let data = response.data.listClinicCreationRequests.data
    setState(response.data.listClinicCreationRequests.data);
    // return data
  }

  async function Approve(e, data) {
    e.target.setAttribute("disabled", true);
    e.target.setAttribute("style", "background:grey");
    let temp_data = {
      requestId: data.id,
    };
    let response = await postMutation(CLINIC_APPROVAL, temp_data);
    // snackbar({
    //     message: "Created Successfully",
    //     color: 'success'
    // });
  }

  const Cancel = (e, email) => {
    e.target.setAttribute("disabled", true);
    snackbar({
      message: "You have requested to cancel the request of " + email,
      color: "danger",
    });
  };

  return (
    <div className="admin-table text-center">
      <h3 className="text-dark mt-2 mb-4">Account Verification</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Mobile Number</th>
            <th>Email</th>
            <th>Clinic Name</th>
            <th>Clinic Address</th>
            <th>Verification</th>
          </tr>
        </thead>
        <tbody>
          {state.map((data) => {
            return (
              <tr>
                <td>{data.id}</td>
                <td>{data.title}</td>
                <td>{data.firstName}</td>
                <td>{data.lastName}</td>
                <td>{data.phoneNumber}</td>
                <td>{data.email}</td>
                <td>{data.clinicName}</td>
                <td>{data.clinicAddress}</td>
                <td>
                  <button
                    className="btn-success"
                    id={data.email}
                    onClick={(e) => Approve(e, data)}
                  >
                    Approve
                  </button>
                  <button
                    className="btn-danger"
                    onClick={(e) => Cancel(e, data.email)}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Admin;
