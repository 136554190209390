import { Route } from "react-router-dom";
import oopsImage from "src/assets/oops.svg";

const PrivateRoute = ({
  component: Component,
  module,
  key,
  componentProps = {},
  ...rest
}) => {
  const doctorVerified = sessionStorage.getItem("doctorVerified");
  console.log(doctorVerified, rest.path);
  if (
    module === "doctor" &&
    (doctorVerified === "true" ||
      (doctorVerified === "false" && ["/profile", "/fee"].includes(rest.path)))
  ) {
    return (
      <Route
        {...rest}
        exact
        key={key}
        render={(props) => {
          console.log(props, componentProps);
          return <Component {...props} {...componentProps} />;
        }}
      />
    );
  } else
    return (
      <>
        <Route
          {...rest}
          exact
          key={key}
          render={(props) => (
            <div className="d-flex justify-content-center align-items-center flex-column w-100 docg-vh-standard">
              <img style={{ maxWidth: 600 }} src={oopsImage} alt="" />
              <br />
              <p className="get-txt">
                {" "}
                Please, Complete{" "}
                <a style={{ color: "#c06c84" }} href="/profile">
                  Profile
                </a>{" "}
                and{" "}
                <a style={{ color: "#c06c84" }} href="/fee">
                  Fee
                </a>{" "}
                Page.
              </p>
              <span>
                {" "}
                Now,{" "}
                <button onClick={() => window.location.reload(false)}>
                  <i>
                    <u>Click here to Refresh</u>
                  </i>
                </button>
              </span>
            </div>
          )}
        />
      </>
    );
};

export default PrivateRoute;
