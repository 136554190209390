import React, { Fragment, useState } from "react";
import { Row, Col, Card, Button, Image, Modal } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Switch } from "@mobiscroll/react";
import { useStore } from "src/store/Store";
import moment from "moment";
import PatientNameCellRenderer from "./PatientNamePendingLabCellRenderer";
import Eye from "../../../assets/icons/Eye.svg";
import Print from "../../../assets/icons/print.svg";
import Note from "../../../assets/icons/Note.svg";
import Bottle from "../../../assets/icons/Bottle.svg";
import PendingLabRepotIcon from "../../../assets/icons/PendingLabRepotIcon.svg";
import "./PendingLabRepot.css";
import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { useDebounce } from "src/lib/useDebounce";

import { readNotification } from "src/api";
import { isMobileOnly } from "react-device-detect";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

function StatusCellRenderer(params) {
  const [checked, setChecked] = useState(params.value);
  const handleChange = async (e) => {
    if (e.target.checked) {
      await readNotification({ id: params.data.id });
      setChecked(e.target.checked);
    }
  };
  return (
    <div style={{ backgroundColor: "transparent" }}>
      <Switch onChange={handleChange} checked={checked}></Switch>
    </div>
  );
}

const PendingLabRepot = () => {
  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [search, setSearch] = useState("");
  const [displayRecords, setDisplayRecords] = useState("03");

  const { dispatchNotifications, globalStateNotifications } = useStore();

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = (val) => {
    console.log(val);
    if(val)
    gridApi.setQuickFilter(val);
  };

  const onPageSizeChanged = (e) => {
    setDisplayRecords(e.valueText);
    gridApi.paginationSetPageSize(Number(e.valueText));
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    suppressMovable: true,
    minWidth: 150,
  };

  // eslint-disable-next-line
  const [rowData, setRowData] = useState([]);
  useDebounce(
    async () => {
      onQuickFilterChanged(search)
    },
    100,
    [search]
  );


  React.useEffect(() => {
    let data = globalStateNotifications.pendingLabReports.map((report) => {
      delete report.__typename;
      return {
        ...report,
        patientName: report.metadata.patientName,
        patientProfilePicture: report.metadata.patientProfilePicture,
        uploadedDate: report.metadata.labReportUploadedDate,
        appointmentDate: report.metadata.appointmentDate,
      };
    });
    setRowData(data);
    return () => {};
  }, [globalStateNotifications.pendingLabReports]);

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  return (
    <Fragment>
      <Card className="docg-card  docg-pending-lab-report">
        <CardHeader className="d-flex justify-content-between cardHeaderUsed">
          <CardTitle className="cardTitleUsed">
            <Image src={PendingLabRepotIcon} alt="" className="mr-2" /> Pending
            Lab Reports ({rowData.length})
          </CardTitle>

          {/* {!isMobileOnly && (
            <div className="docg-table-search mr-0">
              <input
                type="text"
                onChange={(e)=>setSearch(e.target.value)}
                value={search}
                id="labReportFilter"
                placeholder="Search..."
              />
            </div>
          )} */}
        </CardHeader>

        <CardBody className="docg-card-body p-0">
          {/* <div className="records-display">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">ß
              <mobiscroll.Select
                touchUi={false}
                onChange={(e) => {
                  onPageSizeChanged(e);
                }}
                value={displayRecords}
              >
                {Array.from(Array(rowData.length).keys()).map((index) => {
                  let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
                  return <option value={value}>{value}</option>;
                })}
              </mobiscroll.Select>
              <i className="fas fa-chevron-down"></i>
            </label>
            <span>&nbsp;Records</span>
          </div> */}
          <div
            className="ag-theme-alpine"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <AgGridReact
              rowData={rowData}
              defaultColDef={defaultColDef}
              frameworkComponents={{
                statusCellRenderer: StatusCellRenderer,
                patientNameCellRenderer: PatientNameCellRenderer,
              }}
              defaultColGroupDef={defaultColGroupDef}
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={displayRecords}
              onGridReady={onGridReady}
              domLayout={"autoHeight"}
              animateRows={true}
            >
              <AgGridColumn
                headerName="Report Date"
                headerTooltip="Report Date"
                field="uploadedDate"
                valueFormatter={(params) => {
                  return new Date(params.value).toLocaleDateString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                }}
                tooltipValueGetter={(params) => {
                  return new Date(params.value).toLocaleDateString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Consl. Date"
                headerTooltip="Consultation Date"
                field="appointmentDate"
                valueFormatter={(params) => {
                  return new Date(params.value).toLocaleDateString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                }}
                tooltipValueGetter={(params) => {
                  return new Date(params.value).toLocaleDateString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Patient Name"
                headerTooltip="Patient Name"
                field="patientName"
                filter="agTextColumnFilter"
                tooltipField="patientName"
                initialPinned="left"
                cellRenderer="patientNameCellRenderer"
              ></AgGridColumn>

              <AgGridColumn
                headerName="Status"
                headerTooltip="Status"
                field="acknowledged"
                tooltipField="acknowledged"
                cellRenderer="statusCellRenderer"
              ></AgGridColumn>
            </AgGridReact>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PendingLabRepot;
