import React, { Fragment, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { useStore } from "src/store/Store";
import { listEmergencyNotifications } from "src/store/notificationReducer";

import { Dropdown, Modal } from "react-bootstrap";
import PatientNameCellRenderer from "./PatientNameCellRenderer";
import ActionCellRender from "./ActionCellRender";
import WaitingPatients from "../../assets/icons/WaitingPatients.svg";

import SymptomCellRenderer from "./SymptomCellRenderer";

//apii
import { listAllNotifications } from "src/api/index";

const WaitingPatiens = ({ setLgShow, lgShow }) => {
  const { globalStateNotifications } = useStore();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
    suppressMovable: true,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const closePopup = () => setLgShow(false);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={lgShow}
        onHide={closePopup}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <img src={WaitingPatients} alt="" />{" "}
            <span className="ModalTitle">Waiting Patient</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="ag-theme-alpine"
            style={{
              maxHeight: "70vh",
              overflow: "auto",
            }}
          >
            <AgGridReact
              rowData={globalStateNotifications.emergencyNotifications}
              defaultColDef={defaultColDef}
              frameworkComponents={{
                actionCellRender: (params) => (
                  <ActionCellRender
                    params={params}
                    closePopup={closePopup}
                    count={
                      globalStateNotifications.emergencyNotifications.length
                    }
                  />
                ),
                patientCellRenderer: (params) => (
                  <PatientNameCellRenderer
                    params={params}
                    closePopup={closePopup}
                  />
                ),
                symptomCellRenderer: SymptomCellRenderer,
              }}
              defaultColGroupDef={defaultColGroupDef}
              enableRangeSelection={true}
              pagination={true}
              onGridReady={onGridReady}
              domLayout={"autoHeight"}
              animateRows={true}
            >
              <AgGridColumn
                headerName="Patient Name"
                headerTooltip="Patient Name"
                field="patient"
                initialPinned="left"
                initialSort="asc"
                cellRenderer="patientCellRenderer"
                valueGetter={(params) => {
                  return {
                    patientDetails: {
                      patientId: params.data.metadata.patientId,
                      patientName: params.data.metadata.patientName,
                      profilePicture:
                        params.data.metadata?.patientProfilePicture,
                    },
                  };
                }}
                tooltipValueGetter={(params) => {
                  return {
                    patientDetails: {
                      patientId: params.data.metadata.patientId,
                      patientName: params.data.metadata.patientName,
                      profilePicture:
                        params.data.metadata?.patientProfilePicture,
                    },
                  };
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Symptoms"
                headerTooltip="Symptoms"
                field="symptoms"
                cellRenderer="symptomCellRenderer"
                valueSetter={(params) => {
                  return params.data.metadata.patientSymptoms.questions;
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Requested At"
                headerTooltip="Requested At"
                field="symptoms"
                width={230}
                minWidth={230}
                valueFormatter={(params) => {
                  return new Date(
                    params.data.metadata.appointmentDate
                  ).toLocaleString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                }}
                tooltipValueGetter={(params) => {
                  return new Date(
                    params.data.metadata.appointmentDate
                  ).toLocaleString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Accept/ Reject"
                headerTooltip="Accept/ Reject"
                cellRenderer="actionCellRender"
              ></AgGridColumn>
            </AgGridReact>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default WaitingPatiens;
