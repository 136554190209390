import React from "react";
import { Helmet } from "react-helmet";
import ScheduleContent from "./ScheduleContent";
import { StoreProvider } from "./store/Store";
import { initialState, scheduleReducer } from "./store/ScheduleReducer";

const Schedule = (props) => {
  return (
    <>
      <Helmet>
        <title>Doctor Schedule | DOCG</title>
      </Helmet>
      <StoreProvider initialState={initialState} reducer={scheduleReducer}>
        <ScheduleContent closePopup={props.closePopup} />
      </StoreProvider>
    </>
  );
};

export default Schedule;
