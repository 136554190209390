import { snackbar } from "@mobiscroll/react5";
import {
  REJECT_EMERGENCY_APPOINTMENT,
  ACCEPT_EMERGENCY_APPOINTMENT,
  READ_NOTIFICATION,
  ADD_TODO_LIST,
  SET_FOLLOW_UP,
} from "src/components/graphql/GraphQLmutation";
import { DOES_PATIENT_HISTORY_EXISTS } from "src/components/graphql/GraphQLquery";
import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";

export async function listPatientHistory(patientId) {
  try {
    const response = await getQueryWithParams(
      DOES_PATIENT_HISTORY_EXISTS,
      patientId
    );

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.listPatientHistory?.statusCode < 300 &&
        response?.data?.listPatientHistory?.statusCode >= 200
      )
    ) {
      throw new Error("error", response);
    }
    console.log(response);
    if (response.data.listPatientHistory.data.length > 0) return true;
    return false;
  } catch (e) {
    snackbar({
      message: "Some Error Occured While creating follow-up. Try again",
      color: "danger",
    });
    console.log("Error: ", e.message);
    return false;
  }
}
export async function setFollowUp(data) {
  try {
    const response = await postMutation(SET_FOLLOW_UP, data);

    if (typeof response === "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      if (
        response.data.setFollowUp.statusCode < 300 &&
        response.data.setFollowUp.statusCode >= 200
      ) {
        snackbar({
          message: "Follow-up Appointment Created Successfully",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some Error Occured While creating follow-up. Try again",
      color: "danger",
    });
    console.log("Error: ", e);
    return false;
  }
}
export async function addToDoList(data) {
  try {
    const response = await postMutation(ADD_TODO_LIST, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.addToDoNote.statusCode < 300 &&
        response.data.addToDoNote.statusCode >= 200
      ) {
        snackbar({
          message: "To-Do List Added Successfully",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some Error Occured While Adding To-Do List. Try again",
      color: "danger",
    });
    console.log("Error: ", e);
    return false;
  }
}
export async function rejectEmergencyAppointment(data) {
  try {
    const response = await postMutation(REJECT_EMERGENCY_APPOINTMENT, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.rejectEmergencyAppointment.statusCode < 300 &&
        response.data.rejectEmergencyAppointment.statusCode >= 200
      ) {
        snackbar({
          message: "Rejected Appointment Succesfully.",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while rejecting Appointment. Try again",
      color: "danger",
    });
    console.log("Error at reject doctor appointment: ", e);
    return false;
  }
}
export async function acceptEmergencyAppointment(data) {
  try {
    const response = await postMutation(ACCEPT_EMERGENCY_APPOINTMENT, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.acceptEmergencyAppointment.statusCode < 300 &&
        response.data.acceptEmergencyAppointment.statusCode >= 200
      ) {
        snackbar({
          message: "Accepted Appointment Succesfully.",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while accepting Appointment. Try again",
      color: "danger",
    });
    console.log("Error at reject doctor appointment: ", e);
    return false;
  }
}
