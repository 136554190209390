import React, { Fragment } from "react";
import "react-circular-progressbar/dist/styles.css";

const Button = (props) => {
  return (
    <Fragment>
      <div
          onClick={props?.onClick}
        className={`${props?.className}`}
        // "col-lg-2 mt-2 ml-5 newAppointment d-flex justify-content-between"
      >
        {props?.icon}
        <span
          className={`${props?.text}`}
          // "text-white addText"
        >
          {props?.name}
        </span>
      </div>
    </Fragment>
  );
};

export default Button;
