import {
  getQueryWithParams,
  fetchQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  TOTAL_APPOINTMENTS_COUNT,
  LIST_PATIENT_RECORDS,
  GET_BILLINGS,
  LIST_DOCTOR_REVIEWS,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import moment from "moment";

export async function listDoctorReviews() {
  try {
    let response = await getQueryWithParams(LIST_DOCTOR_REVIEWS);
    if (response?.data?.listDoctorReviews?.data) {
      return response.data.listDoctorReviews.data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    snackbar({
      message: "Error while getting DoctorReviews.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return [];
  }
}
export async function getTotalNumberOfAppointments() {
  try {
    let response = await getQueryWithParams(
      TOTAL_APPOINTMENTS_COUNT("status=Completed")
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response?.data?.listAppointmentsDoctor?.totalCount >= 0) {
        return response.data.listAppointmentsDoctor.totalCount;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Total Number of Appointments.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return null;
  }
}

export async function getAppointmentsStartDate() {
  try {
    let response = await getQueryWithParams(
      TOTAL_APPOINTMENTS_COUNT("status=Completed")
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response?.data?.listAppointmentsDoctor?.data) {
        return response.data.listAppointmentsDoctor.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Appointments Chart.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return [];
  }
}

export async function listAppointmentsYearByYear() {
  const data = await getAppointmentsStartDate();
  let appointmentsYearByYear = new Object();
  data.map(function (appt) {
    let currApptDate = new Date(appt.start);
    let currYear = moment(currApptDate).week();
    console.log(appt, currApptDate, currYear, data);
    if (appointmentsYearByYear[currYear] != undefined) {
      appointmentsYearByYear[currYear]++;
    } else {
      appointmentsYearByYear[currYear] = 1;
    }
  });
  console.log(appointmentsYearByYear);
  let arr = new Array();
  Object.keys(appointmentsYearByYear).map(function (key) {
    console.log(key, appointmentsYearByYear[key]);
    arr.push({ year: String(key), value: appointmentsYearByYear[key] });
  });
  return arr;
}
export async function getPatientsCreatedAt() {
  try {
    let response = await getQueryWithParams(LIST_PATIENT_RECORDS(2147483647));
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response?.data?.listDoctorPatients?.data) {
        return response.data.listDoctorPatients.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Patients Chart.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return [];
  }
}

export async function listPatientsYearByYear() {
  try {
    const data = await getPatientsCreatedAt();
    let patientsYearByYear = new Object();
    data.map(function (patient) {
      let currApptDate = new Date(patient.createdAt);
      let currYear = moment(currApptDate).week();
      if (patientsYearByYear[currYear] != undefined) {
        patientsYearByYear[currYear]++;
      } else {
        patientsYearByYear[currYear] = 1;
      }
    });
    console.log(patientsYearByYear);
    let arr = new Array();
    Object.keys(patientsYearByYear).map(function (key) {
      console.log(key, patientsYearByYear[key]);
      arr.push({ year: String(key), value: patientsYearByYear[key] });
    });
    return arr;
  } catch (err) {
    return [];
  }
}
export async function getDoctorBillings() {
  try {
    const data = [];
    const doctorId = sessionStorage.getItem("role-id");
    //this week res
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));

    firstday.setHours(0, 0, 0, 0);
    lastday.setHours(0, 0, 0, 0);

    let thisWeek = await getQueryWithParams(GET_BILLINGS, {
      doctorId,
      start: firstday.toISOString(),
      end: lastday.toISOString(),
    });

    if (thisWeek?.data?.getTotalBillingsOfDoctor?.data?.totalBillings >= 0) {
      data.push({
        title: "This Week",
        value: thisWeek.data.getTotalBillingsOfDoctor.data.totalBillings / 100,
      });
    } else {
      throw new Error("error");
    }

    //last week
    var lastWeekStart = new Date(
      firstday.getFullYear(),
      firstday.getMonth(),
      firstday.getDate() - 7
    );
    var lastWeekEnd = new Date(
      lastday.getFullYear(),
      lastday.getMonth(),
      lastday.getDate() - 7
    );
    let lastWeek = await getQueryWithParams(GET_BILLINGS, {
      doctorId,
      start: lastWeekStart.toISOString(),
      end: lastWeekEnd.toISOString(),
    });

    if (lastWeek?.data?.getTotalBillingsOfDoctor?.data?.totalBillings >= 0) {
      data.push({
        title: "Last Week",
        value: lastWeek.data.getTotalBillingsOfDoctor.data.totalBillings / 100,
      });
    } else {
      throw new Error("error");
    }

    //this month

    var y = curr.getFullYear(),
      m = curr.getMonth();
    firstday = new Date(y, m, 1);
    lastday = new Date(y, m + 1, 0);
    let thisMonth = await getQueryWithParams(GET_BILLINGS, {
      doctorId,
      start: firstday.toISOString(),
      end: lastday.toISOString(),
    });
    if (thisMonth?.data?.getTotalBillingsOfDoctor?.data?.totalBillings >= 0) {
      data.push({
        title: "This Month",
        value: thisMonth.data.getTotalBillingsOfDoctor.data.totalBillings / 100,
      });
    } else {
      throw new Error("error");
    }
    return data;
  } catch (error) {
    snackbar({
      message: "Error while getting Patients Chart.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return [];
  }
}
