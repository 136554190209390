import { snackbar } from "@mobiscroll/react5";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactNotifications } from "react-notifications-component";
import { matchPath } from "react-router";
import { Redirect } from "react-router-dom";
import warningSign from "src/assets/warning-sign.svg";
import HeaderTop from "./HeaderTop";
import IdleTimer from "./IdleTimer";
import LeftSidebar from "./LeftSidebar";
import { signOut, fetchMFAPreference } from "aws-amplify/auth";

import { MFASettings } from "./../components/Dashboard/Profile/MFASettings";

const match = matchPath(window.location.pathname, {
  path: "/video-notes/:patientId",
  exact: false,
  strict: false,
});

const DoctorTemplate = (props) => {
  const [isSideBarVisible, setIsSideBarVisible] = useState(true);
  const [searchBar, setSearchBar] = useState(true);
  const [TimeBar, setTimeBar] = useState(true);
  const [show, setShow] = useState(false);
  const [mfa, setMfa] = useState(null);

  React.useEffect(() => {
    let prompt = sessionStorage.getItem("showPrompt");
    if (prompt) {
      setShow(true);
    }
    sessionStorage.removeItem("showPrompt");
  }, []);

  const onClose = () => setShow(false);

  async function doctorSignout() {
    try {
      signOut()
        .then(() => {
          snackbar({
            message: "You have been logged out",
            color: "danger",
          });
          localStorage.clear();
          sessionStorage.clear();
          window.location = "/";
        })
        .catch(() => {
          snackbar({
            message: "Unable to logout, Please Refresh.",
            color: "danger",
          });
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 15 * 60, //expire after 15 mins
      onTimeout: async () => {
        await doctorSignout();
      },
      onExpired: async () => {
        //do something if expired on load
        await doctorSignout();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const status = sessionStorage.getItem("loginStatus");
  const roles = sessionStorage.getItem("userRole");

  React.useEffect(() => {
    if (window.innerWidth <= 1200) {
      setIsSideBarVisible(false);
    }
  }, []);

  const fetchMFAData = () => {
    fetchMFAPreference().then((data) => {
      console.log("NEW MFA rES", data);
      if (data === "NOMFA") {
        setMfa(true);
      } else {
        setMfa(false);
      }
    });
  };

  React.useEffect(() => {
    if (status) {
      fetchMFAData();
    }
  }, [status]);

  // if there is not user, redirect to the home page
  if (!status) {
    return <Redirect to="/" />;
  }

  // // if there is not user, redirect to the home page
  // if (!user) {
  //   return <Redirect to="/" />
  // }

  const handleSidebar = (...props) => {
    const currentSidebarState = isSideBarVisible;
    setIsSideBarVisible(!currentSidebarState);
  };

  return (
    <>
      {mfa && false ? (
        <>
          <Modal
            show={mfa}
            onHide={() => setMfa(false)}
            backdrop="static"
            keyboard={false}
            dialogClassName="docg-modal"
            size="lg"
            centered
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
                <span className="ModalTitle">MFA Configuration</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                Please select preferred MFA as TOTP for security reasons
              </div>
              <br />
              <MFASettings onChange={fetchMFAData} title={false} />
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <></>
      )}
      {match ? (
        <div className="content">
          <div className="mbsc-grid">
            <div className="mbsc-row">{props.children}</div>
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="docg-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
                <span className="ModalTitle">Warning!</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center justify-content-center patient-permission-modal">
              <img style={{ height: "30vh" }} src={warningSign} alt="" />
              <span>
                "This application is released as test version, without any
                warranty, responsibility or liability. You cannot use this
                application for any real time activities. It is totally meant to
                be used for testing purposes”
              </span>
            </Modal.Body>
          </Modal>
          <LeftSidebar
            handleSidebar={handleSidebar}
            isSideBarVisible={isSideBarVisible}
            setIsSideBarVisible={setIsSideBarVisible}
          />
          {/* <Sidebar isSideBarVisible={isSideBarVisible} /> */}
          <ReactNotifications />
          <div
            className={`main-panel${isSideBarVisible ? "" : " min-sidebar"}`}
          >
            <HeaderTop
              handleSidebar={handleSidebar}
              searchBar={searchBar}
              TimeBar={TimeBar}
            />
            <div className="content">
              <div className="mbsc-grid">
                <div className="mbsc-row">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorTemplate;
