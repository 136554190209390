import { Input, Textarea, snackbar } from "@mobiscroll/react5";
import React from "react";
import Avatar from "react-avatar";
import { Card, Modal } from "react-bootstrap";
import changepic from "src/assets/change-profile-img.svg";
import plus_icon from "src/assets/plus-circle.svg";
import CropProfile from "src/commons/CropProfile";
import Popup from "./Popup";

const Profile = ({
  data,
  setTouched,
  edit,
  setEdit,
  edited,
  setEdited,
  reloadData,
  cancelOnClick,
  editOnClick,
  _renderAction,
  handleChange,
  handleProfileChange,
  handleImageUpload,
}) => {
  const [specializationPopup, setSpecialization] = React.useState(false);
  const [img, setImg] = React.useState(null);
  const findAndSetStateCity = async (pincode) => {
    try {
      let data = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      data = await data.json();
      data = data[0];
      if (data.Status === "Success") {
        let district = data.PostOffice[0].District;
        let state = data.PostOffice[0].State;
        setTouched((prev) => ({ ...prev, district: true, state: true }));
        setEdited((prev) => ({ ...prev, district, state }));
      } else {
        snackbar({
          message: `${data.Message} For this Postal Code ${pincode}`,
          color: "danger",
        });
      }
    } catch (err) {}
  };

  const selectImage = (file) => {
    setImg(URL.createObjectURL(file));
  };

  return (
    <>
      <Modal
        show={img}
        onHide={() => setImg(null)}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal"
        size="lg"
        centered
      >
        <Modal.Header>Crop and save image</Modal.Header>
        <Modal.Body className="overflow-auto">
          <CropProfile
            src={img}
            setSrc={setImg}
            onSubmit={(keyword, file) => {
              handleImageUpload(keyword, file);
              setImg(null);
            }}
          />
        </Modal.Body>
      </Modal>
      <Card className="docg-card bg-white docg-vh-standard p-2 docg-profile-text overflow-auto">
        <div className="mbsc-row">
          <div className="mbsc-col-12 d-flex justify-content-center align-items-center mt-2">
            <div className="profile-img-wrap">
              <div className="profile-image">
                {data?.profilePicture && (
                  <Avatar
                    color="#c06c84"
                    round
                    size="5rem"
                    name={new Array(
                      data?.title,
                      data?.firstName,
                      data?.middleName,
                      data?.lastName
                    )
                      .filter((el) => el != null && el != undefined)
                      .join(" ")}
                    src={data?.profilePicture}
                  />
                )}
              </div>
              <label
                for="profile-picture-change"
                className="profile-change-icon"
              >
                <img src={changepic} alt="" />
              </label>
              <input
                type="file"
                id="profile-picture-change"
                onChange={(e) => selectImage(e.target.files[0])}
                accept="image/*"
              />
            </div>
          </div>
          <div className="mbsc-col-12 mt-2">
            <div className="profile-title d-flex text-center align-items-center justify-content-center">
              <span>
                {new Array(
                  data?.title,
                  data?.firstName,
                  data?.middleName,
                  data?.lastName
                )
                  .filter((el) => el != null && el != undefined)
                  .join(" ")}
              </span>
            </div>
          </div>
          <div className="mbsc-col-12 mt-1">
            {/* {edit ? (
              <Input
                inputStyle="underline"
                labelStyle="floating"
                placeholder=""
                name="specialization"
                type="text"
                value={edited?.specialization}
                onChange={(e)=>{setEdited(prev=>return {...prev, specialization:[]})}}
              />
            ) : ( */}
            <div className="profile-speciality">
              {data?.specialization
                .filter((el) => el != null && el != undefined)
                .join(", ") || "Specialization"}
              {edit && (
                <button
                  name="Specialization"
                  onClick={(e) => {
                    setSpecialization(true);
                  }}
                  className="mbsc-col-1"
                >
                  <img src={plus_icon} />
                </button>
              )}
            </div>
            {/* )} */}
          </div>
          <div className="mbsc-col-12 mt-3">
            {edit ? (
              <Textarea
                name="specializationsDescription"
                inputStyle="underline"
                labelStyle="floating"
                label="Specialization Description"
                value={edited?.specializationsDescription}
                onChange={handleChange}
              ></Textarea>
            ) : (
              <p>
                {data?.specializationsDescription
                  ? data.specializationsDescription
                  : "-"}
              </p>
            )}
            <hr />
          </div>
          <div className="mbsc-col-12 mt-2 w-100 d-flex justify-content-evenly">
            {/* <div className="profile-record-card">
              <span>
                {data?.yearsOfExperience ? data.yearsOfExperience : "-"} <br />{" "}
              </span>
              Exp.
            </div> */}
            <div style={{ fontSize: "14px" }} className="profile-record-card">
              <span>
                {data?.yearsOfExperience ? data.yearsOfExperience : "-"} <br />{" "}
              </span>
              Exp.
            </div>
          </div>
          <div className="mbsc-col-12 mt-3">
            <label className="label_style">Designation:</label>
            {edit ? (
              <Textarea
                name="designation"
                inputStyle="underline"
                labelStyle="floating"
                value={edited?.designation}
                onChange={handleChange}
              ></Textarea>
            ) : (
              <p>{data?.designation ? data.designation : "-"}</p>
            )}
            <hr />
          </div>
          {edit ? (
            <>
              <div className="mbsc-col-12">
                <Textarea
                  name="address"
                  inputStyle="underline"
                  label="Address"
                  labelStyle="floating"
                  value={edited?.address}
                  onChange={handleChange}
                ></Textarea>
              </div>
              <div className="mbsc-col-12">
                <Input
                  name="pinCode"
                  type="number"
                  inputStyle="underline"
                  labelStyle="floating"
                  label="Pin Code"
                  value={edited?.pinCode}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value.length > 5)
                      findAndSetStateCity(e.target.value);
                  }}
                />
              </div>
              <div className="mbsc-col-12">
                <Input
                  name="district"
                  inputStyle="underline"
                  labelStyle="floating"
                  label="City"
                  value={edited?.district}
                  onChange={handleChange}
                />
              </div>
              <div className="mbsc-col-12">
                <Input
                  name="state"
                  inputStyle="underline"
                  labelStyle="floating"
                  label="State"
                  value={edited?.state}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            <div className="mbsc-col-12 mt-3">
              <label className="label_style">Location:</label>
              <p>
                {[data?.address, data?.district, data?.state, data?.pinCode]
                  .filter((item) => item)
                  .join(", ") ?? "NA"}
              </p>
            </div>
          )}

          <hr />
          <div className="mbsc-col-12 mt-3">
            <label className="label_style">Email:</label>
            <p>{data?.email ? data.email : "-"}</p>
            {/* <Textarea
                readOnly={true}
                value={data?.address ? data.address : "-"}
                className="textarea_style"
              /> */}
            <hr />
          </div>
          <div className="mbsc-col-12 mt-3">
            <label className="label_style">Mobile Number:</label>
            <p>{data?.phoneNumber ? data.phoneNumber : "-"}</p>
            {/* <Textarea
                readOnly={true}
                value={data?.address ? data.address : "-"}
                className="textarea_style"
              /> */}
            <hr />
          </div>
        </div>
      </Card>
      <Popup
        show={specializationPopup}
        closePopup={() => setSpecialization(false)}
        title="Specialization"
        data={data?.specialization}
        doctorId={data?.id}
        reloadData={reloadData}
      />
    </>
  );
};

export default Profile;
