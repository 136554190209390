import React, { Suspense } from "react";
import { Dropdown, Modal } from "react-bootstrap";

import Correct from "src/assets/icons/Correct.svg";
import Cross from "src/assets/icons/Cross.svg";

import { rejectEmergencyAppointment } from "./api";
import { readNotification } from "src/api";
import Schedule from "src/components/Dashboard/SchedulePopup/Schedule";

const RescheduleAppointment = React.lazy(() =>
  import("./RescheduleAppointment/index")
);

const ActionCellRender = ({ params, closePopup, count }) => {
  console.log(params);
  const [reschedulePopup, setReschedulePopup] = React.useState(false);
  const [schedulePopup, setSchedulePopup] = React.useState(false);

  const toggleRescheduleAppointment = React.useCallback(() => {
    setReschedulePopup(!reschedulePopup);
  }, [reschedulePopup]);

  const closeReschedulePopup = () => setReschedulePopup(false);
  const openSchedule = () => {
    setSchedulePopup(true);
    setReschedulePopup(false);
  };
  const closeSchedulePopup = () => {
    setSchedulePopup(false);
    setReschedulePopup(true);
  };

  const handleRejectAppointment = React.useCallback(async () => {
    const data = {
      appointmentId: params.data.metadata.appointmentId,
      patientId: params.data.metadata.patientId,
    };
    let res = await rejectEmergencyAppointment(data);
    if (res) {
      await readNotification({ id: params.data.id });
      if (count == 1) closePopup();
    }
  }, [params]);

  return (
    <>
      <div>
        <button onClick={toggleRescheduleAppointment}>
          <img src={Correct} alt="Accept" />
        </button>
        <button onClick={handleRejectAppointment}>
          <img src={Cross} alt="Reject" />
        </button>
      </div>
      <Modal
        size="lg"
        show={reschedulePopup}
        onHide={closeReschedulePopup}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Body className="p-0">
          <Suspense fallback={<div>Loading...</div>}>
            <RescheduleAppointment
              closePopup={closeReschedulePopup}
              openSchedule={openSchedule}
              data={params.data}
              count={count}
              closeWaitingPopup={closePopup}
            />
          </Suspense>
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        show={schedulePopup}
        onHide={closeSchedulePopup}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="p-0">
          <Suspense fallback={<div>Loading...</div>}>
            <Schedule closePopup={closeSchedulePopup} />
          </Suspense>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActionCellRender;
