var windowObjectReference = null; // global variable

export function openNewWindow(URL, title) {
  if (windowObjectReference == null || windowObjectReference.closed) {
    let windowFeatures = "left=150,top=100,width=760,height=560, popup=true";

    windowObjectReference = window.open(URL, title, windowFeatures);
  } else {
    windowObjectReference.focus();
  }
}

export function closeWindow() {
  windowObjectReference?.close();
}
