import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  LIST_DOCTOR_FEE,
  GET_DOCTOR_ID,
  GET_UPLOAD_POLICY_LINK,
  GET_UPLOADED_FILE_LINK,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";

import {
  ADD_DOCTOR_FEE,
  ACTIVATE_DOCTOR_FEE,
} from "src/components/graphql/GraphQLmutation";

export async function getUploadPolicyLink(data) {
  try {
    let response = await getQueryWithParams(GET_UPLOAD_POLICY_LINK, data);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.uploadDoctorFeePolicy.data) {
        return response.data.uploadDoctorFeePolicy.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while Uploading File.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return null;
  }
}

//fetch uploaded file link
export async function getUploadedFileLink(data) {
  try {
    let response = await getQueryWithParams(GET_UPLOADED_FILE_LINK, data);

    if (typeof response == "undefined") {
      throw new Error(response);
      
    } else {
      if (response.data.getFilePresignedUrl.data) {
        return response.data.getFilePresignedUrl.data;

      } else {
        throw new Error(response);
      }
    }

  } catch (error) {
    snackbar({
      message: "Error while Uploading File.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return null;
  }
}

export async function addDoctorFee(data) {
  try {
    const response = await postMutation(ADD_DOCTOR_FEE, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.addDoctorFee.statusCode < 300 &&
        response.data.addDoctorFee.statusCode >= 200
      ) {
        snackbar({
          message: "Fee Added Successfully.",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while adding Fee. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return false;
  }
}

export async function getDoctorId() {
  try {
    let response = await getQuery(GET_DOCTOR_ID);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.getMyProfile.data) {
        return response.data.getMyProfile.data.id;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at list Schedules", error);

    return undefined;
  }
}

export async function getDoctorFees() {
  try {
    let doctorId = sessionStorage.getItem("role-id");
    console.log("Doctor Id", doctorId);
    if (typeof doctorId === undefined) {
      throw new Error(doctorId);
    } else {
      let response = await getQueryWithParams(LIST_DOCTOR_FEE, doctorId);
      if (typeof response == "undefined") {
        throw new Error(response);
      } else {
        if (response.data.listDoctorFee.data) {
          snackbar({
            message: "Fee List fetched Successfully.",
            color: "success",
          });
          return response.data.listDoctorFee.data;
        } else {
          throw new Error(response);
        }
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while fetching fee list.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);

    return [];
  }
}

export async function activateDoctorFee(data) {
  try {
    const response = await postMutation(ACTIVATE_DOCTOR_FEE, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.activateDoctorFee.statusCode < 300 &&
        response.data.activateDoctorFee.statusCode >= 200
      ) {
        snackbar({
          message: "Doctor Fee status updated Successfully.",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while updating Fee. Try again",
      color: "danger",
    });
    console.log("Error at update doctor fee: ", e);
    return false;
  }
}
