import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { LIST_PATIENT_RECORDS } from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import {
  REMOVE_SCHEDULE,
  REMOVE_SCHEDULE_GROUP,
  ADD_SCHEDULE,
} from "src/components/graphql/GraphQLmutation";

export async function listPatients(displayRecords, search) {
  try {
    let take = displayRecords;
    if (displayRecords == 0) {
      take = 10;
    }
    const response = await getQuery(LIST_PATIENT_RECORDS(take, search));
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "List of Patients fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listDoctorPatients?.data) {
        return response?.data?.listDoctorPatients;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    console.log("Error at List Patients: ", e);

    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at List Patients: ", e);
    return [];
  }
}

export async function getPatientBasicDetails(data) {
  try {
    const response = await getQueryWithParams(LIST_PATIENT_RECORDS, data);
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "List of Patients fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listDoctorPatients?.data) {
        return response?.data?.listDoctorPatients?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at List Patients: ", e);
    return [];
  }
}
