import React, { Suspense, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import Eye from "src/assets/icons/Eye.svg";
import downloadIcon from "src/assets/download.svg";
import useInvoicePdf from "../Patient/PatientDetail/DownloadInvoice";
import PDFViewer from "../Layouts/PDFViewer";

const DownloadCellRenderer = (props) => {
  const { downloadInvoice, isInvoiceLoading, invoiceBase64, invoiceFileName, fetchInvoiceData } =
    useInvoicePdf(props.data.id);
  const [preview, setPreview] = React.useState(false);
  const [download, setDownload] = React.useState(false);

  useEffect(()=>{
    if(download && invoiceBase64){
      downloadInvoice();
      setDownload(false)
    }
  }
  ,[download, downloadInvoice, invoiceBase64])

  const previewInvoice = async () => {
    if(!invoiceBase64)
    await fetchInvoiceData();
    setPreview((prev) => !prev);
  };

  const downloadInvoicePDF = async() => {
    await fetchInvoiceData();
    setDownload(true)
  };


  return (
    <>
      <div className="docg-table-action">
        <Button
          onClick={downloadInvoicePDF}
          variant="link"
          title="Download"
          disabled={isInvoiceLoading}
        >
          <Image src={downloadIcon} alt="Patient Detail" />
        </Button>
        <Button
          onClick={previewInvoice}
          variant="link"
          title="Preview"
          disabled={isInvoiceLoading}
        >
          <Image src={Eye} alt="Patient" />
        </Button>
        <Suspense fallback={<div>Loading...</div>}>
          {preview && (
            <PDFViewer
              link={invoiceBase64}
              show={preview}
              setShow={setPreview}
              fileName={invoiceFileName}
            />
          )}
        </Suspense>
      </div>
    </>
  );
};

export default DownloadCellRenderer;
