import React, { Fragment } from "react";
import useQuery from "src/lib/useQuery";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import PatientSidebar from "./PatientSidebar";
import PastConsultation from "./Tabs/PastConsultation";

import PatientTabs from "./PatientTabs";
import Modal from "./PatientModal";
import {
  initialState,
  patientDetailsReducer,
} from "./store/PatientDetailsReducer";
import { StoreProvider } from "./store/Store";

const PatientDetail = (props) => {
  const query = useQuery();
  const history = useHistory();
  React.useEffect(async () => {
    let appointmentId = query.get("appointmentId");
    if (appointmentId == null) {
      history.goBack();
    }
  }, []);

  return (
    <Fragment>
      <StoreProvider
        initialState={initialState}
        reducer={patientDetailsReducer}
      >
        <Helmet>
          <title>Patient History | DOCG</title>
        </Helmet>
        <div className="patient-wrapper docg-vh-standard">
          <div className="">
            <PatientSidebar {...props} hideDiagnosis />
          </div>
          <div className="patient-tab">
            <PastConsultation {...props} />
          </div>
        </div>
      </StoreProvider>
    </Fragment>
  );
};

export default PatientDetail;
