import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  GET_TOTAL_BILLINGS,
  GET_TOTAL_GROWTH_BILLINGS,
  GET_NUMBER_OF_PATIENTS,
  GET_NUMBER_OF_PATIENTS_GROWTH,
  LIST_DOCTOR_REVIEWS,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";

export async function listDoctorReviews() {
  try {
    let response = await getQueryWithParams(LIST_DOCTOR_REVIEWS);
    if (response?.data?.listDoctorReviews?.totalCount >= 0) {
      return response.data.listDoctorReviews.totalCount;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Doctor Reviews.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return [];
  }
}

export async function getTotalBillingsDoctor() {
  try {
    const doctorId = sessionStorage.getItem("role-id");
    if (!doctorId) {
      throw new Error("Invalid doctor id");
    }
    let response = await getQueryWithParams(GET_TOTAL_BILLINGS, { doctorId });
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.getTotalBillingsOfDoctor.data) {
        return response.data.getTotalBillingsOfDoctor.data.totalBillings;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Total Billing Growth List.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return null;
  }
}
export async function getTotalBillingsGrowthOfDoctor(start) {
  try {
    const doctorId = sessionStorage.getItem("role-id");
    const data = { doctorId };
    if (!doctorId) {
      throw new Error("Invalid doctor id");
    }
    let response = await getQueryWithParams(
      GET_TOTAL_GROWTH_BILLINGS(start),
      data
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.getTotalBillingsGrowthOfDoctor.data) {
        return response.data.getTotalBillingsGrowthOfDoctor.data
          .totalBillingsGrowthOfDoctor;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Total Billing Growth List.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return null;
  }
}
export async function getNumberOfPatientsGrowth(start) {
  try {
    const doctorId = sessionStorage.getItem("role-id");
    const data = { doctorId };
    if (!doctorId) {
      throw new Error("Invalid doctor id");
    }
    let response = await getQueryWithParams(
      GET_NUMBER_OF_PATIENTS_GROWTH(start),
      data
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.getNumberOfPatientsGrowth.data) {
        return response.data.getNumberOfPatientsGrowth.data
          .numberOfPatientsGrowth;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Total Billing Growth List.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return null;
  }
}
export async function getNumberOfPatients() {
  try {
    const doctorId = sessionStorage.getItem("role-id");
    const data = { doctorId };
    if (!doctorId) {
      throw new Error("Invalid doctor id");
    }
    let response = await getQueryWithParams(GET_NUMBER_OF_PATIENTS, data);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.getNumberOfPatients.data) {
        return response.data.getNumberOfPatients.data.numberOfPatients;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Total Number of Patients.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return null;
  }
}
