export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const SELECTED_SCHEDULE = "SELECTED_SCHEDULE";

export const initialState = {
  schedules: [],
  selectedSchedule: null,
};
export const replaceSchedule = (schedule) => ({
  type: ADD_SCHEDULE,
  schedule,
});

export const selectedSchedule = (scheduleId) => ({
  type: SELECTED_SCHEDULE,
  selectedSchedule: scheduleId,
});

export const changeCurrentDay = (current) => ({
  type: "CHANGE_CURRENT_DAY",
  current,
});

export const scheduleReducer = (state, action, initialState) => {
  if (action.type === ADD_SCHEDULE) {
    return {
      ...state,
      schedules: action.schedule,
    };
  }
  if (action.type === SELECTED_SCHEDULE) {
    return {
      ...state,
      selectedSchedule: action.selectedSchedule,
    };
  }
  if (action.type === "CHANGE_CURRENT_DAY") {
    return {
      ...state,
      current: action.current,
    };
  }
};
