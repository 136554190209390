import React from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import defaultPatientIcon from "src/assets/defaultPatient.svg";

const PatientNameCellRenderer = (params) => {
  return (
    <>
      <Link
        to={`/patient-history/${params.data.metadata.patientId}?appointmentId=${params.data.metadata.appointmentId}&tab=labtest`}
      >
        <Image
          src={
            params.data.patientProfilePicture
              ? params.data.patientProfilePicture
              : defaultPatientIcon
          }
          alt=""
          roundedCircle
          style={{ width: 35, height: 35 }}
        />{" "}
        {params.data.patientName}
      </Link>
    </>
  );
};

export default PatientNameCellRenderer;
