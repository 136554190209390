import React, { Fragment, useState, useEffect, useRef } from "react";
import "../Reporting.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import ReportingModals from "../../../../shared/Modals/ReportingModals";
import arrowImg from "../../../../assets/purple.svg";
import { listAppointmentsYearByYear } from "../api";

const AppointmentsYear = (props) => {
  const chartRef = useRef(null);
  const [lgShow, setLgShow] = useState(false);

  React.useEffect(() => {
    am4core.ready(function () {
      // Themes begin
      am4core.useTheme(am4themes_dataviz);
      am4core.useTheme(am4themes_animated);
      am4core.addLicense("ch-custom-attribution");
      // Themes end

      // Create chart instance
      var chart = am4core.create(props?.divId, am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();
      // Add data
      chart.data = [];

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "year";
      categoryAxis.renderer.minGridDistance = 10;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minGridDistance = 15;

      // Create series
      var lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "value";
      lineSeries.dataFields.categoryX = "year";
      lineSeries.name = "Total Appointments";
      lineSeries.strokeWidth = 3;
      lineSeries.tooltipText = "{name}\n{categoryX}: {valueY}";

      // Add simple bullet
      var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
      bullet.circle.fill = am4core.color("#fff");
      bullet.circle.strokeWidth = 3;
      chartRef.current = chart;
    }); // end am4core.ready()
    // eslint-disable-next-line
  }, []);

  React.useEffect(async () => {
    const res = await listAppointmentsYearByYear();
    console.log(res);
    chartRef.current.data = res;
  }, []);

  return (
    <Fragment>
      <div
        onClick={(e) => {
          props?.check ? setLgShow(true) : e.preventDefault();
        }}
        className="d-flex align-items-center pl-4 pt-2"
      >
        <p className="review-txt"> Appointments week by week </p>
        <img src={arrowImg} alt="" className="arrow-img ml-2" />
      </div>
      <div id={props?.divId}></div>
      {setLgShow ? (
        <ReportingModals
          setLgShow={setLgShow}
          lgShow={lgShow}
          show={1}
          title={"Appointments year by year"}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default AppointmentsYear;
