import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { GET_PROFILE } from "src/components/graphql/GraphQLquery";
import { LIST_SPECIALIZATIONS } from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import { UPDATE_PROFILE } from "src/components/graphql/GraphQLmutation";

export async function listSpecializations(search) {
  console.log(search);
  try {
    const response = await getQueryWithParams(
      LIST_SPECIALIZATIONS(String(search))
    );
    if (typeof response == "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Doctor's specialisations fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listSpecializations?.data) {
        return response?.data?.listSpecializations?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Specialisations ", e);
    return null;
  }
}

export async function getMyProfile() {
  try {
    const profileData = sessionStorage.getItem("get-user-profile");
    if(profileData == null){

      const response = await getQueryWithParams(GET_PROFILE);
      if (typeof response === "undefined") {
        throw new Error("error", response);
      } else {
        if (
          response.data.getMyProfile.statusCode < 300 &&
          response.data.getMyProfile.statusCode >= 200
        ) {
          snackbar({
            message: "Profile Fetched Successfully.",
            color: "success",
          });
          if (response.data.getMyProfile.data.specialization)
            sessionStorage.setItem(
          "specialization",
          response.data.getMyProfile.data.specialization[0]
        );
        sessionStorage.setItem("get-user-profile", JSON.stringify(response.data.getMyProfile.data))
        return response.data.getMyProfile.data;
      } else {
        throw new Error(response);
      }
    }
  }
  else return JSON.parse(profileData)
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching Profile. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return null;
  }
}
export async function updateProfile(data) {
  try {
    const response = await postMutation(UPDATE_PROFILE, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.updateEmployee.statusCode < 300 &&
        response.data.updateEmployee.statusCode >= 200
      ) {
        snackbar({
          message: "Profile Fetched Successfully.",
          color: "success",
        });
        return response.data.updateEmployee.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while updating profile. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return null;
  }
}
