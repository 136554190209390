import { useFormik } from "formik";
import React, { Fragment, useState } from "react";
import { useStore } from "./store/Store";

import Loader from "src/components/Dashboard/common/Loader";

import { getQuery } from "src/components/ApolloConnection/ApolloProviderComponent";
import { USER_DATA } from "src/components/graphql/GraphQLquery";

import { Number } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Input, Select, snackbar } from "@mobiscroll/react5";
import axios from "axios";
import { capitalize } from "lodash";
import { Card } from "react-bootstrap";
import Checkbox from "react-custom-checkbox";
import { Helmet } from "react-helmet";
import FeeImage from "../../../assets/Fee_image.svg";
import save_image from "../../../assets/icons/save.svg";
import "../Layouts/Card.css";
import "./Fee.css";
import Feecard from "./Feecard";
import { addDoctorFee, getDoctorFees, getUploadPolicyLink } from "./api";
import { refreshFees } from "./store/FeeReducer";
import createFeeSchema from "./validationSchema/createFeeSchema";

const initialValues = {
  cancelationIn24Hours: false,
  cancellationAllowed: false,
  cashPaymentAccepted: false,
  fee: "",
  allowedFreeFollowUpVisits: "",
  followUpExpiry: 1,
  followUpExpiryUnits: "days",
  planName: "",
  planType: "",
  policy: "",
  reschedulingAllowed: 24,
  reschedulingUnits: "hours",
};

const initialTouched = {
  cancelationIn24Hours: true,
  cancellationAllowed: true,
  cashPaymentAccepted: true,
  fee: false,
  allowedFreeFollowUpVisits: false,
  followUpExpiry: true,
  followUpExpiryUnits: true,
  planName: false,
  planType: false,
  policy: false,
  reschedulingUnits: true,
};

function Fee() {
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    handleReset,
    isValid,
  } = useFormik({
    initialValues,
    initialTouched,
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
    validationSchema: createFeeSchema,
  });
  const { globalState, dispatch } = useStore();
  const [loader, setLoader] = React.useState(false);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("01");
  const [uploadFile, setUploadFile] = useState(undefined);
  const [uploadError, setUploadError] = useState("");

  const fetchData = async () => {
    setLoader(true);
    const res = await getDoctorFees();
    dispatch(refreshFees(res));
    setLoader(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onSubmitHandler = async (values) => {
    setLoader(true);
    if (values.policy) {
      try {
        const policy_name = await onSubmitUploadPolicy();
        if (policy_name) {
          await addDoctorFeeAfterUploadPolicy(policy_name, values);
        } else {
          snackbar({
            message: "Error while Uploading Fee Policy.",
            color: "danger",
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      await addDoctorFeeAfterUploadPolicy(undefined, values);
    }
    setLoader(false);
  };

  const addDoctorFeeAfterUploadPolicy = async (name, values) => {
    const doctorId = sessionStorage.getItem("role-id");
    let fee = values.fee;
    fee = fee.toFixed(2);
    values.fee = +fee;
    console.log(values);
    const res = await addDoctorFee({
      ...values,
      doctorId: doctorId,
      policy: name,
    });
    if (res) {
      // customChange(initialValues);
      setUploadFile(undefined);
      document.getElementById("uploadPolicy").value = null;
      await fetchData();
      const doctorVerified = sessionStorage.getItem("doctorVerified");
      handleReset();
      if (doctorVerified == "false") {
        let response = await getQuery(USER_DATA);
        sessionStorage.setItem(
          "doctorVerified",
          response.data.getUserInfo.data.doctorVerified
        );
        if (response.data.getUserInfo.data.doctorVerified) {
          snackbar({
            message: "Doctor is verified Successfully.",
            color: "success",
          });
        } else {
          snackbar({
            message:
              "Please add Signature from profile section for getting started.",
            color: "success",
          });
        }
      }
    }
  };

  const onSubmitUploadPolicy = async () => {
    const res = await getUploadPolicyLink({
      fileName: values.policy.name,
    });
    if (res !== null) {
      const _res = await axios.put(res.url, uploadFile, {
        headers: {
          "Content-Type": "application/pdf",
          "Access-Control-Allow-Origin": "*",
        },
      });
      return _res.status >= 200 && _res.status < 300 ? res.name : undefined;
    }
    return undefined;
  };

  const handleUploadPolicy = (e) => {
    console.log(e);
    setFieldValue("policy", e.target.files[0], true);
  };

  return (
    <Fragment>
      <Loader show={loader} />
      <Helmet>
        <title>Fee | DOCG</title>
      </Helmet>
      <Card className="docg-patient-form-wrapper docg-card h-standard mr-2 ml-2">
        <Card.Header
          className="d-flex justify-content-between cardHeaderUsed"
          // style={{
          //   height: "4rem",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   fontStyle: "ubuntu",
          //   fontSize: "18px",
          //   fontWeight: "normal",
          //   backgroundColor: "#dee2e6",
          // }}
        >
          <Card.Title className="cardTitleUsed" style={{ fontSize: "18px" }}>
            <img src={FeeImage} alt="" className="mr-2" /> Fee
          </Card.Title>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center p-3">
          {/* Fee Settings */}
          <Card className="docg-patient-form-wrapper docg-card">
            <Card.Body>
              <form className="pr-3 pl-3" onSubmit={handleSubmit} action="post">
                <div className="row">
                  <div className="col-6 col-lg-3">
                    <Input
                      label="Plan Name*"
                      name="planName"
                      labelStyle="floating"
                      placeholder=""
                      inputStyle="underline"
                      value={values.planName}
                      onChange={(e) =>
                        setFieldValue(
                          "planName",
                          capitalize(e.target.value),
                          true
                        )
                      }
                      onBlur={handleBlur}
                      error={touched.planName ? errors.planName : false}
                      errorMessage={touched.planName ? errors.planName : ""}
                    />
                  </div>
                  <div className="col-lg-3 col-6">
                    <Select
                      data={[
                        { text: "In person at clinic", value: "Face2Face" },
                        { text: "Online", value: "Online" },
                        { text: "Emergency", value: "Emergency" },
                      ]}
                      label="Plan Type*"
                      name="planType"
                      inputProps={{
                        inputStyle: "box",
                        labelStyle: "floating",
                        placeholder: "Please select",
                      }}
                      touchUi={false}
                      value={values.planType}
                      onChange={(e) => {
                        setFieldValue("planType", e.value, true);
                        ["Emergency", "Online"].includes(e.value) &&
                          setFieldValue("cashPaymentAccepted", false, true);
                      }}
                      onBlur={handleBlur}
                      error={touched.planType ? errors.planType : false}
                      errorMessage={touched.planType ? errors.planType : ""}
                    />
                  </div>
                  <div className="col-lg-3 col-6">
                    <Input
                      label="Set Fee*(Per Visit)"
                      placeholder=""
                      name="fee"
                      step="0.01"
                      type="number"
                      labelStyle="floating"
                      inputStyle="underline"
                      value={values.fee}
                      onChange={(e) => {
                        let num = +e.target.value;
                        setFieldValue("fee", num, true);
                      }}
                      onBlur={handleBlur}
                      error={touched.fee ? errors.fee : false}
                      errorMessage={touched.fee ? errors.fee : ""}
                    />
                  </div>
                  <div className="col-lg-3 col-6">
                    <Select
                      data={
                        ["Emergency", "Online"].includes(values.planType)
                          ? [{ text: "No", value: false }]
                          : [
                              { text: "Yes", value: true },
                              { text: "No", value: false },
                            ]
                      }
                      label="Offline Payment?*"
                      inputProps={{
                        inputStyle: "box",
                        labelStyle: "floating",
                        placeholder: "Please select",
                      }}
                      touchUi={false}
                      value={values.cashPaymentAccepted}
                      onChange={(e) => {
                        setFieldValue("cashPaymentAccepted", e.value, true);
                      }}
                      error={
                        touched.cashPaymentAccepted
                          ? errors.cashPaymentAccepted
                          : false
                      }
                      errorMessage={
                        touched.cashPaymentAccepted
                          ? errors.cashPaymentAccepted
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="row w-100 mt-3">
                  <div className="textarea-wrap col-12 mb-0">
                    <span>Cancellation/Rescheduling Policy*</span>
                    <div className="d-flex justify-content-start flex-row cancellation_reschedule_policy_div flex-wrap mbsc-row">
                      <div className="mbsc-col-6 mbsc-col-md-6 mbsc-col-lg-3">
                        <Select
                          data={[
                            { text: "Yes", value: true },
                            { text: "No", value: false },
                          ]}
                          label="Cancellation Allow?*"
                          inputProps={{
                            inputStyle: "box",
                            labelStyle: "floating",
                            placeholder: "Please select",
                          }}
                          touchUi={false}
                          value={values.cancellationAllowed}
                          onChange={(e) => {
                            setFieldValue("cancellationAllowed", e.value, true);
                            setFieldValue(
                              "cancelationIn24Hours",
                              e.value,
                              true
                            );
                          }}
                          error={
                            touched.cancellationAllowed
                              ? errors.cancellationAllowed
                              : false
                          }
                          errorMessage={
                            touched.cancellationAllowed
                              ? errors.cancellationAllowed
                              : ""
                          }
                        />
                      </div>
                      <div className="no-mobile mt-2 mbsc-col-6 mbsc-col-md-6 mbsc-col-lg-3">
                        <Checkbox
                          label="24 hours Cancellation"
                          checked={values.cancelationIn24Hours}
                          error={
                            touched.cancelationIn24Hours
                              ? errors.cancelationIn24Hours
                              : "false"
                          }
                          errorMessage={
                            touched.cancelationIn24Hours
                              ? errors.cancelationIn24Hours
                              : ""
                          }
                        />
                      </div>
                      <div className="mbsc-col-6 mbsc-col-md-6 mbsc-col-lg-3">
                        <Number
                          theme="ios"
                          themeVariant="light"
                          touchUi={false}
                          display="bubble"
                          layout="fixed"
                          units={["hours", "days"]}
                          step={1}
                          min={1}
                          max={24}
                          width={150}
                          label="Rescheduling Allowed"
                          placeholder=""
                          labelStyle="floating"
                          onBlur={handleBlur}
                          value={`${values.reschedulingAllowed} ${values.reschedulingUnits}`}
                          onChange={(e) => {
                            const split = e.valueText.split(" ");
                            setFieldValue(
                              "reschedulingAllowed",
                              +split[0],
                              true
                            );
                            setFieldValue("reschedulingUnits", split[1], true);
                          }}
                          error={
                            touched.reschedulingUnits
                              ? errors.reschedulingUnits
                              : false
                          }
                          errorMessage={
                            touched.reschedulingUnits
                              ? errors.reschedulingUnits
                              : ""
                          }
                        >
                          <Input
                            inputStyle="underline"
                            placeholder=""
                            labelStyle="floating"
                            label="Rescheduling Allowed"
                          />
                        </Number>
                      </div>
                      <div className="mbsc-col-6 mbsc-col-md-6 mbsc-col-lg-3">
                        <Input
                          type="file"
                          name="uploadPolicy"
                          id="uploadPolicy"
                          label="Upload Policy"
                          labelStyle="floating"
                          onChange={handleUploadPolicy}
                          file={values.policy}
                          error={true}
                          errorMessage={touched.policy ? errors.policy : ""}
                          inputStyle="underline"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row w-100 mt-3">
                  <div className="textarea-wrap col-12 mb-3">
                    <span>Follow Up Policy*</span>
                    <div className="mbsc-row">
                      <div className="d-flex justify-content-start flex-row flex-wrap mbsc-col-6 align-items-end">
                        <Input
                          name="allowedFreeFollowUpVisits"
                          type="number"
                          labelStyle="floating"
                          label="Free Follow Up*"
                          placeholder=""
                          inputStyle="underline"
                          value={values.allowedFreeFollowUpVisits}
                          onChange={(e) =>
                            setFieldValue(
                              "allowedFreeFollowUpVisits",
                              +e.target.value,
                              true
                            )
                          }
                          onBlur={handleBlur}
                          error={
                            touched.allowedFreeFollowUpVisits
                              ? errors.allowedFreeFollowUpVisits
                              : false
                          }
                          errorMessage={
                            touched.allowedFreeFollowUpVisits
                              ? errors.allowedFreeFollowUpVisits
                              : ""
                          }
                        />
                        <div> Days of last appointment</div>

                        <datalist id="type">
                          <option value="01" />
                          <option value="02" />
                        </datalist>
                      </div>
                      <div className="mbsc-col-6 mbsc-col-md-6 mbsc-col-lg-6">
                        <Number
                          theme="ios"
                          themeVariant="light"
                          touchUi={false}
                          display="bubble"
                          layout="fixed"
                          units={["hours", "days"]}
                          step={1}
                          min={1}
                          max={24}
                          width={150}
                          label="Follow up request expiry"
                          placeholder=""
                          labelStyle="floating"
                          value={`${values.followUpExpiry} ${values.followUpExpiryUnits}`}
                          onChange={(e) => {
                            const split = e.valueText.split(" ");
                            setFieldValue("followUpExpiry", +split[0], true);
                            setFieldValue(
                              "followUpExpiryUnits",
                              split[1],
                              true
                            );
                          }}
                          error={
                            touched.followUpExpiryUnits
                              ? errors.followUpExpiryUnits
                              : false
                          }
                          errorMessage={
                            touched.followUpExpiryUnits
                              ? errors.followUpExpiryUnits
                              : ""
                          }
                        >
                          <Input
                            inputStyle="underline"
                            placeholder=""
                            labelStyle="floating"
                            label="Follow up request expiry"
                          />
                        </Number>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <div
                    /* onClick={handleClick} */
                    className="d-flex justify-content-end pb-3"
                  >
                    <button type="submit" className="docg-save-btn">
                      <img className="save_icon" src={save_image} />{" "}
                      <span className="text-white ml-2 ">Save</span>
                    </button>
                  </div>
                </div>
              </form>
            </Card.Body>
            {/* Fee Plans */}
          </Card>
          <br />
          <Feecard />
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default Fee;
