import { gql } from "@apollo/client";

export const subscribeListTimeslotBeep = /* GraphQL */ gql`
  subscription subscribeListTimeslotBeep($doctorId: String!) {
    subscribeListTimeslotBeep(doctorId: $doctorId) {
      statusCode
      doctorId
    }
  }
`;
export const subscribeListAppointmentBeep = (id) => /* GraphQL */ gql`
  subscription subscribeListAppointmentBeep {
    subscribeListAppointmentBeep(doctorId: "${id}") {
      doctorId
      statusCode
    }
  }
`;

export const subscribeNotificationBeep = /* GraphQL */ gql`
  subscription subscribeNotificationBeep($identityId: String!) {
    subscribeNotificationBeep(identityId: $identityId) {
      identityId
      statusCode
    }
  }
`;
