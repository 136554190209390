import React from "react";
import { Modal } from "react-bootstrap";
import Notifications from "./NotificationTopBar";

const NotificationsWrapper = ({
  showNotifications = false,
  handleNotificationsView = null,
  title = "",
}) => {
  return (
    <Modal
      size="lg"
      show={showNotifications}
      onHide={handleNotificationsView}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
          <span className="ModalTitle">{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "82vh", overflow: "auto" }}>
        <Notifications closePopup={handleNotificationsView} />
      </Modal.Body>
    </Modal>
  );
};

export default NotificationsWrapper;
