import React, { Fragment, useState } from "react";
import invoice from "src/assets/invoice-purple.svg";
import { useHistory } from "react-router-dom";
import "./Invoice.scss";
import downloadIcon from "src/assets/download.svg";
import printIcon from "src/assets/print.svg";
import InvoiceMsgIcon from "src/assets/invoice-msg.svg";
import plus from "src/assets/icons/plusWhite.svg";
import sendIcon from "src/assets/send.svg";
import cancel from "src/assets/cancel.svg";
import { useForm, Controller } from "react-hook-form";
import mobiscroll from "@mobiscroll/react";
import { Input, Datepicker, Select } from "@mobiscroll/react5";
import createInvoiceSchema from "./validationSchema/createInvoiceSchema";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import Item from "./Item";

const initialItem = {
  invoiceTo: "",
};

function CreateInvoice(props) {
  const history = useHistory();
  const [listItem, setListItem] = useState([initialItem]);
  const addItem = () => {
    setListItem([...listItem, initialItem]);
  };

  const handleChangePage = () => {
    history.push("/invoice");
  };

  return (
    <Fragment>
      <Card className="docg-card docg-patient-form-wrapper docg-vh-standard">
        <Card.Title className="">
          <Row className="d-flex justify-content-between align-items-center w-100">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Image
                style={{
                  maxHeight: "1.75rem",
                  maxWidth: "1.75rem",
                  height: "1.75rem",
                  width: "auto",
                }}
                src={invoice}
                alt=""
                className="mr-2"
              />{" "}
              Create New Invoice
            </Col>

            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="d-flex justify-content-end align-items-center"
            >
              <img
                // onClick={window.print}
                src={printIcon}
                style={{
                  maxHeight: "1.75rem",
                  maxWidth: "1.75rem",
                  height: "1.75rem",
                  width: "auto",
                }}
                className="mr-3"
                alt=""
              />
              <img
                style={{
                  maxHeight: "1.75rem",
                  maxWidth: "1.75rem",
                  height: "1.75rem",
                  width: "auto",
                }}
                // onClick={savePdf}
                src={downloadIcon}
                className="mr-3"
                alt=""
              />
              <img
                style={{
                  maxHeight: "1.75rem",
                  maxWidth: "auto",
                  height: "1.75rem",
                  width: "auto",
                }}
                src={InvoiceMsgIcon}
                className="mr-3"
                alt=""
              />
            </Col>
          </Row>
        </Card.Title>
        <Card.Body className="docg-card-body p-0 docg-h-standard">
          <Row className="">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Invoice To*"
                name="invoiceTo"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Invoice Number"
                name="username"
              />
            </Col>
          </Row>
          <Row className="">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Address"
                name="invoiceTo"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Datepicker
                theme="ios"
                themeVariant="light"
                dateFormat="DD-MM-YYYY"
                className="pop-up-input"
                controls={["date"]}
                touchUi={true}
                display="bubble"
                label="Date"
                labelStyle="floating"
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="textarea-wrap">
                <span>Item</span>
                {listItem.map(() => (
                  <Item />
                ))}
              </div>
            </Col>
            <Row className="w-100">
              <Col xs={12} sm={5} md={7} lg={7} xl={8}>
                <button className="docg-save-btn" onClick={addItem}>
                  <img
                    style={{
                      maxHeight: "1rem",
                      maxWidth: "1rem",
                      width: "1rem",
                      height: "1rem",
                    }}
                    src={plus}
                    alt="df"
                  />{" "}
                  Add new Item
                </button>
              </Col>
              <Col
                className="d-flex justify-content-end flex-column"
                xs={12}
                sm={7}
                md={5}
                lg={5}
                xl={4}
              >
                <div className="d-flex align-items-center mb-2">
                  <p className="total-tax">Sub Total :</p>
                  <p className="total-amount-tax">&#8377; 1200</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="total-tax">Tax :</p>
                  <p className="total-amount-tax">2%</p>
                </div>
                <div className="d-flex align-items-center total-amount-wrapper">
                  <p className="total-tax text-white">Total :</p>
                  <p className="total-amount-tax text-white">&#8377; 1224</p>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-2 mt-4">
                  <button
                    onClick={handleChangePage}
                    className="docg-save-btn mr-3"
                  >
                    <img src={cancel} alt="" /> Back
                  </button>
                  <button className="docg-save-btn">
                    <img src={sendIcon} alt="" /> Save &amp; Send
                  </button>
                </div>
              </Col>
            </Row>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default CreateInvoice;
