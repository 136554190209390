import React, { Fragment, useState } from "react";

// import Waiting from "../../../assets/icons/Waiting.svg";
import "./Card.css";
import WaitingPatiens from "../../../shared/Modals/WaitingPatiens";
import "react-circular-progressbar/dist/styles.css";
import DashobardIcon from "../../../assets/icons/Untitled.png";
import DashobardIconRotate from "../../../assets/icons/Untitled(1).png";
import CardBody from "../../../shared/cardBody";
import CardExpanded from "../../../shared/KPI/CardExpanded";
import { Row, Col } from "react-bootstrap";
import CardTitle from "../../../shared/cardTitle";

const Card = ({ cards }) => {
  const [lgShow, setLgShow] = useState(false);
  const [toggle, setToggle] = useState(true);

  const toggleChange = async (e) => {
    setToggle(!e);
  };

  return (
    <Fragment>
      {/* <CardExpanded
        className={"card-title text-white add-radius text-center"}
        title="Title"
      /> */}
      {toggle ? (
        <>
          <Row className="d-flex w-100 justify-content-center align-content-center flex-row">
            {cards.map((item, index) => {
              return (
                <Col key={index} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CardExpanded item={item} />
                </Col>
              );
            })}
          </Row>
          <div className="toggle-button">
            <img
              onClick={() => toggleChange(toggle)}
              src={DashobardIconRotate}
              alt=""
              className="icon-size "
            />
          </div>
        </>
      ) : (
        <>
          <Row className="d-flex w-100 justify-content-center align-content-center flex-row">
            {cards.length > 0 &&
              cards.map((data, index) => (
                <Col xs={12} sm={6} md={6} lg={3} xl={3} key={index}>
                  <div className="card-header-show mr-3">
                    <CardTitle
                      className={"card-title text-white add-radius text-center"}
                      title={data.title}
                    />
                  </div>
                </Col>
              ))}
          </Row>
          <div className="toggle-button">
            <img
              onClick={() => toggleChange(toggle)}
              src={DashobardIcon}
              alt=""
              className="icon-size"
            />
          </div>
        </>
      )}

      {setLgShow && <WaitingPatiens setLgShow={setLgShow} lgShow={lgShow} />}
    </Fragment>
  );
};
export default Card;
