import React, { Fragment, useState } from "react";
import { getMyProfile } from "src/components/Dashboard/Profile/api";
import Avatar from "react-avatar";
import { NavLink } from "react-router-dom";
import Logo from "../shared/Logo";
import SwitchIcon from "src/assets/SwitchIcon.svg";
import LogoImage from "../assets/plus-logo.svg";
import AddIcon from "../assets/icons/plusWhite.svg";
import RemoveIcon from "../assets/icons/circle-minus.png";
import Server from "../assets/icons/Server.svg";
import RepotingIcon from "../assets/icons/RepotingIcon.svg";
import SettingIcon from "../assets/icons/SettingIcon.svg";
import calender from "../assets/icons/Calender.svg";
import schedule from "../assets/schedule.svg";
import feeImg from "../assets/Fee.svg";
import membershipImg from "../assets/membership-white.svg";
import historyIcon from "../assets/history.svg";
import symptomIcon from "../assets/Symptom.svg";
import invoice from "../assets/Invoice-white.svg";
import dashboard from "../assets/icons/DashboardIcon.svg";
import DashobardIcon from "src/assets/icons/Dashboard.svg";
import invitePatientIcon from "src/assets/invite-patient.svg";

const LeftSidebar = ({
  isSideBarVisible,
  side,
  Patient,
  handleSidebar,
  setIsSideBarVisible,
}) => {
  const [ShowDropDown, setShowDropDown] = useState(false);
  const [ShowSettingDrop, setShowSettingDrop] = useState(false);
  const [isPatientHistory, setIsPatientHistory] = useState(false);
  const [user, setUser] = useState({ name: "-", profilePicture: null });
  React.useEffect(async () => {
    const res = await getMyProfile();
    sessionStorage.setItem("clinicName", res?.clinicName);
    let name = [res.title, res.firstName, res.middleName, res.lastName]
      .filter((item) => item != null)
      .join(" ");
    setUser({ profilePicture: res.profilePicture, name: name });

    if (side) {
      setShowSettingDrop(side);
    }
    if (Patient) {
      setShowDropDown(Patient);
    }
    // eslint-disable-next-line
  }, []);
  const SideBarVisible = () => {
    if (isSideBarVisible) {
      return;
    }
  };

  return (
    <Fragment>
      {isSideBarVisible === true ? (
        <>
          <div
            onClick={() => {
              console.log("clicked");
              setIsSideBarVisible(false);
            }}
            className="sidebar-backdrop"
          ></div>
          <div className="sidebar" id="sidebar-docg" data-id={isSideBarVisible}>
            <button
              type="button"
              className="btn btn-link mr-1 bg-white d-block d-xl-none"
              style={{
                width: "fit-content",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={handleSidebar}
            >
              <img src={DashobardIcon} alt="" />
            </button>
            <div className="sidebar-logged d-flex justify-content-center align-content-center flex-wrap mt-4 pb-4">
              <Avatar
                color="#c06c84"
                round
                size="4.5rem"
                name={user.name}
                src={user?.profilePicture}
              />
              <h3 className="text-center text-white mt-3 DoctorName">
                {user?.name}
              </h3>
            </div>
            <div className="sidebar-wrapper">
              <ul className="nav">
                <li className="nav-item">
                  <NavLink
                    to="/dashboard"
                    isActive={(match, location) => {
                      if (!match) {
                        console.log(location.pathname);
                        if (location.pathname.includes("/patient-history")) {
                          setIsPatientHistory(true);
                        } else {
                          setIsPatientHistory(false);
                        }
                        if (
                          [
                            "/patient-record",
                            "/add-patient",
                            "/patient-history",
                            "/patient-list",
                          ].includes(location.pathname) ||
                          location.pathname.includes("/patient-record")
                        ) {
                          setShowDropDown(true);
                        } else if (
                          [
                            "/profile",
                            "/invoice",
                            "/create-invoice",
                            "/fee",
                          ].includes(location.pathname) ||
                          location.pathname.includes("/invoice-details")
                        ) {
                          setShowSettingDrop(true);
                        }
                        return false;
                      }
                      return true;
                    }}
                    exact
                    className="nav-link navLink "
                  >
                    <img className="addIcon" src={dashboard} alt="" />
                    <p className="">Dashboard</p>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/schedule" className="nav-link">
                    <img className="addIcon" src={schedule} alt="" />
                    <p>Schedule</p>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/calendar" className="nav-link">
                    <img className="addIcon" src={calender} alt="" />
                    <p>Calendar</p>
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    setShowDropDown((preMode) => !preMode);
                    SideBarVisible();
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <a className="nav-link d-flex">
                    <img className="addIcon" src={Server} alt="" />
                    <p className="">Patient records</p>
                    {ShowDropDown ? (
                      <div className="addIconWrap">
                        <img
                          onClick={() => setShowDropDown(true)}
                          style={{ cursor: "grab" }}
                          className="addIcon"
                          src={RemoveIcon}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="addIconWrap">
                        <img
                          onClick={() => setShowDropDown(false)}
                          style={{ cursor: "grab" }}
                          className="addIcon "
                          src={AddIcon}
                          alt=""
                        />
                      </div>
                    )}
                  </a>
                </li>
                {ShowDropDown && (
                  <>
                    <div
                      className="dropdown-menu-container"
                      id="patient-records-dropdown"
                    >
                      <li className="nav-item dropdownLi">
                        <NavLink
                          to="/patient-list"
                          isActive={(match, location) => {
                            if (!match) {
                              if (
                                ["/patient-record", "/patient-list"].includes(
                                  location.pathname
                                ) ||
                                location.pathname.includes("/patient-record")
                              ) {
                                return true;
                              }
                              return false;
                            }

                            // only consider an event active if its event id is an odd number
                            setShowDropDown(true);
                            return true;
                          }}
                          className="nav-link"
                        >
                          <i className="fa fa-users text-white"></i>
                          <p className="mr-2">Patient List</p>
                        </NavLink>
                      </li>
                      {/* )} */}
                      <li className="nav-item dropdownLi">
                        <NavLink to="/add-patient" className="nav-link">
                          <i className="fa fa-user-plus text-white"></i>
                          <p className="mr-2">Add Patient</p>
                        </NavLink>
                      </li>
                      {isPatientHistory && (
                        <li className="nav-item dropdownLi">
                          <NavLink to="/patient-history" className="nav-link">
                            <img src={historyIcon} alt="" />
                            <p className="mr-2">History</p>
                          </NavLink>
                        </li>
                      )}
                    </div>
                  </>
                )}
                <li className="nav-item ">
                  {/* eslint-disable-next-line */}
                  <NavLink className="nav-link" to="/reporting">
                    <img className="addIcon" src={RepotingIcon} alt="" />
                    <p>Reporting</p>
                  </NavLink>
                </li>

                {/* Setting */}

                <li
                  className="nav-item "
                  onClick={() => {
                    setShowSettingDrop((preMode) => !preMode);
                    SideBarVisible();
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <a className="nav-link d-flex">
                    <img className="addIcon" src={SettingIcon} alt="" />
                    <p className="">Settings</p>
                    {ShowSettingDrop ? (
                      <div className="addIconWrap">
                        <img
                          style={{ cursor: "grab" }}
                          className="addIcon StISpace"
                          src={RemoveIcon}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="addIconWrap">
                        <img
                          style={{ cursor: "grab" }}
                          className="addIcon StISpace"
                          src={AddIcon}
                          alt=""
                        />
                      </div>
                    )}
                  </a>
                </li>
                {ShowSettingDrop && (
                  <>
                    <div
                      className="dropdown-menu-container"
                      id="patient-records-dropdown"
                    >
                      <li className="nav-item dropdownLi">
                        <NavLink
                          to="/invoice"
                          isActive={(match, location) => {
                            if (
                              [
                                "/create-invoice",
                                "/invoice",
                                "/invoice-details",
                              ].includes(location.pathname) ||
                              location.pathname.includes("/invoice-details")
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          className="nav-link"
                        >
                          <img src={invoice} alt="" />
                          <p className="mr-2">Invoice</p>
                        </NavLink>
                      </li>

                      <li className="nav-item dropdownLi">
                        <NavLink to="/profile" className="nav-link">
                          <i className="fa fa-user text-white"></i>
                          <p className="mr-2">Profile</p>
                        </NavLink>
                      </li>

                      <li className="nav-item dropdownLi">
                        <NavLink to="/fee" className="nav-link">
                          <img src={feeImg} alt="" />
                          <p className="mr-2">Fee</p>
                        </NavLink>
                      </li>
                      <li className="nav-item dropdownLi">
                        <NavLink
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Invite Patient(s)"
                          to="/invite-patient"
                          className="nav-link"
                        >
                          <img src={invitePatientIcon} alt="" />
                          <p className="mr-2">Invite Patient(s)</p>
                        </NavLink>
                      </li>
                      <li className="nav-item dropdownLi">
                        <a
                          href={process.env.REACT_APP_CLINIC_URL}
                          target="_blank"
                          className="nav-link"
                        >
                          <img src={SwitchIcon} alt="" />
                          <p className="mr-2">Switch to Clinic</p>
                        </a>
                      </li>
                    </div>
                  </>
                )}
              </ul>
            </div>
            <div
              className="sidebar-footer d-flex align-items-center justify-content-center flex-column"
              style={{ marginBottom: "0" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="d-flex justify-content-center flex-column">
                  <h3 className="m-0">Powered by: </h3>
                  <span
                    style={{
                      color: "#fff",
                      border: "2px solid #fff",
                      borderRadius: "20px",
                      textAlign: "center",
                    }}
                  >
                    BETA
                  </span>
                </div>
                <img style={{ width: "4rem" }} src={LogoImage} alt="" />{" "}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          id="sidebar-docg"
          data-id={isSideBarVisible}
          className="sidebar width-decrease-sidebar docg-hide"
        >
          <div className="d-flex justify-content-center align-content-center flex-wrap mt-4 pb-4 add-border-docname">
            <Avatar
              color="#c06c84"
              round
              size="4.5rem"
              name={user.name}
              src={user?.profilePicture}
            />
          </div>
          <div className="sidebar-wrapper width-decrease-sidebar">
            <ul className="nav">
              <li className="nav-item def-div-content ">
                <NavLink
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Dashboard"
                  to="/dashboard"
                  isActive={(match, location) => {
                    if (!match) {
                      if (
                        [
                          "/patient-record",
                          "/add-patient",
                          "/patient-history",
                          "/patient-list",
                        ].includes(location.pathname)
                      ) {
                        setShowDropDown(true);
                      } else if (
                        [
                          "/profile",
                          "/invoice",
                          "/create-invoice",
                          "/fee",
                        ].includes(location.pathname) ||
                        location.pathname.includes("/invoice-details")
                      ) {
                        setShowSettingDrop(true);
                      }
                      return false;
                    }
                    return true;
                  }}
                  exact
                  className="nav-link navLink "
                >
                  <img className="addIcon" src={dashboard} alt="" />
                </NavLink>
              </li>
              <li className="nav-item  def-div-content ">
                <NavLink
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Schedule"
                  to="/schedule"
                  className="nav-link pl-0"
                >
                  <img className="addIcon" src={schedule} alt="" />
                </NavLink>
              </li>
              <li className="nav-item  def-div-content ">
                <NavLink
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Calendar"
                  to="/calendar"
                  className="nav-link pl-0"
                >
                  <img className="addIcon" src={calender} alt="" />
                </NavLink>
              </li>
              <li
                className="nav-item def-div-content position-relative"
                onClick={() => {
                  setShowDropDown((preMode) => !preMode);
                  SideBarVisible();
                }}
              >
                {/* eslint-disable-next-line */}
                <a
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Patient Records"
                  className="nav-link d-flex pl-0"
                >
                  <img className="addIcon" src={Server} alt="" />

                  {ShowDropDown ? (
                    <img
                      onClick={() => setShowDropDown(true)}
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon"
                      src={RemoveIcon}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={() => setShowDropDown(false)}
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon "
                      src={AddIcon}
                      alt=""
                    />
                  )}
                </a>
              </li>
              {ShowDropDown && (
                <>
                  <div
                    className="dropdown-menu-container"
                    id="patient-records-dropdown"
                  >
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Patient List"
                        to="/patient-list"
                        isActive={(match, location) => {
                          if (!match) {
                            if (
                              ["/patient-record", "/patient-list"].includes(
                                location.pathname
                              )
                            ) {
                              return true;
                            }
                            return false;
                          }

                          // only consider an event active if its event id is an odd number
                          setShowDropDown(true);
                          return true;
                        }}
                        className="nav-link"
                      >
                        <i className="fa fa-users text-white"></i>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Add Patient"
                        to="/add-patient"
                        className="nav-link"
                      >
                        <i className="fa fa-user-plus text-white"></i>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="History"
                        to="/patient-history"
                        className="nav-link"
                      >
                        <img src={historyIcon} alt="" />
                      </NavLink>
                    </li>
                  </div>
                </>
              )}
              <li className="nav-item def-div-content">
                {/* eslint-disable-next-line */}
                <NavLink
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Reporting"
                  className="nav-link pl-0"
                  to="/reporting"
                >
                  <img className="addIcon" src={RepotingIcon} alt="" />
                </NavLink>
              </li>
              <li
                className="nav-item def-div-content position-relative"
                onClick={() => {
                  setShowSettingDrop((preMode) => !preMode);
                  SideBarVisible();
                }}
              >
                {/* eslint-disable-next-line */}
                <a
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Setting"
                  className="nav-link d-flex pl-0"
                >
                  <img className="addIcon" src={SettingIcon} alt="" />
                  {ShowSettingDrop ? (
                    <img
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon"
                      src={RemoveIcon}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon"
                      src={AddIcon}
                      alt=""
                    />
                  )}
                </a>
              </li>
              {ShowSettingDrop && (
                <>
                  <div
                    className="dropdown-menu-container"
                    id="patient-records-dropdown"
                  >
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Invoice"
                        to="/invoice"
                        isActive={(match, location) => {
                          if (
                            [
                              "/create-invoice",
                              "/invoice",
                              "/invoice-details",
                            ].includes(location.pathname) ||
                            location.pathname.includes("/invoice-details")
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        className="nav-link"
                      >
                        <img src={invoice} alt="" />
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Profile"
                        to="/profile"
                        className="nav-link"
                      >
                        <i className="fa fa-user text-white"></i>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Fee"
                        to="/fee"
                        className="nav-link"
                      >
                        <img src={feeImg} alt="" />
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Invite Patient(s)"
                        to="/invite-patient"
                        className="nav-link"
                      >
                        <img src={invitePatientIcon} alt="" />
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Login to Clinic"
                        to="https://develop-fullstack.d1rxubliy514ic.amplifyapp.com/"
                        className="nav-link"
                      >
                        <img src={SwitchIcon} alt="" />
                      </NavLink>
                    </li>
                  </div>
                </>
              )}
            </ul>
          </div>
          <div
            style={{ writingMode: "tb-rl", transform: "rotate(180deg)" }}
            className="sidebar-footer d-flex align-items-center justify-content-center flex-column text-center"
          >
            <h3>Powered by: </h3>
            <img
              style={{ width: "4rem", transform: "rotate(-180deg)" }}
              src={LogoImage}
              alt=""
            />{" "}
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default LeftSidebar;
