import * as Yup from "yup";

const createFeeSchema = Yup.object().shape({
  cancelationIn24Hours: Yup.boolean().required("This field is required"),
  cancellationAllowed: Yup.boolean().required("This field is required"),
  cashPaymentAccepted: Yup.boolean().required("This field is required"),
  fee: Yup.number().required("This field is required"),
  followUpExpiry: Yup.number().required("This field is required"),
  followUpExpiryUnits: Yup.string().required("This field is required"),
  planName: Yup.string().required("This field is required"),
  planType: Yup.string().required("This field is required"),
  reschedulingAllowed: Yup.number().required("This field is required"),
  reschedulingUnits: Yup.string().required("This field is required"),
  allowedFreeFollowUpVisits: Yup.number().required("This field is required"),
});

export default createFeeSchema;
