import React, { Fragment, useEffect, useState } from "react";
import mobiscroll from "@mobiscroll/react";
import { Col, Card, Row, Image } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Helmet } from "react-helmet";
import EditSvg from "src/assets/icons/PencilIcon.svg";

export default function AddAccount() {
  const [gridApi, setGridApi] = React.useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [displayRecords, setDisplayRecords] = React.useState("03");

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const defaultColGroupDef = {};
  const [rowData, setRowDate] = useState([
    {
      firstName: "Vishnu",
      lastName: "Prasanna",
      mobile: "+91900000000",
      email: "vishnuWW@gmail.com",
      status: "Invitation Accepted",
    },
    {
      firstName: "Vishnu",
      lastName: "Prasanna",
      mobile: "+91900000000",
      email: "vishnuWW@gmail.com",
      status: "Invitation Pending",
    },
    {
      firstName: "Vishnu",
      lastName: "Prasanna",
      mobile: "+91900000000",
      email: "vishnuWW@gmail.com",
      status: "Invitation Declined",
    },
    {
      firstName: "Vishnu",
      lastName: "Prasanna",
      mobile: "+91900000000",
      email: "vishnuWW@gmail.com",
      status: "Invitation Accepted",
    },
  ]);

  const iconRender = () => {
    return <img src={EditSvg} alt="edit" />;
  };

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  //   setGridColumnApi(params.columnApi);
  // };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  const onPageSizeChanged = (e) => {
    setDisplayRecords(e.valueText);
    gridApi.paginationSetPageSize(Number(e.valueText));
  };

  return (
    <>
      <Fragment>
        <Helmet>
          <title>Calendar | DOCG</title>
        </Helmet>
        <div className="records-display">
          <span>Showing&nbsp;</span>
          <label className="record-selection-dropdown" htmlFor="">
            <mobiscroll.Select
              touchUi={false}
              onChange={(e) => {
                onPageSizeChanged(e);
              }}
              value={displayRecords}
            >
              {Array.from(Array(rowData.length).keys()).map((index) => {
                let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
                return <option value={value}>{value}</option>;
              })}
            </mobiscroll.Select>
            <i className="fas fa-chevron-down"></i>
          </label>
          <span>&nbsp;Records</span>
        </div>
        <Row className="d-flex justify-content-between align-items-center">
          <Col
            xs={12}
            className="d-flex justify-content-end align-items-center pt-2"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                background: "#c06c84",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  marginLeft: "3px",
                  marginTop: "1.8px",
                  borderTopRightRadius: "0 !important",
                  borderBottomRightRadius: "0 !important",
                }}
                className="docg-table-search"
              >
                <input
                  style={{
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    marginTop: "1.5px",
                  }}
                  type="text"
                  onInput={() => onQuickFilterChanged()}
                  id="quickFilter"
                  placeholder="Search..."
                />
              </div>
              <div style={{ marginTop: "9px", marginRight: "4px" }}>
                <span
                  style={{ color: "white" }}
                  className="material-icons pr-1"
                >
                  search
                </span>{" "}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div
              className="ag-theme-alpine mt-2"
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <AgGridReact
                frameworkComponents={{
                  iconRender: iconRender,
                }}
                rowData={rowData}
                defaultColDef={defaultColDef}
                defaultColGroupDef={defaultColGroupDef}
                enableRangeSelection={true}
                pagination={true}
                onGridReady={onGridReady}
                domLayout={"autoHeight"}
                animateRows={true}
              >
                <AgGridColumn
                  headerName="Account Holder Name"
                  headerTooltip="Account Holder Name"
                  field="firstName"
                  tooltipField="firstName"
                  initialPinned="left"
                  initialSort="asc"
                ></AgGridColumn>

                <AgGridColumn
                  headerName="Mobile"
                  headerTooltip="Mobile"
                  field="mobile"
                  tooltipField="mobile"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Email"
                  headerTooltip="Email"
                  field="email"
                  tooltipField="email"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Status"
                  headerTooltip="Status"
                  field="status"
                  tooltipField="status"
                ></AgGridColumn>
                <AgGridColumn
                  headerName=""
                  cellRenderer="iconRender"
                  field="icon"
                ></AgGridColumn>
              </AgGridReact>
            </div>
          </Col>
        </Row>
      </Fragment>
    </>
  );
}
