import React from "react";
import { Input, Datepicker, Select } from "@mobiscroll/react5";
import { Row, Col, Card, Button, Image } from "react-bootstrap";

const Item = () => {
  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Row>
            <Col xs={3} sm={3} md={2} lg={3} xl={3}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="S No."
                name="username"
              />
            </Col>
            <Col xs={9} sm={9} md={10} lg={9} xl={9}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Description"
                name="username"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Qty.*"
                name="username"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Total Amount"
                name="username"
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Select
                data={[
                  { text: "Offline", value: "Offline" },
                  { text: "Online", value: "Online" },
                ]}
                label="Payment Mode "
                inputProps={{
                  inputStyle: "box",
                  labelStyle: "stacked",
                  placeholder: "Please select",
                }}
                touchUi={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default Item;
