import React, { Fragment, useState } from "react";
import { FormGroup, Input, Listview, Button } from "@mobiscroll/react";
import Symptoms from "src/assets/icons/Symptom.svg";
import { Card, Col } from "react-bootstrap";

import mobiscroll from "@mobiscroll/react";

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
let img_fm = [
  ".tif",
  ".tiff",
  ".jpg",
  ".jpeg",
  ".gif",
  ".png",
  ".eps",
  ".raw",
  ".cr2",
  ".nef",
  ".orf",
  ".sr2",
  ".bmp",
  ".ppm",
  ".heif",
];
let vid_fm = [
  ".flv",
  ".avi",
  ".mp4",
  ".3gp",
  ".mov",
  ".webm",
  ".ogg",
  ".qt",
  ".avchd",
];
let aud_fm = [".flac", ".mp3", ".wav", ".wma", ".aac"];
const AvatarItem = ({ item }) => {
  const { question, textDescription } = item;
  const [videoDescription, setVideoDescription] = useState(null);
  const [imgDescription, setImgDescription] = useState(null);
  const [audioDescription, setAudioDescription] = useState(null);
  const [pdfDescription, setPDFDescription] = useState(null);

  React.useEffect(() => {
    if (item.mediaDescription.length) {
      item.mediaDescription.map((media) => {
        console.log(media);
        const getFileExtention = (fileUrl) => {
          // To get the file extension
          return /[.]/.exec(fileUrl)
            ? /\.\w{3,4}($|\?)/.exec(fileUrl)
            : undefined;
        };
        let file_ext = getFileExtention(media);
        if (file_ext != null) {
          file_ext = file_ext[0];
          file_ext = file_ext.split("?")[0];
        }
        // file_ext = file_ext.split("?")[0];
        if (img_fm.filter((ext) => ext == file_ext).length > 0) {
          setImgDescription(media);
        } else if (vid_fm.filter((ext) => ext == file_ext).length > 0) {
          setVideoDescription(media);
        } else if (aud_fm.filter((ext) => ext == file_ext).length > 0) {
          setAudioDescription(media);
        } else {
          setPDFDescription(media);
        }
        console.log(file_ext);
      });
    }
  }, []);

  return (
    <>
      <div className="card-1">
        <li className="item-left">
          {/* <img src={Doctor} /> */}
          <h3>
            <span>Q.</span>&nbsp; Which Symptoms are bothering you the most?
          </h3>
        </li>
        <li className="item-right">
          <h3>
            <span>A.</span>&nbsp;
            {textDescription}
            <br />
            <br />
            <b>
              {videoDescription ||
              audioDescription ||
              imgDescription ||
              pdfDescription
                ? "Find the below Attachments: "
                : ""}
            </b>
            <br /> <br />{" "}
            {videoDescription && (
              <audio width="320" height="240" controls>
                <source src={videoDescription} />
                Your browser does not support the video tag.
              </audio>
            )}
            <br />
            {audioDescription && (
              <audio width="320" height="240" controls>
                <source src={audioDescription} />
                Your browser does not support the video tag.
              </audio>
            )}
            <br />
            {imgDescription && (
              <img
                className="w-3"
                style={{ maxWidth: "100px" }}
                src={imgDescription}
                alt=""
              />
            )}
            <br />
            {pdfDescription && (
              <a target="_blank" href={pdfDescription}>
                Pdf
              </a>
            )}
          </h3>
        </li>
      </div>
    </>
  );
};

const Symptom = ({ data, open }) => {
  console.log(data);
  return (
    <>
      <div className="mt-2">
        <Listview
          itemType={AvatarItem}
          data={data}
          className="docg-message-list"
          enhance={true}
          actionable={false}
          swipe={false}
          sortable={true}
        />
      </div>
    </>
  );
};

const SymptomTab = ({ data = [] }) => {
  return (
    <Card className="docg-patient-form-wrapper docg-card mt-4 mb-4">
      {/* <Card.Title>
        <div className="w-100 d-flex align-items-center justify-content-between">
          <h4 className="medical-record-title m-0">
            <img src={Symptoms} className="mr-2" alt="" />
            Symptoms
          </h4>
        </div>
      </Card.Title> */}
      <Card.Body className="pl-2 pr-2">
        <Symptom data={data} />
      </Card.Body>
    </Card>
  );
};

export default SymptomTab;
