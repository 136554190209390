import React from "react";
import { Modal, Card } from "react-bootstrap";
import close from "../../../assets/close.svg";

const ScheduleHandler = (props) => {
  const { isOpen, closeModal, deleteRecurring, deleteCurrent } = props;
  return (
    <Modal
      size="md"
      show={isOpen}
      onHide={closeModal}
      dialogClassName="docg-modal"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Card className="docg-patient-form-wrapper docg-card m-0">
        <Card.Title className="">
          <div className="d-flex justify-content-center align-items-center flex-direction-row w-100">
            {" "}
          </div>
          <img
            className="justify-self-end"
            style={{ cursor: "pointer" }}
            onClick={closeModal}
            src={close}
            alt="sd"
          />
          {/* <img src={deleteIcon} alt="" /> */}
        </Card.Title>
        <Card.Body className="p-3 m-0">
          <div className="d-flex flex-column align-items-center docg-delete-recurring">
            <div className="mt-2 title">
              Do you want to delete the Recurring?
            </div>
            <div className="d-flex align-items-center mt-4 mb-4">
              <button
                onClick={deleteRecurring}
                className="docg-save-btn ml-2 mr-2"
              >
                Yes
              </button>
              <button
                onClick={deleteCurrent}
                className="docg-save-btn ml-2 mr-2"
              >
                No
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default ScheduleHandler;
