export const REFRESH_SLOTS = "REFRESH_SLOTS";
export const UPDATE_CONSULTATION = "UPDATE_CONSULTATION";

export const initialState = {
  timeSlots: [],
  current: null,
  consultationType: [],
  cashAccepted: false,
  emergency: false,
};
export const refreshSlots = (timeSlots) => ({
  type: REFRESH_SLOTS,
  timeSlots,
});

export const changeCurrentDay = (current) => ({
  type: "CHANGE_CURRENT_DAY",
  current,
});
export const updateConsultationType = (current, cashAccepted) => {
  let arr = Object.keys(current)
    .filter((type) => current[type] && type !== "Emergency")
    .map((type) => ({
      text: type === "Face2Face" ? "In person at clinic" : type,
      value: type,
    }));
  console.log(arr);
  return {
    type: UPDATE_CONSULTATION,
    payload: {
      consultationType: arr,
      cashAccepted,
      emergency: current.Emergency,
    },
  };
};

export const timeSlotsReducer = (state, action, initialState) => {
  console.log(action);
  switch (action.type) {
    case REFRESH_SLOTS:
      return {
        ...state,
        timeSlots: action.timeSlots,
      };
    case UPDATE_CONSULTATION:
      return {
        ...state,
        ...action.payload,
      };
    case "CHANGE_CURRENT_DAY":
      return {
        ...state,
        current: action.current,
      };

    default:
      return state;
  }
};
