import React from "react";
import { userInitialState, userReducer } from "./userReducer";
import {
  notificationInitialState,
  notificationsReducer,
} from "./notificationReducer";
import { kpiInitialState, kpiReducer } from "./kpiReducer";
const Store = React.createContext();
Store.displayName = "Store";

export const useStore = () => React.useContext(Store);

export const StoreProvider = ({ children }) => {
  //Complete User Data
  const [globalStateUser, dispatchUser] = React.useReducer(
    userReducer,
    userInitialState
  );
  const [globalStateNotifications, dispatchNotifications] = React.useReducer(
    notificationsReducer,
    notificationInitialState
  );
  const [globalStateKPI, dispatchKPI] = React.useReducer(
    kpiReducer,
    kpiInitialState
  );

  return (
    <Store.Provider
      value={{
        globalStateUser,
        dispatchUser,
        globalStateNotifications,
        dispatchNotifications,
        globalStateKPI,
        dispatchKPI,
      }}
    >
      {children}
    </Store.Provider>
  );
};
