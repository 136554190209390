import React, { Suspense } from "react";
import { Button, Image } from "react-bootstrap";
import Clock from "src/assets/icons/Clock.svg";
import RedCross from "src/assets/Red-cross.svg";
import { Dropdown, Modal } from "react-bootstrap";
import "./Appointments.scss";

const StatusBookedModalReschedule = React.lazy(() =>
  import("./RescheduleAppointment/statusBooked/StatusBookedModal")
);

const StatusBookedModal = React.lazy(() =>
  import("./canelAppointment/StatusBookedModal")
);

const ActionCellRender = (props) => {
  const [reschedulePopup, setReschedulePopup] = React.useState(false);
  const [cancelPopup, setCancelPopup] = React.useState(false);

  const toggleRescheduleAppointment = React.useCallback(() => {
    setReschedulePopup(!reschedulePopup);
  }, [reschedulePopup]);

  const toggleCancelAppointment = React.useCallback(() => {
    setCancelPopup(!cancelPopup);
  }, [cancelPopup]);

  return (
    <>
      <div className="docg-table-action">
        <Button
          onClick={toggleRescheduleAppointment}
          variant="link"
          title="Reschedule Appointment"
        >
          <Image src={Clock} alt="Patient Detail" />
        </Button>
        <Button
          onClick={toggleCancelAppointment}
          variant="link"
           title="Cancel Appointment"
        >
          <Image src={RedCross} alt="Patient" />
        </Button>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <StatusBookedModalReschedule
          closePopup={() => setReschedulePopup(false)}
          data={props.data}
          isOpen={reschedulePopup}
        />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <StatusBookedModal
          closePopup={() => setCancelPopup(false)}
          data={props.data}
          isOpen={cancelPopup}
        />
      </Suspense>
    </>
  );
};

export default ActionCellRender;
