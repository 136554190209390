import React from "react";
import CalendarContent from "./CalendarContent";
import { Helmet } from "react-helmet";
import { StoreProvider } from "./store/Store";
import { initialState, timeSlotsReducer } from "./store/CalendarReducer";

const Schedule = () => {
  return (
    <>
      <Helmet>
        <title>Doctor Calendar | DOCG</title>
      </Helmet>
      <StoreProvider initialState={initialState} reducer={timeSlotsReducer}>
        <div className="calendar-docg w-100">
          <CalendarContent />
        </div>
      </StoreProvider>
    </>
  );
};

export default Schedule;
