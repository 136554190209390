import React, { Fragment } from "react";
import Appointment from "../../../assets/icons/Appointment.svg";
import "../Layouts/Card.css";
import MessageIcon from "../../../assets/icons/Message.svg";
import backIcon from "../../../assets/back.svg";
import draftIcon from "../../../assets/draft.svg";
import informationIcon from "../../../assets/information.svg";
import deleteIcon from "../../../assets/delete.svg";
import folderIcon from "../../../assets/folder.svg";
import tagIcon from "../../../assets/tag.svg";
import starblackIcon from "../../../assets/star-black-outline.svg";
import staryellowIcon from "../../../assets/star-yellow-color.svg";
import attachmentIcon from "../../../assets/attachement.svg";
import crossIcon from "../../../assets/cross.svg";
import previousIcon from "../../../assets/pagination-previous.svg";
import nextIcon from "../../../assets/pagination-next.svg";
import "./Setting.css";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import DownArrow from "../../../assets/icons/DownArrow.svg";

function Setting() {
  return (
    <Fragment>
      <div className="wrapper d-none">
        <div className="main-panel panel-wrapper">
          <div className="icon-with-txt-div">
            <img src={MessageIcon} alt="" className="icon-size" />
            <p className="email-txt">Mail</p>
            <img src={crossIcon} alt="" className="icon-size add-position" />
          </div>
          <div className="content add-flex-wrapper mr-0 min-height-fix">
            <div className="row TodoAreaMargin w-100">
              <div className="col-lg-3 col-md-3 col-sm-12  bg-black">
                <div className="card card-left-mail mt-0 ">
                  <button className="compose-btn">Compose</button>
                  <button className="compose-btn width-increase">
                    <span>Inbox</span>
                    <span className="inbox-mail-number">8</span>
                  </button>
                  {/* eslint-disable-next-line */}
                  <a className="sent-txt" href="javascript:;">
                    Sent{" "}
                  </a>
                  {/* eslint-disable-next-line */}
                  <a className="sent-txt" href="javascript:;">
                    Draft{" "}
                  </a>
                  {/* eslint-disable-next-line */}
                  <a className="sent-txt" href="javascript:;">
                    Bin{" "}
                  </a>
                  {/* eslint-disable-next-line */}
                  <a className="sent-txt" href="javascript:;">
                    Important{" "}
                  </a>
                  {/* eslint-disable-next-line */}
                  <a className="sent-txt" href="javascript:;">
                    Starred{" "}
                  </a>
                  {/* eslint-disable-next-line */}
                  <a className="sent-txt add-border-txt" href="javascript:;">
                    Labels{" "}
                  </a>
                  <div className="checkbox-div">
                    <div className="d-flex mr-2">
                      <Checkbox
                        icon={<Icon.FiCheck color="#C06C84" size={14} />}
                        name="my-input"
                        checked={false}
                        className="CheckBoxInput"
                        borderColor="#C06C84"
                        style={{ cursor: "pointer" }}
                      />
                      <span className="ml-2">Family</span>
                    </div>
                    <div className="d-flex mr-2">
                      <Checkbox
                        icon={<Icon.FiCheck color="#C06C84" size={14} />}
                        name="my-input"
                        checked={false}
                        className="CheckBoxInput"
                        borderColor="#C06C84"
                        style={{ cursor: "pointer" }}
                      />
                      <span className="ml-2">Work</span>
                    </div>
                    <div className="d-flex mr-2">
                      <Checkbox
                        icon={<Icon.FiCheck color="#C06C84" size={14} />}
                        name="my-input"
                        checked={false}
                        className="CheckBoxInput"
                        borderColor="#C06C84"
                        style={{ cursor: "pointer" }}
                      />
                      <span className="ml-2">Shop</span>
                    </div>
                    <div className="d-flex mr-2">
                      <Checkbox
                        icon={<Icon.FiCheck color="#C06C84" size={14} />}
                        name="my-input"
                        checked={false}
                        className="CheckBoxInput"
                        borderColor="#C06C84"
                        style={{ cursor: "pointer" }}
                      />
                      <span className="ml-2">Themeforest</span>
                    </div>
                    <div className="d-flex mr-2">
                      <Checkbox
                        icon={<Icon.FiCheck color="#C06C84" size={14} />}
                        name="my-input"
                        checked={false}
                        className="CheckBoxInput"
                        borderColor="#C06C84"
                        style={{ cursor: "pointer" }}
                      />
                      <span className="ml-2">Google</span>
                    </div>
                  </div>
                  {/* eslint-disable-next-line */}
                  <a className="sent-txt add-border-txt" href="javascript:;">
                    Online{" "}
                  </a>
                  <div className="dot-with-txt-div">
                    <div className="green-dot"></div>
                    <span className="ml-2">john</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12 ">
                <div className="card mt-0">
                  <div className="mail-main-wrapper">
                    <div className="d-flex">
                      <div className="checkbox-check"></div>
                      <div className="">
                        <img src={backIcon} alt="" className="icon-size mr-4" />
                        <img
                          src={draftIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <img
                          src={informationIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <img
                          src={deleteIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <img
                          src={folderIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <img src={tagIcon} alt="" className="icon-size mr-4" />
                      </div>
                    </div>
                    <div className="arrow-div">
                      <img src={previousIcon} alt="" className="arrow-size" />
                      <img src={nextIcon} alt="" className="arrow-size" />
                    </div>
                  </div>
                  <div className="mail-main-wrapper">
                    <div className="d-flex">
                      <div className="checkbox-check"></div>
                      <div className="d-flex">
                        <img
                          src={starblackIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <p className="name-txt  min-def-txt">Nelsen Lane</p>
                        <button className="work-btn">Work</button>
                        <p className="name-txt">
                          Nelson Lane is request you for an appointment.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <img
                        src={attachmentIcon}
                        alt=""
                        className="icon-size mr-4"
                      />
                      <p className="name-txt">12:30 P.M</p>
                    </div>
                  </div>
                  <div className="mail-main-wrapper">
                    <div className="d-flex">
                      <div className="checkbox-check"></div>
                      <div className="d-flex">
                        <img
                          src={staryellowIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <p className="name-txt min-def-txt">Kerry Mann</p>
                        <p className="name-txt">
                          Nelson Lane is request you for an appointment.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <img
                        src={attachmentIcon}
                        alt=""
                        className="icon-size mr-4"
                      />
                      <p className="name-txt date-txt">May 14</p>
                    </div>
                  </div>
                  <div className="mail-main-wrapper">
                    <div className="d-flex">
                      <div className="checkbox-check"></div>
                      <div className="d-flex">
                        <img
                          src={starblackIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <p className="name-txt min-def-txt">Nelsen Lane</p>
                        <button className="work-btn report-btn">Report</button>
                        <p className="name-txt">
                          Nelson Lane is request you for an appointment.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <img
                        src={attachmentIcon}
                        alt=""
                        className="icon-size mr-4"
                      />
                      <p className="name-txt">12:30 P.M</p>
                    </div>
                  </div>
                  <div className="mail-main-wrapper">
                    <div className="d-flex">
                      <div className="checkbox-check"></div>
                      <div className="d-flex">
                        <img
                          src={starblackIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <p className="name-txt  min-def-txt">Nelsen Lane</p>
                        <button className="work-btn">Work</button>
                        <p className="name-txt">
                          Nelson Lane is request you for an appointment.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <img
                        src={attachmentIcon}
                        alt=""
                        className="icon-size mr-4"
                      />
                      <p className="name-txt">12:30 P.M</p>
                    </div>
                  </div>
                  <div className="mail-main-wrapper">
                    <div className="d-flex">
                      <div className="checkbox-check"></div>
                      <div className="d-flex">
                        <img
                          src={staryellowIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <p className="name-txt min-def-txt">Kerry Mann</p>
                        {/* <button className="work-btn">Work</button> */}
                        <p className="name-txt">
                          Nelson Lane is request you for an appointment.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <img
                        src={attachmentIcon}
                        alt=""
                        className="icon-size mr-4"
                      />
                      <p className="name-txt date-txt">May 14</p>
                    </div>
                  </div>
                  <div className="mail-main-wrapper border-remove">
                    <div className="d-flex">
                      <div className="checkbox-check"></div>
                      <div className="d-flex">
                        <img
                          src={starblackIcon}
                          alt=""
                          className="icon-size mr-4"
                        />
                        <p className="name-txt min-def-txt">Nelsen Lane</p>
                        <button className="work-btn report-btn">Report</button>
                        <p className="name-txt">
                          Nelson Lane is request you for an appointment.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <img
                        src={attachmentIcon}
                        alt=""
                        className="icon-size mr-4"
                      />
                      <p className="name-txt">12:30 P.M</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ..............Add new appointement screen html................ */}
      <div className="col-lg-12">
        <div className="card appointments mt-0 col-lg-12">
          <div style={{ minHeight: "82vh" }} className="card-body">
            <h5 className="card-title row pt-3 pb-3 text-left pl-4 pr-4 align-items-center">
              <div className="col-lg-10 pl-0 ">
                <img src={Appointment} alt="" className="mr-1" />
                <span className="appointmentText"> Add New Appointment</span>
              </div>
              <div className="col-lg-2 mt-1 m-0 p-0">
                <form action="">
                  <div className="SearchStyle">
                    <input
                      placeholder="Search"
                      type="text"
                      className="appointmentSearchInpu"
                    />
                    <button type="submit" className="searchIcon ">
                      <i style={{ marginTop: -2 }} className="material-icons">
                        search
                      </i>
                    </button>
                  </div>
                </form>
              </div>
            </h5>
            <div className="row pl-4 pr-4 pb-4 pt-4">
              <div className="col-lg-8 pl-0 d-flex row">
                <div className="col-lg-4 mt-1 mr-4">
                  <form action="">
                    <div className="patientsearchstyle">
                      <input
                        placeholder="Patient Name"
                        type="text"
                        className="appointmentSearchInpu"
                      />
                      {/* <button type='submit' className="searchIcon ">
                                             <i style={{ marginTop:-2 }} className='material-icons'>search</i>
                                          </button> */}
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 mt-1">
                  <form action="">
                    <div className="patientsearchstyle">
                      <input
                        placeholder="Purpose"
                        type="text"
                        className="appointmentSearchInpu"
                      />
                      {/* <button type='submit' className="searchIcon ">
                                             <i style={{ marginTop:-2 }} className='material-icons'>search</i>
                                          </button> */}
                    </div>
                  </form>
                </div>
              </div>
              <div className="col d-flex align-items-center">
                <p className="or-txt">Or</p>
              </div>
              <div className="col-lg-3 newAppointment d-flex justify-content-between align-items-center">
                <i className="material-icons AddIcon text-white">add</i>
                <span className="text-white addText"> Add New Appoinment </span>
              </div>
            </div>
            <div className="calendar-view-wrapper">
              <div className="calendar-header">
                <div className="calendar-header-div"></div>
                <div className="calendar-header-div">
                  <p className="week-txt">SUNDAY</p>
                  <div className="week-number">01 </div>
                </div>
                <div className="calendar-header-div">
                  <p className="week-txt">MONDAY</p>
                  <div className="week-number">02 </div>
                </div>
                <div className="calendar-header-div">
                  <p className="week-txt">TUESDAY</p>
                  <div className="week-number">03 </div>
                </div>
                <div className="calendar-header-div">
                  <p className="week-txt">WEDNESDAY</p>
                  <div className="week-number">04 </div>
                </div>
                <div className="calendar-header-div">
                  <p className="week-txt">THURESDAY</p>
                  <div className="week-number">05 </div>
                </div>
                <div className="calendar-header-div">
                  <p className="week-txt">FRIDAY</p>
                  <div className="week-number">05 </div>
                </div>
                <div className="calendar-header-div">
                  <p className="week-txt">SATURDAY</p>
                  <div className="week-number">06 </div>
                </div>
              </div>
              <div className="calendar-content">
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">9:30 AM</div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                </div>
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">10:00 AM</div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div  week-txt holiday-clr">
                    Holiday
                  </div>
                  <div className="time-slot-div"></div>
                </div>
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">10:30 AM</div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                </div>
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">!!:00 AM</div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                </div>
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">11:30 AM</div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                </div>
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">12:00 PM</div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                </div>
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">12:30 PM</div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div week-txt holiday-clr">
                    Holiday
                  </div>
                </div>
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">01:00 PM</div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                </div>
                <div className="calendar-content-div">
                  <div className="time-slot-div bg-white">01:30 PM</div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div week-txt holiday-clr">
                    Holiday
                  </div>
                  <div className="time-slot-div"></div>
                  <div className="time-slot-div week-txt booked-clr">
                    Booked
                  </div>
                  <div className="time-slot-div"></div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-11 ">
                <div className="col-lg-2 ">
                  <div className=" newAppointment save-btn">
                    <i className="material-icons AddIcon text-white">add</i>
                    <span className="text-white ml-2 "> Save </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 pl-0 ArrowClass text-left position-relative">
                {/* <label className="" htmlFor="TimeSlot">
                                             <img src={ClockForSchdule} alt="" />
                                             Time slot duration</label> */}
                <span
                  style={{
                    position: "absolute",
                    right: "27px",
                    top: "47px",
                  }}
                  className="downArrow"
                >
                  <img src={DownArrow} alt="" />
                </span>
                <select
                  className="form-control inputStyle"
                  name="SelectClinic"
                  id=""
                >
                  <option value="">Week </option>
                  <option value="">Week </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Setting;
