import { CSVLink } from "react-csv";

const ExportCSV = ({ csvData, fileName }) => {
  return (
    <button className="docg-outline-btn">
      <CSVLink data={csvData} filename={fileName}>
        Export
      </CSVLink>
    </button>
  );
};

export default ExportCSV;
