import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import DoctorRoutes from "../components/Dashboard/DoctorRoutes";
import AuthRoutes from "../components/Authentication/AuthRoutes";
import OpenRoute from "../commons/OpenRoute";
import PrivateRoute from "../commons/PrivateRoute";
import DoctorTemplate from "../commons/DoctorTemplate";

import PageNotFound from "../commons/PageNotFound";
import VideoCall from "src/components/Dashboard/Patient/PatientDetail/videoCall/VideoCall";

// Importing admin page
import Admin from "../components/Admin/Admin";

// standards
import Standards from "src/components/standards";

const Routers = () => {
  const routes = [...AuthRoutes, ...DoctorRoutes];
  const authRoutes = routes.filter((res) => res.auth);
  const normalRoutes = routes.filter((res) => !res.auth);
  const loggedIn = true;

  return (
    <Router>
      <Switch>
        {normalRoutes.map((route, i) => (
          <OpenRoute
            exact
            key={route.path}
            path={route.path}
            module={route.module}
            component={route.component}
          />
        ))}
        <PrivateRoute
          exact
          key="/video-call/:patientId"
          path="/video-call/:patientId"
          module="doctor"
          component={() => <VideoCall />}
        />
        <DoctorTemplate>
          {authRoutes.map((route, i) => (
            <PrivateRoute
              exact
              key={route.path}
              path={route.path}
              module={route.module}
              component={route.component}
              componentProps={route.componentProps ?? {}}
            />
          ))}
        </DoctorTemplate>

        <Route exact path="/admin">
          <Admin />
        </Route>
        <Route render={() => <PageNotFound />} />
      </Switch>
    </Router>
  );
};

export default Routers;
