import React from "react";
import { Card } from "react-bootstrap";

const CardWrapper = ({
  wrap = true,
  children,
  cardClass = "",
  headerComponent = <></>,
  cardBodyClass = "",
  cardStyle = {},
}) => {
  if (wrap)
    return (
      <>
        <Card style={cardStyle} className={cardClass}>
          {headerComponent}
          <Card.Body className={cardBodyClass}>{children}</Card.Body>
        </Card>
      </>
    );
  else return <>{children}</>;
};

export default CardWrapper;
