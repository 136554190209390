import { Listview } from "@mobiscroll/react";
import { Input } from "@mobiscroll/react5";
import React from "react";
import { Modal } from "react-bootstrap";
import editIcon from "src/assets/edit.svg";
import deleteIcon from "src/assets/icons/delete.svg";
import save_pink from "src/assets/save_pink.svg";
import { updateProfile } from "./api";

function MyListItem(props) {
  const { handleEdit = () => {}, handleDelete = () => {} } = props;
  console.log(props);
  return (
    <li className="d-flex justify-content-between align-items-center">
      {props.item.title}
      <div className="">
        <button onClick={() => handleEdit(props.item.id)}>
          <img src={editIcon} alt="" />
        </button>{" "}
        <button
          onClick={() => {
            handleDelete(props.item.id);
          }}
        >
          <img src={deleteIcon} alt="" />
        </button>{" "}
      </div>
    </li>
  );
}

const DotorPopup = (props) => {
  console.log(props);
  const { show, closePopup, selected } = props;
  const [items, setItems] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");

  const handleEdit = React.useCallback(
    (index) => {
      let value = "";
      let data = items.filter((item) => {
        if (item.id != index) {
          return true;
        } else {
          value = item.title;
        }
      });
      setItems(data);
      setValue(value);
      document.getElementById("editInput").focus();
    },
    [items, setItems, setValue]
  );

  const handleDelete = React.useCallback(
    async (id) => {
      try {
        let data;
        const arr = items.filter((item) => item.id !== id);
        setItems(arr);
      } catch (e) {
        console.log("Error", e);
      }
    },
    [items, value, setItems]
  );

  React.useEffect(() => {
    const items = [];
    if (selected?.items) {
      selected.items.forEach((item, index) =>
        items.push({ id: index, title: item })
      );
    }
    setItems(items);
  }, [selected]);

  const handleChange = async () => {
    if (value.trim() != "") {
      setItems((prev) => {
        let _items = prev;
        _items.push({ id: prev.length, title: value });
        return _items;
      });
      setValue("");
      setError("");
    } else {
      setError("Enter a value");
    }
  };

  const stages = {
    left: [
      {
        percent: 25,
        key: "stage2",
        icon: editIcon,
        color: "#c06c84",
        text: "Edit",
        action: (event, inst) => {
          handleEdit(event.index);
        },
      },
    ],
    right: [
      {
        percent: -25,
        key: "stage1",
        icon: "remove",
        color: "crimson",
        text: "Remove",
        action: (event, inst) => {
          handleDelete(event.index);
        },
      },
    ],
  };

  const handleSubmit = async () => {
    let data;
    const arr = [];
    items.forEach((item, index) => {
      arr.push(item.title);
    });
    if (props.selected.obj?.doctorProfile) {
      const key = Object.keys(props.selected.obj?.doctorProfile);

      data = {
        id: props.data.id,
        doctorProfile: { ...props.data.doctorProfile, [key[0]]: arr },
      };
    } else {
      const key = Object.keys(props.selected.obj);
      data = {
        id: props.data.id,
        [key[0]]: arr,
      };
    }
    const res = await updateProfile(data);
    delete res.__typename;
    delete res.doctorProfile?.__typename;
    props.setData(res);
    props.closePopup();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selected?.placeholder}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mbsc-row">
            <div className="mbsc-col-12">
              <Listview
                stages={stages}
                theme="ios"
                itemType={(props) => (
                  <MyListItem
                    {...props}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                )}
                data={items}
              />
            </div>
            <div className="mbsc-col-12 d-flex align-items-end justify-content-center mt-2">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label={selected?.placeholder}
                placeholder=""
                type="text"
                name="value"
                id="editInput"
                cssClass="w-100"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button onClick={handleChange} class="docg-save-btn m-0">
                Add
              </button>
            </div>
            <div className="mbsc-col-12">
              <span className="mbsc-error-message mbsc-ios">{error}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="docg-patient-cancel-btn" onClick={handleSubmit}>
            <img src={save_pink} alt="" />
            &nbsp; Save{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DotorPopup;
