import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { openNewWindow } from "src/lib/openNewWindow";

import { Modal, Button } from "react-bootstrap";
import Loader from "src/components/Dashboard/common/Loader";
import { Checkbox } from "@mobiscroll/react5";
import { useParams } from "react-router-dom";
import { useStore as useGlobalStore } from "src/store/Store";
import { useStore } from "./store/Store";
import { isStartingConsultation } from "./store/PatientDetailsReducer";
import styled from "styled-components";
import img from "../../../../assets/icons/permission.svg";
import yes from "../../../../assets/icons/yes.svg";
import no from "../../../../assets/icons/no.svg";

//api
import { startConsultation, setAppointmentDelay } from "./api";

const PatientModal = (props) => {
  const { globalState, dispatch } = useStore();
  const { globalStateKPI } = useGlobalStore();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [paymentAccepted, setPaymentAccepted] = useState(false);

  React.useEffect(() => {
    if (props.paymentModeCash) {
      setPaymentAccepted(false);
    } else {
      setPaymentAccepted(true);
    }
  }, [props]);

  const handleNo = () => {
    dispatch(isStartingConsultation(false));
    props.onHide();
  };
  const handleYes = async () => {
    setLoader(true);
    let data = {
      appointmentId: globalState.appointmentId,
    };
    if (globalStateKPI.lastAppointmentId == globalState.appointmentId) {
      data.lastConsultation = true;
    }
    const res = await startConsultation(data);
    if (res) {
      dispatch(isStartingConsultation(true));
      if (globalState.consultationType == "Online") {
        openNewWindow(
          `/video-call/${globalState.patientId}?appointmentId=${globalState.appointmentId}`,
          "Video Call"
        );
      }
      props.onHide();
    }
    setLoader(false);
  };

  return (
    <>
      <Loader show={loader} />
      <Modal
        {...props}
        size={`${props.paymentModeCash ? "lg" : "md"}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center patient-permission-modal">
          <img src={img} alt="" />
          <h4>Are you starting consulation?</h4>
          {props.paymentModeCash && (
            <div className="custom-repeat-checkbox-cont">
              <Checkbox
                checked={paymentAccepted}
                onChange={(e) => {
                  setPaymentAccepted(e.target.checked);
                }}
              >
                Did you Receive your Offline Payment for this Consultation?
              </Checkbox>
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center">
            <Styledbutton
              disabled={!paymentAccepted}
              onClick={handleYes}
              style={{
                background: `#6c5b7b`,
              }}
            >
              {" "}
              <img className="m-auto" src={yes} alt="" /> Yes
            </Styledbutton>
            <Styledbutton
              onClick={handleNo}
              style={{
                background: "#d08097",
              }}
            >
              {" "}
              <img src={no} alt="" /> No
            </Styledbutton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PatientModal;

const Styledbutton = styled(Button)`
  border-radius: 45px;
  opacity: 1;
  text-align: left;
  text-transform: none;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", serif;
  padding: 0.4rem 2.6rem;
  margin: 0.2rem;
  border: none;
`;
