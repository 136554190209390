import React, { Fragment } from "react";
import "./modals.scss";
import { Modal } from "react-bootstrap";
import AppointmentsYear from "../../components/Dashboard/Reporting/chart/AppointmentsYear";
import PatientsYear from "../../components/Dashboard/Reporting/chart/PatientsYear";
import DoctorPerformance from "../../components/Dashboard/Reporting/chart/DoctorPerformance";
import TotalRevenue from "../../components/Dashboard/Reporting/chart/TotalRevenue";
import TotalSales from "../../components/Dashboard/Reporting/chart/TotalSales";
import Reviews from "../../components/Dashboard/Reporting/chart/Reviews";

const ReportingModals = ({ setLgShow, lgShow, show, title }) => {
  return (
    <Fragment>
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-xl"
        // className="right-open-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-xl"
            className="ml-3 center-show-txt"
          >
            <span className="ModalTitle">{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" add-height-modal">
          {show === 1 && (
            <AppointmentsYear divId={"AppointmentsYear1"} check={false} />
          )}
          {show === 2 && <PatientsYear divId={"PatientsYear1"} check={false} />}
          {show === 3 && (
            <DoctorPerformance divId={"DoctorPerformance1"} check={false} />
          )}
          {show === 4 && <TotalRevenue divId={"TotalRevenue1"} check={false} />}
          {show === 5 && <TotalSales divId={"TotalSales1"} check={false} />}
          {show === 6 && <Reviews divId={"Reviews1"} check={false} />}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ReportingModals;
