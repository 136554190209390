import React from "react";
import Avatar, { ConfigProvider } from "react-avatar";
import mobiscroll from "@mobiscroll/react";
const Review = ({ patient = null, comment = "", rating = 1 }) => {
  return (
    <div className="d-flex pl-4 pt-3 pb-3 align-items-center">
      <Avatar
        color="#c06c84"
        round
        size="2rem"
        name={
          patient == null
            ? ""
            : new Array(patient?.firstName, patient?.lastName)
                .filter((el) => el != null && el != undefined)
                .join(" ")
        }
        src={patient?.profilePicture}
      />
      {/* <img src={arrowImg} alt="" /> */}
      <div className="ml-3 text-left">
        <div className="d-flex align-items-center">
          <span style={{ fontSize: "14px" }} className="tom-txt">
            {new Array(patient?.firstName, patient?.lastName)
              .filter((el) => el != null && el != undefined)
              .join(" ")}
          </span>
          <mobiscroll.Rating value={rating} color="warning"></mobiscroll.Rating>
        </div>
        <p className="tom-txt weight-change-font mt-2">{comment}</p>
      </div>
    </div>
  );
};

export default Review;
