import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  GET_DOCTOR_SCHEDULES,
  GET_DOCTOR_ID,
  LIST_APPOINTMENTS_SCHEDULE,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import {
  REMOVE_SCHEDULE,
  REMOVE_SCHEDULE_GROUP,
  ADD_SCHEDULE,
} from "src/components/graphql/GraphQLmutation";

export async function getSchedules(start, end) {
  try {
    const doctorId = sessionStorage["role-id"];
    if (doctorId !== null) {
      const data = {
        doctorId,
        start,
        end,
      };
      console.log(data);
      let response = await getQueryWithParams(GET_DOCTOR_SCHEDULES, data);
      console.log(response);
      if (typeof response == "undefined") {
        throw new Error(response);
      } else {
        if (response.data.listSchedules.data) {
          return response.data.listSchedules.data;
        } else {
          throw new Error(response);
        }
      }
    } else {
      return [];
    }
  } catch (error) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("ERROR at list Schedules", error);
    return [];
  }
}

export async function getData(start, end) {
  const response = await getSchedules(start, end);
  let data = [];
  if (response) {
    response.forEach((_el) => {
      const { duration, end, gap, id, doctorId, recurrence, start, relatedId } =
        _el;
      let el = {
        duration,
        end,
        gap,
        id,
        doctorId,
        recurrence,
        relatedId,
        start,
      };
      data.push(el);
    });
    console.log(data);
  }
  return data;
}

export async function addSchedule(data) {
  try {
    const response = await postMutation(ADD_SCHEDULE, data);

    if (
      response.data !== null &&
      response?.data?.addSchedule?.statusCode >= 200 &&
      response?.data?.addSchedule?.statusCode < 300
    ) {
      snackbar({
        message: "Schedule Added Successfully.",
        color: "success",
      });
      return true;
    } else {
      if (response?.data?.addSchedule?.message)
        throw new Error(response?.data?.addSchedule.message);
      else
        throw new Error(
          "Some error occured while adding Schedule. Try again later."
        );
    }
  } catch (e) {
    snackbar({
      message: e.message,
      color: "danger",
    });
    console.log("Error at add Schedule: ", e);
    return false;
  }
}

export function storeSchedules(data) {}

export async function getDoctorId() {
  try {
    let response = await getQuery(GET_DOCTOR_ID);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.getMyProfile.data) {
        return response.data.getMyProfile.data.id;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at list Schedules", error);

    return null;
  }
}

export async function removeSchedule(data) {
  try {
    let response = await postMutation(REMOVE_SCHEDULE, data);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      snackbar({
        message: "Schedule removed Successfully.",
        color: "success",
      });
      return response;
    }
  } catch (error) {
    console.log("ERROR at list Schedules", error);
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    return undefined;
  }
}
export async function removeScheduleGroup(data) {
  try {
    let response = await postMutation(REMOVE_SCHEDULE_GROUP, data);
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      if (response?.errors) {
        throw new Error("error", response);
      } else if (
        response?.data?.removeScheduleGroup?.statusCode >= 200 &&
        response?.data?.removeScheduleGroup?.statusCode < 300
      ) {
        snackbar({
          message: "Recurring Schedule Removed Successfully.",
          color: "success",
        });
        console.log(response?.data?.removeScheduleGroup?.data);
        return true;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("ERROR at list Schedules group", e);
    return null;
  }
}

export async function listAppointments(filters, start) {
  try {
    const response = await getQuery(LIST_APPOINTMENTS_SCHEDULE(filters, start));
    // let response = await getQuery(GET_DOCTOR(false, "", "", ""));

    console.log(response);
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listAppointmentsDoctor?.data) {
        snackbar({
          message: "List of Appointments fetched successfully.",
          color: "success",
        });
        console.log(response?.data?.listAppointmentsDoctor?.data);
        return response?.data?.listAppointmentsDoctor?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    // snackbar({
    //   message: "Some error occured. Try again",
    //   color: "danger",
    // });
    console.log("Error at Listing Appointments: ", e);
    return [];
  }
}
