import React, { Fragment } from "react";
import "react-circular-progressbar/dist/styles.css";
import CheckBox from "./CheckBox";

const EmailLeftside = (props) => {
  const checkboxData = [
    { name: "Family", value: false },
    { name: "Work", value: false },
    { name: "Shop", value: false },
    { name: "Themeforest", value: false },
    { name: "Google", value: false },
  ];
  return (
    <Fragment>
      <div className="card card-left-mail mt-0 ">
        <button className="compose-btn">Compose</button>

        <button
          id="inbox"
          onClick={() => {
            props.showThisData("inbox");
          }}
          className={`compose-btn ${
            props?.activeID === "inbox" ? "width-increase" : " "
          }`}
        >
          <span>Inbox</span>
          <span className="inbox-mail-number">8</span>
        </button>

        <button
          id="sendList"
          onClick={() => {
            props.showThisData("sendList");
          }}
          className={`compose-btn ${
            props?.activeID === "sendList" ? "width-increase" : " "
          }`}
        >
          <span>Send</span>
          <span className="inbox-mail-number">8</span>
        </button>

        <button
          id="draftData"
          onClick={() => {
            props.showThisData("draftData");
          }}
          className={`compose-btn ${
            props?.activeID === "draftData" ? "width-increase" : " "
          }`}
        >
          <span>Draft</span>
          <span className="inbox-mail-number">8</span>
        </button>

        <button
          id="bin"
          onClick={() => {
            props.showThisData("bin");
          }}
          className={`compose-btn ${
            props?.activeID === "bin" ? "width-increase" : " "
          }`}
        >
          <span>Bin</span>
          <span className="inbox-mail-number">8</span>
        </button>

        <button
          id="important"
          onClick={() => {
            props.showThisData("important");
          }}
          className={`compose-btn ${
            props?.activeID === "important" ? "width-increase" : " "
          }`}
        >
          <span>Important</span>
          <span className="inbox-mail-number">8</span>
        </button>

        <button
          id="starred"
          onClick={() => {
            props.showThisData("starred");
          }}
          className={`compose-btn ${
            props?.activeID === "starred" ? "width-increase" : " "
          }`}
        >
          <span>Starred</span>
          <span className="inbox-mail-number">8</span>
        </button>

        <button
          id="labels"
          onClick={() => {
            props.showThisData("labels");
          }}
          className={`compose-btn ${
            props?.activeID === "labels" ? "width-increase" : " "
          }`}
        >
          <span>Labels</span>
          <span className="inbox-mail-number">8</span>
        </button>

        <div className="checkbox-div">
          <CheckBox checkboxData={checkboxData} />
        </div>
        {/*eslint-disable-next-line */}
        <a className="sent-txt add-border-txt">Online </a>
        <div className="dot-with-txt-div">
          <div className="green-dot"></div>
          <span className="ml-2">john</span>
        </div>
      </div>
    </Fragment>
  );
};

export default EmailLeftside;
