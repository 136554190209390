import { snackbar } from "@mobiscroll/react5";
import moment from "moment/moment";
import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getQueryWithParams,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { CREATE_PRESCRIPTION_PDF } from "src/components/graphql/GraphQLmutation";
import { PRESCRIPTION_DOWNLOAD_PDF } from "src/components/graphql/GraphQLquery";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
export default function usePrescriptionPdf() {
  let query = useQuery();
  const appointmentId = query.get("appointmentId");
  const { patientId } = useParams();

  const [isPdfDataLoading, setIsPdfDataLoading] = useState(null);
  const [prescriptionBase64, setPrescriptionBase64] = useState(null);
  const [patientName, setPatientName] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [pdfLink, setPDFLink] = useState(null);

  const fetchPdfBase64 = async (data, appointmentDate, patientName) => {
    try {
      const _res = await postMutation(CREATE_PRESCRIPTION_PDF, data);
      // return _res?.data?.createPrescriptionPdf?.data?.base64 || null;
      if (_res?.data?.createPrescriptionPdf?.base64) {
        setPrescriptionBase64(
          `data:application/pdf;base64,${_res?.data?.createPrescriptionPdf?.base64}`
        );
        console.log(_res?.data?.createPrescriptionPdf?.base64);
        return _res?.data?.createPrescriptionPdf?.base64;
      } else {
        snackbar({
          message: "Unable to prepare Prescription Pdf",
          color: "danger",
        });
        return null;
      }
    } catch (error) {
      snackbar({
        message: "Unable to prepare Prescription Pdf",
        color: "danger",
      });
      return null;
    } finally {
      setIsPdfDataLoading(false);
      console.log(prescriptionBase64);
    }
  };

  const fetchPdfData = async () => {
    const doctorId = sessionStorage.getItem("role-id");
    const clinic_id = sessionStorage.getItem("clinic_id");
    setIsPdfDataLoading(true);
    const res = await getQueryWithParams(
      PRESCRIPTION_DOWNLOAD_PDF(appointmentId, doctorId, patientId, clinic_id)
    );
    if (
      res.data?.getAppointment &&
      res.data?.getDoctor &&
      res.data?.getPatient
    ) {
      const temaplateData = {
        appointmentData: res.data.getAppointment.data,
        doctorData: res.data.getDoctor.data,
        clinicData: res.data.getClinicProfile.data,
        patientData: res.data.getPatient.data,
      };
      setPatientName(
        `${res.data.getPatient.data.firstName}_${res.data.getPatient.data.lastName}`
      );
      setAppointmentDate(res.data.getAppointment.data.start);

      let link = await fetchPdfBase64(
        temaplateData,
        res.data.getAppointment.data.start,
        `${res.data.getPatient.data.firstName}_${res.data.getPatient.data.lastName}`
      );
      console.log(link);
      return link;
    }
  };

  const downLoadPdf = async (
    prescriptionBase64,
    appointmentDate,
    patientName,
    download
  ) => {
    if (prescriptionBase64) {
      const file_name =
        "prescription_" +
        patientName +
        "_" +
        moment(moment(appointmentDate).format("lll"))
          .format("DD_MM_YYYY")
          .toString() +
        ".pdf";
      const link = document.createElement("a");
      const linkSource = `data:application/pdf;base64,${prescriptionBase64}`;
      setPDFLink(linkSource);
      if (download) {
        link.href = linkSource;
        link.download = file_name;
        link.click();
        link.remove();
      }
    } else {
      snackbar({
        message: "Please wait...Loading Data",
        color: "danger",
      });
    }
  };

  const downloadPrescription = async (download = false) => {
    const base64 = await fetchPdfData();
    downLoadPdf(base64, appointmentDate, patientName, download);
  };

  return {
    downloadPrescription,
    isPdfDataLoading,
    fetchPdfData,
    prescriptionBase64,
    prescriptionPDFLink: pdfLink,
    prescriptionFileName:
      sessionStorage.getItem("clinicName") +
      "_Prescription_" +
      patientName +
      "_" +
      moment(moment(appointmentDate).format("lll"))
        .format("DD_MM_YYYY")
        .toString() +
      ".pdf",
  };
}
