import { Suspense } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";

const HistoryDialog = (props) => {
  console.log(props);
  const prepareEyePrescriptionData = (data) => {
    let arr = [];

    let typeArr = ["distance", "near", "pg"];
    typeArr.forEach((item, index) => {
      let json = {
        type: "",
        leftSph: "",
        leftCyl: "",
        leftAxis: "",
        leftVA: "",
        rightSph: "",
        rightCyl: "",
        rightAxis: "",
        rightVA: "",
      };
      if (index == 0) json.type = "Distant";
      else if (index === 1) json.type = "Near";
      else json.type = "PG";

      json.leftSph = data.leftEye[item].spl;
      json.leftCyl = data.leftEye[item].cyl;
      json.leftAxis = data.leftEye[item].axis;
      json.leftVA = data.leftEye[item].va;
      json.rightSph = data.rightEye[item].spl;
      json.rightCyl = data.rightEye[item].cyl;
      json.rightAxis = data.rightEye[item].axis;
      json.rightVA = data.rightEye[item].va;

      arr.push(json);
    });

    console.log(arr);

    return arr;
  };

  return (
    <>
      <Card className="docg-patient-form-wrapper docg-card mt-4 mb-4">
        {/* <Card.Title>
          <div className="w-100 d-flex align-items-center">
            <img src={prescriptionIcon} className="mr-2" alt="" />
            Prescription
          </div>
        </Card.Title> */}
        <Card.Body>
          <div className="history-dialog">
            <div className="mbsc-grid">
              <div className="mbsc-row mt-3">
                <div className="mbsc-col pl-0  mr-0 ml-0 mt-2 mb-2 justify-content-between">
                  {props?.data?.drugs.length > 0 ? (
                    <Suspense fallback={<div>Loading....</div>}>
                      {props?.data?.drugs.map((drug) => {
                        console.log(drug);
                        return (
                          <>
                            <Container className="d-flex">
                              <div className="row w-100">
                                <div className="col-12">
                                  <div
                                    style={{ color: "#C06C84" }}
                                    className="mr-3"
                                  >
                                    <span style={{ color: "#000" }} x>
                                      Drug:
                                    </span>
                                    &nbsp;
                                    <b>{drug.drugName}</b>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <span>Route: </span>&nbsp;{drug.drugType}
                                </div>
                                <div className="col-12">
                                  <span>Drug quantity: </span>&nbsp;
                                  {drug.dose}
                                </div>
                                <div className="col-12">
                                  <span>Duration: </span>
                                  {drug.duration}
                                </div>
                                <div className="col-12">
                                  <span>Gap Between Dosage: </span>
                                  {drug.gapBetweenDosage || "-"}
                                </div>
                                <div className="col-12">
                                  <span> Dose Frequency: </span>
                                  {drug.doseFrequency || "-"}
                                </div>
                                <div className="col-12">
                                  <span>Instruction: </span>
                                  {drug.instruction}
                                </div>
                                {drug?.additionalAdvice && (
                                  <>
                                    <div className="col-12">
                                      <span>Additional Advice: </span>
                                      {drug.additionalAdvice}
                                    </div>
                                  </>
                                )}
                              </div>
                            </Container>
                          </>
                        );
                      })}
                    </Suspense>
                  ) : (
                    "No Prescription Found!"
                  )}
                </div>

                <div className="mbsc-col-12">
                  {props?.data?.eyePrescription && (
                    <table className="docg-table w-100 text-center">
                      <tr>
                        <th></th>
                        <th colSpan={4}>Left Eye</th>
                        <th colSpan={4}>Right Eye</th>
                      </tr>
                      <tr fontSize={10}>
                        <th> </th>
                        <th>Sph</th>
                        <th>Cyl</th>
                        <th>Axis</th>
                        <th>VA</th>
                        <th>Sph</th>
                        <th>Cyl</th>
                        <th>Axis</th>
                        <th>VA</th>
                      </tr>
                      {prepareEyePrescriptionData(
                        props?.data?.eyePrescription
                      ).map((item) => (
                        <tr>
                          <td>{item.type}</td>
                          <td>{item.leftSph}</td>
                          <td>{item.leftCyl}</td>
                          <td>{item.leftAxis}</td>
                          <td>{item.leftVA}</td>
                          <td>{item.rightSph}</td>
                          <td>{item.rightCyl}</td>
                          <td>{item.rightAxis}</td>
                          <td>{item.rightVA}</td>
                        </tr>
                      ))}
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default HistoryDialog;

const Container = styled.div`
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  color: #929292;
  padding: 10px 0 0 0;
  width: 100%;
  &:first-child,
  .row {
    margin: 0;
  }
  span {
    color: #565656;
    font-weight: 600;
    text-transform: capitalize;
  }
`;

const styles = {
  body: {
    paddingTop: 35,
    paddingHorizontal: 35,
    paddingBottom: 150,
    backgroundColor: "#ECF0F3",
  },
  subtitle: {
    fontSize: 11,
    fontFamily: "gilroy-medium",
    marginVertical: 2,
    fontWeight: "bold",
    marginBottom: 1,
    color: "#434343",
  },
  tableMainHeader: {
    fontSize: 10,
    fontFamily: "gilroy-medium",
    fontWeight: "bold",
    textAlign: "center",
    color: "#434343",
  },
  tablecellStyle: {
    fontSize: 9,
    fontFamily: "gilroy-medium",
    textAlign: "center",
    marginTop: 1,
    color: "#434343",
  },
  text: {
    fontSize: 9,
    textAlign: "justify",
    fontFamily: "gilroy-medium",
    marginVertical: 1,
    color: "#434343",
  },
  image: {
    width: 60,
    height: 60,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 9,
    bottom: 0,
    textAlign: "center",
    left: 0,
    right: 0,
    height: 150,
    fontFamily: "gilroy-medium",
    color: "#434343",
  },
  subtitle1: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: "gilroy-medium",
    marginVertical: 1,
    color: "#434343",
  },
  title1: {
    fontSize: 9,
    fontFamily: "gilroy-medium",
    marginVertical: 1,
    color: "#434343",
  },
  text1: {
    fontSize: 9,
    fontFamily: "gilroy-medium",
    marginVertical: 1,
    color: "#434343",
  },
};
