import React from "react";
import { Listview } from "@mobiscroll/react";
import { getMyProfile, updateProfile, listSpecializations } from "./api";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Input, Select } from "@mobiscroll/react5";
import deleteIcon from "src/assets/icons/delete.svg";
import editIcon from "src/assets/edit.svg";
import save_pink from "src/assets/save_pink.svg";
import { Modal } from "react-bootstrap";
import Loader from "src/components/Dashboard/common/Loader";
import { useState, useCallback } from "react";
import _debounce from "lodash/debounce";

function MyListItem(props) {
  const { handleEdit = () => {}, handleDelete = () => {} } = props;
  console.log(props);
  return (
    <li className="d-flex justify-content-between align-items-center">
      {props.item.title}
      <div className="">
        <button onClick={() => handleEdit(props.item.id)}>
          <img src={editIcon} alt="" />
        </button>{" "}
        <button
          onClick={() => {
            handleDelete(props.item.id);
          }}
        >
          <img src={deleteIcon} alt="" />
        </button>{" "}
      </div>
    </li>
  );
}

const DotorPopup = (props) => {
  console.log(props);
  const {
    show,
    closePopup,
    title,
    data,
    doctorId,
    reloadData = () => {},
  } = props;
  const [items, setItems] = React.useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [showSpecializations, setShowSpecializations] = useState(true);
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  const searchSpecializations = async (search) => {
    let res = await listSpecializations(search);
    res = res.map(({ name }) => ({ text: name, value: name }));
    console.log(res);
    setSpecializations(res);
  };

  console.log(specializations);

  const debounceFn = useCallback(_debounce(searchSpecializations, 1000), []);

  React.useEffect(() => {
    searchSpecializations("");
  }, []);

  React.useEffect(() => {
    if (data?.length) setValue(data[0]);
  }, [data]);

  const stages = {
    left: [
      {
        percent: 25,
        key: "stage2",
        icon: editIcon,
        color: "#c06c84",
        text: "Edit",
        action: (event, inst) => {
          handleEdit(event.index);
        },
      },
    ],
    right: [
      {
        percent: -25,
        key: "stage1",
        icon: "remove",
        color: "crimson",
        text: "Remove",
        action: (event, inst) => {
          handleDelete(event.index);
        },
      },
    ],
  };

  const handleDelete = React.useCallback(
    async (id) => {
      setLoader(true);
      try {
        let arr = items.filter((item) => item.id !== id);
        arr = arr.map((item) => item.title);
        let res = await updateProfile({ id: doctorId, specialization: arr });
        if (res) {
          reloadData();
        }
      } catch (e) {
        console.log("Error", e);
      }
      setLoader(false);
    },
    [items, value, setItems]
  );
  const handleEdit = React.useCallback(
    (index) => {
      let value = "";
      let data = items.filter((item) => {
        if (item.id != index) {
          return true;
        } else {
          value = item.title;
        }
      });
      setItems(data);
      setValue(value);
      document.getElementById("editInput").focus();
    },
    [items, setItems, setValue]
  );
  const handleChange = async () => {
    setLoader(true);

    try {
      if (value.trim() == "") {
        setError("Enter value");
      } else {
        let res = await updateProfile({
          id: doctorId,
          specialization: [value],
        });
        if (res) {
          setValue("");
          reloadData();
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoader(false);
  };

  return (
    <>
      <Loader show={loader} />
      <Modal
        show={show}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "auto", height: "250px" }}>
          <div className="mbsc-row">
            <div className="mbsc-col-12">
              <div className="mbsc-col-12">
                <Dropdown
                  placeholder={`Search ${title}`}
                  fluid
                  search
                  selection
                  forceSelection={false}
                  selectOnBlur={false}
                  options={specializations}
                  value={value}
                  onChange={(e, { value }) => {
                    console.log(e, e.target.textContent);

                    setValue(e.target.textContent);
                  }}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderColor: "#b2b3b4",
                    borderRadius: "0",
                    color: "#93969b",
                    fontWeight: "600",
                  }}
                />
              </div>
              <div className="mbsc-col-12 d-flex align-items-end justify-content-center mt-2">
                {/* <Select
                  label="Specialization"
                  inputProps={{
                    inputStyle: "box",
                    labelStyle: "floating",
                    placeholder: "",
                  }}
                  value={value}
                  data={specializations}
                  onChange={(e) => {
                    setValue(e.value);
                  }}
                /> */}
                <button onClick={handleChange} class="docg-save-btn m-0">
                  Add
                </button>
              </div>
              {/* <Listview
                stages={stages}
                theme="ios"
                itemType={(props) => (
                  <MyListItem
                    {...props}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                )}
                data={items}
              /> */}
            </div>

            {/* <div className="mbsc-col-12 d-flex flex-column mt-2 overflow-auto" style={{height:"100px"}}>
                {specializations
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() => {
                        setValue(item.name)
                        // setShowSpecializations(false);
                      }}
                      key={item.name}
                    >
                      {item.name}
                    </div>
                  ))}
              </div> */}
            {/* <div className="mbsc-col-12 d-flex align-items-end justify-content-center mt-2">
              <datalist id="suggestions">
                {specializations.slice(0, 10).map((item) => (
                  <option
                    value={item.name}
                    onClick={() => {
                      setValue(item.name);
                      // setShowSpecializations(false);
                    }}
                  >
                    {item.name}
                  </option>
                ))}
              </datalist>
            </div> */}
            <div className="mbsc-col-12">
              <span className="mbsc-error-message mbsc-ios">{error}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DotorPopup;
