import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { useHistory } from "react-router-dom";
import defaultDoctorIcon from "src/assets/defaultDoctor.svg";
import { listPatientHistory } from "./api";
import { snackbar } from "@mobiscroll/react5";
import Loader from "src/components/Dashboard/common/Loader";

function PatientNameCellRenderer({ params, closePopup }) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const LINK = `/patient-record/${params.value.patientDetails.patientId}`;
  const handleRedirect = React.useCallback(() => {
    setLoader(true);
    try {
      listPatientHistory(params.value.patientDetails.patientId).then((res) => {
        console.log(res);
        if (res) {
          closePopup();
          history.push(LINK);
        } else {
          snackbar({
            message: "No Medical history found",
            color: "danger",
          });
        }
        setLoader(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, [params, listPatientHistory]);

  return (
    <>
      <Loader show={loader} />
      <button onClick={handleRedirect} className="docg-app-patient-name w-100">
        <Image
          src={
            params.value.patientDetails.profilePicture
              ? params.value.patientDetails.profilePicture
              : defaultDoctorIcon
          }
          alt=""
          roundedCircle
          style={{ width: 35, height: 35 }}
        />{" "}
        {params.value.patientDetails.patientName}{" "}
      </button>
    </>
  );
}

export default PatientNameCellRenderer;
