import React, { Fragment } from "react";
import "react-circular-progressbar/dist/styles.css";

import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
const CheckBox = (props) => {
  return (
    <Fragment>
      {props?.checkboxData?.length > 0 ? (
        props?.checkboxData?.map((data, index) => (
          <Fragment key={index}>
            <div className="d-flex mr-2">
              <Checkbox
                icon={<Icon.FiCheck color="#C06C84" size={14} />}
                name="my-input"
                checked={data?.value}
                className="CheckBoxInput"
                borderColor="#C06C84"
                style={{ cursor: "pointer" }}
              />
              <span className="ml-2">{data?.name}</span>
            </div>
          </Fragment>
        ))
      ) : (
        <p>No data</p>
      )}
    </Fragment>
  );
};

export default CheckBox;
