import React from "react";
import { Card } from "react-bootstrap";
import pdfIcon from "src/assets/icons/labReport.svg";
import Loader from "src/components/Dashboard/common/Loader";
import useInvoicePdf from "../../DownloadInvoice";

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const Invoice = ({ id, index }) => {
  const {
    downloadInvoice,
    isInvoiceLoading,
    invoiceData,
    subTotal,
    total,
    tax,
  } = useInvoicePdf(id);

  return (
    <button
      className="d-flex flex-column align-items-center"
      onClick={() => {
        downloadInvoice();
      }}
    >
      <img src={pdfIcon} alt="" />
      <i className="mt-2">Invoice {index + 1}</i>
    </button>
  );
};

const HistoryDialog = (props) => {
  const { data = [] } = props;

  const [loader, setLoader] = React.useState(false);

  return (
    <>
      <Loader show={loader} />
      <Card className="docg-patient-form-wrapper docg-card mt-4 mb-4">
        <Card.Body>
          <div className="history-dialog">
            <div className="mbsc-grid">
              <div className="mbsc-row mt-3">
                <div className="mbsc-col pl-0">
                  <div className="mbsc-padding">
                    {data?.map((invoice, index) => (
                      <>
                        <Invoice id={invoice} index={index} key={index} />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default HistoryDialog;
