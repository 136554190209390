export const STARTING_CONSULTATION = "STARTING_CONSULTATION";
export const SELECT_CONSULTATION = "SELECT_CONSULTATION";
export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";
export const PAST_CONSULTATION_DATE = "PAST_CONSULTATION_DATE";
export const APPOINTMENT_ID = "APPOINTMENT_ID";
export const PATIENT_ID = "PATIENT_ID";
export const CONSULTATION_TYPE = "CONSULTATION_TYPE";
export const UNSAVED = "UNSAVED";
export const FUNCTION = "FUNCTION";

export const initialState = {
  showConsultation: false,
  startingConsultation: false,
  consultationType: null,
  activeTab: "medicalRecord",
  subActive: null,
  appointmentId: null,
  patientId: null,
  unsaved: false,
  bmi: null,
  tabSwitched: false,
  func: () => {},
  subFunc: () => {},
  appointmentDetails: null,
  patientHistory: [],
  prescriptionTemplate: false,
  labtestTemplate: false,
};

export const isStartingConsultation = (isStartingConsultationValue) => ({
  type: STARTING_CONSULTATION,
  startingConsultation: isStartingConsultationValue,
});

export const setUnsaved = (value) => ({
  type: UNSAVED,
  unsaved: value,
});
export const setFunction = (func) => ({
  type: FUNCTION,
  func: func,
});
export const setsubFunction = (func) => ({
  type: "SUB_FUNCTION",
  subFunc: func,
});
export const setTabSwitched = (value) => ({
  type: "TAB_SWITCHED",
  tabSwitched: value,
});

export const handleConsultationPopupChange = (value) => ({
  type: SELECT_CONSULTATION,
  showConsultation: value,
});

export const isActiveTab = (value) => ({
  type: CHANGE_ACTIVE_TAB,
  activeTab: value,
});
export const isSubActiveTab = (value) => ({
  type: "CHANGE_SUB_ACTIVE_TAB",
  subActive: value,
});

export const setPastConsultationDate = (value) => ({
  type: PAST_CONSULTATION_DATE,
  pastConsultationDate: value,
});
export const setConsultationType = (value) => ({
  type: CONSULTATION_TYPE,
  consultationType: value,
});

export const setAppointmentId = (appointmentId) => ({
  type: APPOINTMENT_ID,
  appointmentId,
});
export const setPatientId = (patientId) => ({
  type: PATIENT_ID,
  patientId,
});

export const patientDetailsReducer = (state, action, initialState) => {
  console.log(action);
  switch (action.type) {
    case STARTING_CONSULTATION:
      return {
        ...state,
        startingConsultation: action.startingConsultation,
      };
    case CONSULTATION_TYPE:
      return {
        ...state,
        consultationType: action.consultationType,
      };
    case CHANGE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case "CHANGE_SUB_ACTIVE_TAB":
      return {
        ...state,
        subActive: action.subActive,
      };
    case SELECT_CONSULTATION:
      return {
        ...state,
        showConsultation: action.showConsultation,
      };
    case PAST_CONSULTATION_DATE:
      return {
        ...state,
        pastConsultationDate: action.pastConsultationDate,
      };
    case APPOINTMENT_ID:
      return {
        ...state,
        appointmentId: action.appointmentId,
      };
    case PATIENT_ID:
      return {
        ...state,
        patientId: action.patientId,
      };
    case UNSAVED:
      return {
        ...state,
        unsaved: action.unsaved,
      };
    case FUNCTION:
      return {
        ...state,
        func: action.func,
      };
    case "SUB_FUNCTION":
      return {
        ...state,
        subFunc: action.subFunc,
      };
    case "APPT_DETAILS":
      return {
        ...state,
        appointmentDetails: action.appointmentDetails,
      };
    case "TAB_SWITCHED":
      return {
        ...state,
        tabSwitched: action.tabSwitched,
      };
    case "BMI":
      let height = +action.payload?.height?.split(" ")[0];
      height = height / 100;
      let weight = +action?.payload?.weight?.split(" ")[0];
      let bmi = weight / (height * height);
      console.log(height, weight, bmi);
      if (Number.isNaN(bmi)) {
        bmi = "-";
      } else bmi = bmi.toFixed(2);
      return {
        ...state,
        bmi,
      };
    case "PATIENT_DETAILS":
      return {
        ...state,
        patientDetails: { ...action.payload },
      };
    case "CUSTOM":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
