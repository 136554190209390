import React, { Fragment, useState, lazy } from "react";
import { FormGroup, Input, Listview, Button } from "@mobiscroll/react";
import mobiscroll from "@mobiscroll/react";
import attachmentIcon from "src/assets/Attachment-icon.svg";
import useQuery from "src/lib/useQuery";
import { useStore } from "../store/Store";
import { handleConsultationPopupChange } from "../store/PatientDetailsReducer";
import { listSymptoms } from "../api";
const ConsultationPopup = lazy(() => import("./Modals/ConsultationPopup"));

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
let img_fm = [
  ".tif",
  ".tiff",
  ".jpg",
  ".jpeg",
  ".gif",
  ".png",
  ".eps",
  ".raw",
  ".cr2",
  ".nef",
  ".orf",
  ".sr2",
  ".bmp",
  ".ppm",
  ".heif",
];
let vid_fm = [
  ".flv",
  ".avi",
  ".mp4",
  ".3gp",
  ".mov",
  ".webm",
  ".ogg",
  ".qt",
  ".avchd",
];
let aud_fm = [".flac", ".mp3", ".wav", ".wma", ".aac"];
const AvatarItem = ({ item }) => {
  const { question, textDescription } = item;
  console.log(item);
  const [videoDescription, setVideoDescription] = useState(null);
  const [imgDescription, setImgDescription] = useState(null);
  const [audioDescription, setAudioDescription] = useState(null);
  const [pdfDescription, setPDFDescription] = useState(null);

  React.useEffect(() => {
    if (item.mediaDescription.length) {
      item.mediaDescription.map((media) => {
        const getFileExtention = (fileUrl) => {
          // To get the file extension
          return /[.]/.exec(fileUrl)
            ? /\.\w{3,4}($|\?)/.exec(fileUrl)
            : undefined;
        };
        let file_ext = getFileExtention(media);
        if (file_ext != null) {
          file_ext = file_ext[0];
          file_ext = file_ext.split("?")[0];
        }

        if (img_fm.filter((ext) => ext == file_ext).length > 0) {
          setImgDescription(media);
        } else if (vid_fm.filter((ext) => ext == file_ext).length > 0) {
          setVideoDescription(media);
        } else if (aud_fm.filter((ext) => ext == file_ext).length > 0) {
          setAudioDescription(media);
        } else {
          setPDFDescription(media);
        }
      });
    }
  }, []);

  return (
    <>
      <div className="card-1">
        <li className="item-left">
          {/* <img src={Doctor} /> */}
          <h3>
            <span>Q.</span>&nbsp; Which Symptoms are bothering you the most?
          </h3>
        </li>
        <li className="item-right">
          <h3>
            <span>A.</span>&nbsp;
            {textDescription}
            <br />
            <br />
            <b>
              {videoDescription ||
              audioDescription ||
              imgDescription ||
              pdfDescription
                ? "Find the below Attachments: "
                : ""}
            </b>
          </h3>
          <br /> <br />{" "}
          <div className="d-flex flex-column">
            {videoDescription && (
              <audio width="320" height="240" controls>
                <source src={videoDescription} />
                Your browser does not support the video tag.
              </audio>
            )}
            {audioDescription && (
              <audio width="320" height="240" controls>
                <source src={audioDescription} />
                Your browser does not support the video tag.
              </audio>
            )}
            {imgDescription && (
              <img
                className="w-3"
                style={{ maxWidth: "100px" }}
                src={imgDescription}
                alt=""
              />
            )}
            {pdfDescription && (
              <a className="d-flex" target="_blank" href={pdfDescription}>
                PDF:{" "}
                <img
                  className="w-3"
                  style={{ maxWidth: "30px" }}
                  src={attachmentIcon}
                  alt=""
                />
              </a>
            )}
          </div>
        </li>
      </div>
    </>
  );
};

export const Symptom = ({ data, open }) => {
  console.log(data);
  return (
    <>
      <div className="mt-2">
        <mobiscroll.Accordion className="p-0">
          <mobiscroll.FormGroup collapsible open={open}>
            {/* <mobiscroll.FormGroupTitle className="mbsc-row mt-0 mb-0 justify-content-between docg-dropdown pt-1 pb-1">
              <div className="d-flex align-items-center justify-content-end">
                <span>
                  {new Date(data?.start).toLocaleDateString("en-US", options)}
                </span>
              </div>
            </mobiscroll.FormGroupTitle> */}
            <mobiscroll.FormGroupContent>
              <Listview
                itemType={AvatarItem}
                data={data}
                className="docg-message-list"
                enhance={true}
                actionable={false}
                swipe={false}
                sortable={true}
              />
            </mobiscroll.FormGroupContent>
          </mobiscroll.FormGroup>
        </mobiscroll.Accordion>
      </div>
    </>
  );
};

const SymptomTab = (props) => {
  const query = useQuery();

  const { globalState = null, dispatch = null } = useStore() || {};

  const [data, setData] = React.useState(null);
  const [appointmentId, setAppointmentId] = useState(null);

  React.useEffect(async () => {
    if (globalState != null && !globalState?.startingConsultation) {
      dispatch(handleConsultationPopupChange(true));
    } else {
      let appointmentId = query.get("appointmentId");
      const res = await listSymptoms(appointmentId);
      setData((prev) => res);
    }
  }, []);

  if (globalState?.showConsultation) {
    return <ConsultationPopup />;
  }

  return (
    <div className="">
      {data != null && <Symptom data={data?.survey?.questions} open={true} />}
    </div>
  );
};

export default SymptomTab;
