import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { GET_TODO_LIST } from "src/components/graphql/GraphQLquery";
import { UPDATE_TODO_LIST } from "src/components/graphql/GraphQLmutation";
import { snackbar } from "@mobiscroll/react5";

export async function getTodoList(displayRecords) {
  try {
    let response = await getQuery(GET_TODO_LIST(displayRecords));
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.listToDoNotes.data) {
        return response.data.listToDoNotes;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Todo List.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return [];
  }
}
export async function updateToDoNote(data) {
  try {
    let response = await getQueryWithParams(UPDATE_TODO_LIST, data);
    if (
      typeof response == "undefined" ||
      response.errors ||
      !(
        response.data.updateToDoNote.statusCode < 300 &&
        response.data.updateToDoNote.statusCode >= 200
      )
    ) {
      throw new Error(response);
    } else {
      if (
        response.data !== null &&
        response.data &&
        response?.data?.updateToDoNote
      ) {
        return true;
      } else {
        throw new Error();
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Todo List.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return [];
  }
}
