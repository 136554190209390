import { Datepicker, momentTimezone } from "@mobiscroll/react5";
import React from "react";
import { Modal } from "react-bootstrap";

import moment from "moment-timezone";
import followupSvg from "../../assets/icons/FollowUpButton.svg";
import Button from "../Button";

import { listTimeSlots } from "src/components/Dashboard/Calendar/api/index";
import { setFollowUp } from "./api";
import Loader from "src/components/Dashboard/common/Loader";
momentTimezone.moment = moment;

const FollowUp = ({ showFollowUp, setShowFollowUp, title, appointmentId }) => {
  const [openSlots, setOpenSlots] = React.useState([]);

  const [selectedSlot, setSelectedSlot] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  const changeButton = (id) => {
    setSelectedSlot(id);
  };

  const fetchTimeSlots = React.useCallback(async () => {
    setLoader(true);
    try {
      const doctorId = sessionStorage.getItem("role-id");
      setSelectedSlot(null);
      setOpenSlots([]);
      if (date != null) {
        let start = new Date(date);
        console.log(date);
        start.setHours(0);
        start.setMinutes(0);
        start.setMilliseconds(0);
        let end = new Date(start);
        end.setDate(start.getDate() + 1);
        const res = await listTimeSlots({
          doctorId,
          start: start.toISOString(),
          end: end.toISOString(),
          statuses: "Active",
        });
        setOpenSlots(res);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  }, [listTimeSlots, date]);

  React.useEffect(() => {
    if (date != null) {
      fetchTimeSlots();
    } else {
      setOpenSlots([]);
    }
  }, [date]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setLoader(true);
      if (date != null) {
        const res = await setFollowUp({
          appointmentId,
          timeslotId: selectedSlot,
        });
        setShowFollowUp(false);
      } else {
        setError("Please Select a slot");
      }
      setLoader(false);
    },
    [setFollowUp, appointmentId, selectedSlot]
  );

  console.log(openSlots);

  return (
    <>
      <Loader show={loader} />
      <Modal
        size="lg"
        show={showFollowUp}
        onHide={() => setShowFollowUp(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="follow-up-dialog-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle">{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="follow-up-body">
            <div className="w-100">
              <Datepicker
                controls={["calendar"]}
                timezonePlugin={momentTimezone}
                displayTimezone="ist"
                dataTimezone="ist"
                dateFormat="MMMM D, YYYY"
                theme="ios"
                themeVariant="light"
                touchUi
                display="anchored"
                label="Select Consultation Date"
                inputProps={{
                  inputStyle: "box",
                  labelStyle: "floating",
                  placeholder: "Please select",
                }}
                onChange={(e) => {
                  console.log(e);
                  setDate(new Date(e.value));
                }}
                value={date}
              />
            </div>
            <div className="follow-modal-header" style={{ marginTop: "20px" }}>
              <span className={`${error ? "mbsc-error-message mbsc-ios" : ""}`}>
                Pick the time slot
              </span>
              <div className="slot-button">
                {date == null ? (
                  "Please Select Date to pick the time slot!"
                ) : (
                  <>
                    {openSlots.length > 0
                      ? openSlots.map((slot) => {
                          return (
                            <Button
                              name={`${new Date(slot.start).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )} - ${new Date(slot.end).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}`}
                              className={`col newAppointment height-35 no-box-shadow ${
                                slot.id === selectedSlot ? "" : "non-selected"
                              }`}
                              text={"text-white "}
                              onClick={() => {
                                changeButton(slot.id);
                              }}
                            />
                          );
                        })
                      : "No Slots Found"}
                  </>
                )}
              </div>
            </div>
            <div className="follow-up-button mt-4 d-flex align-items-center justify-content-center">
              <button
                disabled={selectedSlot == null}
                onClick={handleSubmit}
                className="docg-save-btn"
              >
                <img className="follow-up-img" src={followupSvg} alt="" />
                {title}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FollowUp;
