import mobiscroll, { Switch } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { Fragment, useState } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import Eye from "src/assets/icons/Eye.svg";
import PendingLabRepotIcon from "src/assets/icons/PendingLabRepotIcon.svg";
import Print from "src/assets/icons/print.svg";
import "./PendingLabRepot.css";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

function StatusCellRenderer(params) {
  const width = window.innerWidth;
  return (
    <div style={{ backgroundColor: "transparent" }}>
      {width > 1100 ? (
        <Switch
          className="docg-table-switch"
          defaultChecked={params.value}
          theme="auto"
          position="start"
          color="primary"
        />
      ) : (
        <Switch
          defaultChecked={params.value}
          theme="auto"
          position="start"
          color="success"
          style={{ height: "20px" }}
        />
      )}
    </div>
  );
}

function ActionCellRender(params) {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <div className="docg-table-action">
      <Button variant="link" onClick={handleShow1}>
        <Image src={Eye} alt="Patient Detail" />
      </Button>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton style={{ textAlign: "center" }}>
          <center
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#6c5b7b",
              position: "absolute",
              left: "0",
              right: "0",
            }}
          >
            Lab Report
          </center>
          <div style={{ position: "absolute", left: "87%", top: "2.5%" }}>
            <Image src={Print} alt="Print" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "80%", color: "#6c5b7b" }}>Personal History</p>
          <p style={{ fontSize: "80%" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur
          </p>
          <br />
          <p style={{ fontSize: "80%", color: "#6c5b7b" }}>Physical Health</p>
          <p style={{ fontSize: "80%" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur
          </p>
          <br />
          <p style={{ fontSize: "80%", color: "#6c5b7b" }}>
            Drug History and Current Pattern of Use
          </p>
          <p style={{ fontSize: "80%" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur
          </p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

const PendingLabRepot = ({ data }) => {
  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("05");

  React.useEffect(() => {
    if (Array.isArray(data)) {
      setRowData(data);
    }
  }, [data]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("labReportFilter").value);
  };

  const onPageSizeChanged = (e) => {
    setDisplayRecords(e.valueText);
    // console.log(Number(e.valueText));
    gridApi.paginationSetPageSize(Number(e.valueText));
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  // eslint-disable-next-line
  const [rowData, setRowData] = useState([]);

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  return (
    <Fragment>
      <Card className="docg-patient-form-wrapper docg-card mt-4 mb-4">
        <Card.Title>
          <div className="w-100 d-flex align-items-center">
            <img src={PendingLabRepotIcon} className="mr-2" alt="" />
            Lab Tests
          </div>
        </Card.Title>
        <CardBody className="docg-card-body p-0">
          <Row>
            <Col>
              <div
                className="ag-theme-alpine"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <AgGridReact
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    actionCellRender: ActionCellRender,
                    statusCellRenderer: StatusCellRenderer,
                  }}
                  defaultColGroupDef={defaultColGroupDef}
                  enableRangeSelection={true}
                  pagination={true}
                  onGridReady={onGridReady}
                  domLayout={"autoHeight"}
                  animateRows={true}
                >
                  <AgGridColumn
                    headerName="Instructions"
                    headerTooltip="Instructions"
                    field="instructions"
                    tooltipField="instructions"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Lab Test Name"
                    headerTooltip="Lab Test Name"
                    field="testName"
                    tooltipField="testName"
                    initialPinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Optional Instructions"
                    headerTooltip="Optional Instructions"
                    field="optionalInstructions"
                    valueFormatter={(params) => {
                      return params.value == 0 ? "Fasting" : "Non-Fasting";
                    }}
                    tooltipValueGetter={(params) => {
                      return params.value == 0 ? "Fasting" : "Non-Fasting";
                    }}
                    filter={false}
                    sortable={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PendingLabRepot;
