import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  LIST_TIME_SLOTS,
  GET_DOCTOR_ID,
  GET_DOCTOR_SCHEDULES,
  LIST_PATIENTS_CALENDAR,
  GET_ACTIVE_POLICIES,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import {
  CLOSE_TIME_SLOT,
  OPEN_TIME_SLOT,
  ADD_APPOINTMENT_BY_DOCTOR,
} from "src/components/graphql/GraphQLmutation";

export async function getActivePolicies() {
  try {
    let response = await getQueryWithParams(GET_ACTIVE_POLICIES);
    if (typeof response == "undefined" || response.errors) {
      throw new Error(response);
    } else {
      if (
        response.data !== null &&
        response.data &&
        response?.data?.getMyProfile?.data
      ) {
        return response.data.getMyProfile.data;
      } else {
        if (response?.data?.getMyProfile.message)
          throw new Error(response?.data?.getMyProfile.message);
      }
    }
    throw new Error("Something went wrong!");
  } catch (error) {
    snackbar({
      message: error.message,
      color: "danger",
    });
    console.log("ERROR at list Schedules", error);
    return [];
  }
}
export async function listTimeSlots(data) {
  try {
    let response = await getQueryWithParams(LIST_TIME_SLOTS, data);
    if (typeof response == "undefined" || response.errors) {
      throw new Error(response);
    } else {
      if (
        response.data !== null &&
        response.data &&
        response?.data?.listTimeslots?.data
      ) {
        return response.data.listTimeslots.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Some error occured while fetching Time Slots. Try again",
      color: "danger",
    });
    console.log("ERROR at list Schedules", error);
    return [];
  }
}
export async function getTimeSlots(data) {
  try {
    const response = await listTimeSlots(data);
    if (response) {
      return storeTimeSlots(response);
    }
  } catch (err) {
    console.log("ERROR at list Time Slots", err);
    return [];
  }
}
function storeTimeSlots(response) {
  try {
    let data = [];
    response.forEach((_el) => {
      const el = (({
        end,
        groupId,
        id,
        scheduleId,
        doctorId,
        start,
        status,
        patient,
        appointmentId,
      }) => ({
        end,
        groupId,
        id,
        scheduleId,
        doctorId,
        start,
        status,
        patient,
        title: status,
        appointmentId,
      }))(_el);
      data.push(el);
    });
    return data;
  } catch (err) {
    console.error("Error at storing time slots", err);
    return [];
  }
}
export async function getDoctorId() {
  try {
    let response = await getQuery(GET_DOCTOR_ID);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (
        response.data !== null &&
        response.data &&
        response?.data?.getMyProfile?.data &&
        response?.data?.getMyProfile?.data?.id
      ) {
        return response.data.getMyProfile.data.id;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at get doctor id", error);
    return undefined;
  }
}

export async function openClosedSlot(data) {
  try {
    let response = await postMutation(OPEN_TIME_SLOT, data);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (
        response.data !== null &&
        response.data &&
        response?.data?.openTimeslot &&
        response?.data?.openTimeslot?.statusCode
      ) {
        if (
          response.data.openTimeslot.statusCode < 300 &&
          response.data.openTimeslot.statusCode >= 200
        ) {
          snackbar({
            message: "Slot opened Successfully.",
            color: "success",
          });
          return true;
        } else {
          throw new Error(response);
        }
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("ERROR at opening slot", error);
    return false;
  }
}

export async function closeOpenSlot(data) {
  try {
    let response = await postMutation(CLOSE_TIME_SLOT, data);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data !== null && response.data) {
        if (
          response.data.closeTimeslot?.statusCode < 300 &&
          response.data.closeTimeslot?.statusCode >= 200
        ) {
          snackbar({
            message: "Slot closed Successfully.",
            color: "success",
          });
          return true;
        } else {
          throw new Error(response);
        }
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("ERROR at closing slot", error);
    return false;
  }
}

export async function listPatients(search, take) {
  try {
    let response = await getQuery(LIST_PATIENTS_CALENDAR(search, take));
    if (
      response.data !== null &&
      response?.data?.listClinicPatientsAll?.data !== null &&
      Array.isArray(response.data?.listClinicPatientsAll?.data)
    ) {
      return response.data?.listClinicPatientsAll?.data;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching patient list. Try again later.",
      color: "danger",
    });
    return [];
  }
}

export async function addAppointmentByDoctor(data) {
  try {
    let response = await postMutation(ADD_APPOINTMENT_BY_DOCTOR, data);
    if (
      response.data !== null &&
      response?.data?.addAppointmentByDoctor?.statusCode >= 200 &&
      response?.data?.addAppointmentByDoctor?.statusCode < 300
    ) {
      return true;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching patient list. Try again later.",
      color: "danger",
    });
    return false;
  }
}
