import React, { Fragment } from "react";
import "react-circular-progressbar/dist/styles.css";

const CardTitle = (props) => {
  return (
    <Fragment>
      <h5 className={`${props.className}`}>{props.title}</h5>
    </Fragment>
  );
};

export default CardTitle;
