import React from "react";
import { openNewWindow, closeWindow } from "src/lib/openNewWindow";
import { Modal, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import img from "src/assets/icons/permission.svg";
import moment from "moment";
import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { END_CONSULTATION } from "src/components/graphql/GraphQLmutation";
import { snackbar } from "@mobiscroll/react5";
import Loader from "../common/Loader";

const EndConsultationModal = ({ data, show, onClose, onConfirm = null }) => {
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  console.log(data);
  let LINK;
  if (data)
    LINK = `/patient-record/${data?.patient?.id}?appointmentId=${data?.id}`;

  const endConsultation = async () => {
    setLoader(true);
    try {
      let response = await postMutation(END_CONSULTATION, {
        appointmentId: data.id,
      });
      if (
        typeof response == "undefined" ||
        response.errors ||
        !(
          response.data.finishConsultation.statusCode < 300 &&
          response.data.finishConsultation.statusCode >= 200
        )
      ) {
        console.log("here");
        throw new Error(response);
      } else {
        if (
          response.data !== null &&
          response.data &&
          response?.data?.finishConsultation
        ) {
          console.log("here");
          closeWindow();
          console.log("here");
          snackbar({
            message: "Consultation ended successfully.",
            color: "success",
          });

          redirect("/dashboard");
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      snackbar({
        message: "Error while ending Consultation.",
        color: "danger",
      });
      console.log("ERROR at list fee", error);
    }
    setLoader(false);
    onClose();
  };

  const redirect = (link) => {
    if (onConfirm != null) {
      onConfirm();
    } else {
      history.push(link);
    }
  };

  const handleNo = () => {
    redirect(LINK);
    onClose();
  };

  return (
    <div>
      <Loader show={loader} />
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle">In-Progress Consultation</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center patient-permission-modal">
          <img src={img} alt="" />
          <h4>
            Do you want to end on-going <a href={LINK}>consultation</a> ?
          </h4>
          Patient Information:&nbsp;
          {data?.patient?.firstName} {data?.patient?.lastName}
          <br />
          Appointment Time: {moment(data?.start).format("DD MMM HH:mm")} -{" "}
          {moment(data?.end).format("DD MMM HH:mm")}
          <br />
          <div className="d-flex align-items-center justify-content-center mt-2">
            <button
              disabled={loader}
              onClick={endConsultation}
              className="docg-save-btn mr-2"
            >
              Yes
            </button>
            <button
              disabled={loader}
              onClick={handleNo}
              className="docg-patient-cancel-btn"
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EndConsultationModal;
