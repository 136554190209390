import { Input, Select, Textarea } from "@mobiscroll/react5";
import QRCode from "qrcode.react";
import React, { Fragment, useState } from "react";

//componentes de
import DoctorPopup from "./DotorPopup";

import mobiscroll, { NavItem, TabNav } from "@mobiscroll/react";
import { Card, Col, Row } from "react-bootstrap";
import patient from "src/assets/person.svg";
import AccountDetail from "../../../assets/Group 87.svg";
import plus_icon from "../../../assets/plus-circle.svg";
import { MFASettings } from "./MFASettings";

console.log(process.env.REACT_APP_REGISTRATION_URL);

const RightPanel = (props) => {
  const [activeTab, setActiveTab] = useState("about");

  return (
    <Fragment>
      <Card className=" docg-card docg-appointment docg-vh-standard margin-none">
        <Card.Body className="m-0 p-0">
          <TabNav className="docg-tabs" theme="windows" context="#tab-content">
            <NavItem
              selected={activeTab === "about"}
              onClick={() => setActiveTab("about")}
            >
              <img src={patient} alt="" className="mr-2" />
              About
            </NavItem>
            <NavItem
              selected={activeTab === "account"}
              onClick={() => setActiveTab("account")}
            >
              <img src={AccountDetail} alt="" className="mr-2" />
              Account
            </NavItem>
          </TabNav>
          {activeTab === "about" && <AboutSection {...props} />}
          {activeTab === "account" && <AccountSection {...props} />}
        </Card.Body>
      </Card>
    </Fragment>
  );
};

let yrs = new Array();
let mnth = new Array();

const yrsUnit = ["Y"];
const mnthUnit = ["M"];

for (let index = 0; index < 101; index++) {
  yrs.push(index);
}
for (let index = 0; index <= 11; index++) {
  mnth.push(index);
}

const yrsOfExp = [
  [
    {
      circular: false,
      data: yrs,
    },
    {
      circular: false,
      data: yrsUnit,
    },
    {
      circular: false,
      data: mnth,
    },
    {
      circular: false,
      data: mnthUnit,
    },
  ],
];
const parseValueYrs = (val) => {
  if (val) {
    val = val.toString().split(" ");
    return val;
  }

  return [0, "years", 0, "months"];
};

const formatValueYrs = (data) => {
  return data[0] + " " + data[1] + " " + data[2] + " " + data[3];
};
const AboutSection = ({
  data,
  setData,
  edit,
  setEdit,
  edited,
  setEdited,
  setTouched,
  cancelOnClick,
  editOnClick,
  _renderAction,
  handleChange,
}) => {
  const scroller = React.useRef(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [selected, setSelected] = useState(null);

  const closePopup = React.useCallback(() => {
    setIsOpenPopup(false);
    setSelected(null);
  }, []);

  const openPopup = (e, title, items, obj) => {
    setSelected({ placeholder: title, items: items, obj: obj });
    setIsOpenPopup(true);
  };
  return (
    <>
      <div
        style={{
          height: "calc(100% - 54px",
          background: "#fff",
          borderRadius: "0.25rem",
        }}
        className="mbsc-grid p-3"
      >
        <div className="mbsc-row">
          <div className="mbsc-col-12 profile-list-title">
            <span>Biography</span>
          </div>
        </div>
        <div className="mbsc-row mt-2">
          <div className="mbsc-col-12 docg-profile-text">
            {edit ? (
              <Textarea
                name="biography"
                inputStyle="underline"
                labelStyle="floating"
                value={edited?.doctorProfile?.biography}
                onChange={(e) => {
                  setEdited((prev) => {
                    return {
                      ...prev,
                      doctorProfile: {
                        ...prev?.doctorProfile,
                        biography: e.target.value,
                      },
                    };
                  });
                  setTouched((prev) => {
                    return { ...prev, doctorProfile: true };
                  });
                }}
              ></Textarea>
            ) : (
              <span>{data?.doctorProfile?.biography}</span>
            )}
          </div>
        </div>
        <hr />
        <div className="mbsc-row horizontal-bar">
          <div className="mbsc-col-12 profile-list-title">
            <span>Qualifications</span>
          </div>
        </div>
        <mobiscroll.FormGroup>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                <span>Hospital Affiliations :</span>{" "}
                {data?.doctorProfile?.hospitalAffiliations
                  ? data?.doctorProfile?.hospitalAffiliations
                      .filter((el) => el != null && el != undefined)
                      .join(", ")
                  : "NA"}
              </div>
            </div>
            <button
              name="Hospital Affiliations"
              onClick={(e) =>
                openPopup(
                  e,
                  "Hospital Affiliations",
                  data?.doctorProfile?.hospitalAffiliations,
                  {
                    doctorProfile: {
                      hospitalAffiliations:
                        data?.doctorProfile?.hospitalAffiliations,
                    },
                  }
                )
              }
              className="mbsc-col-1"
            >
              <img src={plus_icon} />
            </button>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Degree*</span> :{" "}
                {data?.degree
                  ? data?.degree
                      .filter((el) => el != null && el != undefined)
                      .join(", ")
                  : "NA"}
              </div>
            </div>
            <button
              name="Degree"
              onClick={(e) =>
                openPopup(e, "Degree", data?.degree, {
                  degree: data?.degree,
                })
              }
              className="mbsc-col-1"
            >
              <img src={plus_icon} />
            </button>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Medical School*</span> :{" "}
                {data?.doctorProfile?.medicalSchool
                  ? data?.doctorProfile?.medicalSchool
                      .filter((el) => el != null && el != undefined)
                      .join(", ")
                  : "NA"}
              </div>
            </div>
            <button
              name="Medical School"
              onClick={(e) =>
                openPopup(
                  e,
                  "Medical School",
                  data?.doctorProfile?.medicalSchool,
                  {
                    doctorProfile: {
                      medicalSchool: data?.doctorProfile?.medicalSchool,
                    },
                  }
                )
              }
              className="mbsc-col-1"
            >
              <img src={plus_icon} />
            </button>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span>Residency* </span> :{" "}
                {data?.doctorProfile?.residencies
                  ? data?.doctorProfile?.residencies
                      .filter((el) => el != null && el != undefined)
                      .join(", ")
                  : "NA"}
              </div>
            </div>
            <button
              name="Residency"
              onClick={(e) =>
                openPopup(e, "Residency", data?.doctorProfile?.residencies, {
                  doctorProfile: {
                    residencies: data?.doctorProfile?.residencies,
                  },
                })
              }
              className="mbsc-col-1"
            >
              <img src={plus_icon} />
            </button>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Certifications*</span> :{" "}
                {data?.certifications
                  ? data?.certifications
                      .filter((el) => el != null && el != undefined)
                      .join(", ")
                  : "NA"}
              </div>
            </div>
            <button
              name="Certifications"
              onClick={(e) =>
                openPopup(e, "Certifications", data?.certifications, {
                  certifications: data?.certifications,
                })
              }
              className="mbsc-col-1"
            >
              <img src={plus_icon} />
            </button>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                {edit ? (
                  <Select
                    data={[
                      { text: "M", value: "M" },
                      { text: "F", value: "F" },
                      { text: "TG", value: "TG" },
                    ]}
                    label="Gender *"
                    inputProps={{
                      inputStyle: "underline",
                      labelStyle: "floating",
                      placeholder: "Please select",
                    }}
                    touchUi={false}
                    value={edited?.gender}
                    onChange={(e) => {
                      setEdited((prev) => {
                        return {
                          ...prev,
                          gender: e.valueText,
                        };
                      });
                      setTouched((prev) => {
                        return { ...prev, gender: true };
                      });
                    }}
                  />
                ) : (
                  <>
                    <span>Gender*</span> : {data?.gender}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                {edit ? (
                  <mobiscroll.Scroller
                    wheels={yrsOfExp}
                    placeholder="Please Select..."
                    ref={scroller}
                    touchUi={false}
                    display="bubble"
                    parseValueYrs={parseValueYrs}
                    formatValueYrs={formatValueYrs}
                    value={edited?.yearsOfExperience}
                    onChange={(e) => {
                      setTouched((prev) => {
                        return { ...prev, yearsOfExperience: true };
                      });
                      setEdited((prev) => {
                        return {
                          ...prev,
                          yearsOfExperience: e.valueText,
                        };
                      });
                    }}
                  >
                    <Input
                      inputStyle="underline"
                      labelStyle="floating"
                      placeholder="Please Select..."
                      label="Years of Experience*"
                      name="yearsOfExperience"
                    />
                  </mobiscroll.Scroller>
                ) : (
                  <>
                    <span>Year of Experience*</span> : {data?.yearsOfExperience}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                {edit ? (
                  <Input
                    label="Awards"
                    type="number"
                    min={0}
                    inputStyle="underline"
                    labelStyle="floating"
                    placeholder=""
                    touchUi={false}
                    value={edited?.award}
                    onChange={(e) => {
                      setEdited((prev) => {
                        return {
                          ...prev,
                          award: e.target.value,
                        };
                      });
                      setTouched((prev) => {
                        return { ...prev, award: true };
                      });
                    }}
                  />
                ) : (
                  <>
                    <span>Awards*</span> : {data?.award}
                  </>
                )}
              </div>
            </div>
          </div>
          {_renderAction()}
        </mobiscroll.FormGroup>
      </div>
      <DoctorPopup
        data={data}
        closePopup={closePopup}
        setData={setData}
        selected={selected}
        show={isOpenPopup}
      />
    </>
  );
};

const AccountSection = ({ data, handleImageChange }) => {
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QRCode_${data?.clinicName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const url = process.env.REACT_APP_CLINIC_URL;

  let id = sessionStorage.getItem("clinic_id");
  const [value, setValue] = useState(url + "/add-patient-by-url/" + id);
  return (
    <div
      style={{
        height: "calc(100% - 54px",
        background: "#fff",
        borderRadius: "1.25rem",
      }}
      className="mbsc-grid p-3 "
    >
      <Card className="docg-patient-form-wrapper docg-card h-standard mr-2">
        <Card.Title className="d-flex justify-content-start">
          The QR code available here is for Patient App, to login to your clinic
        </Card.Title>
        <Card.Body className="d-flex flex-column align-items-center pb-3">
          <Row>
            <Col>
              {!!value && (
                <div className="d-flex align-items-center flex-column">
                  <QRCode
                    id="qr-gen"
                    value={value}
                    size={256}
                    level={"H"}
                    includeMargin={true}
                  />

                  <button className="docg-outline-btn" onClick={downloadQRCode}>
                    Download QR Code
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <hr />
      <Card className="docg-patient-form-wrapper docg-card h-standard mr-2">
        <Card.Title className="d-flex justify-content-start">
          Upload Signature
        </Card.Title>
        <Row>
          <Col className="d-flex align-items-center justify-content-center w-100">
            {data?.signature && (
              <img
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  width: "100px",
                  height: "100px",
                }}
                src={data.signature}
                alt=""
              />
            )}
            <label for="sign-uplaod" className="">
              <div className="file-upload-button">Upload Signature</div>
            </label>
            <input
              type="file"
              id="sign-uplaod"
              onChange={(e) => handleImageChange("signature", e)}
              accept="image/*"
            />
          </Col>
        </Row>
      </Card>
      <hr />

      <MFASettings />
    </div>
  );
};

export default RightPanel;
