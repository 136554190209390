import React, { Fragment, useState } from "react";
import moment from "moment";
import { AiOutlineUserSwitch } from "react-icons/ai";

import { useStore } from "src/store/Store";
import {
  listEmergencyNotifications,
  updateAllNotifications,
  listPendingLabReports,
} from "src/store/notificationReducer";

import NotificationTopBar from "src/components/Dashboard/NotificationTopBar/index";
import { Store as store } from "react-notifications-component";

import DashobardIcon from "src/assets/icons/Dashboard.svg";
import SignOutIcon from "src/assets/icons/SignOutIcon.svg";
import notificationIcon from "src/assets/notification-tab.svg";
import video from "src/assets/icons/VideoIcon.svg";
import doctorRed from "src/assets/icons/doctorRed.svg";
import WaitingPatient from "src/shared/Modals/WaitingPatiens";
import waitingPatientIcon from "src/assets/waitingPatient.svg";
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { snackbar } from "@mobiscroll/react";

//apii
import { subscribeNotificationBeep } from "src/components/graphql/GraphQLsubscription";
import { listAllNotifications } from "src/api/index";
import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";
import { generateClient } from "@aws-amplify/api";
import {
  subClient,
  subscription,
} from "../components/ApolloConnection/ApolloProviderComponent";

const HeaderTop = ({ handleSidebar, searchBar, TimeBar }) => {
  const { dispatchNotifications, globalStateNotifications, globalStateKPI } =
    useStore();
  const [time, setTime] = useState("-:-");
  const [isDoctorOnline, setisDoctorOnline] = useState(true);
  const [lgShow, setLgShow] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [emergencyAppointmentsCount, setEmergencyAppointmentsCount] =
    useState(0);

  const handleNotificationsView = React.useCallback(() => {
    setShowNotifications((prevState) => !prevState);
  }, [showNotifications]);

  const fetchNotifications = async () => {
    const res = await listAllNotifications();
    return res;
  };

  const filterEmergencyAppointments = React.useCallback((res) => {
    console.log(res);
    try {
      let data = res.filter(
        (notification) =>
          !notification.acknowledged &&
          notification.metadata.status == "Pending" &&
          notification.metadata.notificationType ==
            "EmergencyAppointmentRequestNotification"
      );

      return data;
    } catch (e) {
      console.log("ERror at filtering emergency appointments", e);
      return [];
    }
  }, []);
  const filterPendingLabReports = React.useCallback((res) => {
    try {
      let data = res.filter(
        (notification) =>
          !notification.acknowledged &&
          notification.metadata.notificationType ==
            "LabReportUploadNotification"
      );

      return data;
    } catch (e) {
      console.log("ERror at filtering emergency appointments", e);
      return [];
    }
  }, []);

  const filterTodaysNotifications = React.useCallback((res) => {
    try {
      const data = res
        .filter((notification) => {
          let todayDate = new Date();
          let currentDate = new Date(notification?.createdAt);
          return (
            todayDate.getDate() == currentDate.getDate() &&
            todayDate.getMonth() == currentDate.getMonth() &&
            todayDate.getFullYear() == currentDate.getFullYear() &&
            notification.metadata.notificationType !=
              "EmergencyAppointmentRequestNotification"
          );
        })
        .map((el) => {
          return {
            ...el,
            title: returnTitle(el.metadata.notificationType),
            message: returnMessage(el),
          };
        });
      return data;
    } catch (err) {
      console.log("Error at filtering today's appointments", err);
      return [];
    }
  });
  const returnTitle = (title) => {
    switch (title) {
      case "LabReportUploadNotification":
        return "Lab Report";
      case "EmergencyAppointmentRequestNotification":
        return "Emergency Appointment";
      case "BookAppointmentNotification":
        return "Rescheduled Appointment";
      case "PatientCancelAppointmentNotification":
        return "Appointment Canellation";
      case "ReferPatientToNotification":
        return "Referral";
      default:
        return "";
    }
  };
  const returnMessage = (data) => {
    switch (data.metadata.notificationType) {
      case "LabReportUploadNotification":
        return `You have received a Lab Test from your appointment on ${new Date().toLocaleDateString(
          [],
          {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }
        )} with Patient ${data.metadata.patientName}`;
      case "EmergencyAppointmentRequestNotification":
        return `You have received an Emergency Appointment for Patient ${data.metadata.patientName}`;
      case "BookAppointmentNotification":
        return `Patient ${
          data.metadata.patientName
        } has successfully rescheduled your appointment on ${new Date(
          data.metadata.appointmentDate
        ).toLocaleDateString([], {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}`;
      case "PatientCancelAppointmentNotification":
        return `Patient ${
          data.metadata.patientName
        } has cancelled your appointment on ${new Date(
          data.metadata.appointmentDate
        ).toLocaleDateString([], {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}`;
      case "ReferPatientToNotification":
        return `Doctor ${data.metadata.doctorName} has referred you for Patient ${data.metadata.patientName}`;
      default:
        return "";
    }
  };
  const displayNotification = (data) => {
    let title;
    title = returnTitle(data.metadata.notificationType);
    let message = returnMessage(data);
    store.addNotification({
      title,
      message,
      type: "success", // 'default', 'success', 'info', 'warning'
      container: "top-right", // where to position the notifications
      animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
      dismiss: {
        duration: 3000,
      },
    });
  };
  React.useEffect(() => {
    fetchAuthSession().then((options) => {
      const { userSub } = options;
      console.log(options);
      //console.log(identityId.split("ap-south-1:")[1]);
      subscription(
        subscribeNotificationBeep,
        { identityId: userSub },
        async (response) => {
          console.log(response);
          if (
            !(
              response.data.subscribeNotificationBeep.statusCode >= 200 &&
              response.data.subscribeNotificationBeep.statusCode < 300
            ) ||
            response.data.subscribeNotificationBeep.errors != null
          ) {
            return;
          }
          //fetch notifications
          let notifications = await fetchNotifications();
          let emergencyNotifications =
            filterEmergencyAppointments(notifications);
          let pendingLabReportNotifications =
            filterPendingLabReports(notifications);
          let allNotifications = filterTodaysNotifications(notifications);
          if (globalStateNotifications.notifications.length < notifications) {
            displayNotification(notifications[0]);
          }

          dispatchNotifications(
            listEmergencyNotifications(emergencyNotifications)
          );
          dispatchNotifications(
            listPendingLabReports(pendingLabReportNotifications)
          );
          dispatchNotifications(updateAllNotifications(allNotifications));
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }, []);

  React.useEffect(async () => {
    //fetch notifications
    let notifications = await fetchNotifications();
    let emergencyNotifications = filterEmergencyAppointments(notifications);
    let pendingLabReportNotifications = filterPendingLabReports(notifications);
    let allNotifications = filterTodaysNotifications(notifications);
    dispatchNotifications(listEmergencyNotifications(emergencyNotifications));
    dispatchNotifications(listPendingLabReports(pendingLabReportNotifications));
    dispatchNotifications(updateAllNotifications(allNotifications));
    return () => {};
  }, []);

  async function WebSignOut() {
    try {
      signOut()
        .then(() => {
          snackbar({
            message: "You have been logged out",
            color: "danger",
          });
        })
        .catch(() => {
          snackbar({
            message: "Unable to logout, Please Refresh.",
            color: "danger",
          });
        });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  const nextAppointment = () => {
    let timestamp = globalStateKPI.nextAppointment;
    let hours = moment(timestamp).diff(moment(), "hours");
    let minutes = moment(timestamp).diff(moment(), "minutes") % 60;
    let seconds = moment(timestamp).diff(moment(), "seconds") % 60;
    if (seconds < 0) {
      setTime("00:00");
      return;
    }
    hours = String(hours).padStart(2, "0");
    minutes = String(minutes).padStart(2, "0");
    if (seconds == 0 || time == "-:-") {
      setTime(hours + ":" + minutes);
    }
  };
  React.useEffect(() => {
    let interval;
    console.log(globalStateKPI);
    if (globalStateKPI.nextAppointment) {
      nextAppointment();
      interval = setInterval(() => {
        nextAppointment();
      }, 1000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
      setTime("-:-");
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [globalStateKPI]);

  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-transparent fixed-top navbar-fixed-top">
        <div className="container-fluid d-md-flex">
          {/* <button
            type="button"
            className="btn btn-link ml-3"
            onClick={() => {
              handleSidebar(true);
            }}
          >
            <img src={DashobardIcon} alt="" />
          </button> */}
          <button
            type="button"
            className="btn btn-link mr-1"
            onClick={() => {
              handleSidebar(false);
            }}
          >
            <img src={DashobardIcon} alt="" />
          </button>
          <div className="docg-top-bar-left  jc-sb">
            <div className="navbar-top-first  mbsc-align-items-center d-lg-flex">
              <div className="navbar-wrapper"></div>
              <div className="navbar-wrapper appointment-top-bar">
                {time != "-:-" && (
                  <>
                    <span className="pulse-appointment"></span>
                    &emsp;{" "}
                    <span className="text">
                      Appointment in next <span>{time}</span> mins
                      {/* <img src={video} alt="" /> */}
                    </span>
                  </>
                )}
              </div>
            </div>
            <ul className="navbar-nav  align-items-center">
              {globalStateNotifications.emergencyNotifications.length > 0 && (
                <li className="nav-item doctor-logs">
                  <div className="nav-link">
                    <img
                      onClick={() => {
                        setLgShow(true);
                      }}
                      src={waitingPatientIcon}
                      alt=""
                    />
                    <span>
                      {globalStateNotifications.emergencyNotifications.length}
                    </span>
                  </div>
                </li>
              )}

              <li className="nav-item doctor-logs">
                <button
                  onClick={handleNotificationsView}
                  title="Notifications"
                  className="nav-link"
                >
                  <img src={notificationIcon} alt="" />
                  <span>{globalStateNotifications.notifications.length}</span>
                </button>
              </li>
              <li className="nav-item doctor-logs">
                <button
                  onClick={WebSignOut}
                  title="Logout"
                  className="nav-link"
                >
                  <img src={SignOutIcon} alt="" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {lgShow && <WaitingPatient setLgShow={setLgShow} lgShow={lgShow} />}
      <NotificationTopBar
        showNotifications={showNotifications}
        handleNotificationsView={handleNotificationsView}
        title="Notifications"
      />
    </Fragment>
  );
};

export default HeaderTop;
