import React from "react";
import { Modal, Card } from "react-bootstrap";

const TabLeave = ({ show, closePopup, handleYes, handleNo, title = "" }) => {
  return (
    <Modal
      show={show}
      onHide={closePopup}
      backdrop="static"
      keyboard={false}
      dialogClassName="docg-modal"
      size="sm"
      centered
      style={{ zIndex: 1233434 }}
    >
      <Card>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
          <span>{title}</span>
          <div className="d-flex mt-3">
            <button
              onClick={handleYes}
              className="docg-save-btn bg-purple mr-2"
            >
              Yes
            </button>
            <button onClick={handleNo} className="docg-save-btn">
              No
            </button>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default TabLeave;
