import React from "react";
import numtoString from "src/lib/numToString";
import { Datepicker, momentTimezone } from "@mobiscroll/react5";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import moment from "moment-timezone";
import { useStore } from "src/store/Store";
const boxInputProps = {
  inputStyle: "box",
  labelStyle: "stacked",
  placeholder: "Since Please Select",
  className: "add-font-since",
};

momentTimezone.moment = moment;

const CardExpanded = (props) => {
  const { globalStateKPI } = useStore();

  const [value, setValue] = React.useState("-");
  const [growth, setGrowth] = React.useState(null);
  const [growthPercent, setGrowthPercent] = React.useState("-");

  React.useEffect(async () => {
    if (props?.item?.api) {
      const val = sessionStorage.getItem(props.item.title);
      if (val && val > 0) {
        setValue(val);
      } else {
        const res = await props?.item?.api();
        if (props.item.showRupees) {
          setValue(+res);
          sessionStorage.setItem(props.item.title, +res);
        } else {
          setValue(res);
          sessionStorage.setItem(props.item.title, +res);
        }
      }
    } else {
      if (props?.item?.globalState) {
        setValue(globalStateKPI[props.item.globalState]);
      } else {
        const val = sessionStorage.getItem(props.item.title);
        if (val) {
          setValue(val);
        }
      }
    }
  }, []);

  React.useEffect(() => {
    if (props?.item?.globalState) {
      setValue(globalStateKPI[props.item.globalState]);
    }
  }, [globalStateKPI]);

  React.useEffect(async () => {
    if (props?.item?.growthApi && growth) {
      const res = await props?.item?.growthApi(growth);
      setGrowthPercent(res);
    }
  }, [growth]);

  return (
    <>
      <div className="card widgets card-effect mr-3 mb-3">
        <div
          className="card-body"
          style={{ overflowY: "hidden", height: "135px" }}
        >
          <h5 className="card-title text-white add-radius text-center">
            {props.item.title}
          </h5>

          <div className="card-text add-display-direction pb-2">
            <div className="leftSide">
              <CircularProgressbarWithChildren
                className={props.item?.Circular}
                value={props.item?.percentage}
              >
                <img
                  style={{ width: 35 }}
                  src={props.item?.icon}
                  alt="Billing"
                />
                <div style={{ fontSize: 12, marginTop: -5 }}></div>
              </CircularProgressbarWithChildren>
            </div>
            <div className="RightSide add-height-line">
              <div className="FourthCardNumber mb-0">
                {props.item.showRupees && (
                  <span className="rsIcon">&#8377;</span>
                )}
                <b style={{ fontSize: "30px" }}>
                  {value == "-" ? value : numtoString(value)}
                </b>
                <br />

                {props.item.footer ? (
                  <p
                    style={{
                      fontSize: "35%",
                      color: "grey",
                    }}
                    className="float-right"
                  >
                    {props.item.footer}
                  </p>
                ) : (
                  <div
                    style={{
                      fontSize: "35%",
                      color: "green",
                    }}
                    className="float-right"
                  >
                    {growthPercent} %{" "}
                    <b style={{ fontWeight: "normal", color: "grey" }}>
                      Growth
                    </b>
                    <span
                      style={{
                        alignItems: "center",
                        color: "grey",
                        fontWeight: "normal",
                        display: "flex",
                      }}
                    >
                      <Datepicker
                        controls={["calendar"]}
                        inputProps={boxInputProps}
                        displayTimezone="local"
                        timezonePlugin={momentTimezone}
                        dateFormat="DDD D MMM, YYYY"
                        onChange={(e) =>
                          setGrowth(new Date(e.value).toISOString())
                        }
                        value={growth}
                        style={{ margin: "0%", width: "10%!important" }}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardExpanded;
