import axios from "axios";

export default async function getImageBlobFromUrl(url) {
  try {
    const image = await axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) =>
        window.URL.createObjectURL(new Blob([response.data]))
      );
    return image;
  } catch (e) {
    return null;
  }
}
