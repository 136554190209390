import React from "react";
import { Helmet } from "react-helmet";
import ScheduleContent from "./ScheduleContent";
import { StoreProvider } from "./store/Store";
import { initialState, scheduleReducer } from "./store/ScheduleReducer";

const Schedule = () => {
  return (
    <>
      <Helmet>
        <title>Doctor Schedule | DOCG</title>
      </Helmet>
      <StoreProvider initialState={initialState} reducer={scheduleReducer}>
        <ScheduleContent />
      </StoreProvider>
    </>
  );
};

export default Schedule;
