import React, { Fragment, useState } from "react";
import Review from "./Review";
import "../Reporting.css";
import arrowImg from "../../../../assets/purple.svg";
import ReportingModals from "../../../../shared/Modals/ReportingModals";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import { listDoctorReviews } from "../api";

const Reviews = (props) => {
  const { check } = props;
  const [lgShow, setLgShow] = useState(false);
  const [data, setData] = useState([]);

  React.useEffect(async () => {
    const res = await listDoctorReviews();
    setData(res);
  }, []);

  return (
    <Fragment>
      <div
        onClick={(e) => {
          props?.check ? setLgShow(true) : e.preventDefault();
        }}
        style={
          props.check
            ? { overflow: "hidden", height: "100%" }
            : { overflow: "auto", height: "100%" }
        }
      >
        {props.check && (
          <div className="d-flex align-items-center pl-4 pt-2">
            <p className="review-txt">Reviews</p>
            <img src={arrowImg} alt="" className="arrow-img ml-2" />
          </div>
        )}
        {data.map((review) => (
          <Review
            key={review.patientId}
            comment={review.comment}
            rating={review.rating}
            patient={review.patient}
          />
        ))}
      </div>
      {setLgShow ? (
        <ReportingModals
          setLgShow={setLgShow}
          lgShow={lgShow}
          show={6}
          title={"Reviews"}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Reviews;
