import React, { Fragment } from "react";
import "react-circular-progressbar/dist/styles.css";
import starblackIcon from "../../../assets/star-black-outline.svg";
import staryellowIcon from "../../../assets/star-yellow-color.svg";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
const EmailTableRow = (props) => {

  const topCheckBox = (e) => {
    console.log("Clicked", e);
  };

  return (
    <Fragment>
      {props?.listData?.length > 0 ? (
        props?.listData?.map((data, index) => (
          <Fragment key={index}>
            <div className="mail-main-wrapper">
              <div className="d-flex">
                <Checkbox
                  icon={<Icon.FiCheck color="#C06C84" size={14} />}
                  name={data.id}
                  onChange={topCheckBox}
                  checked={props.isSelected}
                  className="CheckBoxInput checkbox-check"
                  borderColor="#6C5B7B"
                  style={{ cursor: "pointer" }}
                />
                <div className="d-flex">
                  <img
                    id={data?.id}
                    onClick={(e) => {
                      props.starIcon(e.target.id);
                    }}
                    src={data?.star === 1 ? staryellowIcon : starblackIcon}
                    alt=""
                    className="icon-size mr-4"
                  />

                  <p className="name-txt  min-def-txt">{data?.name}</p>
                  {data?.Work !== "" && (
                    <button className="work-btn">{data?.Work}</button>
                  )}
                  <p className="name-txt">{data?.text}</p>
                </div>
              </div>
              <div className="d-flex">
                <img
                  src={data?.attachmentIcon}
                  alt=""
                  className="icon-size mr-4"
                />
                <p className="name-txt">{data?.time}</p>
              </div>
            </div>
          </Fragment>
        ))
      ) : (
        <p>No data</p>
      )}
    </Fragment>
  );
};

export default EmailTableRow;
