import { Fragment } from "react";
import { Helmet } from "react-helmet";
import BillingIcon from "src/assets/icons/Billing.svg";
import CalanderDarkIcon from "src/assets/icons/CalenderDarkIcon.svg";
import PendingTask from "src/assets/icons/PendingTask.svg";
import TotalPaitent from "src/assets/icons/TotalPatient.svg";
import "./Dashboard.scss";
import Appointments from "./Layouts/Appointments";
import PendingLabRepot from "./Layouts/PendingLabRepot";
import TodoList from "./Layouts/TodoList";
import {
  getNumberOfPatients,
  getNumberOfPatientsGrowth,
  getTotalBillingsDoctor,
  getTotalBillingsGrowthOfDoctor,
} from "./Layouts/api/kpiCards";

const tableArr = [
  {
    icon: BillingIcon,
    title: "Total Billing",
    Circular: "proggressBar",
    percentage: 100,
    growthSince: "Since May 2021",
    footer: null,
    api: getTotalBillingsDoctor,
    showRupees: true,
    growthApi: getTotalBillingsGrowthOfDoctor,
  },
  {
    icon: TotalPaitent,
    title: "Total Patient",
    Circular: "proggressBar pateintsPer",
    percentage: 80,
    growthSince: "Since May 2021",
    footer: null,
    showRupees: false,
    api: getNumberOfPatients,
    growthApi: getNumberOfPatientsGrowth,
  },
  {
    icon: CalanderDarkIcon,
    title: "Today's Appointment",
    Circular: "proggressBar",
    percentage: 10,
    footer: "Appointments",
    showRupees: false,
    globalState: "appointments",
  },
  {
    icon: PendingTask,
    title: "Pending Task",
    Circular: "proggressBar PendingTask",
    footer: `Appointments ${new Date().toLocaleDateString([], {
      month: "short",
    })}`,
    percentage: 60,
    showRupees: false,
    globalState: "pendingTask",
  },
];

const Dashboard = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Dashboard | DOCG</title>
      </Helmet>
      {/* <CardWrap> */}
      <div className="mbsc-col-12 docg-dashboard docg-vh-standard d-flex flex-column">
        {/* <div className="mbsc-row">
          <div className="d-flex mbsc-col-12">
            <KPICard cards={tableArr} />
          </div>
        </div> */}
        <div className="mbsc-row mt-2">
          <div className="mbsc-col-12 mb-3">
            <Appointments />
          </div>

          <div className="mbsc-col-12 mbsc-col-xl-6 mb-3">
            <TodoList />
          </div>
          <div className="mbsc-col-12 mbsc-col-xl-6 mb-3">
            <PendingLabRepot />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
