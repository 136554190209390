import * as Yup from "yup";

const createInvoiceSchema = Yup.object({
  invoiceto: Yup.string().min(3).max(32).required(),
  address: Yup.string().min(3).max(32).required(),
  description: Yup.string().min(3).max(32).required(),
  qty: Yup.number()
    .typeError("qty is not valid")
    .nullable(true)
    .min(1)
    .required(),
  totalamount: Yup.number()
    .typeError("totalamount is not valid")
    .nullable(true)
    .min(1)
    .required(),
  paymentMode: Yup.string().required(),
});

export default createInvoiceSchema;
