import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <h2>Terms and conditions</h2>
      <ul>
        <li>
          The following are the Terms and Conditions (the "Agreement") between
          Doc G (“Company”, “Doc G”, “We”, “Our”, “Us”) that owns and operates
          the website <a href="www.docg.com">www.docg.com </a> (“Site” Or
          “Platform”)and persons/users who register on the site ("User",
          "Client" or "You") for the purposes of utilizing the services of the
          Company (as defined below)through the Platform.
        </li>
        <li>
          The Company offers this Site include all information, tools and
          services available on this Site to You conditioned upon your
          acceptance of all terms, conditions, policies and notices stated
          herein.
        </li>
        <li>
          PLEASE NOTE: Doc G IS NOT AND DOES NOT PROVIDE EMERGENCY SERVICES. IF
          YOU THINK YOU HAVE A MEDICAL OR MENTAL HEALTH EMERGENCY, OR IF AT ANY
          TIME YOU ARE CONCERNED ABOUT YOUR CARE OR TREATMENT, CALL 100 OR GO TO
          THE NEAREST OPEN CLINIC OR EMERGENCY ROOM.
        </li>
        <li>
          IF YOU ARE CONSIDERING OR CONTEMPLATING SUICIDE OR FEEL THAT YOU ARE A
          DANGER TO YOURSELF OR TO OTHERS, YOU MUST DISCONTINUE USE OF THE
          SERVICES IMMEDIATELY, CALL 100, OR NOTIFY APPROPRIATE POLICE OR
          EMERGENCY MEDICAL PERSONNEL.
        </li>
        <li>
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures. This document is published
          in accordance with the provisions of Rule 3(1) of the Information
          Technology (Intermediaries guidelines) Rules, 2011 that require
          publishing the rules and regulations, privacy policy and Terms and
          Conditions for access or usage of the Site accessible at ‘
          <a href="www.docg.com">www.docg.com </a>’
        </li>
        <li>
          Please read these Terms and Conditions carefully before accessing
          and/or using the Site. By visiting, accessing or using any part of the
          Site and/or the Services provided therein, you agree to be bound by
          these Terms and Conditions, including those additional terms,
          conditions and policies referenced herein and/or available by
          hyperlinks. These Terms and Conditions apply to all Users of the Site.
          If you do not agree to all the terms and conditions herein, then you
          shall not access the Site and/or use any of the Services.
        </li>
        <li>
          <h4> 1. INTERPRETATION</h4>
        </li>
        <li>
          1.1.The words "hereof", "hereto", "herein" and "hereunder" and words
          of similar import, when used in this Agreement, shall refer to this
          Agreement as a whole and not to any particular provision of this
          Agreement.
        </li>
        <li>
          1.2.Words denoting the singular shall include the plural and words
          denoting any gender shall include all genders.
        </li>
        <li>
          1.3.The headings to clauses, sub-clauses and paragraphs of this
          Agreement shall only serve the purpose of easier orientation and shall
          not affect the contents and interpretation of this Agreement.
        </li>
        <li>
          1.4.Reference to days, months and years are to English calendar days,
          calendar months and calendar years, respectively.
        </li>
        <li>
          1.5.Any reference to "writing" shall include printing, typing,
          lithography, an electronic record in terms of Information Technology
          Act, 2000 and rules framed thereunder as applicable, and other means
          of reproducing words in a visible form.
        </li>
        <li>
          1.6.The words "include" and "including" are to be construed without
          limitation.
        </li>
        <li>
          1.7."Law" means all statutes, enactments, acts of legislature, laws,
          ordinances, rules, by-laws, regulations, negotiations, guidelines,
          policies, directions, directives and orders of any Authorized
          Authority of or within the Republic of India.
        </li>
        <li>
          1.8."Person" includes any individual, partnership, corporation,
          company, unincorporated organization or association, trust or other
          entity.
        </li>
        <li>
          1.9.The words "Your" or "your" or "You" or "you", when used in this
          Agreement, may include and refer to both "Practitioner" and
          "Practitioners", as the case may be.
        </li>
        <li>
          <h4>2.DEFINITIONS</h4>
        </li>
        <li>
          The following words and terms, whenever used in this Agreement, unless
          repugnant to the meaning or context thereof, shall have the respective
          meanings set forth below.
        </li>
        <li>
          2.1."Applicable Laws" or "Law" shall mean any applicable national,
          federal, state or local laws (both common law and statute law and
          civil and criminal law) and all applicable subordinate legislation and
          regulatory codes of practice (including statutory instruments,
          guidance notes, circulars, directives, decisions, regulations,
          treaties, conventions, ordinances, order of any government agency,
          requirement or recommendation of any statute, statutory instrument,
          by-law or any public, governmental or statutory authority or person);
          all being of the Republic of India.
        </li>
        <li>
          2.2."Company" or "Us" or "We", shall mean and include Doc G, its
          Offices, Officers, Directors, Partners, Owners, Administrator,
          independent Contractors, Employees, Agents or affiliates, and
          its/theirs successors and assigns.
        </li>
        <li>
          2.3."Intellectual Property Rights", which may also be referred as IPR,
          shall mean and include all registered and unregistered trademarks,
          copyright in all forms including but not limited to the contents of
          the Site, images, text, illustrations, audio clips, trademarks, logos,
          labels, video clips, software and coding; industrial designs, patents,
          inventions, domain names, trade secrets, methodology, processes,
          features, functionality, User Information and common law rights in the
          aforesaid, which are associated with the Company, Services or the
          System.
        </li>
        <li>
          2.4."Lab" or "Laboratories" shall mean and include the Diagnostic
          laboratories and their collection centres.
        </li>
        <li>
          2.5."Person" shall mean a person, and includes any individual,
          corporation, firm, partnership, joint venture, association,
          organisation, trust, state or Governmental Authority or other legal
          entity (in each case, whether or not having separate legal
          personality).
        </li>
        <li>
          2.6."Practitioner" or "Practitioners" shall mean and include medical
          health professional(s), and/or doctors registered and listed on the
          Site.
        </li>
        <li>
          2.7."Practitioner Account Information" shall mean and include your
          name, email address, mobile number, password, date of birth, gender,
          registration number, and certain other sensitive personal information
          collected by the Company or any other information required by the
          Company for creation of the Practitioner Account.
        </li>
        <li>
          2.8."Practitioner Information" shall mean and include Practitioner
          Account Information, Practitioner generated content or any other
          information generated, directly or indirectly, subject to the privacy
          policy of the Company, with respect to the Practitioner Account,
          during the process of obtaining Practitioner Services by the
          Practitioner, or otherwise.
        </li>
        <li>
          2.9."Practitioner Services" shall mean and include, but not limited to
          the following services made available by the Company through its Site
          and System:
          <li>
            2.9.1.Manage Practice Services: a) Integrated calendar for booking
            and managing Online Consultations and Clinic Visits, b) Maintaining
            electronic medical records of patient visits, c) Invoicing and
            payment, d) SMS and email notifications to patients and User and e)
            Other services which are ancillary to the area of Manage Practice.
          </li>
          <li>
            2.9.2.Grow Practice Services: A complete platform to grow your
            online practice by providing User Services.
          </li>
        </li>
        <li></li>
        <li>
          2.10."Services" shall mean and include any service(s), including User
          Services and Practitioner Services, provided by the Company through
          its Site and System.{" "}
        </li>
        <li>
          2.11."Site" shall mean and include, whole or in part, the internet
          resource and website known as Logintohealth.com including but not
          limited to all its associated sub-domains, mobile application
          'Logintohealth', any accompanying or associated data, applications,
          utilities or interface.{" "}
        </li>
        <li>
          2.12."System" shall mean and include the Site, electronic
          communication network including all software, all hardware, all files
          and images and data contained in or generated by the System associated
          with the Site.{" "}
        </li>
        <li>
          2.13."Territory" shall mean and include only the territorial
          jurisdiction of Republic of India, to the exclusion of all other
          countries and territories of the world.{" "}
        </li>
        <li>
          2.14."Use" shall mean and include any use of the Site or Services by a
          Person, including without limitation, Practitioner and/or the
          Workforce (interns, employees, servants, affiliates, representatives,
          agents, independent contractors employed or hired) of the
          Practitioner.{" "}
        </li>
        <li>
          2.15."User" and "Users" shall mean and include a registered user,
          unregistered user, or any Person who uses the Site, Users, including
          the patient and his relative(s), representatives, agents, servants or
          affiliates, for whom or on whose behalf, the User is using the Site.{" "}
        </li>
        <li>
          2.16."User Account Information" shall mean and include the name, email
          address, mobile number, password, date of birth, gender, city, pincode
          and any other information as may be required by the Company in
          relation of the User Account.{" "}
        </li>
        <li>
          2.17."User Information" shall mean and include User Account
          Information, User generated content, Practitioner(s) generated
          content, medical records; information generated, directly or
          indirectly, subject to the privacy policy of the Company, with respect
          to the User Account, during the process of obtaining User Services by
          the User, or otherwise.
        </li>
        <li>
          2.18."User Services", shall mean and include Services offered by the
          Company from time to time, through the Site, including without
          limitation, Online Consultation, Next Available General Physician and
          Next Available Paediatrician, Quick Advice, Ask a Paid Question, Ask a
          Free Question, Diagnostic Test, Health Feed, Health Vault and Clinic
          appointment.
        </li>
        <li>
          <h4>3.ACCEPTANCE OF TERMS</h4>
        </li>
        <li>
          3.1.This Agreement applies to all healthcare professionals (whether an
          individual professional or an organization including authorised
          associates of such practitioners or institutions) who want to list
          themselves as "Practitioner" on the Site and governs the provision of
          access to Practitioner on the Site or any Practitioner Services
          provided through the Site.{" "}
        </li>
        <li>
          3.2.If you are a member of the Workforce (interns, employees, agents,
          independent contractors employed or hired by you) of the Practitioner
          that has listed themselves on the Site to provide Practitioner
          Services, and the Listed Practitioner has authorized you, explicitly
          or implicitly, to use the Practitioner Services or any other service,
          this Agreement is a three-way agreement between you, the Practitioner
          and the Company. Both the Practitioner and the Company may seek
          recourse against you for any violation of the terms of this Agreement.{" "}
        </li>
        <li>
          3.3.By accepting or clicking the tab/button "I agree" at the time of
          registration or by mere use of the Practitioner Services provided by
          the Company through Site, the Practitioner shall be and be deemed to
          be bound by this Agreement, and to have read and understood and
          unconditionally accepted this Agreement in its entirety for availing
          the use of the Practitioner Services provided on or through the Site.{" "}
        </li>
        <li>
          3.4.The Company is and the Practitioner accepts that the Company is
          the owner, author and publisher of the Site and the operator of the
          System associated with the Site for providing Services.{" "}
        </li>
        <li>
          3.5.By using the Site or accessing any material, information or
          services through the Site, the Practitioner agrees, admits, confirms
          and declares that the Practitioner has completed 18 years of age as on
          date of this Agreement, and that the Practitioner has fully read and
          understood the Terms and Conditions (also referred as "Terms of
          Practitioner's Use") as set forth in this Agreement, without any
          impairment in judgment resulting from (but not limited to) mental
          illness, mental handicap, intoxication, medication, or any other
          health or other problem that could impair judgment.{" "}
        </li>
        <li>
          3.6.In case the Practitioner has any issue or difficulty with the
          understanding of this Agreement or any of its clause or portion, you
          are advised not to Use the Site and if the Practitioner uses the Site,
          it shall be presumed and signified that the Practitioner has agreed to
          be bound by the terms and conditions of the Agreement, without any
          objection or reservation.{" "}
        </li>
        <li>
          3.7.If you do not wish to be bound by any part of this Agreement, you
          are not allowed to and shall not use the Site or any of Services
          offered by the Company or the Site, any such use of the Site or any of
          the Services shall not be valid and shall not vest any rights or
          entitlements in you, and shall be a breach of this Agreement by you.
          3.8.By listing as a Practitioner on the Site, or by mere use of or
          access to the Site, you shall be contracting with Company, and these
          terms and conditions including the privacy policy, constitute your
          binding obligation and you agree and declare that you are intending to
          use the Practitioner Services offered by the Company through Site, on
          your own volition, free will, without any undue influence, force or
          coercion, while in sound and disposing mind and your being legally
          capable of contracting in law.
        </li>
        <li>
          <h4>4.PRACTITIONER OBLIGATIONS, UNDERTAKINGS AND CONSENT</h4>
        </li>
        <li>
          4.1.You hereby undertake that if you are agreeing to these Terms of
          Practitioner's Use and Privacy Policy on behalf of another person or
          any legal entity, that you are duly authorised to do so.{" "}
        </li>
        <li>
          4.2.You also represent and warrant that you are not a person barred
          from receiving the Practitioner Services under the laws of India.{" "}
        </li>
        <li>
          4.3.You also undertake to inform us of any pending or decided
          cases/complaint against you in India/abroad for medical negligence
          and/or medical offences.{" "}
        </li>
        <li>
          4.4.You represent and warrant that you are qualified to provide
          medical services within the territory of India.{" "}
        </li>
        <li>
          4.5.You represent and warrant that you have obtained all licenses as
          required by law to provide medical services and have not committed any
          act or omission that might prejudice its continuance or renewal.{" "}
        </li>
        <li>
          4.6.You hereby agree that for availing Practitioner Services, you will
          have to register and create an Account ("Practitioner Account") and
          purchase a Subscription Plan (defined hereinafter). When you create a
          Practitioner Account, you are required to enter your name, email
          address, mobile number, password, date of birth, gender, registration
          number, and certain other sensitive personal information collected by
          the Company.{" "}
        </li>
        <li>
          4.7.You hereby undertake and agree that if you permit your Workforce
          to use the System and the Services on your behalf, subject to
          compliance with this Agreement, you will train all members of your
          Workforce with respect to compliance with this Agreement relating to
          their access to and use of the System, Site and the Practitioner
          Services, and ensure that they comply with such requirements. You
          further undertake to take appropriate disciplinary action against any
          member of your Workforce who violates these Terms and Conditions or
          the Privacy Policy.{" "}
        </li>
        <li>
          4.8.You hereby undertake and agree that in case of any loss or injury
          caused to the Company or any third party including Users, due to the
          illegal activity committed through your account by your Workforce or
          you, you shall be solely responsible and liable for the consequences.{" "}
        </li>
        <li>
          4.9.You hereby consent to immediately notify the Company of any actual
          or suspected unauthorized use or breach of the Practitioner's Account.
          You hereby agree that the Company shall not be liable for any direct
          or indirect losses caused to you by any unauthorized use of
          Practitioner Account, and you shall be liable for the losses caused to
          the Company or others, due to such unauthorized use.{" "}
        </li>
        <li>
          4.10.The verification of mobile number and / or the email shall be
          compulsory without which the Company will not create a Practitioner
          Account.{" "}
        </li>
        <li>
          4.11.If you register on the Site and successfully get a Practitioner
          Account, You shall be responsible for maintaining the confidentiality
          of the account, including display name and password of the account,
          and you shall be responsible for all activities that occur under
          Practitioner's Account.{" "}
        </li>
        <li>
          4.12.You hereby declare and verify that all information provided by
          you, is true, accurate and genuine. You hereby agree that in case any
          information provided is not true or accurate or genuine or complete,
          or the Company has reasonable grounds to suspect that such information
          is untrue, inaccurate, not current or incomplete, or not in accordance
          with these Terms of Practitioner's Use, then the Company shall have
          the right to indefinitely suspend or terminate or block access of
          Practitioner's Account on the Site.{" "}
        </li>
        <li>
          4.13.You agree not to access (or attempt to access) any of the
          Practitioner Services by any means other than through the interface
          that is provided by the Company.{" "}
        </li>
        <li>
          4.14.You agree that your use of the Site and System is subject to
          verification by us of your identity, qualifications, documents and
          credentials as a Practitioner.{" "}
        </li>
        <li>
          4.15.You agree and warrant that you will be in compliance of all
          applicable laws while using the Site, Services or providing services
          through the Site.
        </li>
        <li>
          4.16.You agree that we may terminate your access to or use of the
          System and Services at any time if we are unable at any time to
          determine or verify your qualifications or credentials.{" "}
        </li>
        <li>
          4.17.You agree that we may use and disclose your Practitioner Account
          information for such purposes, including (without limitation) making
          inquiries through third parties concerning your identity and
          professional and practice credentials. You authorize us to disclose to
          third parties such information as required for such purposes, and you
          agree to hold them and us harmless from any claim or liability arising
          from the request for or disclosure of such information.{" "}
        </li>
        <li>
          4.18.The Company may, at its sole discretion, suspend Practitioner's
          ability to use or access the Practitioner Services at any time while
          the Company investigates complaints or alleged violations of this
          Agreement, or for any other reason.{" "}
        </li>
        <li>
          4.19.Use of the Site and the Services by you, shall be solely at the
          discretion of the Company. The Company reserves its right to add,
          delete, withdraw or discontinue its any Service or Site in part or
          whole.{" "}
        </li>
        <li>
          4.20.The Company reserves, at its sole discretion, the right to refuse
          any Practitioner to access the Site or avail any Services, without
          assigning any reason.{" "}
        </li>
        <li>
          4.21.The Company also reserves, at its sole discretion, the right to
          block any Practitioner from creation of any Practitioner Account, or
          to block any Practitioner Account from accessing Site or Services,
          without assigning any reason.{" "}
        </li>
        <li>
          4.22.You hereby agree, declare and warrant that You are fully
          authorized and have taken all requisite approvals from the person (if
          any), on whose behalf, you are acting on the Site.
        </li>
        <li>
          4.23.You will cooperate with us in the administration of the System,
          including providing reasonable assistance in evaluating the System and
          collecting and reporting data requested by us.{" "}
        </li>
        <li>
          4.24.You hereby agree that you will implement and maintain appropriate
          administrative, physical and technical safeguards, and reasonable and
          appropriate security precautions to protect the User Information or
          any other System information, from any unauthorized use or access.{" "}
        </li>
        <li>
          4.25.You will immediately notify us of any breach or suspected breach
          of the security of the System of which you become aware, or any
          unauthorized use or disclosure of information within or obtained from
          the System, and you will take such action to mitigate the breach or
          suspected breach as we may direct, and will cooperate with us in
          investigating and mitigating such breach.{" "}
        </li>
        <li>
          4.26.You hereby understand and agree that the relationship between you
          and the User or patient, shall be independently governed as per the
          Applicable Laws.{" "}
        </li>
        <li>
          4.27.You warrant and agree that you shall be solely responsible for
          the professional services you will be providing to the User or the
          patient, including any consequential or incidental loss or injury.{" "}
        </li>
        <li>
          4.28.You shall be solely liable for your dealings and interaction with
          patient or User, his/her representatives or affiliates.{" "}
        </li>
        <li>
          4.29.You hereby understand and agree that the Company is only
          providing a platform to enable User and Practitioner to interact with
          each other, without any professional intervention of the Company.{" "}
        </li>
        <li>
          4.30.You warrant and agree that any material provided by you, will not
          infringe any intellectual property or any other right of any third
          person, and will not be in contravention of any other applicable laws.{" "}
        </li>
        <li>
          4.31.You will ensure that you are in compliance of all applicable
          laws, regulations and conventions.{" "}
        </li>
        <li>
          4.32.You hereby understand and agree that you acquire no ownership
          rights in any Practitioner Account.{" "}
        </li>
        <li>
          4.33.You hereby agree and undertake that you will not or attempt to:
          <br />
          a. impersonate any other Practitioner, person or entity,
          <br />
          b. misrepresent your affiliation with the Company;
          <br />
          c. use the Site or Services to violate any local, state, national or
          international law;
          <br />
          d. use abusive or derogatory language while communicating with the
          patients and Users;
          <br />
          e. transfer, assign or sublicense their Practitioner Account or right
          to use the Practitioner Services to any third party;
          <br />
          f. use the Practitioner Services provided by the Company for any
          unauthorized and unlawful purpose; and
          <br />
          g. engage in any activity that interferes with or disrupts the
          Practitioner Services, Site or System.
        </li>
        <li>5.SUBSCRIPTION PLANS FOR PRACTITIONER SERVICES</li>

        <li>
          5.1.The Practitioner will purchase a Subscription Plan to avail the
          Practitioner Services. The Subscription Plans will be provided for a
          fee "Subscription Fee" or free which will depend on the type of the
          Plan. We may proactively amend Subscription Fee for the existing
          services or add new services for additional fees. Subscription Fees
          stated prior to subscription being purchased, as amended at our
          discretion will apply. You agree to pay all Subscription Fees,
          applicable as per the Subscription Plan taken by you.{" "}
        </li>
        <li>
          5.2.The Company has provided a subscription guidance to enable the
          Practitioners to select the Subscription Plan suited to their needs.
          However, the Practitioners are solely responsible for selecting a
          Subscription Plan that is most appropriate for them. The Company will
          not be liable to refund any amount due to an inappropriate selection
          or non-adherence to instructions by the Practitioner.{" "}
        </li>
        <li>
          5.3.The Company may provide a Free Subscription Plan to the
          Practitioners for a limited period of time as specified at the Site.
          The Company may amend the features of the Free Subscription Plan at
          any point of time. The Company, by giving 45 days' notice to the
          Practitioner, may terminate the Free Subscription Plan at any point of
          time.{" "}
        </li>
        <li>
          5.4.Any data the Practitioner enters into the System, and any
          customizations made to the System by or for Practitioner, during
          Practitioner's Free Subscription Plan will be permanently lost after
          45 days of the expiry of the Free Subscription Plan unless the
          Practitioner upgrades his/her Free Subscription Plan to one of the
          Paid Subscription Plans.{" "}
        </li>
        <li>
          5.5.In case of the Clinic Subscription Plan, it is deemed that the
          Practitioner owns the Clinic for which he/she is purchasing the
          Subscription Plan and/or has been authorised by other Practitioners
          for that Clinic to purchase the Clinic Subscription Plan on their
          behalf and to add them as consultants ("Consultants") for that Clinic.
          In the event of any misrepresentation in this regard, the Company will
          terminate the account of the Practitioner with immediate effect and
          retains the right to claim damages on account of the loss incurred to
          the Company due to such misrepresentation. On discontinuation,
          non-renewal or termination of the Clinic Subscription Plan, the
          Practitioner who has taken the Clinic Subscription Plan is solely
          responsible for informing the other Consultants for that Clinic that
          the Practitioner Services will no longer be accessible to them. In
          this case, if the other Consultants want to continue using our
          Practitioner Services for this Clinic, please contact us through the
          Contact Us form on the Site for further assistance. Alternatively, you
          can delete them as a Consultant by going to My Account {">"}{" "}
          Consultant. The Consultant can then add this Clinic by taking an
          appropriate plan and continue using the Practitioner Services for this
          Clinic.{" "}
        </li>
        <li>
          5.6.The Company offers its Practitioner Services on "as is" basis and
          has the sole right to modify any feature or customize them at its
          discretion and there shall be no obligation to honour customization
          requests of any Practitioner. The Subscription Fee charged is
          exclusive of any customisation costs.
        </li>
        <li>
          5.7.Practitioner shall not create a Practitioner Account to access the
          Practitioner Services of the Company if the Practitioner or the
          organisation that he/she/it represents is the Company's direct
          competitor, except with the Company's prior written consent. In
          addition, the Practitioner shall not access the Practitioner Services
          for purposes of monitoring their availability, performance or
          functionality, or for any other benchmarking or competitive purposes.
        </li>
        <li>
          5.8.The Company will not be liable for the non-availability of the
          Practitioner Services due to circumstances beyond the Company's
          reasonable control, including, without limitation, telecom outages,
          3rd party server issues, internet service provider failures or delays,
          acts of God, acts of government, flood, fire, earthquakes, civil
          unrest, labour issues, acts of terror and strikes or other such
          problems.{" "}
        </li>
        <li>
          5.9.The Company will inform the Practitioners about the planned
          downtime with at least an 8 hours' notice. The Practitioner Services
          will not be available during that period.{" "}
        </li>
        <li>
          5.10.In the event the System is not available due to apparent default
          at the Company's end or is rendered unusable, the Company may at its
          discretion extend the subscription period of the Practitioner only by
          such number of calendar days when the Practitioner Services were not
          available. However, you shall agree that the Company is not
          responsible and will not be held liable for the any failure of the
          intermediary services such as, internet connectivity failure or
          telephonic disconnections or phone calls not coming to the User or the
          Practitioner for a scheduled Online Consultation Phone call due to a
          telecom outage or the 3rd party server being down.{" "}
        </li>
        <li>
          5.11.The Practitioner Services may be subject to certain limitations
          depending on the Subscription Plan, for example, number of SMS that
          can be sent, storage space provided, number of consultants that can be
          added, number of clinics that can be added, validity of subscription
          and any other limitations. Any such limitations are specified in the
          Subscription Plans. The Practitioner is advised to make
          himself/herself aware of these limitations prior to the purchase of
          the Subscription Plan.{" "}
        </li>
        <li>
          5.12.The Practitioners will be solely responsible to ensure that they
          renew their subscriptions in time. The Company will not be liable or
          responsible to send any reminders to the Practitioners for this
          purpose. If the Practitioners do not renew their Subscription Plans,
          the Company may, anytime post 45 days of the expiry of the
          Subscription Plan, delete all Practitioner Information.{" "}
        </li>
        <li>
          5.13.The Practitioner can send us a request in writing through the
          Contact Us form to provide a copy of the Practitioner Information, to
          the extent the same can be provided by the Company, by exporting to
          CSV format or any other computer file format (as available with the
          Company) in compliance to the privacy laws applicable to the
          Practitioner's field and other terms and conditions of this Agreement.{" "}
        </li>
        <li>
          5.14.It is the sole responsibility of the Practitioners to take a
          back-up of all their Practitioner Information and store the same on
          their own computer or any other place or in any other form. The
          Company will retain the Practitioner Information and any other
          information that you may upload on the Site for as long as it is
          needed to fulfil the service you seek to provider on the Site. If you
          send us a request to delete your Practitioner Account, the Company
          will delete all Practitioner Information. But please note: (1) there
          might be some latency in deleting this information from our servers
          and back-up storage; and (2) we may retain this information, for the
          period permitted under the laws of India, if necessary to comply with
          our legal obligations, for historical and statistical purposes,
          resolve disputes, or enforce our agreements. (3) We may retain this
          information if there are other Consultants attached to your
          subscription to enable them to continue using the Site and the
          Services.{" "}
        </li>
        <li>
          5.15.For making the payment of the Subscription Fee, the Practitioner
          will be directed to the portal of the third party, which has been
          outsourced by the Company, to receive payment.{" "}
        </li>
        <li>
          5.16.For any of the payment method opted by the Practitioner for
          making payment on the portal of the outsourced party/agency/service
          provider, and thereafter by entering banking information and details
          on that portal while making payment, the Company does not assume any
          liability, and shall never be responsible for any direct or indirect
          costs, charges, expenses, damage or loss suffered by the Practitioner,
          as the Company has outsourced third party(s) for receiving such
          payment, who is an independent party, and the Company has no access or
          control over any information and details inserted by the Practitioner
          when making or attempting to make any payment. Unless otherwise
          stated, all fees shall be quoted in Indian Rupees (INR).{" "}
        </li>
        <li>
          5.17.The Company does not ask for or retain any financial data or
          information of the Practitioner/payer except for the bank account
          details required to transfer the amount collected by the Company on
          behalf of the Practitioners.{" "}
        </li>
        <li>
          5.18.The Company shall send an intimation of the receipt of
          Subscription Plan Fee to the Practitioners through an email within 7
          working days of the receipt of Fee into the Company's designated bank
          account.{" "}
        </li>
        <li>
          5.19.Fees and charges for the Subscription Plans shall be calculated
          solely based on the records maintained by the Company or a 3rd party
          billing service provider. Decision of the Company shall be final and
          binding with respect to the Subscription Fee and charges payable by
          the Practitioner. {" "}
        </li>
        <li>
          5.20.Fee is non-refundable. However, the Company may, in its
          discretion, refund the pro-rata Fees for the unused portion of the
          Subscription Plan (as on date of termination) if the Practitioner who
          had taken the Subscription Plan, sends a notice in writing of his/her
          decision to terminate the Subscription Plan. Such a refund will be
          done upon expiry of 45 days of the receipt of such a termination
          request.{" "}
        </li>
        <li>5.21.The Subscription Fee is non-transferrable.</li>
        <li>
          5.22.The Company does not guarantee or make any representation with
          respect to the correctness, completeness or accuracy of the
          information or detail provided by Users or any third party through the
          Practitioner Services.
        </li>
        <li>6.LISTING OF PRACTITIONER INFORMATION</li>
        <li>
          6.1.Your personal profile describing your credentials, experience,
          academic background, awards, papers published shall be available for
          viewing by Users and will be considered non-confidential and
          non-proprietary. Providing additional information in your personal
          profile beyond what is required at registration is entirely optional
          and can be edited or deleted by you at any time.{" "}
        </li>
        <li>
          6.2.The Company automatically enables the listing of Practitioners'
          information on its Site for every 'Consultant' or 'Clinic' added to a
          Practice using its Subscription Plans. This information listed on Site
          is displayed when Users search for Practitioners for various User
          Services to shortlist them and avail User Services such as name,
          specialisation, experience, mobile number, clinic number and address,
          clinic details such as timings, services provided, charges and such
          other information for other online services as well.{" "}
        </li>
        <li>
          6.3.The Company reserves the right to list Practitioners who are not
          party to this Agreement. The Company reserves the right to modify the
          listing of Practitioners on its Site. In case Practitioner or Practice
          wishes to change or remove information as listed and displayed on the
          Site or disable appointment requests, or any User Services, the
          Practitioners or Practices can do so by using options available on its
          Practitioner Services or by contacting the Company through the Contact
          Us form on the Site.
        </li>
        <li>
          <h4>7.LISTING ALGORITHM:</h4>
        </li>
        <li>
          7.1.The Company's listing algorithm for the Practitioners is a fully
          automated system that lists the Practitioner, their profile and
          information regarding their practice on its Site when the User
          searches for any User Service. These listings of Practitioner do not
          represent any fixed objective ranking or endorsement by the Company.
          The Company will not be liable for any change in the listing order of
          the Practitioner, which may take place from time to time. The listing
          of Practitioner will be based on automated computation of the various
          factors including inputs made by the Users including their comments
          and feedback. The ranking algorithm is proprietary technology and
          cannot be disclosed in full. Such factors may change from time to
          time, in order to improve the listing algorithm. The Company in no
          event will be held responsible for the accuracy and the relevancy of
          the listing order of the Practitioner on the Site. The Company will
          not be liable for any consequence on the Practitioner or his Practice
          arising out of the listing order generated by the algorithm.
        </li>
        <li>
          <h4>8.CLINIC APPOINTMENT</h4>
        </li>
        <li>
          o8.1.The Company shall provide you with an option to make your
          calendar available for instant booking (Instant Booking) or just list
          your phone number. The Company reserves the right to extend and
          withdraw Instant Booking functionality to you at its sole discretion,
          based on the number of User appointments being honoured by you. The
          extension or withdrawal of such facility shall be intimated to you by
          the Company.{" "}
        </li>
        <li>
          8.2.If you have selected the Instant Booking option, the System
          accepts online appointment requests for you. It is possible that some
          appointment requests do not reach you at all or in a timely manner due
          to technical or operational reasons including but not limited to cases
          when Practitioner does not read emails or text messages sent by the
          Company in timely manner. The Company shall have no liability or
          responsibility in this regard.{" "}
        </li>
        <li>
          8.3.We are not responsible if the User cancels the appointment later
          or does not inform you of a cancellation in advance.{" "}
        </li>
        <li>
          8.4.Listing as Practitioner for Clinic Appointment without registering
          for a Practitioner Account: Listing as a Practitioner for Clinic
          Appointments can be availed by any Practitioner without registering
          for a Practitioner Account by providing information regarding your
          name, mobile number, email address, clinic details such as timings,
          services provided, charges and such other information as requested by
          any of the Company's employees or agents who contact you in person or
          by telephone. The Company will, after such information is collected
          from you, send you a confirmation email containing the information
          provided and the fact that you will be listed on the Site. In the
          event you do not wish to be listed on the Site, please inform the
          Company immediately.
        </li>
        <li>
          <h4>9.PRESCRIPTION:</h4>
        </li>
        <li>
          9.1.In case of Online Consultation, we enable you to provide the
          prescription to your patients after the call is completed. The
          prescription is to be provided at your discretion and we will in no
          way be held liable for the content of the prescription and for the
          provision or lack of provision of the same to the User. You can upload
          a scanned version of a written subscription signed by you. You can
          also type the prescription in text box provided against the name of
          the patient. In this option, an unsigned prescription will be
          generated which will be visible to the patient when he/she logs into
          his account on the Site. The Practitioner hereby declares that such an
          unsigned prescription will be deemed to be generated only by him/her
          and he/she is completely responsible for the outcome of the
          prescription generated using his/her account.
        </li>
        <li>
          <h4>10.ONLINE CONSULTATION - AUDIO AND VIDEO CALL DROPS</h4>
        </li>
        <li>
          10.1.After the User and the Practitioner have been connected, either
          entity can experience network issues which might result in the call
          getting disconnected. The steps to be followed in such an eventuality
          are listed below:
          <ol>
            <li>
              10.1.1. In case of any audio call drop
              <ul>
                <li>
                  10.1.1.1.The User will have to call our IVR number
                  +918431200200 within 15 minutes of the scheduled consultation
                  end time and press 3 for the Online Consultation Call drop
                  option.
                </li>
                <li>
                  10.1.1.2.Our System will immediately try to connect the User
                  to the Practitioner based on the User's request. 10.1.1.3.The
                  Practitioner is requested to be available to take the calls
                  from our System during the scheduled Online Consultation by
                  phone so that he/she can be reconnected to the User and the
                  call can be completed.
                </li>
              </ul>
            </li>
            <li>
              a.10.1.2.In case of any video call drop:
              <ul>
                <li>
                  10.1.2.1.Either the User or the Practitioner can click on the
                  call connect link to re-join the call.
                </li>
                <li>
                  10.1.2.2.If the call does not reconnect for loss of internet
                  on either side, the User will have SMS us CD on +918431200200
                  within 15 minutes of the scheduled consultation end time.
                </li>
                <li>
                  10.1.2.3.Our System will send an email and also call the
                  Practitioner to confirm the same. During the call, the
                  Practitioner must press 1 to confirm the call drop or 2 to
                  confirm that the call was completed.{" "}
                </li>
                <li>
                  10.1.2.4.If the Practitioner confirms that the video call
                  dropped, 100% of the amount will be re-credited to the User's
                  account within 7 days of the Online Consultation call.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h4>
            11.TRANSCRIPTS OF VIDEO AND AUDIO CALLS BETWEEN USERS AND
            PRACTITIONERS
          </h4>
        </li>
        <li>
          11.1.The records of electronic communications and video and phone
          calls may be stored in the Company's servers and also on the servers
          of providers of communication service. Such records are dealt with
          only in accordance with the Privacy Policy and are maintained for the
          purpose of administration of Services, customer support, research and
          development and for better listing of Practitioners.
        </li>
        <li>
          <h4>12.ASK A PAID QUESTION</h4>
        </li>
        <li>
          12.1.Subject to your verification and you opting for this service, we
          will let Users post questions to you. The responses will only be
          visible to the User who has posted the question. In the event that you
          do not want to accept the question, you can cancel the same and we
          will send a notification to the User. However, if you accept the
          question and the payment is made by the User, the responses to the
          questions need to be provided within a stipulated time period of 24
          hours. In the event that the question is not replied to within the
          stipulated time period, we will re-credit the amount to the User and
          the request will be cancelled. The Company, at its discretion, may
          impose a penalty on the Practitioner, not exceeding 5% of the fee, in
          the event that the User asks for a refund
        </li>
        <li>
          <h4>13.QUICK ADVICE</h4>
        </li>
        <li>
          13.1.Subject to your verification and you opting for Quick Advice, we
          will let Users connect with you through our System only if he/she is
          your registered patient. For this service, as a measure of
          convenience, the User is provided with the facility to pay after the
          call is connected. If the User defaults in the payment for the Quick
          Advice we will deactivate this service for him/her. However, under no
          circumstance, will the Company be held liable for any default in
          payment done by the User or be expected to recover the amount on the
          Practitioner's behalf from the User. If you do not want to take the
          risk of payment defaults by your existing registered patients, please
          do not activate this service.
        </li>
        <li>
          <h4>14.NEXT AVAILABLE GP AND NEXT AVAILABLE PEDIATRICIAN</h4>
        </li>
        <li>
          o14.1.The Practitioner will be eligible to provide this service only
          if he/she falls under the relevant area of specialisation.{" "}
        </li>
        <li>
          14.2.The objective of this service is to be able to connect the User
          immediately to the Practitioner. Hence, we send the request to
          multiple Practitioners who are online and connect the User to the
          Practitioner who accepts the request first. Hence the identity of the
          Practitioner is only known to the User once you join the video or
          phone call.{" "}
        </li>
        <li>
          14.3.The Practitioner is advised to activate this service only if
          he/she has a minimum available time of 45 minutes and no pre-scheduled
          appointments, either in clinic or online, during this period.{" "}
        </li>
        <li>
          14.4.While the duration of the Next Available GP and Next Available
          Pediatrician will be 15 minutes, it is at the discretion of the
          Practitioner if he or she wants to reduce or increase the duration of
          the Online Consultation subject to an upper limit of 30 minutes after
          which the session will terminate automatically.
        </li>
        <li>
          <h4>15.CHARGES FOR USER SERVICES</h4>
        </li>
        <li>
          15.1.There will be a certain minimum charge that is mandatory for the
          Practitioner to charge the Users and the User Services cannot be
          provided free.{" "}
        </li>
        <li>
          15.2.The charges for Next Available GP and Next Available
          Paediatrician will be determined by the Company and this will be a
          fixed charge across all Practitioners. If you are not in agreement
          with the charges that have been specified by the Company, please do
          not provide this service.
        </li>
        <li>
          <h4>16.COMMISSION AND PAYMENT FOR USER SERVICES:</h4>
        </li>
        <li>
          16.1.We will charge a Commission for most of the User Services
          provided by the Practitioners through the System. You are advised to
          kindly go through the details after you register, on your My Account
          page under Online Service Settings. In the event that you do not agree
          to the commission being charged, please do not provide the commission
          based User Services.{" "}
        </li>
        <li>
          16.2.We may add new User Services from time to time which will be
          commission based.{" "}
        </li>
        <li>
          16.3.We may proactively amend Commissions for the existing services.
          Commissions stated prior to services being provided, as amended at our
          discretion will apply.
        </li>
        <li>
          16.4.THE COMPANY RESERVES THE RIGHT, AT ANY TIME, TO CHANGE ITS
          COMMISSION AND PAYMENT TERMS, EFFECTIVE IMMEDIATELY UPON POSTING ON
          THE SITE OR BY E-MAIL DELIVERY TO YOU.{" "}
        </li>
        <li>
          16.5.You will provide accurate billing details, Permanent Account
          Number (PAN) details, Service Tax registration details and shall not
          use billing details not lawfully owned by you.{" "}
        </li>
        <li>
          16.6.You will provide your bank account details to enable us to
          transfer the amount owed to you. We will not be responsible for any
          errors or omissions in the details provided.
        </li>
        <li>
          16.7.For the User Services that are chargeable, payment will be done
          by the User using the secure payment gateway on the Site. The Company
          will collect this amount on behalf of the Practitioner and will
          transfer the balance amount after deducting the applicable Commission
          and deducting any amount for Service Fee.{" "}
        </li>
        <li>16.8.The amounts will be transferred at the end of each month. </li>
        <li>
          16.9.Service Fee are those amounts for e.g. payment gateway charges,
          which we have to incur for any transactions that have to be refunded
          at pure discretion of the Company.{" "}
        </li>
        <li>
          16.10.The amount transferred will be supported by a detailed Statement
          of earnings. The Statement of earnings will provide, for the
          corresponding period, details including date and time, nature of
          service, the amount paid by the User, the name of the User, the
          commission %, discount provided if any, the service tax applicable,
          Service Fee deducted, Tax at Source deducted and net amount payable to
          the Practitioner.{" "}
        </li>
        <li>
          16.11.Unless there is any system based error, the decision of the
          Company shall be final and binding with respect to the amount being
          transferred based on the records maintained by the Company or the 3rd
          party billing provider.{" "}
        </li>
        <li>
          16.12.You are solely responsible for payment of all taxes, legal
          compliances, and statutory registrations and reporting. We are in no
          way responsible for any of the Practitioner taxes or legal or
          statutory compliances.{" "}
        </li>
        <li>
          16.13.The Company will not be liable to anyone for any modification or
          discontinuation of the Practitioner Services. 16.14.The Company
          reserves the right at any time to withhold or cancel any of your
          payments or fees due for any reason including any actions or omissions
          on your part that are not completed, unauthorized, fraudulent,
          unacceptable, and inadequate or otherwise violate the terms of this
          Agreement.
        </li>
        <li>
          <h4>17.CORPORATE DISCOUNT</h4>
        </li>
        <li>
          17.1.The Company may do certain corporate tie-ups to promote the usage
          of the Services on the Site. Such tie-ups would be mutually beneficial
          both for the Practitioner as well as the Company.{" "}
        </li>
        <li>
          17.2.As a part of such tie-ups, the Company may have to extend a
          discount to the participating companies. This discount will be in the
          form of a % and will be applied on the amount charged by the
          Practitioner to the Users ("Corporate Discount").{" "}
        </li>
        <li>
          17.3.The Corporate Discount will not exceed 10% of the amount charged
          by the Practitioner to the Users. 17.4.The Company will accordingly
          charge its Commission to the Practitioner only on the net amount which
          will be calculated after deducting the Corporate Discount, where
          applicable.
        </li>
        <li>
          <h4>18.USER DISCOUNT</h4>
        </li>

        <li>
          18.1.The Company may also, from time to time, extend discount for any
          of the User Services ("User Discount").{" "}
        </li>
        <li>
          18.2.This discount will be in the form of a percentage and will be
          applied on the amount charged by the Practitioner to the Users or on
          the net amount calculated after deducting the Corporate Discount,
          where applicable.{" "}
        </li>
        <li>
          18.3.The Company will compensate the Practitioners by reducing its
          Commission to the extent of the User Discount %. For e.g. if the User
          Discount % for Online Consultation is 10% and if the Commission on the
          amount charged by the Practitioner for Online Consultation is 20%, the
          Company will reduce its Commission to 10% for the period when the User
          Discount is operational.
        </li>
        <li>
          <h4>19.FEEDBACK</h4>
        </li>

        <li>
          19.1.Our feedback system will send an SMS, email and notifications to
          the Users asking for feedback which may then be published on the Site.
          You agree to make your patients fully aware of the possibility of
          their receiving such feedback queries.{" "}
        </li>
        <li>
          19.2.Further, the Company shall not be liable, under any event, for
          any comments or feedback given by any of the Users in relation to the
          User Services provided by the Practitioner. All such feedback should
          be made in accordance with applicable law. The option of Users to give
          feedback remains at the Company's sole discretion and may be modified
          or withdrawn at its sole discretion. The Company may moderate such
          feedback at any time.
        </li>
        <li>
          19.3.In no event shall the Company be liable for any comments or
          feedback given by any of the Users in relation to the Practitioner
          Services provided by a Practitioner. 19.4.The listing order of
          Practitioners and/or Practices on the Practitioner Services is based
          on numerous factors including Users' comments and feedbacks. In no
          event shall the Company be liable or responsible for the listing order
          of Practitioners and/or Practices on the Practitioner Service.
          Further, the Company shall not be responsible for adverse feedback or
          comments, or ratings on the Practitioner Services which are a subject
          matter of automated processes, and the Company disclaims any liability
          for lost business or reputation of a Practitioner due to information,
          data or ratings that are available on the User Service. The Company at
          its discretion holds the sole right to display the User comments and
          feedback and the listing order of the Practitioner and/ or Practices.
        </li>
        <li>20.SYSTEM REQUIREMENTS</li>

        <li>
          20.1.You will acquire, install, configure and maintain all hardware,
          software and communications systems necessary to access the System
          (your "Implementation"). Your Implementation will comply with the
          specifications from time to time established by us. You will ensure
          that your Implementation is compatible with the System and Services.
          If we notify you that your Implementation is incompatible with the
          System, you will rectify such incompatibility, and we will have the
          right to suspend Services to you until such rectification has been
          implemented.{" "}
        </li>
        <li>
          20.2.Upon request, we may provide goods or services in connection with
          your Implementation. You will pay our then standard charges for such
          assistance, and our out-of-pocket costs.{" "}
        </li>
        <li>
          20.3.From time to time, updates to the App may be made available to
          you. We recommend that you use the latest version of the App at all
          times, to take advantage of the latest enhancements.{" "}
        </li>
        <li>
          20.4.By using the App or any of the Practitioner Services, you consent
          to us collecting and using technical information about the Devices and
          related software, hardware and peripherals for Practitioner Services
          that are internet-based or wireless to improve our products and to
          provide any Practitioner Services to you.{" "}
        </li>
        <li>
          20.5.If you use these Practitioner Services, you consent to us and our
          Affiliates' and licensees' transmission, collection, maintenance,
          processing and use of your location data and queries to provide and
          improve location-based products and Practitioner Services.
        </li>
        <li>
          <h4>
            21.IPR, TRADEMARK, COPYRIGHT - USE, RESTRICTIONS AND LIMITATIONS
          </h4>
        </li>
        <li></li>
        <li>
          21.1.The Site is owned, controlled and operated by the Company, and
          all Intellectual Property Rights /IPR including copyright in the Site
          and Service solely and exclusively belongs to and is owned by the
          Company. All intellectual property rights in and title to the Site,
          System and Services, the present or future modifications /
          upgradations thereof and standard enhancements thereto shall remain
          the property of the Company.{" "}
        </li>
        <li>
          21.2.All IPR protected material on the Site, including images, text,
          illustrations, audio clips, trademarks, logos, labels, video clips and
          User Information, are the intellectual property of the Company and /
          or of their respective owner(s). The IPR including the Practitioner
          Information or any other information that is collected by the Company
          from the Practitioner(s), and/ or provided by you, including responses
          for Ask a Free Question, articles under Health Feed and information,
          your contributions to forums, discussion groups, etc., subject to the
          privacy policy of the Company, shall be owned and controlled by the
          Company. Any redistribution, modification or reproduction of part or
          all of the contents featured in the Site or Service in any form is
          prohibited and actionable. You are not permitted to distribute or
          commercially exploit the IPR except as permitted under this agreement.
          Nothing in these Terms of Practitioner's Use grants the Practitioner
          any right in respect of Intellectual Property Rights of the Company.{" "}
        </li>
        <li>
          21.3.Subject to the terms of this Agreement, the Company authorizes
          the Practitioner and to view and access the Practitioner Account
          Information and User Information, on or from the Site, solely for
          rendering their professional services through the Site to the Users/
          Patients.
        </li>
        <li>
          21.4.This Agreement permits you to use the Site only for your
          personal, non-commercial use only. You must not reproduce, distribute,
          modify, create derivative works of, publicly display, publicly
          perform, republish, download, store or transmit any of the material on
          our Site except as generally and ordinarily permitted through the Site
          according to these Terms of Practitioner's Use. In addition to the
          foregoing, the Company also reserves the right to use information
          provided by you or about you, for the following purposes:
          <br /> i. Publishing such information on the Site.
          <br /> ii. Publishing ii.Contacting you for offering new products or
          services.
          <br /> iii. Publishing iii.Contacting you for taking any feedback.
          <br /> iv. Publishing iv.Analyzing software usage patterns for
          improving product design and utility.
          <br /> Analyzing anonymized practice information including financial
          information for commercial use.
        </li>
        <li>
          <h4>22.FORUMS</h4>
        </li>
        <li>
          22.1.We may offer forums for providing health related information to
          our Users such as Ask a Free Question and Health Articles.
          Participation in such forums is voluntary. You agree to comply with
          all applicable forum rules and guidelines, while participating in such
          forums. You acknowledge and understand that any information you post
          as response to Ask a Free Question or through the Health Article, will
          be available to the public, and we will be disclosing your name and
          speciality which may result in you receiving communications from other
          persons. You shall be responsible for safeguarding the privacy of the
          patients' or Users information when you participate in such forums,
          discussion groups and the like. You agree not to disclose individually
          identifiable health information of any person through such forums. You
          acknowledge that any liability resulting from your sharing of any
          information in any forum or discussion group, is your sole
          responsibility. You will hold the Company harmless for any damages,
          direct or indirect, arising from any post made by you.
        </li>
        <li>
          <h4>23.RIGHTS AND OBLIGATIONS RELATING TO CONTENT</h4>
        </li>
        <li>
          o23.1.As mandated by Regulation 3(2) of the Information Technology
          (Intermediaries guidelines) Rules, 2011 (IG Rules)
          (http://deity.gov.in/sites/upload_files/dit/files/GSR314E_10511(1).pdf)
          , the Company hereby informs you that you are not permitted to host,
          display, upload, modify, publish, transmit, update or share any
          information that:
          <ul>
            <li>
              23.1.1.belongs to another person and to which you do not have any
              right to;
            </li>
            <li>
              23.1.2.is grossly harmful, harassing, blasphemous, defamatory,
              obscene, pornographic, paedophilic, libellous, invasive of
              another's privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              or otherwise unlawful in any manner whatever;
            </li>
            <li>
              23.1.2.is grossly harmful, harassing, blasphemous, defamatory,
              obscene, pornographic, paedophilic, libellous, invasive of
              another's privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              or otherwise unlawful in any manner whatever;{" "}
            </li>
            <li>23.1.3.harm minors in any way;</li>
            <li>
              23.1.4.infringes any patent, trademark, copyright or other
              proprietary rights;
            </li>
            <li>23.1.5.violates any law for the time being in force;</li>
            <li>
              23.1.6.deceives or misleads the addressee about the origin of such
              messages or communicates any information which is grossly
              offensive or menacing in nature;{" "}
            </li>
            <li>23.1.7.impersonate another person; </li>
            <li>
              23.1.8.contains software viruses or any other computer code, files
              or programs designed to interrupt, destroy or limit the
              functionality of any computer resource;{" "}
            </li>
            <li>
              23.1.9.threatens the unity, integrity, defence, security or
              sovereignty of India, friendly relations with foreign states, or
              public order or causes incitement to the commission of any
              cognisable offence or prevents investigation of any offence or is
              insulting any other nation.
            </li>
          </ul>{" "}
        </li>
        <li>
          23.2.The Company, upon obtaining knowledge by itself or any third
          party of any of the above acts committed by you, then it shall be
          entitled to remove or disable access to the material or information
          that is in contravention of this Agreement and to immediately
          terminate the access or your usage rights of the Site.
        </li>
        <li>
          <h4>24.USE AND DISCLOSURE OF USER INFORMATION</h4>
        </li>
        <li>
          24.1.You represent and warrant that you will, at all times during the
          use of the System and thereafter, comply with all laws directly or
          indirectly applicable to you that may now or hereafter govern the
          gathering, use, transmission, processing, receipt, reporting,
          disclosure, maintenance, and storage of the User Information or any
          other information, shared with you.{" "}
        </li>
        <li>
          24.2.You will ensure that all persons or entities under your direction
          or control, including your affiliates, representatives, employees and
          agents, will comply with all applicable laws, including but not
          limited to the Information Technology Act, 2000 and the rules made
          thereunder.{" "}
        </li>
        <li>
          24.3.If, you use the patient's/ User's personal information including
          personal sensitive information, except for the purpose it has been
          provided to you, You shall be solely responsible for obtaining the
          patient/ Users consents, and all other legally necessary consents or
          permissions required, to use, disclose, process, retrieve, transmit,
          and view patient's/ User's personal information including personal
          sensitive information.{" "}
        </li>
        <li>
          24.4.We cannot and do not assume any responsibility for your use or
          misuse of User information or other information transmitted,
          monitored, stored or received while using the Site. We reserve the
          right to amend or delete any content of Site, along with the right to
          revoke any membership or restrict access to Site and System that in
          our sole discretion violates the above.
        </li>
        <li>
          <h4>25.GEOGRAPHICAL RESTRICTIONS</h4>
        </li>
        <li>
          25.1The Company makes no representation that all products,
          Practitioner Services and/or material described on the Site, or the
          User Services available through the Site, are appropriate or available
          for use in locations in all states and territories within India.
        </li>
        <li>
          <h4>26.COMMUNICATION TO THE PRACTITIONER</h4>
        </li>

        <li>
          26.1.When you use the Site or access any material, information or
          Services through the Site, you agree and understand that you are
          communicating with us, through electronic medium and you consent to
          receive communications, through any mode of communication from
          Company, time to time, as and when required by Company, for any
          purpose.{" "}
        </li>
        <li>
          26.2.You understand that once you register as a Practitioner on the
          Site, you hereby consent to be contacted by Company via phone, and/or
          SMS notifications, WhatsApp, email or any other utility, application,
          method or mode of communication, and authorize the Company, to send
          any information or communication relating to the Services availed by
          the User from the Company or the Practitioner, newsletter, promotional
          material, advertisements, or to contact for feedbacks, reviews, etc.
          or for any other purpose, including the communications relating to
          your registration, transactions that you carry out through the Site,
          reviews, feedbacks, and promotions that are undertaken by the Company.
          Please note that while the Company endeavours to provide these
          notifications and reminders to you promptly, the Company does not
          provide any guarantee and shall not be held liable or responsible for
          the failure to send such notifications or reminders to you. It is your
          responsibility to ensure that you attend any appointments that you may
          schedule with a User through the Site. 26.3.You agree that the
          delivery of any communications from the Company shall be deemed to be
          effective, when sent by Company, through any mode of communication,
          regardless of whether you read the communication on receipt of it, or
          whether you actually receive the communication. You can withdraw your
          consent to receive communications by cancelling your use of the
          Practitioner Account.
        </li>
        <li>
          <h4>27.CAUTION</h4>
        </li>
        <li>
          27.1.The Practitioner Services are not intended for and must not be
          used for emergency purposes such as emergency appointments, emergency
          consultations, emergency advice – written or oral, emergency
          healthcare procedures or any other emergency situations.
        </li>
        <li>
          <h4>28.DISCLAIMER OF WARRANTIES, GUARANTEES AND REPRESENTATIONS</h4>
        </li>
        <li>
          28.1.You hereby agree that use of the Practitioner Services is
          entirely at your own risk, cost and consequences. The Service is
          provided on 'as is' basis, without any warranty or guarantee of any
          kind and/or any responsibility or liability either express or implied
          or whether vicarious, or contingent.{" "}
        </li>
        <li>
          28.2.We make no representations concerning the completeness, accuracy
          or utility of any information in the Site and System, or concerning
          the qualifications or competence of individuals who placed it there.{" "}
        </li>
        <li>
          28.3.While every effort has been made to ensure that the information
          hosted on this Site is accurate & correct, however the Company does
          not warrant the accuracy of information obtained from Site or that it
          will not violate or infringe any third party right in any manner,
          whatsoever.{" "}
        </li>
        <li>
          28.4.The Company reserves the right, at its sole discretion, to
          change, modify, add to or remove any part of the Practitioner Service
          or portions of these Terms of Practitioner's Use at any time without
          any prior written notice to you, and any such change, modification,
          addition or removal (hereinafter referred as "Modifications") shall be
          considered as a part of these Terms of Practitioner's Use. It is your
          responsibility to review these Terms of Practitioner's Use
          periodically for updates/changes. Your continued use of and access to
          the Site following the Modifications will mean that you accept and
          agree to the Modifications.{" "}
        </li>
        <li>
          28.5.Any right granted to you to use the Services offered by the
          Company by its Site is personal, non-exclusive and non-transferable
          and a limited and revocable (at the discretion of the Company)
          permission to use the Site and Practitioner Services.
        </li>
        <li>
          28.6.The Company makes no representation or warranty regarding the
          genuineness, credibility, worthiness or otherwise of any User, patient
          or any other person, or any information provided by them, for any
          purpose, whatsoever.{" "}
        </li>
        <li>
          28.7.The Company further makes no representation or gives any warranty
          or condition either express or implied with regard to any information
          or Practitioner Services available on the Site.{" "}
        </li>
        <li>
          28.8.The Company disclaims all warranties including, but not limited
          to, the implied warranty of merchantability and suitability for any
          Services.{" "}
        </li>
        <li>
          28.9.Company also, does not warrant that: .this Site will be
          constantly available, or available at all;
          <br /> i.any or all the Practitioner Services or any other Services on
          the Site will be constantly available, or available at all;
          <br /> ii.you will be able to access your account at any or all times;
          <br />
          iii.the information on this Site is complete, true, accurate or
          non-misleading;
          <br /> iv.the warranties and representations made by any Users, other
          Practitioners, the content or information provided by the Users and/or
          Practitioners on the Site, or any opinion or suggestion given or
          expressed by the Company or any User is complete, true, accurate or
          non-misleading;
          <br /> v.the quality of any Services, information, or other thing
          obtained by you through the Site will meet your expectation;
          <br /> vi.the you will be able to use any Services;
          <br /> vii.the Site, information, content, materials, or Practitioner
          Services included on or otherwise made available to you through the
          Site; their servers; or electronic communication sent from the Company
          or the Site, are free of virus's or any other harmful components.{" "}
        </li>
        <li>
          28.10.The Company assumes no responsibility, and shall not be liable
          for, any damages to, or viruses that may infect your equipment on
          account.
        </li>
        <li>
          28.11.Except as expressly set out in these Terms of Practitioner's
          Use, all warranties, representations, terms, conditions or
          undertakings whether implied by statute, common law, custom, trade
          usage, course of dealing or otherwise (including any implied warranty,
          representation, term, condition or undertaking of satisfactory quality
          or fitness for a particular purpose) are, to the fullest extent
          permitted by law, hereby excluded.
        </li>
        <li>
          <h4>29.REFERENECE</h4>
        </li>
        <li>
          29.1.You hereby allow us to provide your reference to other potential
          Practitioners and/or Lab that we would like to introduce to the Site
          as a referral to our Services.
        </li>
        <li>
          <h4>30.THIRD PARTY ADVERTISEMENTS</h4>
        </li>
        <li>
          30.1.The Company reserves the rights to display sponsored
          advertisements or third party advertisements on the Site "Sponsored
          Listings". Without prejudice to the status of other content, the
          Company will not be liable for the accuracy of information or the
          claims made in the Sponsored Listings. The Company does not require or
          encourage you to visit any Sponsored Listings page or to avail any
          services from them. The Company is not be liable for the services of
          the providers of the Sponsored Listings. Your dealings with, or
          participation in promotions of, advertisers other than Company, found
          on or through the Site, including payment and delivery of related
          goods or services, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between you
          and such advertiser. The Company shall not be responsible or liable
          for any claim, error, omission, inaccuracy in advertising material or
          any loss or damage of any sort incurred as the result of any such
          dealings or as the result of the presence of such advertisers on the
          Site.{" "}
        </li>
        <li>
          30.2.Further, the Company shall not be responsible nor liable for any
          consequential damages arising on account of you relying on the
          contents of these advertisements.
        </li>
        <li>
          <h4>31.NO UNSOLICITED CALLS</h4>
        </li>
        <li>
          31.1.You agree that you will not make any unsolicited calls or use any
          information displayed on the Site, to breach any applicable rules and
          guidelines related to unsolicited commercial communications, including
          but not limited to regulations & guidelines such as TRAI guidelines
          for telemarketers, or otherwise violate applicable law while using the
          Practitioner Services.
        </li>
        <li>
          <h4>32.RIGHTS OF MODIFICATION</h4>
        </li>
        <li>
          32.1.You agree that this Agreement and the Practitioner Services of
          the Company are subject to any modification, or may be removed by the
          Company, as a result of change in government regulations, policies and
          local laws as applicable.
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
