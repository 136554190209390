import { snackbar } from "@mobiscroll/react";
import React from "react";
import { Card } from "react-bootstrap";
import upload from "src/assets/upload.svg";
import downloadIcon from "src/assets/download-white.svg";
import Loader from "src/components/Dashboard/common/Loader";
import axios from "axios";

import SingleInvite from "./SingleInvite";
import { getUploadPolicyLink } from "../Fee/api";
import { postMutation } from "src/components/ApolloConnection/ApolloProviderComponent";
import { SEND_BULK_INVITE } from "src/components/graphql/GraphQLmutation";

const InvitePatients = () => {
  const [logo, setLogo] = React.useState(undefined);
  const [disabledUpload, setDisabledUpload] = React.useState(true);
  const [disabledSend, setDisabledSend] = React.useState(true);
  const [loader, setLoader] = React.useState(false);

  const fileUpload = (event) => {
    if (event.target.files.length) {
      let file = event.target.files[0];
      let file_size = event.target.files[0].size;
      console.log(file_size);
      if (file_size > 5 * 1024 * 1024) {
        snackbar({
          message: "File Size should be less than 5MB.",
          color: "danger",
        });
        setDisabledSend(true);
        setLogo(null);
      } else {
        setDisabledSend(false);
        setLogo(file);
      }
    }
  };

  const onSubmitUploadPolicy = async () => {
    setLoader(true);
    try {
      let res = await getUploadPolicyLink({
        fileName: logo.name,
      });
      if (res !== null) {
        const _res = await axios.put(res.url, logo, {
          headers: {
            "Content-Type": "application/pdf",
            "Access-Control-Allow-Origin": "*",
          },
        });

        let fileName =
          _res.status >= 200 && _res.status < 300 ? res.name : undefined;
        if (fileName) {
          res = await postMutation(SEND_BULK_INVITE, { fileName });
          if (res.data) {
            snackbar({
              message: "Invites sent successfully",
              color: "success",
            });
          } else throw new Error();
        } else throw new Error();
      }
    } catch (e) {
      snackbar({
        message: "Something went wrong.",
        color: "danger",
      });
    }
    setLoader(false);
  };

  const download = function (data) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: "text/csv" });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);

    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a");

    // Passing the blob downloading url
    a.setAttribute("href", url);

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute("download", "send_bulk_invite.csv");

    // Performing a download with click
    a.click();
    a.remove();
    setDisabledUpload(false);
  };

  const csvmaker = function (data) {
    // Empty array for storing the values
    const csvRows = [];

    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    const headers = Object.keys(data);

    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    csvRows.push(headers.join(","));

    // Pushing Object values into array
    // with comma separation
    const values = Object.values(data).join(",");
    csvRows.push(values);

    // Returning the array joining with new line
    return csvRows.join("\n");
  };

  const get = async function () {
    // JavaScript object
    const data = {
      email: "aa8@inteliyo.net",
      name: "Sarath Sharma",
      phoneNumber: 9879879870,
    };

    const csvdata = csvmaker(data);
    download(csvdata);
  };

  // Getting element by id and adding
  // eventlistener to listen everytime
  // button get pressed

  return (
    <>
      <Loader show={loader} />
      <Card className="docg-card docg-appointment patient-wrapper card-wrapper m-0 mb-4">
        <Card.Header className="d-flex justify-content-between cardHeaderUsed">
          <Card.Title className="cardTitleUsed">
            Invite Multiple Patients (only CSV format)
          </Card.Title>
        </Card.Header>
        <Card.Body className="docg-card-body docg-h-standard">
          <div className="mbsc-row mb-3 d-flex items-center">
            <h3 className="profile-title d-flex mr-3 align-items-center w-auto">
              Step 1:
            </h3>

            <button onClick={get} className="docg-save-btn">
              <img src={downloadIcon} alt="" />
              Download CSV Template
            </button>
          </div>
          <div className="mbsc-row mb-3 d-flex items-center">
            <h3 className="profile-title d-flex mr-3 align-items-center w-auto">
              Step 2:
            </h3>

            <div className="docg-signup-upload mb-2">
              <div
                className={`docg-save-btn ${
                  disabledUpload ? "docg-disabled" : ""
                }`}
              >
                <img
                  style={{ height: "20px", marginRight: "7px", width: "auto" }}
                  src={upload}
                  alt=""
                />
                Upload CSV
                <span style={{ fontSize: 10 }}>* (max size is 5mb)</span>
              </div>
              <input
                accept=".csv"
                onChange={(e) => {
                  fileUpload(e);
                }}
                type="file"
                id="logo-upload"
                style={{ display: "block" }}
                disabled={disabledUpload}
              />
            </div>
          </div>
          <div className="mbsc-row">
            <button
              disabled={disabledSend}
              className={`docg-save-btn ${disabledSend && "docg-disabled"}`}
              onClick={onSubmitUploadPolicy}
            >
              Send Invitations
            </button>
          </div>
        </Card.Body>
      </Card>
      <SingleInvite />
    </>
  );
};

export default InvitePatients;
