import React, { Suspense, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import Avatar from "react-avatar";
import arrow from "src/assets/icons/black-arrow.svg";
import { useStore } from "./store/Store";

import endCallIcon from "src/assets/icons/endCallIcon.svg";
import followUp from "src/assets/icons/followUp.svg";
import prescription from "src/assets/icons/prescription-white.svg";
import video from "src/assets/icons/videoCallIcon.svg";
import invoiceIcon from "src/assets/Invoice-white.svg";

const AppointmentActionsPopup = ({
  isDialog,
  closeDialog,
  onVideoCallClick,
  onRefferedToClick,
  downloadInvoice,
  downloadPrescription,
  appointmentId,
  setShowFollowUp,
  isInvoiceLoading,
}) => {
  const { globalState, dispatch } = useStore();

  return (
    <>
      <Modal
        show={isDialog}
        onHide={closeDialog}
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle">Appointment Actions</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-start justify-content-center docg-patient-sidebar">
          <div className="d-flex section align-items-start">
            {globalState.consultationType &&
              globalState.consultationType == "Online" &&
              globalState.startingConsultation && (
                <button
                  onClick={onVideoCallClick}
                  className="docg-patient-options"
                >
                  <div className="img">
                    <img src={video} alt="" />
                  </div>
                  <span>Video Call</span>
                </button>
              )}
            {globalState.startingConsultation && (
              <button
                onClick={onRefferedToClick}
                className="docg-patient-options "
              >
                <div className="img">
                  <img src={endCallIcon} alt="" />
                </div>
                <span>End</span>
              </button>
            )}
            {appointmentId && (
              <button
                onClick={downloadPrescription}
                className="docg-patient-options"
              >
                <div className="img">
                  <img src={prescription} alt="" />
                </div>
                <span>Prescription</span>
              </button>
            )}
            {!isInvoiceLoading && globalState.logoLink && (
              <button
                onClick={downloadInvoice}
                className="docg-patient-options "
              >
                <div className="img">
                  <img src={invoiceIcon} alt="" />
                </div>
                <span> Invoice</span>
              </button>
            )}

            {/* <button className="docg-patient-options ">
              <div className="img">
                <img src={chat} alt="" />
              </div>
              <span>Chat</span>
            </button> */}

            {globalState?.appointmentId ? (
              <button
                onClick={() => {
                  setShowFollowUp(true);
                }}
                className="docg-patient-options "
              >
                <div className="img">
                  <img src={followUp} alt="" />
                </div>
                <span>Follow Up</span>
              </button>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AppointmentActionsPopup;
