import { snackbar } from "@mobiscroll/react5";
import { SignalingClient } from "amazon-kinesis-video-streams-webrtc";
import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { BiMicrophoneOff } from "react-icons/bi";
import { BsFillMicFill } from "react-icons/bs";
import { MdCallEnd, MdVideocam, MdVideocamOff } from "react-icons/md";
import { useParams } from "react-router-dom";
import videocallIcon from "src/assets/videocall.svg";
import useQuery from "src/lib/useQuery";
import {
  sendVideoCallEndNotification,
  sendVideoCallStartNotification,
} from "./api";
import "./videocall.scss";

import { getConsultationDetails } from "../api";
import { Auth } from "aws-amplify";
import {
  CreateSignalingChannelCommand,
  DeleteSignalingChannelCommand,
  DescribeSignalingChannelCommand,
  GetSignalingChannelEndpointCommand,
  KinesisVideoClient,
  ListStreamsCommand,
} from "@aws-sdk/client-kinesis-video";
import { fetchAuthSession } from "@aws-amplify/auth";
import {
  GetIceServerConfigCommand,
  KinesisVideoSignalingClient,
} from "@aws-sdk/client-kinesis-video-signaling";

// accesskey: process.env.DOCTOR_PATIENT_VIDEOCALL_ACCESSKEY;
// secretkey;
// region: process.env.REACT_APP_REGION;

const OPTIONS = {
  TRAVERSAL: {
    STUN_TURN: "stunTurn",
    TURN_ONLY: "turnOnly",
    DISABLED: "disabled",
  },
  ROLE: {
    VIEWER: "VIEWER",
    MASTER: "MASTER",
  },
  RESOLUTION: {
    WIDESCREEN: "widescreen",
    FULLSCREEN: "fullscreen",
  },
};

var videoStream = {
  signalingClient: null,
  peerConnectionByClientId: {},
  peerConnectionStatsInterval: null,
  peerConnection: null,
  viewerOffer: null,
  negotiate: false,
};

var messageChannel = {
  dataChannel: null,
};

var localStream = null;
var remoteStream = null;
function getRandomClientId() {
  return Math.random().toString(36).substring(2).toUpperCase();
}

function VideoCall() {
  const query = useQuery();
  const appointmentId = query.get("appointmentId");
  const { patientId } = useParams();
  const [role, setRole] = useState(OPTIONS.ROLE.MASTER);
  const [clientId, setClientId] = useState(getRandomClientId());
  const [sendVideo, setSendVideo] = useState(true);
  const [sendAudio, setSendAudio] = useState(true);
  const [useTrickleICE, setUseTrickleICE] = useState(true);
  const [channelARN, setChannelARN] = useState(null);

  const exitCallControl = false;
  var localView = useRef(null);
  var remoteView = useRef(null);

  const initChannel = async () => {
    const res = await getConsultationDetails({ id: appointmentId });
    if (res != null && res?.consultationType === "Online") {
      createChannel().then(() => {
        sendVideoCallStartNotification(appointmentId);
      });
    } else {
      window.open("", "_self").close();
    }
  };

  React.useEffect(() => {
    initChannel();
  }, []);

  const createChannel = async () => {
    let error = null;

    try {
      const { credentials } = await fetchAuthSession();

      const kinesisVideoClientObj = new KinesisVideoClient({
        region: process.env.REACT_APP_REGION,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      });
      const command = new CreateSignalingChannelCommand({
        ChannelName: appointmentId,
      });
      await kinesisVideoClientObj.send(command);

      //CHECK THIS IMPORTANT
      // await kinesisVideoClient
      //   .createSignalingChannel({ ChannelName: appointmentId })
      //   .promise();
    } catch (err) {
      console.log(err);
      error = err;
    }
    if (error) {
      setRole(OPTIONS.ROLE.VIEWER);
      await startAsViewer();
    } else {
      setRole(OPTIONS.ROLE.MASTER);
      await startAsMaster();
    }
  };

  //Message Channel

  const createMessageChannel = async () => {
    messageChannel.dataChannel =
      await videoStream.peerConnection.createDataChannel(
        `${appointmentId}InternalMessage`,
        { id: 1, maxRetransmits: 0, ordered: false }
      );
    messageChannel.dataChannel.onerror = (error) => {
      console.log("dataChannel.onerror", error);
    };

    messageChannel.dataChannel.onopen = () => {
      console.log("dataChannel.onopen");
    };

    messageChannel.dataChannel.onclose = () => {
      console.log("dataChannel.onclose");
    };

    videoStream.peerConnection.ondatachannel = (event) => {
      console.log("PEERCONNECTION DATACHANNEL " + event.channel);
      event.channel.onmessage = (event) => {
        console.log("dataChannel.onmessage:", event.data);
      };
    };
  };

  const sendMessage = (data) => {
    try {
      console.log("SENDING MESSAGE " + data, messageChannel.dataChannel);
      messageChannel.dataChannel.send(data);
    } catch (e) {
      console.error(" Send DataChannel: ", e.toString());
    }
  };

  const exitCall = async () => {
    if (role === OPTIONS.ROLE.VIEWER) await this.stopViewer();
    else await this.stopMaster();
    snackbar({
      message: "Your Call has been ended",
      color: "success",
    });
  };

  const startAsMaster = async () => {
    // Create KVS client

    const { credentials } = await fetchAuthSession();
    const kinesisVideoClientObj = new KinesisVideoClient({
      region: process.env.REACT_APP_REGION,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });

    // Get signaling channel ARN
    const sendcommand = new DescribeSignalingChannelCommand({
      ChannelName: appointmentId,
    });
    const describingChannelResponse = await kinesisVideoClientObj.send(
      sendcommand
    );
    // const describeSignalingChannelResponse = await kinesisVideoClient
    //   .describeSignalingChannel({
    //     ChannelName: appointmentId,
    //   })
    //   .promise();
    const channelARN = describingChannelResponse.ChannelInfo.ChannelARN;

    console.log("[MASTER] Channel ARN: ", channelARN);
    setChannelARN(channelARN);
    // Get signaling channel ARN
    console.log("Getting signaling channel ARN...");

    // const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    // console.log("[MASTER] Channel ARN: ", channelARN);
    // setChannelARN(channelARN);

    // Get signaling channel endpoints
    console.log("Getting signaling channel endpoints...");
    const endpointCommand = new GetSignalingChannelEndpointCommand({
      ChannelARN: channelARN,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ["WSS", "HTTPS"],
        Role: OPTIONS.ROLE.MASTER,
      },
    });
    const getSignalingChannelEndpointResponse =
      await kinesisVideoClientObj.send(endpointCommand);

    // Get signaling channel endpoints
    // console.log("Getting signaling channel endpoints...");
    // const getSignalingChannelEndpointResponse = await kinesisVideoClient
    //   .getSignalingChannelEndpoint({
    //     ChannelARN: channelARN,
    //     SingleMasterChannelEndpointConfiguration: {
    //       Protocols: ["WSS", "HTTPS"],
    //       Role: OPTIONS.ROLE.MASTER,
    //     },
    //   })
    //   .promise();

    const endpointsByProtocol =
      getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
        (endpoints, endpoint) => {
          endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
          return endpoints;
        },
        {}
      );
    console.log("[MASTER] Endpoints: ", endpointsByProtocol);
    const KinesisVideoSignalingIns = new KinesisVideoSignalingClient({
      region: process.env.REACT_APP_REGION,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
      endpoint: endpointsByProtocol.HTTPS,
    });

    console.log(videoStream.signalingClient);

    // Get ICE server configuration
    console.log("Creating ICE server configuration...");

    // Get ICE server configuration
    // console.log("Creating ICE server configuration...");
    // const kinesisVideoSignalingChannelsClient =
    //   new KinesisVideoSignalingChannels({
    //     region: process.env.REACT_APP_REGION,
    //     endpoint: endpointsByProtocol.HTTPS,
    //     correctClockSkew: true,
    //     accessKeyId: credentials.accessKeyId,
    //     secretAccessKey: credentials.secretAccessKey,
    //     sessionToken: credentials.sessionToken,
    //   });

    const iceServerCommand = new GetIceServerConfigCommand({
      ChannelARN: channelARN,
    });
    const getIceServerConfigResponse = await KinesisVideoSignalingIns.send(
      iceServerCommand
    );
    const iceServers = [];
    iceServers.push({
      urls: `stun:stun.kinesisvideo.${process.env.REACT_APP_REGION}.amazonaws.com:443`,
    });

    // console.log("Getting ICE server config...");
    // const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
    //   .getIceServerConfig({
    //     ChannelARN: channelARN,
    //   })
    //   .promise();

    // const iceServers = [];
    // iceServers.push({
    //   urls: `stun:stun.kinesisvideo.${process.env.REACT_APP_REGION}.amazonaws.com:443`,
    // });
    getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      })
    );

    console.log("[MASTER] ICE servers: ", iceServers);

    videoStream.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      role: OPTIONS.ROLE.MASTER,
      region: process.env.REACT_APP_REGION,
      systemClockOffset: kinesisVideoClientObj.config.systemClockOffset,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });

    const configuration = {
      iceServers,
      iceTransportPolicy: "all",
    };

    const _resolution = OPTIONS.TRAVERSAL.WIDESCREEN
      ? { width: { ideal: 1280 }, height: { ideal: 720 } }
      : { width: { ideal: 640 }, height: { ideal: 480 } };

    const constraints = {
      video: sendVideo
        ? { width: { ideal: 1280 }, height: { ideal: 720 } }
        : false,
      audio: sendAudio,
    };

    console.log(constraints);

    // Get a stream from the webcam and display it in the local view.
    // If no video/audio needed, no need to request for the sources.
    // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
    if (sendVideo || sendAudio) {
      try {
        console.log("Getting user media stream...");
        localStream = await navigator.mediaDevices.getUserMedia(constraints);
        console.log(localStream);
        localView.current.srcObject = localStream;
      } catch (e) {
        console.log("Error: ", e);
        console.error("[MASTER] Could not find webcam");
      }
    }

    console.log("Adding signalingClient.on open handler...");
    videoStream.signalingClient.on("open", async () => {
      console.log("[MASTER] Connected to signaling service");
    });

    console.log("Adding signalingClient.on sdpOffer handler...");

    videoStream.signalingClient.on(
      "sdpOffer",
      async (offer, remoteClientId) => {
        console.log(
          "[MASTER] Received SDP offer from client: " + remoteClientId
        );

        // Create a new peer connection using the offer from the given client
        videoStream.peerConnection = new RTCPeerConnection(configuration);

        videoStream.peerConnectionByClientId[remoteClientId] =
          videoStream.peerConnection;

        videoStream.peerConnection.addEventListener(
          "connectionstatechange",
          async () => {
            console.log(
              "[MASTER] Connection state changed:",
              videoStream.peerConnection.connectionState
            );
          }
        );
        // Send any ICE candidates to the other peer
        videoStream.peerConnection.addEventListener(
          "icecandidate",
          ({ candidate }) => {
            console.log("[MASTER] candidate " + candidate);

            if (candidate) {
              console.log(
                "[MASTER] Generated ICE candidate for client: " + remoteClientId
              );

              // When trickle ICE is enabled, send the ICE candidates as they are generated.
              if (useTrickleICE) {
                console.log(
                  "[MASTER] Sending ICE candidate to client: " + remoteClientId,
                  candidate
                );
                videoStream.signalingClient.sendIceCandidate(
                  candidate,
                  remoteClientId
                );
              }
            } else {
              console.log(
                "[MASTER] All ICE candidates have been generated for client: " +
                  remoteClientId
              );

              // When trickle ICE is disabled, send the answer now that all the ICE candidates have ben generated.
              if (!useTrickleICE) {
                console.log(
                  "[MASTER] Sending SDP answer to client: " + remoteClientId
                );
                videoStream.signalingClient.sendSdpAnswer(
                  videoStream.peerConnection.localDescription,
                  remoteClientId
                );
              }
            }
          }
        );

        // As remote tracks are received, add them to the remote view
        console.log('Adding peerConnection listener for "track"...');

        videoStream.peerConnection.addEventListener("track", (event) => {
          console.log(
            "[MASTER] Received remote track from client: " + remoteClientId
          );
          // if (remoteView?.current?.srcObject) {
          //   return;
          // }
          remoteView.current.srcObject = event.streams[0];
        });

        videoStream.peerConnection.oniceconnectionstatechange = async (
          event
        ) => {
          if (
            !exitCallControl &&
            event.target.iceConnectionState === "closed"
          ) {
            console.log(
              "[MASTER] icconnectchange .........................[MASTER]"
            );

            if (localView)
              setTimeout(() => {
                exitCall();
              }, 4000);
          }

          console.log(
            "[MASTER]......./////////",
            videoStream.peerConnection.connectionState
          );
          console.log(
            "[MASTER]......./////////",
            event.target.iceConnectionState
          );

          if (
            videoStream.peerConnection.connectionState === "connected" &&
            event.target.iceConnectionState === "connected"
          ) {
            console.log("[MASTER] ");
            await createMessageChannel();
            console.log("[MASTER] ");
          }

          if (
            event.target.iceConnectionState === "disconnected" &&
            videoStream.peerConnection.connectionState === "connected" &&
            remoteView &&
            !exitCallControl
          ) {
            console.log(
              "[MASTER] icconnectchange .........................[MASTER]"
            );

            if (localView)
              setTimeout(() => {
                exitCall();
              }, 4000);
          }
        };

        // If there's no video/audio, master.localStream will be null. So, we should skip adding the tracks from it.

        if (localStream) {
          localStream
            .getTracks()
            .forEach((track) =>
              videoStream.peerConnection.addTrack(track, localStream)
            );
        }
        console.log(localStream);
        await videoStream.peerConnection.setRemoteDescription(offer);

        // Create an SDP answer to send back to the client
        console.log(
          "[MASTER] Creating SDP answer for client: " + remoteClientId
        );
        await videoStream.peerConnection.setLocalDescription(
          await videoStream.peerConnection.createAnswer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          })
        );

        // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
        if (useTrickleICE) {
          console.log(
            "[MASTER] Sending SDP answer to client: " + remoteClientId
          );
          videoStream.signalingClient.sendSdpAnswer(
            videoStream.peerConnection.localDescription,
            remoteClientId
          );
        }
        console.log(
          "[MASTER] Generating ICE candidates for client: " + remoteClientId
        );
      }
    );

    videoStream.signalingClient.on(
      "iceCandidate",
      async (candidate, remoteClientId) => {
        console.log(
          "[MASTER] Received ICE candidate from client: " + remoteClientId
        );

        // Add the ICE candidate received from the client to the peer connection
        const peerConnection =
          videoStream.peerConnectionByClientId[remoteClientId];
        peerConnection.addIceCandidate(candidate);
      }
    );

    videoStream.signalingClient.on("close", () => {
      console.log("[MASTER] Disconnected from signaling channel");
    });

    videoStream.signalingClient.on("error", (err) => {
      console.error("[MASTER] Signaling client error", err);
    });
    console.log("[MASTER] Starting master connection");
    videoStream.signalingClient.open();
  };

  async function stopMaster() {
    const res = await sendVideoCallEndNotification({
      appointmentId: appointmentId,
    });
    if (res) {
      const { credentials } = await fetchAuthSession();
      const kinesisVideoClient = new KinesisVideoClient({
        region: process.env.REACT_APP_REGION,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      });
      await kinesisVideoClient.send(
        new DeleteSignalingChannelCommand({
          ChannelARN: channelARN,
        })
      );

      // await kinesisVideoClient
      //   .deleteSignalingChannel({
      //     ChannelARN: channelARN,
      //   })
      //   .promise();
      console.log("[MASTER] Stopping master connection");
      if (videoStream.signalingClient) {
        console.log("[MASTER] stopping");
        await videoStream.signalingClient.close();
      }
      Object.keys(videoStream.peerConnectionByClientId).forEach((clientId) => {
        videoStream.peerConnectionByClientId[clientId].close();
      });
      videoStream.peerConnectionByClientId = [];

      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
        localStream = null;
      }

      if (remoteStream)
        remoteStream.getTracks().forEach((track) => track.stop());
      remoteStream = null;
      videoStream.remoteStreams = [];

      if (videoStream.peerConnectionStatsInterval) {
        clearInterval(videoStream.peerConnectionStatsInterval);
        videoStream.peerConnectionStatsInterval = null;
      }

      if (videoStream.localView) {
        videoStream.localView.srcObject = null;
      }

      if (videoStream.remoteView) {
        videoStream.remoteView.srcObject = null;
      }

      if (videoStream.dataChannelByClientId) {
        videoStream.dataChannelByClientId = {};
      }
    }
    window.open("", "_self").close();
  }

  async function startAsViewer() {
    // Create KVS client
    const { credentials } = await fetchAuthSession();

    const kinesisVideoClient = new KinesisVideoClient({
      region: process.env.REACT_APP_REGION,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });

    // Get signaling channel ARN
    const command = new DescribeSignalingChannelCommand({
      ChannelName: appointmentId,
    });
    const describingChannelResponse = await kinesisVideoClient.send(command);

    // const describeSignalingChannelResponse = await kinesisVideoClient
    //   .describeSignalingChannel({
    //     ChannelName: appointmentId,
    //   })
    //   .promise();
    const channelARN = describingChannelResponse.ChannelInfo.ChannelARN;
    console.log("[VIEWER] Channel ARN: ", channelARN);
    setChannelARN(channelARN);

    const endpointCommand = new GetSignalingChannelEndpointCommand({
      ChannelARN: channelARN,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ["WSS", "HTTPS"],
        Role: OPTIONS.ROLE.VIEWER,
      },
    });
    const getSignalingChannelEndpointResponse = await kinesisVideoClient.send(
      endpointCommand
    );

    // Get signaling channel endpoints
    // const getSignalingChannelEndpointResponse = await kinesisVideoClient
    //   .getSignalingChannelEndpoint({
    //     ChannelARN: channelARN,
    //     SingleMasterChannelEndpointConfiguration: {
    //       Protocols: ["WSS", "HTTPS"],
    //       Role: OPTIONS.ROLE.VIEWER,
    //     },
    //   })
    //   .promise();
    const endpointsByProtocol =
      getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
        (endpoints, endpoint) => {
          endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
          return endpoints;
        },
        {}
      );
    console.log("[VIEWER] Endpoints: ", endpointsByProtocol);

    const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingClient(
      {
        region: process.env.REACT_APP_REGION,
        endpoint: endpointsByProtocol.HTTPS,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      }
    );

    // Get ICE server configuration
    // const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
    //   .getIceServerConfig({
    //     ChannelARN: channelARN,
    //   })
    //   .promise();
    const iceServers = [];
    iceServers.push({
      urls: `stun:stun.kinesisvideo.${process.env.REACT_APP_REGION}.amazonaws.com:443`,
    });
    const getIceServerConfigResponse =
      await kinesisVideoSignalingChannelsClient.send(
        new GetIceServerConfigCommand({ ChannelARN: channelARN })
      );
    getIceServerConfigResponse?.IceServerList?.forEach((iceServer) => {
      iceServers.push({
        urls: iceServer.Urls,
        username: iceServer.Username,
        credential: iceServer.Password,
      });
    });
    // getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
    //   iceServers.push({
    //     urls: iceServer.Uris,
    //     username: iceServer.Username,
    //     credential: iceServer.Password,
    //   })
    // );
    console.log("[VIEWER] ICE servers: ", iceServers);

    // Create Signaling Client
    videoStream.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId,
      role: OPTIONS.ROLE.VIEWER,
      region: process.env.REACT_APP_REGION,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });

    // const _resolution = OPTIONS.TRAVERSAL.WIDESCREEN
    //   ? { width: { ideal: 1280 }, height: { ideal: 720 } }
    //   : { width: { ideal: 640 }, height: { ideal: 480 } };

    const _resolution = { width: { ideal: 1280 }, height: { ideal: 720 } };
    const constraints = {
      video: sendVideo ? _resolution : false,
      audio: sendAudio,
    };
    const configuration = {
      iceServers,
      iceTransportPolicy: "all",
    };

    videoStream.peerConnection = new RTCPeerConnection(configuration);

    videoStream.signalingClient.on("open", async () => {
      console.log("[VIEWER] Connected to signaling service");

      // Get a stream from the webcam, add it to the peer connection, and display it in the local view.
      // If no video/audio needed, no need to request for the sources.
      // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
      if (sendVideo || sendAudio) {
        try {
          localStream = await navigator.mediaDevices.getUserMedia(constraints);
          localStream
            .getTracks()
            .forEach((track) =>
              videoStream.peerConnection.addTrack(track, localStream)
            );
          localView.current.srcObject = localStream;
        } catch (e) {
          console.error("[VIEWER] Could not find webcam");
          return;
        }
      }

      // Create an SDP offer to send to the master
      console.log("[VIEWER] Creating SDP offer");
      await videoStream.peerConnection.setLocalDescription(
        await videoStream.peerConnection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (useTrickleICE) {
        console.log("[VIEWER] Sending SDP offer");
        videoStream.signalingClient.sendSdpOffer(
          videoStream.peerConnection.localDescription
        );
      }
      console.log("[VIEWER] Generating ICE candidates");
    });

    videoStream.signalingClient.on("sdpAnswer", async (answer) => {
      // Add the SDP answer to the peer connection
      console.log("[VIEWER] Received SDP answer");
      await videoStream.peerConnection.setRemoteDescription(answer);
    });

    videoStream.signalingClient.on("iceCandidate", (candidate) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log("[VIEWER] Received ICE candidate");
      videoStream.peerConnection.addIceCandidate(candidate);
    });

    videoStream.signalingClient.on("close", () => {
      console.log("[VIEWER] Disconnected from signaling channel");
    });

    videoStream.signalingClient.on("error", (error) => {
      console.error("[VIEWER] Signaling client error: ", error);
    });

    // Send any ICE candidates to the other peer
    videoStream.peerConnection.addEventListener(
      "icecandidate",
      ({ candidate }) => {
        if (candidate) {
          console.log("[VIEWER] Generated ICE candidate");

          // When trickle ICE is enabled, send the ICE candidates as they are generated.
          if (useTrickleICE) {
            console.log("[VIEWER] Sending ICE candidate");
            videoStream.signalingClient.sendIceCandidate(candidate);
          }
        } else {
          console.log("[VIEWER] All ICE candidates have been generated");

          // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
          if (!useTrickleICE) {
            console.log("[VIEWER] Sending SDP offer");
            videoStream.signalingClient.sendSdpOffer(
              videoStream.peerConnection.localDescription
            );
          }
        }
      }
    );

    // As remote tracks are received, add them to the remote view
    videoStream.peerConnection.addEventListener("track", (event) => {
      console.log("[VIEWER] Received remote track");
      if (remoteView.current.srcObject) {
        return;
      }
      remoteStream = event.streams[0];
      remoteView.current.srcObject = remoteStream;
    });

    videoStream.peerConnection.oniceconnectionstatechange = async (event) => {
      if (!exitCallControl && event.target.iceConnectionState === "closed") {
        console.log(
          "[VIEWER] icconnectchange .........................[VIEWER]"
        );
        await this.setState({
          remoteView: false,
        });

        if (localView)
          setTimeout(() => {
            exitCall();
          }, 4000);
      }

      if (
        videoStream.peerConnection.connectionState === "connected" &&
        event.target.iceConnectionState === "connected"
      ) {
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        await createMessageChannel();
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
        console.log("[VIEWER] ");
      }

      console.log(
        "[VIEWER]......./////////",
        videoStream.peerConnection.connectionState
      );
      console.log("[VIEWER]......./////////", event.target.iceConnectionState);

      if (
        event.target.iceConnectionState === "disconnected" &&
        videoStream.peerConnection.connectionState === "connected" &&
        remoteView &&
        !exitCallControl
      ) {
        console.log(
          "[VIEWER] icconnectchange .........................[VIEWER]"
        );

        if (localView)
          setTimeout(() => {
            exitCall();
          }, 4000);
      }
    };

    console.log("[VIEWER] Starting viewer connection");
    videoStream.signalingClient.open();
  }
  const stopViewer = async () => {
    remoteView = null;
    localView = null;

    await videoStream.signalingClient.close();
    await videoStream.peerConnection.removeStream();
    await videoStream.peerConnection.close();

    localStream.getTracks().forEach((track) => track.stop());
    if (remoteStream) {
      remoteStream.getTracks().forEach((track) => track.stop());
    }
    remoteStream = null;
    localStream = null;
  };
  const handleCameraOffOn = () => {
    const _sendVideo = sendVideo;
    console.log(localStream);
    localStream.getVideoTracks().forEach((track) => {
      track.enabled = !_sendVideo;
    });
    if (_sendVideo) sendMessage("VIDEO_ON");
    else sendMessage("VIDEO_OFF");
    setSendVideo(!_sendVideo);
  };
  const handleMicOffOn = () => {
    const _sendAudio = sendAudio;
    console.log(localStream);
    localStream.getAudioTracks().forEach((track) => {
      track.enabled = !_sendAudio;
    });
    if (_sendAudio) sendMessage("AUDIO_ON");
    else sendMessage("AUDIO_OFF");
    setSendAudio(!_sendAudio);
  };
  var windowObjectReference = null; // global variable

  const openFollowUpPopup = () => {
    console.log(window.location);
    if (windowObjectReference == null || windowObjectReference.closed) {
      let windowFeatures = "left=150,top=100,width=760,height=560, popup=true";

      windowObjectReference = window.open(
        `/video-notes/${patientId}?appointmentId=${appointmentId}`,
        "Doctor Notes",
        windowFeatures
      );
      windowObjectReference.onbeforeunload = function (e) {
        var dialogText = "Dialog text here";
        e.returnValue = dialogText;
        return dialogText;
      };
    } else {
      windowObjectReference.focus();
      windowObjectReference.onbeforeunload = function (e) {
        var dialogText = "Dialog text here";
        e.returnValue = dialogText;
        return dialogText;
      };
    }
  };

  return (
    <>
      <Card style={{ height: "100vh" }} className="docg-card w-100">
        <Card.Header
          className="d-flex justify-content-between cardHeaderUsed"
          // style={{
          //   height: "4rem",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   fontStyle: "ubuntu",
          //   fontSize: "18px",
          //   fontWeight: "normal",
          //   backgroundColor: "#dee2e6",
          // }}
        >
          <Card.Title className="cardTitleUsed">
            <img src={videocallIcon} alt="" className="mr-2" /> Video Call
          </Card.Title>
        </Card.Header>
        <Card.Body
          style={{ background: "rgba(0,0,0,1)", padding: "0.5rem" }}
          className="docg-card-body docg-h-standard w-100 patient-channel-wrapper"
        >
          <div className="patient-container">
            <video
              className="return-view w-100 h-100"
              ref={remoteView}
              autoPlay
              playsInline
              style={{ objectFit: "cover" }}
            />
            <div className="video-container">
              <video
                className="output-view"
                ref={localView}
                autoPlay
                playsInline
                muted
              />
            </div>
          </div>
          <div className="in-call-functions">
            <button
              className=""
              onClick={async () => {
                handleMicOffOn();
              }}
            >
              {sendAudio ? (
                <BsFillMicFill color="#fff" size={18} />
              ) : (
                <BiMicrophoneOff color="#fff" size={24} />
              )}
            </button>
            <button className="bg-end-call" onClick={stopMaster}>
              <MdCallEnd color="#fff" size={30} />
            </button>
            <button
              className=""
              onClick={async () => {
                handleCameraOffOn();
              }}
            >
              {sendVideo ? (
                <MdVideocam color="#fff" size={24} />
              ) : (
                <MdVideocamOff color="#fff" size={24} />
              )}
            </button>
            {/* <BiFullscreen color="#fff" size={24} /> */}
            {/* <BiExitFullscreen color="#fff" size={24} /> */}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default VideoCall;
