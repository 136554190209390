import React, { Suspense, lazy } from "react";
import { Card, Col } from "react-bootstrap";
import LabTest from "src/assets/icons/labTestIcon.svg";
import icon from "src/assets/icons/labReport.svg";
import styled from "styled-components";
import PendingLabRepot from "./PendingLabRepot";
import { useHistory } from "react-router-dom";

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const HistoryDialog = (props) => {
  const { labTests = [], tests = [] } = props;
  console.log(props);
  return (
    <>
      <PendingLabRepot data={tests} />
      <Card className="docg-patient-form-wrapper docg-card mt-4 mb-4">
        <Card.Title>
          <div className="w-100 d-flex align-items-center">
            <img src={LabTest} className="mr-2" alt="" />
            Lab Results
          </div>
        </Card.Title>
        <Card.Body>
          <div className="history-dialog">
            <div className="mbsc-grid">
              <div className="mbsc-row mt-3">
                <div className="mbsc-col pl-0 mt-0 mb-0 justify-content-between ">
                  <Suspense fallback={<div>Loading....</div>}>
                    <div className="row pt-2">
                      {labTests.length > 0 ? (
                        <>
                          {labTests.map((item, index) => (
                            <Col
                              className="mt-4"
                              xl={3}
                              lg={4}
                              md={4}
                              sm={6}
                              xm={6}
                            >
                              <Result href={item} target="_blank">
                                <button>
                                  <img src={icon} alt="" />
                                </button>
                                <span>Report {index + 1} </span>
                              </Result>
                            </Col>
                          ))}
                        </>
                      ) : (
                        "No Lab Reports Uploaded"
                      )}
                    </div>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default HistoryDialog;

const Result = styled.a`
  img {
    margin: 0;
    padding: 0;
    /* max-height: 5rem; */
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
`;
