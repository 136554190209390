import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  LIST_ALL_NOTIFICATIONS,
  GET_INVOICE_PDF,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import {
  ADD_INSURANCE,
  READ_NOTIFICATION,
} from "src/components/graphql/GraphQLmutation";

export async function addInsurance(data) {
  try {
    let response = await postMutation(ADD_INSURANCE, data);
    if (
      response.data !== null &&
      response?.data?.addInsurance?.statusCode >= 200 &&
      response?.data?.addInsurance?.statusCode < 300
    ) {
      snackbar({
        message: "Insurance Added Successfully.",
        color: "success",
      });
      return true;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while adding Patient's Insurance. Try again later.",
      color: "danger",
    });
    return false;
  }
}

export async function listAllNotifications() {
  try {
    let response = await getQuery(LIST_ALL_NOTIFICATIONS);
    if (
      response.errors == null &&
      response?.data != null &&
      response?.data?.listAllNotifications?.statusCode >= 200 &&
      response?.data?.listAllNotifications?.statusCode < 300
    ) {
      return response?.data?.listAllNotifications.data;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching Notifications. Try again later.",
      color: "danger",
    });
    return [];
  }
}
export async function getInvoicePdf(id) {
  try {
    let response = await getQueryWithParams(GET_INVOICE_PDF, { id });
    if (
      response.errors == null &&
      response?.data != null &&
      response?.data?.getInvoice?.statusCode >= 200 &&
      response?.data?.getInvoice?.statusCode < 300
    ) {
      return response?.data?.getInvoice.data;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while opening Invoice. Try again later.",
      color: "danger",
    });
    return null;
  }
}
export async function readNotification(data) {
  try {
    const response = await postMutation(READ_NOTIFICATION, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.readNotification.statusCode < 300 &&
        response.data.readNotification.statusCode >= 200
      ) {
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while reading Notification. Try again",
      color: "danger",
    });
    console.log("Error at reject doctor appointment: ", e);
    return false;
  }
}
