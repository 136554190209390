import { Fragment } from "react";
import { Helmet } from "react-helmet";
import PatientSidebar from "./PatientSidebar";
import PatientTabs from "./PatientTabs";
import {
  initialState,
  patientDetailsReducer,
} from "./store/PatientDetailsReducer";
import { StoreProvider } from "./store/Store";

const PatientDetail = ({ hideSidebar = false, historySection = false }) => {
  console.log(hideSidebar, historySection);
  return (
    <Fragment>
      {/* {patientId} {query.get("appointmentId")} */}
      <StoreProvider
        initialState={initialState}
        reducer={patientDetailsReducer}
      >
        <Helmet>
          <title>Patient Detail | DOCG</title>
        </Helmet>
        <div
          className={
            hideSidebar
              ? "patient-wrapper "
              : "patient-wrapper docg-vh-standard"
          }
        >
          <div className="">{!hideSidebar && <PatientSidebar />}</div>
          {hideSidebar ? (
            <>
              {/* <div className="bg-white"> */}
              <PatientTabs historySection={historySection} />
              {/* </div> */}
            </>
          ) : (
            <div className="patient-tab">
              <PatientTabs historySection={historySection} />
            </div>
          )}
        </div>
      </StoreProvider>
    </Fragment>
  );
};

export default PatientDetail;
