import { NavItem, TabNav } from "@mobiscroll/react";
import React from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Invoice from "src/assets/all-invoice.svg";
import historyIcon from "src/assets/history-purple.svg";
import Prescription from "src/assets/icons/Bottle.svg";
import LabTest from "src/assets/icons/LabTest.svg";
import Symptoms from "src/assets/icons/Symptom.svg";
import useQuery from "src/lib/useQuery";
import { useStore } from "../store/Store";
import AttachmentPopup from "./medicalCards/AttachmentPopup";
import InvoicePopup from "./medicalCards/InvoicePopup";
import LabTestPopup from "./medicalCards/LabTestPopup";
import PrescriptionPopup from "./medicalCards/PrescriptionPopup";

import { setOptions } from "@mobiscroll/react5";
import { getAppointmentDetails } from "../api";
const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const eyePressure = (data) => {
  let arr = [];
  let json = {
    time: data.time ? new Date(data.time).toLocaleString() : "NA",
    iopOD: data.iop.od,
    iopOS: data.iop.os,
    patchyOD: data.pachy.od,
    patchyOS: data.pachy.os,
    method: data.method,
  };
  arr.push(json);
  return arr;
};

const prepareVisionTableData = (data) => {
  // data will take vision
  let arr = [];

  let visionTypeArr = ["unaided", "withGlass", "withPHole"];

  visionTypeArr.forEach((item, index) => {
    let json = {
      visionType: "",
      distanceOD: "",
      distanceOS: "",
      nearOD: "",
      nearOS: "",
    };
    if (index == 0) json.visionType = "Unaided";
    else if (index === 1) json.visionType = "With glass";
    else json.visionType = "With P. Hole";

    json.distanceOD = data.distance[item].od;
    json.distanceOS = data.distance[item].os;
    json.nearOD = data.near[item].od;
    json.nearOS = data.near[item].os;
    arr.push(json);
  });
  return arr;
};

const PastConsultation = (props) => {
  const query = useQuery();
  const appointmentId = query.get("appointmentId");
  const tab = query.get("tab");
  const { patientId } = useParams();

  const { globalState, dispatch } = useStore();

  const [start, startRef] = React.useState(null);
  const [end, endRef] = React.useState(null);
  const [data, setData] = React.useState(null);
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState(tab || "prescription");

  const [followUpPopup, setFollowUpPopup] = React.useState(false);

  React.useEffect(async () => {
    let appointmentId = query.get("appointmentId");
    console.log(appointmentId);
    const res = await getAppointmentDetails({ id: appointmentId });
    console.log(res);
    dispatch({ type: "APPT_DETAILS", appointmentDetails: res });
    setData(res);
  }, []);

  var windowObjectReference = null; // global variable

  const openFollowUpPopup = () => {
    console.log(window.location);
    if (windowObjectReference == null || windowObjectReference.closed) {
      windowObjectReference = window.open(
        `/video-notes/${patientId}?appointmentId=${appointmentId}`,
        "Doctor Notes",
        "popup"
      );
      windowObjectReference.onbeforeunload = function (e) {
        var dialogText = "Dialog text here";
        e.returnValue = dialogText;
        return dialogText;
      };
    } else {
      windowObjectReference.focus();
      windowObjectReference.onbeforeunload = function (e) {
        var dialogText = "Dialog text here";
        e.returnValue = dialogText;
        return dialogText;
      };
    }
  };

  setOptions({
    theme: "ios",
    themeVariant: "light",
  });

  const route = () => {
    history.goBack();
  };

  return (
    <>
      {/* <Followup show={followUpPopup} closeFollowUp={closeFollowUpPopup} /> */}
      <Card className="h-100 docg-card docg-patient-card docg-patient-form-wrapper m-0 mb-1">
        <Card.Header className="d-flex justify-content-between cardHeaderUsed w-100">
          <Card.Title>
            <span>
              <img src={historyIcon} alt="" className="mr-2" />
              History
            </span>
          </Card.Title>
          <button onClick={route} className="docg-save-btn">
            Back
          </button>
        </Card.Header>

        <Card.Body className="docg-patient-body">
          <div className="">
            <div className="mbsc-grid">
              <div className="mbsc-row mt-3">
                <div className="mbsc-col d-flex justify-content-between">
                  <div className="">
                    <span className="purple-bold">Consultancy Date: </span>
                    <span className="purple-medium">
                      {data?.start
                        ? new Date(data?.start).toLocaleString([], {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "-"}
                    </span>
                  </div>
                  <button onClick={openFollowUpPopup} className="docg-save-btn">
                    Update Notes
                  </button>
                </div>
              </div>
              <div className="mbsc-row">
                <div className="mbsc-col">
                  <div className="textarea-wrap pt-3">
                    <span>Notes</span>
                    <b>Patient Complaints:</b>
                    {data?.note?.patientComplaints?.map((item) => (
                      <>
                        <div className="mbsc-col-12 p-0">
                          {item.updatedBy.firstName} {item.updatedBy.lastName}:{" "}
                          <b>{item.text}</b>
                        </div>
                        <br />
                      </>
                    ))}
                    {data?.note?.observation && (
                      <>
                        <div className="w-100">
                          <b>Doctor's Observation:</b> {data?.note?.observation}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.diagnosis?.message && (
                      <>
                        <div className="w-100">
                          <b>Diagnosis:</b> {data?.note?.diagnosis?.message}
                        </div>
                        <br />
                      </>
                    )}

                    {data?.note?.diagnosis?.diagnosisName && (
                      <>
                        <div className="w-100">
                          <b>Diagnosis list:</b>{" "}
                          {data?.note?.diagnosis?.diagnosisName.join(", ") ||
                            "NA"}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.procedure?.message && (
                      <>
                        <div className="w-100">
                          <b>Procedure:</b> {data?.note?.procedure.message}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.procedure?.procedureName && (
                      <>
                        <div className="w-100">
                          <b>Procedure list:</b>{" "}
                          {data?.note?.procedure?.procedureName.join(", ") ||
                            "NA"}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.instruction && (
                      <>
                        <div className="w-100">
                          <b>Instruction:</b>{" "}
                          {data?.note?.instruction.join(", ") || "NA"}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.planOfCare && (
                      <>
                        <div className="w-100">
                          <b>Advice/ Plan of Care:</b>{" "}
                          {data?.note?.planOfCare.join(", ") || "NA"}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.prognosis && (
                      <>
                        <div className="w-100">
                          <b>Prognosis:</b>{" "}
                          {data?.note?.prognosis.join(", ") || "NA"}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.nutritionalAdvice && (
                      <>
                        <div className="w-100">
                          <b>Nutritional Advice:</b>{" "}
                          {data?.note?.nutritionalAdvice.join(", ") || "NA"}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.immunizationStatus && (
                      <>
                        <div className="w-100">
                          <b>Immunization Status:</b>{" "}
                          {data?.note?.immunizationStatus.join(", ") || "NA"}
                        </div>
                        <br />
                      </>
                    )}
                    {data?.note?.visionTable && (
                      <>
                        <div className="mbsc-row text-center">
                          <div className="mbsc-col-6">
                            <table className="docg-table w-100">
                              <tr>
                                <th>Vision</th>
                                <th className="text-center" colSpan={2}>
                                  Distance
                                </th>
                                <th className="text-center" colSpan={2}>
                                  Near
                                </th>
                              </tr>
                              <tr fontSize={10} textAlign="center">
                                <th></th>
                                <th>OD</th>
                                <th>OS</th>
                                <th>OD</th>
                                <th>OS</th>
                              </tr>
                              {prepareVisionTableData(
                                data?.note?.visionTable?.vision
                              ).map((item) => (
                                <tr>
                                  <td>{item.visionType}</td>
                                  <td>{item.distanceOD}</td>
                                  <td>{item.distanceOS}</td>
                                  <td>{item.nearOD}</td>
                                  <td>{item.nearOS}</td>
                                </tr>
                              ))}
                            </table>
                          </div>
                          <div className="mbsc-col-6">
                            <table className="docg-table w-100">
                              <tr>
                                <th>Time</th>
                                <th>Method</th>
                                <th className="text-center" colSpan={2}>
                                  IOP
                                </th>
                                <th className="text-center" colSpan={2}>
                                  Patchy
                                </th>
                              </tr>
                              <tr fontSize={10} textAlign="center">
                                <th></th>
                                <th></th>
                                <th>OD</th>
                                <th>OS</th>
                                <th>OD</th>
                                <th>OS</th>
                              </tr>
                              {eyePressure(
                                data?.note?.visionTable?.eyePressure
                              ).map((item) => (
                                <tr>
                                  <td>{item.time}</td>
                                  <td>{item.method}</td>
                                  <td>{item.iopOD}</td>
                                  <td>{item.iopOS}</td>
                                  <td>{item.patchyOD}</td>
                                  <td>{item.patchyOS}</td>
                                </tr>
                              ))}
                            </table>
                          </div>
                          {data?.note?.visionTable?.autoRefrectometer &&
                            data?.note?.visionTable?.autoRefrectometer.length >
                              0 && (
                              <div className="mbsc-col-12">
                                <table className="docg-table w-100">
                                  <b>Auto Refractometer</b>
                                  <tr>
                                    <th>Method</th>
                                    <th className="text-center" colSpan={3}>
                                      Right Eye
                                    </th>
                                    <th className="text-center" colSpan={3}>
                                      Left Eye
                                    </th>
                                  </tr>
                                  <tr fontSize={10} textAlign="center">
                                    <th></th>
                                    <th>Sph</th>
                                    <th>Cyl</th>
                                    <th>Axis</th>
                                    <th>Sph</th>
                                    <th>Cyl</th>
                                    <th>Axis</th>
                                  </tr>
                                  {data.note.visionTable.autoRefrectometer.map(
                                    (item) => (
                                      <tr>
                                        <td>{item.label}</td>
                                        <td>{item.rightEye.sph}</td>
                                        <td>{item.rightEye.cyl}</td>
                                        <td>{item.rightEye.axis}</td>
                                        <td>{item.leftEye.sph}</td>
                                        <td>{item.leftEye.cyl}</td>
                                        <td>{item.leftEye.axis}</td>
                                      </tr>
                                    )
                                  )}
                                </table>
                              </div>
                            )}
                        </div>
                      </>
                    )}
                    {/* <div className="">
                      <span className="purple-bold">Follow Up: </span>
                      <span className="purple-medium">Nov 17, 2021</span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="mbsc-row history-scroll align-items-center justify-content-center mt-1">
                <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-lg-12">
                  <TabNav
                    className="docg-tabs"
                    theme="windows"
                    context="#tab-content"
                  >
                    <NavItem
                      selected={activeTab === "prescription"}
                      onClick={() => setActiveTab("prescription")}
                    >
                      <img src={Prescription} alt="" />
                      Prescription
                    </NavItem>

                    <NavItem
                      selected={activeTab === "labtest"}
                      onClick={() => setActiveTab("labtest")}
                    >
                      <img src={LabTest} alt="" />
                      Lab Test
                    </NavItem>
                    <NavItem
                      selected={activeTab === "symptoms"}
                      onClick={() => setActiveTab("symptoms")}
                    >
                      <img src={Symptoms} alt="" />
                      Symptoms
                    </NavItem>
                    <NavItem
                      selected={activeTab === "invoice"}
                      onClick={() => setActiveTab("invoice")}
                    >
                      <img src={Invoice} alt="" />
                      Invoice
                    </NavItem>
                  </TabNav>
                  {activeTab === "prescription" && (
                    <PrescriptionPopup data={data?.prescription} />
                  )}
                  {activeTab === "labtest" && (
                    <LabTestPopup
                      tests={data?.tests}
                      labTests={data?.labTestReports}
                    />
                  )}
                  {activeTab === "symptoms" && (
                    <AttachmentPopup data={data?.survey?.questions} />
                  )}
                  {activeTab === "invoice" && (
                    <InvoicePopup data={data?.invoices} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default PastConsultation;
