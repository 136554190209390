export const PENDING_TASK = "PENDING_TASK";
export const APPOINTMENTS = "APPOINTMENTS";

export const kpiInitialState = {
  appointments: 0,
  pendingTask: 0,
  nextAppointment: null,
  lastAppointmentId: null,
};

export const kpiReducer = (state, action, initialState) => {
  switch (action.type) {
    case PENDING_TASK:
      return { ...state, pendingTask: action.payload };
    case APPOINTMENTS:
      return { ...state, appointments: action.payload };
    case "NEXT_APPOINTMENT":
      return { ...state, nextAppointment: action.payload };
    case "LAST_APPOINTMENT":
      return { ...state, lastAppointmentId: action.payload };
    default:
      return state;
  }
};
