import Dashboard from "../Dashboard/Dashboard";
import Calendar from "../Dashboard/Calendar/Calendar";
import DoctorProfile from "./Profile/Profile";
import Fee from "../Dashboard/Fee/FeeWrapper";
import Setting from "../Dashboard/Setting/Setting";
import PatientDetail from "../Dashboard/Patient/PatientDetail/PatientDetail";
import PatientHistory from "../Dashboard/Patient/PatientDetail/PatientHistory";
import Schedule from "../Dashboard/Schedule/Schedule";
import Email from "../Dashboard/email/email";
import AddPatient from "../Dashboard/Patient/AddPatient";
import PatientList from "../Dashboard/PatientList/PatientList";
import Invoice from "../Dashboard/Invoice/Invoice";
import InvoiceDetail from "../Dashboard/Invoice/InvoiceDetail";
import CreateInvoice from "../Dashboard/Invoice/CreateInvoice";
import Reporting from "../Dashboard/Reporting/Reporting";
import TermsAndConditions from "../Dashboard/TermsAndConditions";
// import Followup from "../Dashboard/Patient/PatientDetail/dialogs/Followup";
import InvitePatients from "../Dashboard/InvitePatients/InvitePatients";

const DoctorRoutes = [
  {
    path: "/terms-conditions",
    module: "doctor",
    auth: false,
    component: TermsAndConditions,
  },
  {
    path: "/invite-patient",
    module: "doctor",
    auth: true,
    component: InvitePatients,
  },
  {
    path: "/dashboard",
    module: "doctor",
    auth: true,
    component: Dashboard,
  },
  {
    path: "/patient-record/:patientId",
    module: "doctor",
    auth: true,
    component: PatientDetail,
  },
  {
    path: "/video-notes/:patientId",
    module: "doctor",
    auth: true,
    componentProps: { hideSidebar: true, historySection: true },
    component: PatientDetail,
  },
  // {
  //   path: "/video-call/:patientId",
  //   module: "doctor",
  //   auth: true,
  //   component: VideoCall,
  // }, added in routers.js without template
  {
    path: "/add-patient",
    module: "doctor",
    auth: true,
    component: AddPatient,
  },

  {
    path: "/calendar",
    module: "doctor",
    auth: true,
    component: Calendar,
  },
  {
    path: "/schedule",
    module: "doctor",
    auth: true,
    component: Schedule,
  },
  {
    path: "/profile",
    module: "doctor",
    auth: true,
    component: DoctorProfile,
  },

  // {
  //   path: "/membership",
  //   module: "doctor",
  //   auth: true,
  //   component: Index,
  // },
  {
    path: "/fee",
    module: "doctor",
    auth: true,
    component: Fee,
  },
  {
    path: "/setting",
    module: "doctor",
    auth: true,
    component: Setting,
  },

  {
    path: "/email",
    module: "doctor",
    auth: true,
    component: Email,
  },
  {
    path: "/patient-history/:patientId",
    module: "doctor",
    auth: true,
    component: PatientHistory,
  },

  {
    path: "/patient-list",
    module: "doctor",
    auth: true,
    component: PatientList,
  },
  {
    path: "/invoice",
    module: "doctor",
    auth: true,
    component: Invoice,
  },
  {
    path: "/invoice-details/:id",
    module: "doctor",
    auth: true,
    component: InvoiceDetail,
  },
  // {
  //   path: "/symptom",
  //   module: "doctor",
  //   auth: true,
  //   component: Symptom,
  // },
  // {
  //   path: "/resources",
  //   module: "doctor",
  //   auth: true,
  //   component: Resources,
  // },
  // {
  //   path: "/notification",
  //   module: "doctor",
  //   auth: true,
  //   component: Notification,
  // },
  // {
  //   path: "/payment",
  //   module: "doctor",
  //   auth: true,
  //   component: Payment,
  // },
  {
    path: "/create-invoice",
    module: "doctor",
    auth: true,
    component: CreateInvoice,
  },
  {
    path: "/reporting",
    module: "doctor",
    auth: true,
    component: Reporting,
  },
  // {
  //   path: "/chat",
  //   module: "doctor",
  //   auth: true,
  //   component: Chat,
  // },
  // {
  //   path: "/notification-top-bar",
  //   module: "doctor",
  //   auth: true,
  //   component: NotificationTopBar,
  // },
];

export default DoctorRoutes;
