import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useStore } from "src/store/Store";
import step4_doctor from "../../assets/Doctors 05.svg";
import line from "../../assets/Lines.svg";
import next_icon from "../../assets/Next icon.svg";
import resend_icon from "../../assets/Resend icon.svg";
import grid from "../../assets/grid.svg";
import signin_circle from "../../assets/signin-circle.svg";
import { Input, snackbar } from "@mobiscroll/react5";
import QRCode from "qrcode.react";
import Loader from "src/components/Dashboard/common/Loader";
import {
  setUpTOTP,
  updateMFAPreference,
  verifyTOTPSetup,
} from "@aws-amplify/auth";
import { fetchMFAPreference } from "aws-amplify/auth";

const SetMFAasTOTP = ({ userData, onSuccess }) => {
  const [mfa, setMfa] = useState(null);
  const [codeTOTP, setCodeTOTP] = useState(null);
  const [answer, setAnswer] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const fetchMFAPreferenceData = async () => {
    try {
      const { enabled } = await fetchMFAPreference();
      console.log("[[[[[", enabled);
      setMfa(enabled || []);
    } catch (err) {
      console.log(err);
    }
  };

  const changePreferredMFA = (value) => {
    if (value == "TOTP")
      updateMFAPreference({
        totp: "ENABLED",
        sms: "DISABLED",
      })
        .then((data) => {
          if (codeTOTP !== null) {
            setCodeTOTP(null);
          }
          if (value === "SMS") setMfa("SMS_MFA");
          else if (value === "TOTP") setMfa("SOFTWARE_TOKEN_MFA");
          else setMfa(value);
          onSuccess();
        })
        .catch((err) => {
          console.log(err);
          snackbar({ message: "Unable to switch MFA option", color: "danger" });
        });
  };

  const _setUpTOTP = () => {
    setLoading(true);
    setUpTOTP()
      .then(({ sharedSecret, getSetupUri }) => {
        setCodeTOTP(sharedSecret);
        const authCode =
          "otpauth://totp/DocG AI:" +
          userData.username +
          "?secret=" +
          sharedSecret +
          "&issuer=DocG AI";
        setAuthCode(authCode);
      })
      .catch((err) => {
        snackbar({
          message: err.message || "Unable to switch MFA option",
          color: "danger",
        });
      });
    setLoading(false);
  };

  const changeToTOTP = () => {
    if (answer === "") {
      snackbar({ message: "Code can't be empty", color: "danger" });
      setError(true);
      return;
    }
    setLoading(true);
    setCodeTOTP(null);
    verifyTOTPSetup({ code: answer }).then(() => {
      changePreferredMFA("TOTP");
    });
    setLoading(false);
  };

  useEffect(() => {
    _setUpTOTP();
  }, []);

  return (
    <>
      <Loader show={loading} />

      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-12 signup-right">
                <img alt="" src={grid} className="grid-top" />
                <img alt="" src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <div className="signup-wrapper-inner">
                      {!loading && codeTOTP !== null && (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <p className="get-txt">
                            Setup TOTP for Multi-factor Authentication
                          </p>
                          <h4 numberOfLines={5}>
                            Step 1 :Open the Authenticator app and scan this QR
                            Code
                          </h4>

                          {authCode && (
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <QRCode
                                id="qr-gen"
                                value={authCode}
                                up
                                size={256}
                                level={"H"}
                                includeMargin={true}
                              />
                            </div>
                          )}
                          <br />
                          <span>or enter the following code manually:</span>
                          <br />
                          <b>{codeTOTP}</b>
                          <br />
                          <br />
                          <h4>
                            Step 2 : Validate by entering the code generated by
                            the application
                          </h4>

                          {/* <Input
                            labelStyle="floating"
                            inputStyle="underline"
                            label="Enter code*"
                            onChange={(input) => setAnswer(input.target.value)}
                            value={answer}
                          /> */}
                          <OtpInput
                            value={answer}
                            onChange={(e) => setAnswer(e)}
                            numInputs={6}
                            hasErrored={error}
                            errorStyle={"otp-input-error"}
                            separator={<span>&emsp;</span>}
                            containerStyle={"otp-container"}
                            inputStyle={"form-input-otp"}
                            isInputNum={true}
                          />
                          <br />
                          <button
                            className="docg-save-btn"
                            onClick={() => {
                              changeToTOTP();
                            }}
                          >
                            Verify
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <img alt="" src={line} className="lines-bottom" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetMFAasTOTP;
