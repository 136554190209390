import React, { Fragment } from "react";

import Avatar, { ConfigProvider } from "react-avatar";
import { useStore } from "src/store/Store";
import moment from "moment";
import _ from "lodash";

import "../Layouts/Card.css";
import "./NotificationTopBar.css";
import watchIcon from "../../../assets/watch.svg";

function NotificationTopBar(props) {
  const { dispatchNotifications, globalStateNotifications } = useStore();

  const convertTimeStamp = React.useCallback(
    (timestamp) => {
      return moment().diff(moment(timestamp), "days") != 0
        ? `${moment().diff(moment(timestamp), "days")} days ago`
        : moment().diff(moment(timestamp), "hours") != 0
        ? `${moment().diff(moment(timestamp), "hours")} hours ago`
        : moment().diff(moment(timestamp), "minutes") != 0
        ? `${moment().diff(moment(timestamp), "minutes")} minutes ago`
        : `${moment().diff(moment(timestamp), "seconds")} seconds ago`;
    },
    [moment]
  );
  return (
    <Fragment>
      <p className="card-text m-0 add-overflow-content">
        {globalStateNotifications.notifications?.length > 0 ? (
          globalStateNotifications.notifications?.map((data, index) => (
            <Fragment key={index}>
              <div className="d-flex p-3 add-color-div align-items-center">
                <Avatar
                  color="#c06c84"
                  round
                  size="50"
                  name={data?.metadata?.patientName}
                  src={data?.metadata?.patientProfilePicture}
                />
                <div className="ml-3 text-left w-100">
                  <div className="d-flex justify-content-between">
                    <span style={{}} className="notification-name-txt">
                      {data.title}
                    </span>
                    <span className="minute-txt">
                      <img src={watchIcon} className="mr-2" alt="" />
                      {convertTimeStamp(data?.createdAt)}
                    </span>
                  </div>
                  <p className="paitentName m-0">{data.message}</p>
                </div>
              </div>
            </Fragment>
          ))
        ) : (
          <p>No data</p>
        )}
      </p>
    </Fragment>
  );
}

export default NotificationTopBar;
