import React from "react";
import Avatar, { ConfigProvider } from "react-avatar";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import UserTwo from "../../../assets/icons/UserTwo.png";

const PatientNameCellRenderer = (params) => {
  console.log(params);
  const LINK = `/patient-record/${params.value.patientId}`;
  return (
    <>
      <Link
        className="docg-app-patient-name w-100 align-items-center"
        to={LINK}
      >
        <Avatar
          color="#c06c84"
          round
          size="33"
          name={params.value.name}
          src={params?.data?.profilePicture}
        />
        <span className="ml-2">{params.value.name}</span>
      </Link>
    </>
  );
};

export default PatientNameCellRenderer;
