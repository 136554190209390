import { Datepicker, Input, Select } from "@mobiscroll/react5";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "src/components/Dashboard/common/Loader";
import * as Yup from "yup";
import addtodolist from "../../assets/icons/ToDoListIcon.svg";
import saveIcon from "../../assets/icons/save.svg";
import { addToDoList } from "./api";
import "./modals.scss";

var initialValues = {
  name: "",
  date: "",
  priority: "",
};
const initialTouched = {
  name: false,
  date: false,
  priority: false,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  date: Yup.string().required("required"),
  priority: Yup.string().required("required"),
});

const AddToDoList = ({ setLgShow, lgShow, fetchData }) => {
  const onSubmit = async (values) => {
    setLoader(true);
    values.date = new Date(values.date).toISOString();
    const res = await addToDoList(values);
    if (res) {
      await fetchData();
    }
    setLoader(false);
    setLgShow(false);
    handleReset();
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    handleReset,
    isValid,
  } = useFormik({
    initialValues,
    onSubmit,
    initialTouched,
    validationSchema,
  });

  const [loader, setLoader] = useState(false);

  return (
    <Fragment>
      <Loader show={loader} />
      <Modal
        size="md"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
        dialogClassName="docg-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <img src={addtodolist} alt="" /> &nbsp;
            <span
              style={{
                fontSize: "18px",
                fontFamily: "ubuntu",
                fontWeight: "normal",
              }}
            >
              Add To-Do item
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" add-height-modal todoModal docg-patient-form-wrapper">
          <form onSubmit={handleSubmit} action="" method="post">
            <div className="mbsc-row justify-content-between mt-3">
              <div className="mbsc-col-12 mt-2">
                <Input
                  inputStyle="underline"
                  labelStyle="floating"
                  label="To Do List Item*"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={touched.name ? errors.name : false}
                  errorMessage={errors.name}
                />
              </div>
              <div className="mbsc-col-6 mt-2">
                <Datepicker
                  controls={["calendar"]}
                  min={new Date().toISOString()}
                  labelStyle="floating"
                  theme="ios"
                  themeVariant="light"
                  inputProps={{
                    label: "Due Date*",
                    labelStyle: "floating",
                    inputStyle: "underline",
                  }}
                  onChange={(e) => setFieldValue("date", e.valueText, true)}
                  value={values.date}
                  name="date"
                  error={touched.date ? errors.date : false}
                  errorMessage={errors.date}
                />
              </div>

              <div className="mbsc-col-6 mt-2">
                <Select
                  data={[
                    { text: "High", value: "High" },
                    { text: "Medium", value: "Medium" },
                    { text: "Low", value: "Low" },
                  ]}
                  label="Priority*"
                  inputProps={{
                    inputStyle: "box",
                    labelStyle: "floating",
                    placeholder: "Please select",
                  }}
                  touchUi={false}
                  value={values.priority}
                  name="priority"
                  error={touched.priority ? errors.priority : false}
                  errorMessage={errors.priority}
                  onChange={(e) => setFieldValue("priority", e.valueText, true)}
                />
              </div>
            </div>
            <div className="mbsc-col-12 d-flex justify-content-end">
              <button type="submit" className="docg-save-btn">
                <img src={saveIcon} alt="" />
                <span className="text-white ml-2 "> Save </span>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default AddToDoList;
