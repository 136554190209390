import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import EndConsultationModal from "src/components/Dashboard/Layouts/EndConsultationModal";

export const RouteLeavingGuard = ({
  navigate,
  when,
  shouldBlockNavigation,
  data,
}) => {
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const showModal = (location) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <EndConsultationModal
        show={modalVisible}
        onClose={closeModal}
        data={data}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};

export default RouteLeavingGuard;
