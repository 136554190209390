import { Input } from "@mobiscroll/react5";
import { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import doctor from "src/assets/Doctors 05.svg";
import line from "src/assets/Lines.svg";
import next_icon from "src/assets/Next icon.svg";
import resend_icon from "src/assets/Resend icon.svg";
import grid from "src/assets/grid.svg";
import signin_circle from "src/assets/signin-circle.svg";
import Loader from "src/components/Dashboard/common/Loader";
import "../Dashboard/Layouts/Card.css";
import "./Auth.scss";
import ResetPassword from "./ResetPassword";
import { resetPassword } from "@aws-amplify/auth";

function ForgotPassword() {
  const [formErrors, setFormErrors] = useState();
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [loader, setLoader] = useState(false);

  async function sendOTP(username) {
    setLoader(true);
    resetPassword({ username })
      .then((data) => {
        setLoader(false);
        setStep(2);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const nextStep = async () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username)) {
      await sendOTP(username);
    } else {
      setFormErrors("Enter Valid username");
    }
  };

  if (step == 2) return <ResetPassword username={username} />;

  return (
    <Fragment>
      <Helmet>
        <title>Forgot Password | DOCG</title>
      </Helmet>
      <Loader show={loader} />
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-6 doctor-images-left mbsc-col-12">
                <div className="left-signup-div" id="reset-password-page"></div>
                <img src={doctor} className="left-signup-img left-steps-img" />
              </div>
              <div className="mbsc-col-xl-6 mbsc-col-12 signup-right">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="signup-link">Step 1/2</p>
                    <p className="signup-link mt-2">
                      Enter username to Change Password.
                    </p>
                    <br />
                    {/* <mobiscroll.Input
                            inputStyle="underline"
                            passwordToggle={true}
                            placeholder="Enter your otp here"
                            type="number"
                            onChange={handleChange}
                            valid={!formErrors}
                            errorMessage={formErrors}
                          ></mobiscroll.Input> */}
                    <div className="signup-wrapper-inner">
                      <div className="row">
                        <div className="col-12">
                          <Input
                            labelStyle="floating"
                            inputStyle="underline"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          <br />
                          {formErrors ? (
                            <span className="otp-error">{formErrors}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <br />
                      <button className="purple-button" onClick={nextStep}>
                        <img src={next_icon} />
                        &nbsp;&nbsp; Continue
                      </button>
                      <br />
                      <br />
                      {/* <button className="purple-color">
                        <img src={resend_icon} className="resend-icon" />
                        Send me a new code
                      </button> */}
                    </div>
                  </div>
                </div>
                <img
                  src={line}
                  style={{ marginLeft: "8%" }}
                  className="lines-bottom"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ForgotPassword;
