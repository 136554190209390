import React from "react";
import { Route } from "react-router-dom";
import AuthTemplate from "./AuthTemplate";

const OpenRoute = ({ component: Component, module, key, strict, ...rest }) => {
  return (
    <AuthTemplate>
      <Route
        {...rest}
        exact={strict}
        strict={strict}
        key={key}
        render={(props) => <Component {...props} />}
      />
    </AuthTemplate>
  );
};

export default OpenRoute;
