import moment from "moment";

export default function getAge(dateString) {
  var diff = moment().diff(moment(dateString), "milliseconds");
  var duration = moment.duration(diff);
  console.log(duration, duration.years(), duration.years(), duration.years());
  return duration.years() > 0
    ? duration.years() + " years"
    : duration.months() + " months";
}
