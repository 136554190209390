import React, { Fragment, lazy, Suspense, useState } from "react";
import _ from "lodash";

import { useParams, useLocation } from "react-router-dom";
import Loader from "src/components/Dashboard/common/Loader";
import { Card, CardContent, Page, TabNav, NavItem } from "@mobiscroll/react";
import Modal from "./PatientModal";
import { useStore } from "./store/Store";
import {
  isActiveTab,
  setAppointmentId,
  isStartingConsultation,
  setPatientId,
  setUnsaved,
  setConsultationType,
  handleConsultationPopupChange,
  setTabSwitched,
} from "./store/PatientDetailsReducer";
import Note from "src/assets/icons/Note.svg";
import Prescription from "src/assets/icons/Bottle.svg";
import MedicalRecord from "src/assets/icons/MedicalRecordIcon.svg";
import Symptom from "src/assets/icons/Symptom.svg";
import Vaccination from "src/assets/icons/Vaccination.svg";
import LabTest from "src/assets/icons/LabTest.svg";
import "./patient.scss";
//api
import { setAppointmentDelay, getConsultationDetails } from "./api";

import TabLeave from "./Tabs/Modals/TabLeave";

const MedicalRecordTab = lazy(() => import("./Tabs/MedicalRecordTab"));
const SymptomTab = lazy(() => import("./Tabs/SymptomTab"));
const NotesTab = lazy(() => import("./Tabs/notes/NotesTab"));
const PrescriptionTab = lazy(() =>
  import("./Tabs/prescription/PrescriptionTab")
);
const LabTestTab = lazy(() => import("./Tabs/labTest/LabTestTab"));
const VaccinationTab = lazy(() => import("./Tabs/vaccination/VaccinationTab"));
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PatientTabs = ({ historySection = false }) => {
  const { globalState, dispatch } = useStore();
  const { patientId } = useParams();
  const tabRef = React.useRef();
  console.log(historySection);

  let query = useQuery();

  const [loader, setLoader] = useState(false);
  const [tabLeave, setTabLeave] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [paymentModeCash, setPaymentModeCash] = React.useState(false);
  const [consultationDetails, setConsultationDetails] = React.useState(null);
  const [clicked, setClicked] = React.useState(null);

  async function fetchData() {
    dispatch(setPatientId(patientId));
    let appointmentId = query.get("appointmentId");
    dispatch(setAppointmentId(appointmentId));
    if (appointmentId != null) {
      setLoader(true);
      try {
        const res = await getConsultationDetails({ id: appointmentId });
        setConsultationDetails(res);
        dispatch({ type: "APPT_DETAILS", appointmentDetails: res });
        dispatch(setConsultationType(res.consultationType));
        if (res.paymentMode === "Offline") {
          setPaymentModeCash(true);
        }
        if (res.status === "In-Progress" || historySection) {
          console.log("hee");
          dispatch(isStartingConsultation(true));
        } else if (res.status === "Completed") {
          dispatch(isStartingConsultation(false));
        } else {
          dispatch(isStartingConsultation(false));
          setModalShow(true);
        }
      } catch (err) {
        console.log(err);
      }
      setLoader(false);
    }
  }

  React.useEffect(() => {
    fetchData();
    return () => {
      sessionStorage.removeItem(`PatientHistory-${patientId}`);
    };
  }, []);

  React.useEffect(() => {
    if (historySection) {
      dispatch(isActiveTab("notes"));
    }
    return () => null;
  }, [dispatch, historySection]);

  function onItemTap(event, inst) {
    dispatch(setTabSwitched(true));
    setClicked(event);
    if (globalState.activeTab !== event.target.dataset.id) {
      if (globalState.unsaved) {
        tabRef.current.instance.deselect(event.target);
        tabRef.current.instance.select(globalState.activeTab);
        setTabLeave(true);
      } else {
        tabRef.current.instance.select(event.target);
        dispatch(isActiveTab(event.target.dataset.id));
      }
    }
  }

  const handleYesTabLeave = () => {
    setTabLeave(false);
    if (globalState.activeTab == "notes") {
      globalState.subFunc();
    }
    console.log(globalState.func);
    globalState.func();
    handleNoTabLeave();
  };
  const handleNoTabLeave = () => {
    tabRef.current.instance.select(clicked.target);
    dispatch(isActiveTab(clicked.target.dataset.id));
    dispatch(setUnsaved(false));
    setTabLeave(false);
  };

  return (
    <Fragment>
      <Loader show={loader} />
      <Modal
        paymentModeCash={paymentModeCash}
        show={modalShow}
        consultationDetails={consultationDetails}
        onHide={() => setModalShow(false)}
      />
      <TabLeave
        show={tabLeave}
        closePopup={() => setTabLeave(!tabLeave)}
        handleYes={handleYesTabLeave}
        handleNo={handleNoTabLeave}
        title={`Do you want to save ${_.startCase(globalState.activeTab)}`}
      />
      {historySection ? (
        <Suspense fallback={<div>Loading....</div>}>
          <NotesTab showPopup={true} />
        </Suspense>
      ) : (
        <Card className="docg-card docg-patient-card m-0 mb-1">
          <CardContent className="docg-card-content docg-patient-bar">
            <TabNav ref={tabRef} className="docg-tabs" theme="windows">
              <NavItem
                id="medicalRecord"
                className="medicalRecord"
                selected={globalState.activeTab === "medicalRecord"}
                onClick={onItemTap}
              >
                <img src={MedicalRecord} alt="" />
                Medical Record
              </NavItem>
              <NavItem
                id="symptom"
                selected={globalState.activeTab === "symptom"}
                onClick={onItemTap}
              >
                <img src={Symptom} alt="" />
                Symptom
              </NavItem>
              <NavItem
                id="notes"
                selected={globalState.activeTab === "notes"}
                onClick={onItemTap}
              >
                <img src={Note} alt="" />
                Notes
              </NavItem>
              <NavItem
                id="prescription"
                selected={globalState.activeTab === "prescription"}
                onClick={onItemTap}
              >
                <img src={Prescription} alt="" />
                Prescription
              </NavItem>
              <NavItem
                onClick={onItemTap}
                id="labTest"
                selected={globalState.activeTab === "labTest"}
              >
                <img src={LabTest} alt="" />
                Lab Test
              </NavItem>
              <NavItem
                onClick={onItemTap}
                id="vaccination"
                selected={globalState.activeTab === "vaccination"}
              >
                <img src={Vaccination} alt="" />
                Vaccination
              </NavItem>
            </TabNav>
            <div className="docg-patient-body">
              {globalState.activeTab === "medicalRecord" && (
                <Suspense fallback={<div>Loading....</div>}>
                  <MedicalRecordTab />
                </Suspense>
              )}
              {globalState.activeTab === "symptom" && (
                <Suspense fallback={<div>Loading....</div>}>
                  <SymptomTab />
                </Suspense>
              )}
              {globalState.activeTab === "notes" && (
                <Suspense fallback={<div>Loading....</div>}>
                  <NotesTab showPopup={true} />
                </Suspense>
              )}
              {globalState.activeTab === "prescription" && (
                <Suspense fallback={<div>Loading....</div>}>
                  <PrescriptionTab showPopup={true} />
                </Suspense>
              )}
              {globalState.activeTab === "labTest" && (
                <Suspense fallback={<div>Loading....</div>}>
                  <LabTestTab showPopup={true} />
                </Suspense>
              )}
              {globalState.activeTab === "vaccination" && (
                <Suspense fallback={<div>Loading....</div>}>
                  <VaccinationTab />
                </Suspense>
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
};

export default PatientTabs;
