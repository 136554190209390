import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ActionCellRender from "./ActionCellRender";
import Appointment from "../../../assets/icons/Appointment.svg";
import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const DataListingCard = (props) => {
  const history = useHistory();

  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("03");

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  const onPageSizeChanged = (e) => {
    setDisplayRecords(e.valueText);
    gridApi.paginationSetPageSize(Number(e.valueText));
  };

  // changes, needs to be state
  // eslint-disable-next-line
  const [rowData, setRowData] = useState([
    {
      id: 1,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      PatientName: { name: "Kate Barke", status: "Medium" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 1,
      PatientName: { name: "Kate Barke", status: "Medium" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 1,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 1,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 1,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
    },
    {
      id: 4,
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
  ]);

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const routeChange = () => {
    history.push("/add-new-appointment");
  };
  return (
    <Fragment>
      <Card className="docg-card docg-appointment">
        <CardHeader
          className="d-flex justify-content-between cardHeaderUsed"
          // style={{
          //   height: "4rem",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   fontStyle: "ubuntu",
          //   fontSize: "18px",
          //   fontWeight: "normal",
          //   backgroundColor: "#dee2e6",
          // }}
        >
          <CardTitle className="cardTitleUsed">
            <Image src={Appointment} alt="" className="mr-2" /> Appointments
          </CardTitle>
          <div style={{ display: "flex", alignItems:"center" }}>
            <div className="docg-table-search">
              <input
                type="text"
                onInput={() => onQuickFilterChanged()}
                id="quickFilter"
                placeholder="Search..."
              />
            </div>
            <Button className="theme-button btn-xs" onClick={routeChange}>
              <span className="material-icons pr-1">add_circle_outline</span>{" "}
              Add
            </Button>
          </div>
        </CardHeader>
        <CardBody className="docg-card-body p-0">
          <div className="records-display">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              <mobiscroll.Select
                touchUi={false}
                onChange={(e) => {
                  onPageSizeChanged(e);
                }}
                value={displayRecords}
              >
                {Array.from(Array(rowData.length).keys()).map((index) => {
                  let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
                  return <option value={value}>{value}</option>;
                })}
              </mobiscroll.Select>
              <i className="fas fa-chevron-down"></i>
            </label>
            <span>&nbsp;Records</span>
          </div>
          <Row>
            <Col>
              <div
                className="ag-theme-alpine"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <AgGridReact
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    actionCellRender: ActionCellRender,
                  }}
                  defaultColGroupDef={defaultColGroupDef}
                  enableRangeSelection={true}
                  pagination={true}
                  onGridReady={onGridReady}
                  paginationPageSize={displayRecords}
                  domLayout={"autoHeight"}
                  animateRows={true}
                >
                  <AgGridColumn
                    headerName="Time"
                    field="ApptTime"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Disease"
                    field="Disease"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Consl. Type"
                    field="ConslType"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Room No."
                    field="RoomNo"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName=""
                    field="actions"
                    cellRenderer="actionCellRender"
                    filter={false}
                    sortable={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DataListingCard;
