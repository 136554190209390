import React, { Fragment } from "react";
import "react-circular-progressbar/dist/styles.css";

const EmailTableHeader = (props) => {
  return (
    <Fragment>
      <div className="">
        {props?.headerData?.length > 0 ? (
          props?.headerData?.map((data, index) => (
            <Fragment key={index}>
              <img src={data?.icon} alt="" className="icon-size mr-4" />
            </Fragment>
          ))
        ) : (
          <p>No data</p>
        )}
      </div>
    </Fragment>
  );
};

export default EmailTableHeader;
