import React, { useCallback, useState, Suspense } from "react";
import { Modal } from "react-bootstrap";
import close from "src/assets/close.svg";
import { useStore } from "./store/Store";
import {
  replaceSchedule,
  changeCurrentDay,
  selectedSchedule,
} from "./store/ScheduleReducer";
import { addSchedule, getData } from "./api/index";
import {
  Eventcalendar,
  snackbar,
  setOptions,
  Button,
  Input,
  Textarea,
  Datepicker,
  SegmentedGroup,
  SegmentedItem,
  getJson,
  CalendarNav,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  Select,
  Checkbox,
  RadioGroup,
  Radio,
  formatDate,
  momentTimezone,
  Popup,
  // Switch,
} from "@mobiscroll/react5";
import mobiscroll from "@mobiscroll/react";
import Loader from "src/components/Dashboard/common/Loader";
import Recurring from "./ScheduleHandler";
import moment from "moment-timezone";
import "./schedule.scss";
import calendar from "../../../assets/schedule-purple.svg";
import cancel from "../../../assets/cancel.svg";
import { isMobileOnly } from "react-device-detect";

const CancelScheduleCurrent = React.lazy(() =>
  import("./deleteSchedule/StatusBookedModal")
);
const CancelScheduleRecurring = React.lazy(() =>
  import("./deleteScheduleRecurring/StatusBookedModal")
);

setOptions({
  theme: "ios",
  themeVariant: "light",
});

// setup Mobiscroll Moment plugin
momentTimezone.moment = moment;
const responsivePopup = {
  medium: {
    display: "anchored",
    width: 600,
    maxHeight: 370,
    fullScreen: false,
    touchUi: true,
  },
};
const selectResponsive = {
  xsmall: {
    touchUi: true,
  },
  small: {
    touchUi: true,
  },
};

const days = [
  {
    name: "Sun",
    value: "SU",
    checked: true,
  },
  {
    name: "Mon",
    value: "MO",
    checked: false,
  },
  {
    name: "Tue",
    value: "TU",
    checked: false,
  },
  {
    name: "Wed",
    value: "WE",
    checked: false,
  },
  {
    name: "Thu",
    value: "TH",
    checked: false,
  },
  {
    name: "Fri",
    value: "FR",
    checked: false,
  },
  {
    name: "Sat",
    value: "ST",
    checked: false,
  },
];

const dateInputProps = {
  className: "custom-repeat-input custom-specific-date",
  inputStyle: "outline",
};

function App(props) {
  const { globalState, dispatch } = useStore();
  const [loader, setLoader] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [tempEvent, setTempEvent] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const [start, startRef] = React.useState(null);
  const [end, endRef] = React.useState(null);
  const [popupEventTitle, setTitle] = React.useState("Open Schedule");
  const [popupSlotDuration, setSlot] = React.useState("");
  const [popupGap, setGap] = React.useState("");
  const [popupEventDate, setDate] = React.useState([]);
  const [mySelectedDate, setSelectedDate] = React.useState(new Date());
  const [view, setView] = React.useState("day");
  const [recurring, setRecurring] = React.useState(false);
  const [cancelSchedule, setCancelSchedule] = React.useState(false);
  const [cancelScheduleRecurring, setCancelScheduleRecurring] =
    React.useState(false);

  // recurring editor data
  const [repeatData, setRepeatData] = React.useState([
    {
      value: "norepeat",
      text: "Does not repeat",
    },
    {
      value: "custom",
      text: "Custom",
    },
  ]);
  const [selectedRepeat, setSelectedRepeat] = React.useState("norepeat");
  const [repeatType, setRepeatType] = React.useState("daily");
  const [repeatNr, setRepeatNr] = React.useState(1);
  const [condition, setCondition] = React.useState("until");
  const [untilDate, setUntilDate] = React.useState();
  const [selectedMonth, setMonth] = React.useState(1);
  const [weekDays, setWeekDays] = React.useState([...days]);
  const [invalidData, setInvalidData] = React.useState([]);

  // set custom values to default
  const resetCustomValues = React.useCallback(
    (recurring) => {
      setRepeatType("daily");
      setRepeatNr(1);
      setCondition("until");
      setMonth(1);
      const newWeekDays = [...days];
      for (let i = 0; i < newWeekDays.length; i++) {
        const day = newWeekDays[i];
        day.checked = day.value === "SU";
      }
      setWeekDays(newWeekDays);
      setSelectedRepeat("norepeat");
      setRepeatData(repeatData.filter((item) => item.value !== "custom-value"));
    },
    [repeatData]
  );

  const navigateTo = React.useCallback(() => {
    const rec = tempEvent.recurrence;
    const d = new Date(tempEvent.start);
    // navigate the calendar to the correct view
    if (rec && rec.repeat === "yearly") {
    } else {
      setSelectedDate(d);
    }
  }, [tempEvent]);

  const repeatChange = React.useCallback((ev) => {
    setSelectedRepeat(ev.value);
  }, []);

  const repeatTypeChange = React.useCallback((ev) => {
    setRepeatType(ev.target.value);
  }, []);

  const repeatNrChange = React.useCallback((ev) => {
    setRepeatNr(ev.target.value);
  }, []);

  const weekDayChange = React.useCallback(
    (ev) => {
      weekDays.find((i) => i.value === ev.target.value).checked =
        ev.target.checked;
      setWeekDays([...weekDays]);
    },
    [weekDays]
  );

  const conditionChange = React.useCallback((ev) => {
    setCondition(ev.target.value);
  }, []);

  const untilDateChange = React.useCallback((ev) => {
    setUntilDate(ev.value);
  }, []);

  const [calView, setCalView] = React.useState({
    schedule: {
      type: "day",
      timeCellStep: 15,
      dayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
  });

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "month":
        calView = {
          calendar: { labels: true },
        };
        break;
      case "week":
        calView = {
          schedule: { type: "week", timeCellStep: 15 },
        };
        break;
      case "day":
        calView = {
          schedule: { type: "day", timeCellStep: 15 },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };
  const customWithNavButtons = () => {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex justify-content-center align-items-center ml-3">
            <img
              style={{ maxWidth: "20px", maxHeight: "20px" }}
              src={calendar}
              className="mr-2"
              alt=""
            />{" "}
            Schedule
          </div>
          <div className="cal-header-picker d-flex align-items-center">
            <CalendarNav className="cal-header-nav" />
            {isMobileOnly ? (
              <></>
            ) : (
              <>
                <SegmentedGroup value={view} onChange={changeView}>
                  <SegmentedItem style={{ width: "fit-content" }} value="day">
                    Day
                  </SegmentedItem>
                  <SegmentedItem style={{ width: "fit-content" }} value="week">
                    Week
                  </SegmentedItem>
                  <SegmentedItem style={{ width: "fit-content" }} value="month">
                    Month
                  </SegmentedItem>
                </SegmentedGroup>
              </>
            )}
          </div>
          <div className="">
            <CalendarPrev className="cal-header-prev" />
            <CalendarToday className="cal-header-today" />
            <CalendarNext className="cal-header-next" />
          </div>
          <img className="mr-3" onClick={props.closePopup} src={close} alt="" />
        </div>
      </React.Fragment>
    );
  };

  const renderScheduleEvent = useCallback((data) => {
    return (
      <div
        className="docg-custom-schedule-event d-flex"
        style={{
          background: "#1AAB73",
          color: "#fff",
        }}
      >
        <div className="docg-custom-event-title">Open Schedule</div>
        <div className="docg-custom-event-time">
          {data.start} to {data.end}
        </div>
      </div>
    );
  });
  const renderLabel = useCallback((data) => {
    return (
      <div
        className="docg-custom-schedule-event d-flex"
        style={{
          background: "#1AAB73",
          color: "#fff",
        }}
      >
        <div className="docg-custom-event-title">Open Schedule</div>
      </div>
    );
  });

  const handleValidation = () => {
    let isValid = true;
    let err = {};
    if (popupSlotDuration.length <= 0 || popupSlotDuration <= 0) {
      isValid = false;
      err["popupSlotDuration"] = "Enter a valid Slot Duration";
    }
    if (popupGap.length <= 0 || popupGap < 0) {
      isValid = false;
      err["popupGap"] = "Enter a valid Slot Gap";
    }
    setErrors({ ...err });
    return isValid;
  };

  const saveEvent = React.useCallback(async () => {
    let isValid = handleValidation();
    if (isValid) {
      setOpen(false);
      setLoader(true);
      let recurringRule;
      switch (selectedRepeat) {
        case "custom":
        case "custom-value":
          recurringRule = {
            repeat: repeatType,
            interval: repeatNr,
          };

          switch (repeatType) {
            case "weekly":
              let _weekDays = [];
              weekDays
                .filter((i) => i.checked)
                .map((i) => {
                  _weekDays.push(i.value);
                  return i.value;
                });
              recurringRule.weekDays = _weekDays;

              break;

            default:
          }

          switch (condition) {
            case "until":
              recurringRule.until = new Date(untilDate).toISOString();
              break;
            default:
          }
          break;
        default:
      }

      const newEvent = {
        start: popupEventDate[0].toISOString(),
        end: popupEventDate[1].toISOString(),
        duration: popupSlotDuration,
        gap: popupGap,
        recurrence: typeof recurringRule == "undefined" ? null : recurringRule,
      };
      if (isEdit) {
        // ...
      } else {
        setLoader(true);
        const doctorId = sessionStorage.getItem("role-id");
        if (typeof doctorId != "undefined") {
          const res = await addSchedule({ ...newEvent, doctorId });
          console.log(res);
          if (res) {
            setLoader(true);
            const start = globalState?.current?.start;
            const end = globalState?.current?.end;
            const data = await getData(start, end);
            dispatch(replaceSchedule([...data]));
            setLoader(false);
            props.closePopup();
          } else {
            console.log("res", res);
            dispatch(replaceSchedule([...globalState.schedules]));
          }
          setLoader(false);
        } else {
          dispatch(replaceSchedule([...globalState.schedules]));
        }
      }

      // navigate the calendar
      navigateTo();
    } else {
      snackbar({
        message: "Fill all the required fields Properly.",
        color: "danger",
      });
    }
    // close the popup
  }, [
    isEdit,
    globalState.schedules,
    popupEventDate,
    popupEventTitle,
    popupSlotDuration,
    popupGap,
    tempEvent,
    navigateTo,
    condition,
    repeatNr,
    repeatType,
    selectedMonth,
    selectedRepeat,
    untilDate,
    weekDays,
  ]);

  const deleteEvent = React.useCallback(
    (event) => {
      //   setglobalState.schedules(myEvents.filter((item) => item.id !== event.id));
      setTimeout(() => {
        snackbar({
          button: {
            action: () => {
              //   setMyEvents((prevEvents) => [...prevEvents, event]);
            },
            text: "Undo",
          },
          message: "Event deleted",
        });
      });
    },
    [globalState.schedules]
  );
  const loadPopupForm = React.useCallback(
    (event) => {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);
      setSlot(event.duration);
      setGap(event.gap);
      setDate([startDate, endDate]);
      setUntilDate(
        formatDate(
          "YYYY-MM-DD",
          new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + 1
          )
        )
      );
      const d = new Date(event.start);
      const weekday = d.getDay();
      const monthday = d.getDate();
      const newData = repeatData.slice(0);

      // update select texts by selected date
      for (let i = 0; i < newData.length; ++i) {
        var item = newData[i];
        switch (item.value) {
          case "weekly":
            item.text = "Weekly on " + days[weekday].name;
            break;
          case "monthly":
            item.text = "Monthly on day " + monthday;
            break;

          default:
        }
      }

      setRepeatData(newData);

      const rec = event.recurrence;

      if (rec) {
        setRepeatType(rec.repeat);
        if (rec.interval) {
          // set custom text
          let customText = "";
          const nr = rec.interval;
          setRepeatNr(nr);

          switch (rec.repeat) {
            case "weekly":
              const newWeekDays = [...days];
              const weekD = rec.weekDays;
              for (let i = 0; i < newWeekDays.length; i++) {
                const day = newWeekDays[i];
                day.checked = weekD.includes(day.value);
              }

              setWeekDays(newWeekDays);
              customText = nr > 1 ? "Every " + nr + " weeks" : "Weekly";
              customText +=
                " on " +
                newWeekDays
                  .filter((i) => i.checked)
                  .map((i) => i.name)
                  .join(", ");
              break;
            default:
          }

          if (rec.until) {
            setCondition("until");
            setUntilDate(rec.until);
            customText +=
              " until " + formatDate("MMMM D, YYYY", new Date(rec.until));
          }

          // add custom value
          setRepeatData([
            ...repeatData,
            { value: "custom-value", text: customText },
          ]);
          // set custom value
          setSelectedRepeat("custom-value");
        } else if (rec.weekDays === "MO,TU,WE,TH,FR") {
          setSelectedRepeat("weekday");
        } else {
          setSelectedRepeat(rec.repeat);
        }
      } else {
        resetCustomValues();
      }
    },
    [repeatData, resetCustomValues]
  );

  // handle popup form changes

  const titleChange = React.useCallback((ev) => {
    setTitle(ev.target.value);
  }, []);

  const slotChange = React.useCallback((ev) => {
    setSlot(ev.target.value);
  });
  const gapChange = React.useCallback((ev) => {
    setGap(ev.target.value);
  });

  const dateChange = React.useCallback((args) => {
    setDate(args.value);
  }, []);

  //   const DeleteEvent = React.useCallback(() => {
  //     deleteEvent(tempEvent);
  //     setOpen(false);
  //   }, [deleteEvent, tempEvent]);

  const closeRecurring = React.useCallback(() => {
    setRecurring(false);
  });

  const openRecurring = React.useCallback(() => {
    setRecurring(true);
  });
  const openCancelSchedule = React.useCallback(() => {
    setCancelSchedule(true);
  });
  const closeCancelSchedule = React.useCallback(() => {
    setCancelSchedule(false);
  });
  const openCancelScheduleRecurring = React.useCallback(() => {
    setCancelScheduleRecurring(true);
  });
  const closeCancelScheduleRecurring = React.useCallback(() => {
    setCancelScheduleRecurring(false);
  });
  const goBackRecurring = React.useCallback(() => {
    closeCancelScheduleRecurring();
    openRecurring();
  });
  const goBackCurrent = React.useCallback(() => {
    closeCancelSchedule();
    openRecurring();
  });

  const onDeleteClick = React.useCallback(() => {
    if (!(tempEvent.end < new Date().toISOString())) {
      onClose();
      if (tempEvent.recurrence === null) {
        deleteCurrent();
      } else {
        openRecurring();
      }
    }
  });

  const deleteRecurring = React.useCallback(() => {
    dispatch(selectedSchedule(tempEvent?.relatedId));
    closeRecurring();
    openCancelScheduleRecurring();
  });
  const deleteCurrent = React.useCallback(() => {
    dispatch(selectedSchedule(tempEvent?.id));

    closeRecurring();
    openCancelSchedule();
  });

  // scheduler options

  const onSelectedDateChange = React.useCallback((event) => {
    setSelectedDate(event.date);
  }, []);

  const onEventClick = React.useCallback(
    (args) => {
      setEdit(true);
      dispatch(selectedSchedule(args.event.id));
      setTempEvent({ ...args.event });
      // fill popup form with event data
      loadPopupForm(args.event);
      setAnchor(args.domEvent.target);
      setOpen(true);
    },
    [loadPopupForm]
  );

  const onEventCreated = React.useCallback(
    (args) => {
      console.log("view", args);
      setEdit(false);
      setTempEvent(args.event);
      // fill popup form with event data
      loadPopupForm(args.event);
      setAnchor(args.target);
      // open the popup
      setOpen(true);
    },
    [loadPopupForm, resetCustomValues]
  );

  const onEventDeleted = React.useCallback(
    (args) => {
      deleteEvent(args.event);
    },
    [deleteEvent]
  );

  const onEventUpdated = React.useCallback((args) => {
    // here you can update the event in your storage as well, after drag & drop or resize
    // ...
  }, []);

  // datepicker options

  const responsiveOptions = {
    medium: {
      controls: ["calendar", "time"],
      touchUi: true,
    },
  };

  // popup options
  const headerText = React.useMemo(
    () => (isEdit ? "View Schedule" : "New Schedule"),
    [isEdit]
  );
  const onClose = React.useCallback(() => {
    setErrors({});
    setRepeatData(repeatData.filter((item) => item.value !== "custom-value"));
    dispatch(replaceSchedule([...globalState.schedules]));
    dispatch(selectedSchedule(null));
    setOpen(false);
  }, [isEdit, globalState.schedules, repeatData]);

  const newEventData = () => ({
    gap: 0,
    duration: 10,
  });

  const blockPastDate = React.useCallback((start) => {
    setInvalidData([
      {
        start: start,
        end: new Date().toISOString(),
        allDay: false,
        title: "Blocked",
      },
    ]);
  }, []);

  async function onPageLoading(event, inst) {
    setLoader(true);
    const start = new Date(event.firstDay).toISOString();
    const end = new Date(event.lastDay).toISOString();
    //eslint-disable-next-line
    start < new Date().toISOString() ? blockPastDate(start) : null;
    // setCalendarCurrentDayRange({ start, end });
    dispatch(changeCurrentDay({ start, end }));
    const data = await getData(start, end);
    dispatch(replaceSchedule([...data]));
    setLoader(false);
  }
  const onEventCreateFailed = React.useCallback(() => {
    mobiscroll.toast({
      message: "Can't create event on this date",
    });
  });

  const onEventUpdateFailed = React.useCallback(() => {
    mobiscroll.toast({
      message: "Can't add event on this date",
    });
  });
  return (
    <div className="docg-schedule-card docg-reschedule w-100 m-0">
      <Loader show={loader} />
      <Eventcalendar
        onPageLoading={onPageLoading}
        renderHeader={customWithNavButtons}
        renderScheduleEvent={renderScheduleEvent}
        renderLabel={renderLabel}
        renderEventContent={renderLabel}
        extendDefaultEvent={newEventData}
        displayTimezone="local"
        timezonePlugin={momentTimezone}
        view={calView}
        data={globalState.schedules}
        dayNames={[
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ]}
        cssClass="md-switching-view-cont"
        clickToCreate="double"
        dragToCreate={true}
        dragToMove={false}
        dragToResize={true}
        externalDrop={true}
        selectedDate={mySelectedDate}
        onSelectedDateChange={onSelectedDateChange}
        onEventClick={onEventClick}
        onEventCreated={onEventCreated}
        onEventDeleted={onEventDeleted}
        onEventUpdated={onEventUpdated}
        invalidateEvent="strict"
        invalid={invalidData}
        onEventCreateFailed={onEventCreateFailed}
        onEventUpdateFailed={onEventUpdateFailed}
      />

      <Modal
        show={isOpen}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal docg-schedule-popup"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header className="p-0">
          <div className="docg-schedule-popup-header d-flex align-items-center justity-content-between w-100">
            <button onClick={onClose} className="docg-save-btn">
              <img src={cancel} alt="" />
              Cancel
            </button>
            <div className="header-title">{headerText}</div>
            <button
              onClick={saveEvent}
              className="docg-save-btn"
              style={{ display: isEdit ? "none" : "" }}
            >
              Save
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="mbsc-form-group">
              <Input
                label="Schedule Name"
                value={popupEventTitle}
                readOnly
                onChange={titleChange}
              />

              <Input
                ref={startRef}
                readOnly={isEdit}
                label="Start Time"
                touchUi={true}
              />
              <Input
                ref={endRef}
                readOnly={isEdit}
                label="End Time"
                touchUi={true}
              />
              <Datepicker
                select="range"
                controls={["datetime"]}
                touchUi={true}
                disabled={isEdit}
                startInput={start}
                endInput={end}
                showRangeLabels={false}
                responsive={responsiveOptions}
                onChange={dateChange}
                value={popupEventDate}
              />
              <Input
                type="number"
                min={0}
                readOnly={isEdit}
                value={popupSlotDuration}
                error={true}
                errorMessage={errors.popupSlotDuration}
                onChange={slotChange}
                label="Slot Duration (mins)"
              />

              <Input
                min={0}
                type="number"
                readOnly={isEdit}
                value={popupGap}
                error={true}
                errorMessage={errors.popupGap}
                onChange={gapChange}
                label="Gap between Slots (mins)"
              />
              <Select
                className="mt-2"
                readOnly={isEdit}
                data={repeatData}
                label="Repeats"
                value={selectedRepeat}
                responsive={selectResponsive}
                onChange={repeatChange}
              />
              {(selectedRepeat === "custom" ||
                selectedRepeat === "custom-value") && (
                <div className="mt-2">
                  <div>
                    <SegmentedGroup
                      disabled={isEdit}
                      onChange={repeatTypeChange}
                    >
                      <SegmentedItem
                        value="daily"
                        checked={repeatType === "daily"}
                      >
                        Daily
                      </SegmentedItem>
                      <SegmentedItem
                        value="weekly"
                        checked={repeatType === "weekly"}
                      >
                        Weekly
                      </SegmentedItem>
                    </SegmentedGroup>

                    <div className="custom-repeat-settings">
                      Repeat every
                      <Input
                        readOnly={isEdit}
                        className="custom-repeat-input"
                        min="1"
                        value={repeatNr}
                        onChange={repeatNrChange}
                        inputStyle="outline"
                      />
                      {repeatType === "daily" && <span>days</span>}
                      {repeatType === "weekly" && <span>weeks</span>}
                      {repeatType === "daily" && (
                        <p className="custom-repeat-desc">
                          The event will be repeated every day or every x days,
                          depending on the value
                        </p>
                      )}
                      {repeatType === "weekly" && (
                        <p className="custom-repeat-desc">
                          The event will be repeated every x weeks on specific
                          weekdays
                        </p>
                      )}
                      {repeatType === "monthly" && (
                        <p className="custom-repeat-desc">
                          The event will be repeated every x month on specific
                          day of the month
                        </p>
                      )}
                      {repeatType === "weekly" && (
                        <div className="custom-repeat-checkbox-cont">
                          {days.map((day) => {
                            return (
                              <Checkbox
                                value={day.value}
                                disabled={isEdit}
                                key={day.value}
                                checked={day.checked}
                                onChange={weekDayChange}
                              >
                                {day.name}
                              </Checkbox>
                            );
                          })}
                        </div>
                      )}
                      <div>Stop condition</div>
                      <div className="custom-condition-cont">
                        <RadioGroup>
                          <Radio
                            checked={condition === "until"}
                            onChange={conditionChange}
                            value="until"
                          >
                            Run until a specific date{" "}
                            <Datepicker
                              inputProps={dateInputProps}
                              controls={["calendar"]}
                              display="anchored"
                              touchUi={true}
                              disabled={isEdit}
                              dateFormat="YYYY-MM-DD"
                              returnFormat="iso8601"
                              value={untilDate}
                              onChange={untilDateChange}
                              onOpen={() => setCondition("until")}
                            />
                            <span className="mbsc-description">
                              The event will run until it reaches a specific
                              date
                            </span>
                          </Radio>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isEdit && !(tempEvent.end < new Date().toISOString()) && (
                <button className="docg-save-btn mt-3" onClick={onDeleteClick}>
                  <img src={cancel} alt="" />
                  Delete Event
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Recurring
        isOpen={recurring}
        closeModal={closeRecurring}
        deleteRecurring={deleteRecurring}
        deleteCurrent={deleteCurrent}
      />
      {cancelSchedule && (
        <Suspense fallback={<div>Loading...</div>}>
          <CancelScheduleCurrent
            isOpen={cancelSchedule}
            closeModal={closeCancelSchedule}
            data={tempEvent}
            goBack={goBackCurrent}
          />
        </Suspense>
      )}
      {cancelScheduleRecurring && (
        <Suspense fallback={<div>Loading...</div>}>
          <CancelScheduleRecurring
            isOpen={cancelScheduleRecurring}
            closeModal={closeCancelScheduleRecurring}
            goBack={goBackRecurring}
            data={tempEvent}
          />
        </Suspense>
      )}
    </div>
  );
}

export default App;
