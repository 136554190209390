import React, { useRef, Suspense } from "react";

import Loader from "src/components/Dashboard/common/Loader";
import { Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { isMobileOnly } from "react-device-detect";

import { addPatientByDoctor } from "../api/index";

const AddPatientGeneralInfo = React.lazy(() =>
  import("./Tabs/AddPatientGeneralInfo")
);
const AddPatientMedicalInfo = React.lazy(() =>
  import("./Tabs/AddPatientMedicalInfo")
);

const AddPatient = () => {
  const [formValues, setValues] = React.useState({});
  const [errors, setErrors] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [key, setKey] = React.useState(1);

  const tab1ref = useRef();
  const tab2ref = useRef();

  const handlePageChange = (key) => {
    if (key == 2) tab1ref.current.onSubmit();
    else setKey(key);
  };
  return (
    <>
      <Loader show={loader} />
      <Helmet>
        <title>Add Patient | DOCG</title>
      </Helmet>
      <Tabs
        activeKey={key}
        onSelect={handlePageChange}
        id="controlled-tab-example"
      >
        <Tab
          eventKey={1}
          title={
            <>
              <div className="left-line-tab">
                <div className="tab-number">
                  <p>{isMobileOnly ? "General" : "General Information"}</p>{" "}
                </div>
              </div>
              <br />
            </>
          }
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AddPatientGeneralInfo
              formValues={formValues}
              setValues={setValues}
              setKey={setKey}
              ref={tab1ref}
            />
          </Suspense>
          {/* <AddPatientTab onChangeTab={() => setKey("medical")} /> */}
          {/* <AccountDetails goNext ={()=>NextStep()}  /> */}
        </Tab>
        <Tab
          eventKey={2}
          title={
            <>
              <div className="left-line-tab">
                <div className="tab-number">
                  <p>{isMobileOnly ? "Medical" : "Medical Information"}</p>{" "}
                </div>
              </div>
              <br />
            </>
          }
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AddPatientMedicalInfo
              formValues={formValues}
              setValues={setValues}
              setKey={setKey}
              ref={tab2ref}
            />
          </Suspense>
        </Tab>
      </Tabs>
    </>
  );
};

export default AddPatient;
