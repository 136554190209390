import React, { Fragment, useState } from "react";
import "../Reporting.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import ReportingModals from "../../../../shared/Modals/ReportingModals";
import arrowImg from "../../../../assets/purple.svg";

import { getDoctorBillings } from "../api";

const TotalRevenue = (props) => {
  const [lgShow, setLgShow] = useState(false);
  const chartRef = React.useRef();

  React.useEffect(() => {
    am4core.ready(function () {
      // Themes begin
      am4core.useTheme(am4themes_frozen);
      am4core.useTheme(am4themes_animated);
      am4core.addLicense("ch-custom-attribution");

      // Themes end

      // Create chart instance
      var chart = am4core.create(props?.divId, am4charts.PieChart);
      // Add data
      chart.data = [];

      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "title";
      pieSeries.innerRadius = am4core.percent(50);
      pieSeries.ticks.template.disabled = true;
      pieSeries.labels.template.disabled = true;
      pieSeries.legendSettings.labelText = "{category}";
      pieSeries.legendSettings.valueText = "{value}";

      var rgm = new am4core.RadialGradientModifier();
      rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, -0.5);
      pieSeries.slices.template.fillModifier = rgm;
      pieSeries.slices.template.strokeModifier = rgm;
      pieSeries.slices.template.strokeOpacity = 0.4;
      pieSeries.slices.template.strokeWidth = 0;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "down";
      chartRef.current = chart;
    }); // end am4core.ready()
    // eslint-disable-next-line
  }, []);

  React.useEffect(async () => {
    const res = await getDoctorBillings();
    console.log(res);
    chartRef.current.data = res;
  }, []);

  return (
    <Fragment>
      <div
        onClick={(e) => {
          props?.check ? setLgShow(true) : e.preventDefault();
        }}
        className=""
      >
        <span className="patient_style">
          Total Revenue
          <img src={arrowImg} alt="" className="arrow-img ml-2" />
        </span>
      </div>
      <div
        onClick={(e) => {
          props?.check ? setLgShow(true) : e.preventDefault();
        }}
        id={props?.divId}
      ></div>
      {setLgShow ? (
        <ReportingModals
          setLgShow={setLgShow}
          lgShow={lgShow}
          show={4}
          title={"Total Revenue"}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default TotalRevenue;
