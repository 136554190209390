import React from "react";
import { Modal } from "react-bootstrap";
import { Symptom } from "src/components/Dashboard/Patient/PatientDetail/Tabs/SymptomTab";

const SymptomCellRenderer = (params) => {
  const [openPopup, setOpenPopup] = React.useState(false);
  console.log(params);
  return (
    <>
      <Modal
        show={openPopup}
        onHide={() => setOpenPopup(false)}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle">Patient Symptoms</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Symptom
            data={params?.data?.metadata?.patientSymptoms?.questions}
            open={true}
          />
        </Modal.Body>
      </Modal>
      <button
        onClick={() => setOpenPopup(true)}
        className="docg-patient-cancel-btn m-2"
      >
        <span>View Symptoms</span>
      </button>
    </>
  );
};

export default SymptomCellRenderer;
