import React from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import defultPatientIcon from "src/assets/icons/defultPatientIcon.svg";

const color = (value) => {
  switch (value) {
    case "Red":
      return "#dc3545";
    case "Yellow":
      return "#ffc107";
    case "Grey":
      return "#6c757d";
    case "Green":
      return "#1aab73";
    default:
      return;
  }
};

const PatientNameCellRenderer = (params) => {
  const LINK = `patient-record/${params.value.patientDetails.id}?appointmentId=${params.value.appointmentId}`;
  return (
    <>
      <Link className="docg-app-patient-name w-100" to={LINK}>
        <div
          style={{
            background: color(params?.data?.patientStatus),
            width: "0.5rem",
            height: "0.5rem",
            margin: "auto 0.5rem",
            borderRadius: "50%",
            display: "inline-block",
          }}
        >
          &nbsp;
        </div>
        <Image
          src={
            params.value.patientDetails.profilePicture
              ? params.value.patientDetails.profilePicture
              : defultPatientIcon
          }
          alt={params.value.firstName}
          roundedCircle
          style={{ width: 35, height: 35 }}
        />{" "}
        {params.value.patientDetails.firstName}{" "}
        {params.value.patientDetails.lastName}
        {params.data?.delay ? (
          <i style={{ color: "red", fontSize: "9px" }}>
            ({params.data.patientDelay}mins)
          </i>
        ) : null}
      </Link>
    </>
  );
};

export default PatientNameCellRenderer;
