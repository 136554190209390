import { Card, CardContent, CardTitle } from "@mobiscroll/react";
import React, { Suspense, useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

import arrowWhite from "../../../../../assets/icons/arrowWhite.svg";
import arrow from "../../../../../assets/icons/black-arrow.svg";
import HistoryDialog from "../dialogs/HistoryDialog";
import { useStore } from "../store/Store";

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};
const HistoryCard = ({
  title = "",
  doctorName = "",
  data = null,
  date,
  key,
  showDiagnosis = false,
}) => {
  console.log(data);
  const history = useHistory();
  const { globalState } = useStore();

  const [isHistoryDialog, setHistoryDialog] = useState(false);
  const [followUpPopup, setFollowUpPopup] = React.useState(false);

  const openHistoryDialog = useCallback(() => {
    setHistoryDialog(true);
  }, []);

  const closeHistoryDialog = useCallback(() => {
    setHistoryDialog(false);
  }, []);

  const openFollowUp = useCallback(() => {
    setHistoryDialog(false);
    setFollowUpPopup(true);
  }, []);

  return (
    <>
      {!showDiagnosis ? (
        <div
          key={key}
          className={`history-card ${isHistoryDialog ? "active" : ""}`}
        >
          <div className="">
            <div
              className=""
              style={{ cursor: "pointer" }}
              onClick={() =>
                globalState.startingConsultation
                  ? openHistoryDialog()
                  : history.push(
                    `/patient-history/${globalState.patientId}?appointmentId=${data.appointmentDetails.id}`
                  )
              }
            >
              <Card className={`docg-card`}>
                <CardTitle className="docg-card-title">
                  {new Date(data?.appointmentDetails?.start).toLocaleString(
                    [],
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                  {isHistoryDialog ? (
                    <img src={arrowWhite} alt="" />
                  ) : (
                    <img src={arrow} alt="" />
                  )}
                </CardTitle>
                <CardContent className="docg-card-content">
                  <>
                    <h6>Dr. {doctorName}</h6>
                    {data?.prescription?.drugs.map((drug) => (
                      <h5>{drug.drugName}</h5>
                    ))}
                    {/* <h5>{medicineName}</h5> */}
                    <h6>{title}</h6>
                  </>
                </CardContent>
              </Card>
            </div>
            {/* <Followup show={followUpPopup} closeFollowUp={closeFollowUp} /> */}

            <Modal
              show={isHistoryDialog}
              onHide={closeHistoryDialog}
              dialogClassName="history-dialog-modal"
              aria-labelledby="example-custom-modal-styling-title"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body>
                <Suspense fallback={<div>Loading...</div>}>
                  <HistoryDialog
                    followUpPopup={followUpPopup}
                    openFollowUp={openFollowUp}
                    onClose={closeHistoryDialog}
                    data={data}
                  />
                </Suspense>
              </Modal.Body>
            </Modal>
          </div>
          <div className="history-card-timeline">
            <span className="history-card-timeline-pointer"></span>
          </div>
        </div>
      ) : (
        <>
          <button
            className="mbsc-row my-2 p-2 justify-content-between past-diagnosis-card d-flex"
            onClick={() =>
              globalState.startingConsultation
                ? openHistoryDialog()
                : history.push(
                  `/patient-history/${globalState.patientId}?appointmentId=${data.appointmentDetails.id}`
                )
            }
          >
            <span>
              {data?.appointmentDetails?.start &&
                new Date(data?.appointmentDetails?.start).toLocaleDateString(
                  "en-US",
                  options
                )}
            </span>
            <span>
              {[
                ...(data?.appointmentDetails?.note?.diagnosis?.diagnosisName ??
                  []),
                ...(data?.appointmentDetails?.note?.diagnosis?.message
                  ? [data?.appointmentDetails?.note?.diagnosis?.message]
                  : []),
              ].join(", ") || "NA"}
            </span>
          </button>

          <Modal
            show={isHistoryDialog}
            onHide={closeHistoryDialog}
            dialogClassName="history-dialog-modal"
            aria-labelledby="example-custom-modal-styling-title"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <Suspense fallback={<div>Loading...</div>}>
                <HistoryDialog
                  followUpPopup={followUpPopup}
                  openFollowUp={openFollowUp}
                  onClose={closeHistoryDialog}
                  data={data}
                />
              </Suspense>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default HistoryCard;
