import React from "react";
import Modal from "react-bootstrap/Modal";
import { useStore } from "./store/Store";
import HistoryCard from "./Tabs/HistoryCard";

const PastDiagnosisModal = ({ isOpen, onClose }) => {
  const { globalState, dispatch } = useStore();
  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        dialogClassName="history-dialog-modal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle">Past Diagnosis</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="past-diagnosis">
            {globalState?.patientHistory.map((ele, index) => (
              <HistoryCard
                key={ele?.id}
                title={ele?.title}
                doctorName={ele?.doctorName}
                data={ele}
                date={ele?.start}
                showDiagnosis
              />
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PastDiagnosisModal;
