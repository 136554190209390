import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {} from "src/components/graphql/GraphQLquery";
import {
  SEND_VIDEO_CALL_END_NOTIFICATION,
  SEND_VIDEO_CALL_START_NOTIFICATION,
} from "src/components/graphql/GraphQLmutation";
import { snackbar } from "@mobiscroll/react5";

export async function sendVideoCallStartNotification(appointmentId) {
  try {
    const response = await postMutation(
      SEND_VIDEO_CALL_START_NOTIFICATION,
      appointmentId
    );

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.sendVideoCallStartNotification.statusCode < 300 &&
        response.data.sendVideoCallStartNotification.statusCode >= 200
      ) {
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while Sending Notification To Patient. Refresh and Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return false;
  }
}
export async function sendVideoCallEndNotification(data) {
  try {
    const response = await postMutation(SEND_VIDEO_CALL_END_NOTIFICATION, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.sendVideoCallEndNotification.statusCode < 300 &&
        response.data.sendVideoCallEndNotification.statusCode >= 200
      ) {
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while Sending Notification To Patient. Refresh and Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return false;
  }
}
