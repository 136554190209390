import React from "react";
import SmallCard from "./SmallCard";
import DataListingCard from "./DataListingCard/DataListingCard";
import InputFloating from "./InputFields";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;
const list = [
  {
    title: "Small Card",
    useCases: "Dashboard",
    component: SmallCard,
  },
  {
    title: "Data Listing Card",
    useCases: "Dashboard",
    component: DataListingCard,
  },
  {
    title: "Input Floating",
    useCases: "Dashboard",
    component: InputFloating,
  },
];

const index = () => {
  return (
    <div>
      <ul>
        {list.map((item) => {
          return (
            <li>
              <div>{item.title}</div>
              <div>{item.useCases}</div>
              <item.component />
            </li>
          );
        })}
      </ul>
      <Card style={{ padding: "0" }}>
        <CardHeader
          className="d-flex justify-content-between cardHeaderUsed"
          // style={{
          //   height: "4rem",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   fontStyle: "ubuntu",
          //   fontSize: "18px",
          //   fontWeight: "normal",
          //   backgroundColor: "#dee2e6",
          // }}
        >
          <CardTitle className="cardTitleUsed">
            {/* <Image src={Appointment} alt="" className="mr-2" />*/}
            Abc
          </CardTitle>
          <Button className="theme-button btn-xs">
            <span className="material-icons pr-1">add_circle_outline</span> Add
          </Button>
        </CardHeader>
        <CardBody>
          <p>Hello World!</p>
        </CardBody>
      </Card>
    </div>
  );
};

export default index;
