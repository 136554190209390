import React, { Fragment, useState, useEffect } from "react";
import "../Layouts/Card.css";
import MessageIcon from "../../../assets/icons/Message.svg";
import backIcon from "../../../assets/back.svg";
import draftIcon from "../../../assets/draft.svg";
import informationIcon from "../../../assets/information.svg";
import deleteIcon from "../../../assets/delete.svg";
import folderIcon from "../../../assets/folder.svg";
import tagIcon from "../../../assets/tag.svg";
import attachmentIcon from "../../../assets/attachement.svg";
import crossIcon from "../../../assets/cross.svg";
import previousIcon from "../../../assets/pagination-previous.svg";
import nextIcon from "../../../assets/pagination-next.svg";
import "./email.css";
import EmailTableRow from "./EmailTableRow";
import EmailTableHeader from "./EmailTableHeader";
import EmailLeftside from "./EmailLeftside";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";

function Email(props) {
  const [allSelected, setAllSelected] = useState(false);
  const [showInRight, setShowInRight] = useState([]);
  const [activeID, setActiveID] = useState("inbox");

  useEffect(() => {
    const listData = [
      {
        star: 1,
        id: 1,
        name: "Nelsen Lane",
        Work: "Work",
        text: "Nelson Lane is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
      {
        star: 0,
        id: 2,
        name: "Nelsen Lane",
        Work: "",
        text: "Nelson Lane is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
      {
        star: 0,
        id: 3,
        name: "Nelsen Lane",
        Work: "Work",
        text: "Nelson Lane is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
      {
        star: 0,
        id: 4,
        name: "Nelsen Lane",
        Work: "",
        text: "Nelson Lane is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
      {
        star: 0,
        id: 5,
        name: "Nelsen Lane",
        Work: "Work",
        text: "Nelson Lane is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
    ];

    setShowInRight(listData);
  }, []);

  const headerData = [
    { icon: backIcon },
    { icon: draftIcon },
    { icon: informationIcon },
    { icon: deleteIcon },
    { icon: folderIcon },
    { icon: tagIcon },
  ];

  const handleChange = async (e) => {
    setAllSelected(!allSelected);
  };

  const showInMailBox = (activeTab) => {
    const sendlistData = [
      {
        star: 1,
        id: 1,
        name: activeTab,
        Work: "Work",
        text: "David Paul is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
      {
        star: 0,
        id: 2,
        name: activeTab,
        Work: "",
        text: "David Paul is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
      {
        star: 0,
        id: 3,
        name: activeTab,
        Work: "Work",
        text: "David Paul is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
      {
        star: 0,
        id: 4,
        name: activeTab,
        Work: "",
        text: "David Paul is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
      {
        star: 0,
        id: 5,
        name: activeTab,
        Work: "Work",
        text: "David Paul is request you for an appointment.",
        attachmentIcon: attachmentIcon,
        time: "12:30 P.M",
      },
    ];

    setShowInRight(sendlistData);
    setActiveID(activeTab);
  };

  const starIconFun = (id) => {
    let starID = id;
  };

  return (
    <Fragment>
      <div className="icon-with-txt-div">
        <img src={MessageIcon} alt="" className="icon-size" />
        <p className="email-txt">Mail</p>
        <img
          onClick={props.history.goBack}
          src={crossIcon}
          alt=""
          className="icon-size add-position"
        />
      </div>
      <div className="content add-flex-wrapper mr-0 min-height-fix">
        <div className="row TodoAreaMargin w-100">
          <div className="col-lg-3 col-md-3 col-sm-12  bg-black">
            <EmailLeftside
              showThisData={(activeTab) => showInMailBox(activeTab)}
              activeID={activeID}
            />
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12 ">
            <div className="card mt-0">
              <div className="mail-main-wrapper">
                <div className="d-flex">
                  <Checkbox
                    icon={<Icon.FiCheck color="#C06C84" size={14} />}
                    name="isAllSelected"
                    onChange={handleChange}
                    checked={allSelected}
                    className="CheckBoxInput checkbox-check"
                    borderColor="#6C5B7B"
                    style={{ cursor: "pointer" }}
                  />
                  <EmailTableHeader headerData={headerData} />
                </div>
                <div className="arrow-div">
                  <img src={previousIcon} alt="" className="arrow-size" />
                  <img src={nextIcon} alt="" className="arrow-size" />
                </div>
              </div>
              <EmailTableRow
                isSelected={allSelected}
                setAllSelected={setAllSelected}
                listData={showInRight}
                starIcon={(starID) => starIconFun(starID)}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Email;
