import { Button, Card, CardContent } from "@mobiscroll/react";
import { snackbar } from "@mobiscroll/react5";
import React, { Fragment, Suspense, useCallback, useState } from "react";
import Avatar from "react-avatar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import arrow from "src/assets/icons/black-arrow.svg";
import endCallIcon from "src/assets/icons/endCallIcon.svg";
import followUp from "src/assets/icons/followUp.svg";
import prescription from "src/assets/icons/prescription-white.svg";
import video from "src/assets/icons/videoCallIcon.svg";
import invoiceIcon from "src/assets/Invoice-white.svg";
import RouteLeavingGuard from "src/commons/RouteLeavingGuard";
import { getQuery } from "src/components/ApolloConnection/ApolloProviderComponent";
import Loader from "src/components/Dashboard/common/Loader";
import EndConsultationModal from "src/components/Dashboard/Layouts/EndConsultationModal";
import { getMyProfile } from "src/components/Dashboard/Profile/api";
import {
  GET_CLINIC_ID,
  PRESCRIPTION_DOWNLOAD_PDF,
} from "src/components/graphql/GraphQLquery";
import getAge from "src/lib/getAge";
import getImageBlobFromUrl from "src/lib/getImageBlobFromUrl";
import { openNewWindow } from "src/lib/openNewWindow";
import FollowUp from "src/shared/Modals/FollowUp";

import { getPatientBasicDetails } from "./api";
import useInvoicePdf from "./DownloadInvoice";
import usePrescriptionPdf from "./DownloadPrescription";
import PastDiagnosisModal from "./PastDiagnosisModal";
import PatientDetailsPopup from "./PatientDetailsPopup";
import { useStore } from "./store/Store";
import AppointmentActionsPopup from "./AppointmentActionsPopup";
import { isMobileOnly } from "react-device-detect";
import PDFViewer from "src/components/Dashboard/Layouts/PDFViewer";
import defaultPatientIcon from "src/assets/defaultPatient.svg";

//api
//templat3es
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PatientSidebar = ({ hideDiagnosis }) => {
  const { globalState, dispatch } = useStore();
  const {
    fetchPdfData,
    isPdfDataLoading,
    prescriptionPDFLink,
    prescriptionBase64,
    prescriptionFileName,
  } = usePrescriptionPdf();
  let query = useQuery();
  const appointmentId = query.get("appointmentId");
  const history = useHistory();
  const [isContactDialog, setContactDialog] = useState(false);
  const [isPersonalDialog, setPersonalDialog] = useState(false);
  const [isAppointmentDialog, setAppointmentDialog] = useState(false);

  const [isReferredToPopup, setIsReferredToPopup] = useState(false);
  const [isHistoryDialog, setHistoryPopup] = useState(false);
  const [showFollowUp, setShowFollowUp] = React.useState(false);
  const [patientDetails, setPatientDetails] = useState(null);
  const [appointmentData, setAppointmentData] = useState(null);
  const [doctorData, setDoctorData] = useState(null);
  const [signature, setSignature] = useState(null);
  const [logoLink, setLogoLink] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const {
    downloadInvoice,
    isInvoiceLoading,
    invoiceBase64,
    fetchInvoiceData,
    invoiceFileName,
  } = useInvoicePdf(invoice);
  const [loader, setLoader] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const { patientId } = useParams();

  React.useEffect(async () => {
    setLoader(true);
    let res = await getPatientBasicDetails(patientId);
    console.log(res);
    if (res) {
      const age = getAge(res?.dateOfBirth);
      dispatch({
        type: "BMI",
        payload: { height: res?.height, weight: res?.weight },
      });
      dispatch({
        type: "PATIENT_DETAILS",
        payload: res,
      });
      res.age = age;
      let name = [res?.title, res?.firstName, res?.lastName];

      name = name.filter((key) => key != null).join(" ");
      res.name = name;
      sessionStorage.setItem(
        "vitals",
        JSON.stringify(res?.patientGrowthReport)
      );
    }
    setPatientDetails(res);
    setLoader(false);
  }, []);

  const onVideoCallClick = () => {
    openNewWindow(
      `/video-call/${globalState.patientId}?appointmentId=${appointmentId}`,
      "Video Call"
    );
  };

  const openHistoryDialog = useCallback(() => {
    setHistoryPopup(true);
  }, []);

  const closeHistoryDialog = useCallback(() => {
    setHistoryPopup(false);
  }, []);
  const openContactDialog = useCallback(() => {
    setContactDialog(true);
  }, []);

  const closeContactDialog = useCallback(() => {
    setContactDialog(false);
  }, []);
  const openPersonalDialog = useCallback(() => {
    setPersonalDialog(true);
  }, []);
  const closePersonalDialog = useCallback(() => {
    setPersonalDialog(false);
  }, []);

  const closeAppointmentDialog = useCallback(() => {
    setAppointmentDialog(false);
  }, []);

  const openAppointmentDialog = useCallback(() => {
    setAppointmentDialog(true);
  }, []);

  const openReferredToPopup = useCallback(() => {
    setIsReferredToPopup(true);
  }, []);
  const closeReferredToPopup = useCallback(() => {
    setIsReferredToPopup(false);
  }, []);

  // downloading prescription content beforehand
  React.useEffect(async () => {
    if (appointmentId)
      try {
        // getAppointmentDetails({
        //   id: appointmentId,
        // }).then((res) => setAppointmentData(res));
        getMyProfile().then((res) => {
          setDoctorData(res);
          res?.signature &&
            getImageBlobFromUrl(res?.signature).then((blob) =>
              dispatch({ type: "CUSTOM", payload: { signature: blob } })
            );
        });
        const res = await getQuery(GET_CLINIC_ID);
        const logoLink = res.data.listClinics?.data[0].logoLink;
        const logoLinkBlob = await getImageBlobFromUrl(logoLink);
        dispatch({
          type: "CUSTOM",
          payload: { logoLink: logoLinkBlob },
        });
      } catch (err) {
        console.log(err);
      }
  }, []);

  React.useEffect(() => {
    const invoices = globalState?.appointmentDetails?.invoices ?? null;
    if (Array.isArray(invoices)) {
      const invoiceId = invoices[invoices.length - 1];
      setInvoice(invoiceId);
    }
  }, [globalState?.appointmentDetails?.invoices]);

  const prepareVisionTableData = (data) => {
    // data will take vision
    let arr = [];

    let visionTypeArr = ["unaided", "withGlass", "withPHole"];

    visionTypeArr.forEach((item, index) => {
      let json = {
        visionType: "",
        distanceOD: "",
        distanceOS: "",
        nearOD: "",
        nearOS: "",
      };
      if (index == 0) json.visionType = "Unaid";
      else if (index === 1) json.visionType = "With glass";
      else json.visionType = "With P. Hole";

      json.distanceOD = data.distance[item].od;
      json.distanceOS = data.distance[item].os;
      json.nearOD = data.near[item].od;
      json.nearOS = data.near[item].os;
      arr.push(json);
    });
    return arr;
  };

  const prepareEyePrescriptionData = (data) => {
    let arr = [];

    let typeArr = ["distance", "near", "pg"];
    typeArr.forEach((item, index) => {
      let json = {
        type: "",
        leftSph: "",
        leftCyl: "",
        leftAxis: "",
        leftVA: "",
        rightSph: "",
        rightCyl: "",
        rightAxis: "",
        rightVA: "",
      };
      if (index == 0) json.type = "Distant";
      else if (index === 1) json.type = "Near";
      else json.type = "PG";

      json.leftSph = data.leftEye[item].spl;
      json.leftCyl = data.leftEye[item].cyl;
      json.leftAxis = data.leftEye[item].axis;
      json.leftVA = data.leftEye[item].va;
      json.rightSph = data.rightEye[item].spl;
      json.rightCyl = data.rightEye[item].cyl;
      json.rightAxis = data.rightEye[item].axis;
      json.rightVA = data.rightEye[item].va;

      arr.push(json);
    });

    return arr;
  };

  const eyePressure = (data) => {
    let arr = [];
    let json = {
      time: data.time ? new Date(data.time).toLocaleString() : "NA",
      iopOD: data.iop.od,
      iopOS: data.iop.os,
      patchyOD: data.pachy.od,
      patchyOS: data.pachy.os,
      method: data.method,
    };
    arr.push(json);
    return arr;
  };

  const getBlob = async () => {
    try {
      const res = await getQuery(
        PRESCRIPTION_DOWNLOAD_PDF(
          appointmentId,
          sessionStorage.getItem("role-id"),
          patientId,
          sessionStorage.getItem("clinic_id")
        )
      );
      const temaplateData = {
        appointmentData: res.data.getAppointment.data,
        doctorData: res.data.getDoctor.data,
        clinicData: res.data.getClinicProfile.data,
        patientData: res.data.getPatient.data,
        eyePrescription: res.data.getAppointment.data.eyePrescriptionExists
          ? prepareEyePrescriptionData(
              res.data.getAppointment.data.prescription.eyePrescription
            )
          : null,
        visionTableData: res.data.getAppointment.data.note.visionTableExists
          ? prepareVisionTableData(
              res.data.getAppointment.data.note.visionTable.vision
            )
          : null,
        eyePressureTable: res.data.getAppointment.data.note.visionTableExists
          ? eyePressure(
              res.data.getAppointment.data.note.visionTable.eyePressure
            )
          : null,
      };
      // const blob = await pdf(
      //   <PrescriptionTemplate
      //     logoLink={globalState.logoLink}
      //     signature={globalState.signature}
      //     data={temaplateData}
      //   />
      // ).toBlob();
      // return blob;
      return null;
    } catch {
      snackbar({
        message: "Something went wrong!",
        color: "danger",
      });
    }
  };

  // const downloadPrescription = async () => {
  //   setLoader(true);
  //   try {
  //     const blob = await getBlob();
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = `prescription-${patientDetails?.name}.pdf`;
  //     link.click();
  //     link.remove();
  //   } catch (err) {
  //     snackbar({
  //       message: "Something went wrong!",
  //       color: "danger",
  //     });
  //   }

  //   setLoader(false);
  // };

  // const downloadInvoice = async () => {
  //   let subTotal = 0;
  //   let tax = 0;
  //   let total = 0;
  //   invoice.items.forEach((item) => {
  //     subTotal += item.amount;
  //     tax += item.cgst + item.sgst;
  //     total =
  //       total +
  //       (subTotal +
  //         (subTotal * item.cgst) / 100 +
  //         (subTotal * item.sgst) / 100);
  //   });
  //   const blob = await pdf(
  //     <InvoiceTemplate
  //       logoLink={logoLink}
  //       invoice={invoice}
  //       subTotal={subTotal}
  //       tax={tax}
  //       total={total}
  //       signature={signature}
  //     />
  //   ).toBlob();
  //   const link = document.createElement("a");
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = `invoice-${invoice?.to}`;
  //   link.click();
  //   link.remove();
  // };

  return (
    <Fragment>
      <PDFViewer
        link={showPDF == "prescription" ? prescriptionBase64 : invoiceBase64}
        show={showPDF}
        setShow={setShowPDF}
        fileName={
          showPDF == "prescription" ? prescriptionFileName : invoiceFileName
        }
      />
      <Loader show={loader || isPdfDataLoading} />
      <Card className="docg-card docg-patient-sidebar">
        <CardContent className="docg-card-content docg-patient-body px-0">
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              {/* <div className="mbsc-col-5 mbsc-col-sm-5 mbsc-col-md-5 mbsc-col-lg-5 pl-0 mt-1"> */}
              <div className="d-flex justify-content-center align-items-center pl-2">
                <Avatar
                  color="#c06c84"
                  round
                  size="50"
                  name={patientDetails?.name}
                  src={patientDetails?.profilePicture || defaultPatientIcon}
                />
              </div>
              <div className="d-flex flex-column ml-1">
                <span className="docg-patient-name">
                  {patientDetails?.name ? patientDetails?.name : "-"}
                </span>
                <span>
                  {[
                    patientDetails?.age,
                    patientDetails?.gender,
                    patientDetails?.bloodGroup,
                  ]
                    .filter((key) => key != null)
                    .join(", ")}
                </span>
              </div>
              {/* </div> */}

              <div
                className={`section d-flex gap-2 flex-column align-items-center justify-content-center`}
              >
                <span className="docg-patient-label">
                  <Button
                    className="docg-patient-link"
                    onClick={() => openContactDialog()}
                  >
                    Contact Details
                    <img src={arrow} alt="" />
                  </Button>
                </span>
                <span className="docg-patient-label">
                  <Button
                    className="docg-patient-link"
                    onClick={() => openPersonalDialog()}
                  >
                    Personal Details
                    <img src={arrow} alt="" />
                  </Button>
                </span>
              </div>
              <div className="section d-flex flex-column gap-2 align-items-center justify-content-center">
                {!hideDiagnosis && (
                  <span className="docg-patient-label">
                    <Button
                      className="docg-patient-link"
                      onClick={() => openHistoryDialog()}
                    >
                      Past Diagnosis
                      <img src={arrow} alt="" />
                    </Button>
                  </span>
                )}
                {isMobileOnly && (
                  <span className="">
                    <Button
                      className="docg-patient-link"
                      onClick={() => openAppointmentDialog()}
                    >
                      <b className="purple-color">Actions</b>
                      <img src={arrow} alt="" />
                    </Button>
                  </span>
                )}
              </div>
            </div>
          </div>
          {!isMobileOnly && (
            <div className="d-flex section align-items-start">
              {globalState.consultationType &&
                globalState.consultationType == "Online" &&
                globalState.startingConsultation && (
                  <button
                    onClick={onVideoCallClick}
                    className="docg-patient-options"
                  >
                    <div className="img">
                      <img src={video} alt="" />
                    </div>
                    <span>Video Call</span>
                  </button>
                )}
              {globalState.startingConsultation && (
                <button
                  onClick={openReferredToPopup}
                  className="docg-patient-options "
                >
                  <div className="img">
                    <img src={endCallIcon} alt="" />
                  </div>
                  <span>End</span>
                </button>
              )}
              {appointmentId && (
                <button
                  onClick={async () => {
                    let res = await fetchPdfData();
                    console.log(res, prescriptionBase64);
                    setShowPDF("prescription");
                  }}
                  className="docg-patient-options"
                >
                  <div className="img">
                    <img src={prescription} alt="" />
                  </div>
                  <span>Prescription</span>
                </button>
              )}
              {appointmentId && (
                <button
                  onClick={async () => {
                    await fetchInvoiceData();
                    setShowPDF("invoice");
                  }}
                  className="docg-patient-options "
                >
                  <div className="img">
                    <img src={invoiceIcon} alt="" />
                  </div>
                  <span> Invoice</span>
                </button>
              )}

              {/* <button className="docg-patient-options ">
              <div className="img">
                <img src={chat} alt="" />
              </div>
              <span>Chat</span>
            </button> */}

              {globalState?.appointmentId ? (
                <button
                  onClick={() => {
                    setShowFollowUp(true);
                  }}
                  className="docg-patient-options "
                >
                  <div className="img">
                    <img src={followUp} alt="" />
                  </div>
                  <span>Follow Up</span>
                </button>
              ) : null}
            </div>
          )}
        </CardContent>
      </Card>
      <PatientDetailsPopup
        isDialog={isContactDialog}
        closeDialog={closeContactDialog}
        patientDetails={patientDetails}
        title="Contact details"
      />
      <PatientDetailsPopup
        isDialog={isPersonalDialog}
        closeDialog={closePersonalDialog}
        patientDetails={patientDetails}
        title="Personal details"
      />
      <AppointmentActionsPopup
        isDialog={isAppointmentDialog}
        closeDialog={closeAppointmentDialog}
        onVideoCallClick={onVideoCallClick}
        onRefferedToClick={openReferredToPopup}
        downloadPrescription={fetchPdfData}
        downloadInvoice={downloadInvoice}
        appointmentId={appointmentId}
        isInvoiceLoading={isInvoiceLoading}
        setShowFollowUp={setShowFollowUp}
      />
      <PastDiagnosisModal
        isOpen={isHistoryDialog}
        onClose={closeHistoryDialog}
      />

      <Suspense fallback={<div>Loading...</div>}>
        {isReferredToPopup && (
          <EndConsultationModal
            data={{
              ...globalState.appointmentDetails,
              patient: {
                id: patientDetails.id,
                firstName: patientDetails.firstName,
                lastName: patientDetails.lastName,
              },
            }}
            show={isReferredToPopup}
            onClose={closeReferredToPopup}
          />
        )}
      </Suspense>
      <FollowUp
        title="Follow Up"
        showFollowUp={showFollowUp}
        setShowFollowUp={setShowFollowUp}
        appointmentId={globalState?.appointmentId}
      />
      <RouteLeavingGuard
        data={{
          ...globalState?.appointmentDetails,
          patient: {
            id: patientDetails?.id,
            firstName: patientDetails?.firstName,
            lastName: patientDetails?.lastName,
          },
        }}
        when={globalState?.startingConsultation}
        navigate={(path) => {
          history.push(path);
        }}
        shouldBlockNavigation={(location) => {
          if (globalState?.startingConsultation && !isReferredToPopup) {
            // if (location.pathname === 'signup') {
            //     return true
            //   }
            return true;
          }
          return false;
        }}
        yes="yes"
        no="no"
        content="Are you sure you want to leave?"
      />
    </Fragment>
  );
};

export default PatientSidebar;
