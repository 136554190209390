import React, { Fragment, useState } from "react";
import "../Reporting.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_moonrisekingdom from "@amcharts/amcharts4/themes/moonrisekingdom";
import ReportingModals from "../../../../shared/Modals/ReportingModals";
import arrowImg from "../../../../assets/purple.svg";

const TotalSales = (props) => {
  const [lgShow, setLgShow] = useState(false);

  React.useEffect(() => {
    am4core.ready(function () {
      // Themes begin
      am4core.useTheme(am4themes_moonrisekingdom);
      am4core.useTheme(am4themes_animated);
      am4core.addLicense("ch-custom-attribution");

      // Themes end

      // Create chart instance
      var chart = am4core.create(props?.divId, am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();
      // Add data
      chart.data = [
        {
          date: "2016",
          value: 10,
          value2: 19,
        },
        {
          date: "2017",
          value: 100,
          value2: 50,
        },
        {
          date: "2018",
          value: 20,
          value2: 60,
        },
        {
          date: "2019",
          value: 120,
          value2: 100,
        },
        {
          date: "2020",
          value: 55,
          value2: 10,
        },
        {
          date: "2021",
          value: 80,
          value2: 5,
        },
      ];

      // Create axes
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 30;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minGridDistance = 30;

      // Create series
      var lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "value";
      lineSeries.dataFields.dateX = "date";
      lineSeries.name = "Sales";
      lineSeries.strokeWidth = 2;
      lineSeries.tensionX = 0.77;

      // Create series2
      var lineSeries2 = chart.series.push(new am4charts.LineSeries());
      lineSeries2.dataFields.valueY = "value2";
      lineSeries2.dataFields.dateX = "date";
      lineSeries2.name = "Sales";
      lineSeries2.strokeWidth = 2;
      lineSeries2.tensionX = 0.77;
    }); // end am4core.ready()
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <div
        onClick={(e) => {
          props?.check ? setLgShow(true) : e.preventDefault();
        }}
        className=""
      >
        <span className="patient_style">
          Total Sales
          <img src={arrowImg} alt="" className="arrow-img ml-2" />
        </span>
      </div>
      <div
        onClick={(e) => {
          props?.check ? setLgShow(true) : e.preventDefault();
        }}
        id={props?.divId}
      ></div>
      {setLgShow ? (
        <ReportingModals
          setLgShow={setLgShow}
          lgShow={lgShow}
          show={5}
          title={"Total Sales"}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default TotalSales;