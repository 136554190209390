import "./historyDialog.scss";

import mobiscroll from "@mobiscroll/react";
import React, { lazy, Suspense, useState } from "react";
import { useParams } from "react-router-dom";
import Symptoms from "src/assets/icons/symptomWhite.svg";
import Loader from "src/components/Dashboard/common/Loader";
import styled from "styled-components";

import close from "../../../../../assets/icons/close.svg";
import invoice from "../../../../../assets/icons/invoice.svg";
import LabTest from "../../../../../assets/icons/labTest-white.svg";
import Prescription from "../../../../../assets/icons/prescription-white.svg";
import { getAppointmentDetails } from "../api";
import usePrescriptionPdf from "../DownloadPrescription";

const PrescriptionDropdown = lazy(() =>
  import("../Tabs/medicalCards/PrescriptionPopup")
);

const LabTestPopup = lazy(() => import("../Tabs/medicalCards/LabTestPopup"));
const AttachmentPopup = lazy(() =>
  import("../Tabs/medicalCards/AttachmentPopup")
);
const InvoicePopup = lazy(() => import("../Tabs/medicalCards/InvoicePopup"));

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const eyePressure = (data) => {
  let arr = [];
  let json = {
    time: data.time ? new Date(data.time).toLocaleString() : "NA",
    iopOD: data.iop.od,
    iopOS: data.iop.os,
    patchyOD: data.pachy.od,
    patchyOS: data.pachy.os,
    method: data.method,
  };
  arr.push(json);
  return arr;
};

const prepareVisionTableData = (data) => {
  // data will take vision
  let arr = [];

  let visionTypeArr = ["unaided", "withGlass", "withPHole"];

  visionTypeArr.forEach((item, index) => {
    let json = {
      visionType: "",
      distanceOD: "",
      distanceOS: "",
      nearOD: "",
      nearOS: "",
    };
    if (index == 0) json.visionType = "Unaided";
    else if (index === 1) json.visionType = "With glass";
    else json.visionType = "With P. Hole";

    json.distanceOD = data.distance[item].od;
    json.distanceOS = data.distance[item].os;
    json.nearOD = data.near[item].od;
    json.nearOS = data.near[item].os;
    arr.push(json);
  });
  return arr;
};

const HistoryDialog = (props) => {
  const { patientId } = useParams();

  const { downloadPrescription, isPdfDataLoading } = usePrescriptionPdf();

  var windowObjectReference = null; // global variable

  const openFollowUp = () => {
    if (windowObjectReference == null || windowObjectReference.closed) {
      windowObjectReference = window.open(
        `/video-notes/${patientId}?appointmentId=${props.data.appointmentDetails.id}`,
        "Doctor Notes",
        "popup"
      );
      windowObjectReference.onbeforeunload = function (e) {
        var dialogText = "Dialog text here";
        e.returnValue = dialogText;
        return dialogText;
      };
    } else {
      windowObjectReference.focus();
      windowObjectReference.onbeforeunload = function (e) {
        var dialogText = "Dialog text here";
        e.returnValue = dialogText;
        return dialogText;
      };
    }
  };

  const [data, setData] = React.useState(null);
  const [loader, setLoader] = useState(false);

  React.useEffect(async () => {
    const res = await getAppointmentDetails({
      id: props.data.appointmentDetails.id,
    });
    setData(res);
  }, []);
  return (
    <>
      <Loader show={loader || isPdfDataLoading} />
      <div className="history-dialog">
        <div className="docg-popup-title"> </div>
        <div className="mbsc-grid p-0">
          <div className="mbsc-row">
            <div
              className="d-flex justify-content-between align-items-center mt-2"
              style={{ width: "100%" }}
            >
              <h5 className="header">
                {data?.start &&
                  new Date(data?.start).toLocaleDateString("en-US", options)}
              </h5>
              <span className="d-flex" style={{ float: "right" }}>
                <button
                  className="docg-patient-cancel-btn"
                  onClick={downloadPrescription}
                >
                  Download
                </button>
                <button className="docg-save-btn" onClick={openFollowUp}>
                  Update Details
                </button>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={props.onClose}
                  className="ml-2"
                  src={close}
                  alt=""
                />
              </span>
            </div>
          </div>
          <div className="mbsc-row d-flex justify-content-between mt-3">
            <div className="title mb-2">Notes</div>
            <div className="notes-date">
              {new Date(data?.start).toLocaleDateString("en-US", options)}
            </div>
          </div>

          <div className="mbsc-row notes-description">
            <div style={{ fontWeight: 500, fontSize: 14 }} className="w-100">
              Patient Complaints:
            </div>
            <br />
            {data?.note?.patientComplaints.map((data) => (
              <div className="w-100">
                <span style={{ fontWeight: 500 }}>
                  {data.updatedBy.firstName} {data.updatedBy.lastName}:
                </span>{" "}
                {data?.text}
                <br />
              </div>
            ))}
            {data?.note?.observation && (
              <>
                <div className="w-100">
                  <b>Doctor's Observation:</b> {data?.note?.observation}
                </div>
                <br />
              </>
            )}
            {data?.note?.diagnosis?.message && (
              <>
                <div className="w-100">
                  <b>Diagnosis:</b> {data?.note?.diagnosis?.message}
                </div>
                <br />
              </>
            )}
            {data?.notes?.diagnosis?.diagnosisName && (
              <>
                <div className="w-100">
                  <b>Diagnosis list:</b>{" "}
                  {data?.note?.diagnosis?.diagnosisName.join(", ") || "NA"}
                </div>
                <br />
              </>
            )}
            {data?.note?.procedure?.message && (
              <>
                <div className="w-100">
                  <b>Procedure:</b> {data?.note?.procedure.message}
                </div>
                <br />
              </>
            )}
            {data?.notes?.procedure?.procedureName && (
              <>
                <div className="w-100">
                  <b>Procedure list:</b>{" "}
                  {data?.note?.procedure?.procedureName.join(", ") || "NA"}
                </div>
                <br />
              </>
            )}
            {data?.notes?.instruction && (
              <>
                <div className="w-100">
                  <b>Instruction:</b>{" "}
                  {data?.note?.instruction.join(", ") || "NA"}
                </div>
                <br />
              </>
            )}
            {data?.notes?.planOfCare && (
              <>
                <div className="w-100">
                  <b>Advice/ Plan of Care:</b>{" "}
                  {data?.note?.planOfCare.join(", ") || "NA"}
                </div>
                <br />
              </>
            )}
            {data?.notes?.prognosis && (
              <>
                <div className="w-100">
                  <b>Prognosis:</b> {data?.note?.prognosis.join(", ") || "NA"}
                </div>
                <br />
              </>
            )}
            {data?.notes?.nutritionalAdvice && (
              <>
                <div className="w-100">
                  <b>Nutritional Advice:</b>{" "}
                  {data?.note?.nutritionalAdvice.join(", ") || "NA"}
                </div>
                <br />
              </>
            )}
            {data?.notes?.immunizationStatus && (
              <>
                <div className="w-100">
                  <b>Immunization Status:</b>{" "}
                  {data?.note?.immunizationStatus.join(", ") || "NA"}
                </div>
                <br />
              </>
            )}
          </div>

          {data?.note?.visionTable && (
            <>
              <div className="mbsc-row text-center">
                <div className="mbsc-col-6">
                  <table className="docg-table w-100">
                    <tr>
                      <th>Vision</th>
                      <th className="text-center" colSpan={2}>
                        Distance
                      </th>
                      <th className="text-center" colSpan={2}>
                        Near
                      </th>
                    </tr>
                    <tr fontSize={10} textAlign="center">
                      <th></th>
                      <th>OD</th>
                      <th>OS</th>
                      <th>OD</th>
                      <th>OS</th>
                    </tr>
                    {prepareVisionTableData(
                      data?.note?.visionTable?.vision
                    ).map((item) => (
                      <tr>
                        <td>{item.visionType}</td>
                        <td>{item.distanceOD}</td>
                        <td>{item.distanceOS}</td>
                        <td>{item.nearOD}</td>
                        <td>{item.nearOS}</td>
                      </tr>
                    ))}
                  </table>
                </div>
                <div className="mbsc-col-6">
                  <table className="docg-table w-100">
                    <tr>
                      <th>Time</th>
                      <th>Method</th>
                      <th className="text-center" colSpan={2}>
                        IOP
                      </th>
                      <th className="text-center" colSpan={2}>
                        Patchy
                      </th>
                    </tr>
                    <tr fontSize={10} textAlign="center">
                      <th></th>
                      <th></th>
                      <th>OD</th>
                      <th>OS</th>
                      <th>OD</th>
                      <th>OS</th>
                    </tr>
                    {eyePressure(data?.note?.visionTable?.eyePressure).map(
                      (item) => (
                        <tr>
                          <td>{item.time}</td>
                          <td>{item.method}</td>
                          <td>{item.iopOD}</td>
                          <td>{item.iopOS}</td>
                          <td>{item.patchyOD}</td>
                          <td>{item.patchyOS}</td>
                        </tr>
                      )
                    )}
                  </table>
                </div>
              </div>
            </>
          )}

          <div className="mbsc-row mt-3">
            <div className="mbsc-col p-0">
              <h2 className="title  mb-0">Linked Documents</h2>
            </div>
          </div>
          <div className="mbsc-row mt-3">
            <div className="mbsc-col pl-0">
              <div className="mbsc-row mr-0 ml-0 mt-2 mb-2 justify-content-between">
                <mobiscroll.Accordion className="pl-0 w-100">
                  <mobiscroll.FormGroup collapsible>
                    <mobiscroll.FormGroupTitle className="mbsc-row mt-0 mb-0 justify-content-between docg-linked-documents">
                      <div className="mbsc-col pl-0 pt-1 pb-1 d-flex align-items-center">
                        <span>
                          <img src={Prescription} alt="" />
                        </span>
                        <span>Prescription</span>
                      </div>
                    </mobiscroll.FormGroupTitle>
                    <mobiscroll.FormGroupContent>
                      <Suspense fallback={<div>Loading....</div>}>
                        <div className="m-2">
                          <PrescriptionDropdown
                            isDropdown={true}
                            data={data?.prescription}
                          />
                        </div>
                      </Suspense>
                    </mobiscroll.FormGroupContent>
                  </mobiscroll.FormGroup>
                </mobiscroll.Accordion>
              </div>
              <div className="mbsc-row mr-0 ml-0 mt-2 mb-2 justify-content-between">
                <mobiscroll.Accordion className="p-0 w-100">
                  <mobiscroll.FormGroup collapsible>
                    <mobiscroll.FormGroupTitle className="mbsc-row mt-0 mb-0 justify-content-between docg-linked-documents">
                      <div className="mbsc-col pl-0 pt-1 pb-1 d-flex align-items-center">
                        <span>
                          <img src={LabTest} alt="" />
                        </span>
                        <span>Lab Results</span>
                      </div>
                    </mobiscroll.FormGroupTitle>
                    <mobiscroll.FormGroupContent>
                      <Suspense fallback={<div>Loading....</div>}>
                        <LabTestPopup
                          tests={data?.tests}
                          labtests={data?.labTestReports}
                        />
                      </Suspense>
                    </mobiscroll.FormGroupContent>
                  </mobiscroll.FormGroup>
                </mobiscroll.Accordion>
              </div>
              <div className="mbsc-row mr-0 ml-0 mt-2 mb-2 justify-content-between">
                <mobiscroll.Accordion className="p-0 w-100">
                  <mobiscroll.FormGroup collapsible>
                    <mobiscroll.FormGroupTitle className="mbsc-row mt-0 mb-0 justify-content-between docg-linked-documents">
                      <div className="mbsc-col pl-0 pt-1 pb-1 d-flex align-items-center">
                        <span>
                          <img src={Symptoms} alt="" />
                        </span>
                        <span>Symptoms</span>
                      </div>
                    </mobiscroll.FormGroupTitle>
                    <mobiscroll.FormGroupContent>
                      <Suspense fallback={<div>Loading....</div>}>
                        <AttachmentPopup data={data?.survey?.questions} />
                      </Suspense>
                    </mobiscroll.FormGroupContent>
                  </mobiscroll.FormGroup>
                </mobiscroll.Accordion>
              </div>
              <div className="mbsc-row mr-0 ml-0 mt-2 mb-2 justify-content-between">
                <mobiscroll.Accordion className="p-0 w-100">
                  <mobiscroll.FormGroup collapsible>
                    <mobiscroll.FormGroupTitle className="mbsc-row mt-0 mb-0 justify-content-between docg-linked-documents">
                      <div className="mbsc-col pl-0 pt-1 pb-1 d-flex align-items-center">
                        <span>
                          <img src={invoice} alt="" />
                        </span>
                        <span>Invoice</span>
                      </div>
                    </mobiscroll.FormGroupTitle>
                    <mobiscroll.FormGroupContent>
                      <Suspense fallback={<div>Loading....</div>}>
                        <InvoicePopup data={data?.invoices} />
                      </Suspense>
                    </mobiscroll.FormGroupContent>
                  </mobiscroll.FormGroup>
                </mobiscroll.Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryDialog;
const Container = styled.div`
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  color: #929292;
  padding: 10px 0 0 0;
  width: 100%;
  &:first-child,
  .row {
    margin: 0;
  }
  span {
    color: #565656;
    font-weight: 600;
    text-transform: capitalize;
  }
`;
