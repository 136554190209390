import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  LIST_INVOICES,
  GET_INVOICE,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";

export async function listInvoices(take, filters) {
  try {
    const response = await getQueryWithParams(LIST_INVOICES(take, filters));

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.listInvoices.statusCode < 300 &&
        response.data.listInvoices.statusCode >= 200
      ) {
        if (response.data.listInvoices.data) {
          snackbar({
            message: "Patient Notes Fetched Successfully.",
            color: "success",
          });
          return response.data.listInvoices;
        } else {
          throw new Error(response);
        }
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while Fetching notes. Try again",
      color: "danger",
    });
    console.log("Error at Fetching notes: ", e);
    return false;
  }
}
export async function getInvoice(data) {
  try {
    const response = await getQueryWithParams(GET_INVOICE, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.getInvoice.statusCode < 300 &&
        response.data.getInvoice.statusCode >= 200
      ) {
        if (response.data.getInvoice.data) {
          snackbar({
            message: "Invoice Details Fetched Successfully.",
            color: "success",
          });
          return response.data.getInvoice.data;
        } else {
          throw new Error(response);
        }
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while Fetching Invoice Details. Try again",
      color: "danger",
    });
    console.log("Error at Fetching invoice details: ", e);
    return null;
  }
}
