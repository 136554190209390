export const FEE_LIST = "FEE_LIST";

export const initialState = {
  fees: [],
};
export const refreshFees = (fees) => ({
  type: FEE_LIST,
  fees,
});

export const feeReducer = (state, action, initialState) => {
  if (action.type === FEE_LIST) {
    return {
      ...state,
      fees: action.fees,
    };
  }
};
