import React, { useState, Suspense } from "react";
import { TabNav, NavItem } from "@mobiscroll/react";
import { Col, Card, Row, Image, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import _ from "lodash";
import AddPatient from "./Tab2/AddPatient";
import AddAccount from "./addAccount/AddAccount";

import addAccount from "src/assets/patient/addAccount.svg";
import addPatient from "src/assets/patient/addPatient.svg";

import "./addpatient.scss";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const AddPatientPage = () => {
  const [tab, setTab] = useState(2);

  return (
    <>
      <Helmet>
        <title>Add Patient | DOCG</title>
      </Helmet>
      <div className="mbsc-col-12">
        <Card className="docg-card docg-appointment docg-add-patient docg-vh-standard">
          <CardHeader className="docg-card-header pb-0">
            <TabNav
              style={{ width: "fit-content" }}
              className="docg-tabs d-inline-flex"
              theme="windows"
              context="#tab-content"
            >
              {/* <NavItem
                className="docg-nav-tab"
                selected={tab === 1}
                onClick={() => {
                  setTab(1);
                }}
              >
                <img src={addAccount} alt="" />
                Account Details
              </NavItem> */}
              <NavItem selected={tab === 2} onClick={() => setTab(2)}>
                <img src={addPatient} alt="" />
                Add Patient
              </NavItem>
            </TabNav>
          </CardHeader>

          {tab === 1 ? (
            <>
              <CardBody
                style={{
                  overflow: "auto",
                  borderTopRightRadius: "17px",
                  borderTopLeftRadius: "17px",
                }}
                className="docg-card-body-add-account p-0"
              >
                <AddAccount />
              </CardBody>
            </>
          ) : (
            <>
              <CardBody className="docg-card-body add-employee-tab w-100 p-0">
                <AddPatient />
              </CardBody>
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default AddPatientPage;
