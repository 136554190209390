import { snackbar } from "@mobiscroll/react5";
import {
  getQueryWithParams,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  REFER_TO,
  START_CONSULTATION,
} from "src/components/graphql/GraphQLmutation";
import {
  CHECK_CONSULTATION,
  GET_APPOINTMENT_DETAILS,
  GET_PATIENT,
  LIST_DOCTORS,
  LIST_PATIENT_HISTORY,
  LIST_PATIENT_SYMPTOMS,
} from "src/components/graphql/GraphQLquery";

export async function getPatientBasicDetails(data) {
  try {
    const response = await getQueryWithParams(GET_PATIENT, { id: data });
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.getPatient?.statusCode >= 200 &&
        response?.data?.getPatient?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Patient Information fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.getPatient?.data) {
        return response?.data?.getPatient?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching patient information ", e);
    return null;
  }
}

export async function listPatientHistory(patientId, start, end, filters) {
  try {
    const response = await getQueryWithParams(
      LIST_PATIENT_HISTORY(patientId, String(start), String(end), filters)
    );
    if (typeof response == "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Patient Medical History fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listPatientHistory?.data) {
        return response?.data?.listPatientHistory?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching patient Medical History ", e);
    return null;
  }
}

export async function referTo(data) {
  try {
    const response = await postMutation(REFER_TO, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.referPatientTo.statusCode < 300 &&
        response.data.referPatientTo.statusCode >= 200
      ) {
        return true;
        snackbar({
          message: "Referred Successfully.",
          color: "success",
        });
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while referring patient. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return false;
  }
}
export async function startConsultation(data) {
  try {
    const response = await postMutation(START_CONSULTATION, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.startConsultation.statusCode < 300 &&
        response.data.startConsultation.statusCode >= 200
      ) {
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching appointment details. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return false;
  }
}
export async function getConsultationDetails(data) {
  try {
    const response = await getQueryWithParams(CHECK_CONSULTATION, data);

    if (typeof response === "undefined" || response.errors) {
      throw new Error("error", response);
    } else {
      if (
        response.data.getAppointment.statusCode < 300 &&
        response.data.getAppointment.statusCode >= 200
      ) {
        if (response?.data?.getAppointment) {
          return response?.data?.getAppointment?.data;
        } else {
          throw new Error("error", response);
        }
      } else {
        throw new Error(response);
        return null;
      }
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching appointment details. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return null;
  }
}

export async function getAppointmentDetails(data) {
  try {
    const response = await getQueryWithParams(GET_APPOINTMENT_DETAILS, data);
    console.log(response);
    if (typeof response === "undefined" || response.errors != null) {
      throw new Error("error", response);
    } else {
      if (
        response.data.getAppointment.statusCode < 300 &&
        response.data.getAppointment.statusCode >= 200
      ) {
        return response.data.getAppointment.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching Appointment details. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return null;
  }
}

export async function listSymptoms(patientId) {
  try {
    const response = await getQueryWithParams(LIST_PATIENT_SYMPTOMS(patientId));

    if (typeof response === "undefined" || response.errors != null) {
      throw new Error("error", response);
    } else {
      if (
        response.data.getAppointment.statusCode < 300 &&
        response.data.getAppointment.statusCode >= 200
      ) {
        return response.data.getAppointment.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching Symptoms. Try again",
      color: "danger",
    });
    console.log("Error at list symptoms tab: ", e);
    return null;
  }
}

export async function listDoctors() {
  try {
    const response = await getQueryWithParams(LIST_DOCTORS);

    if (typeof response === "undefined" || response.errors != null) {
      throw new Error("error", response);
    } else {
      if (
        response.data.listDoctors.statusCode < 300 &&
        response.data.listDoctors.statusCode >= 200
      ) {
        return response.data.listDoctors.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while Doctors List. Try again",
      color: "danger",
    });
    console.log("Error at list symptoms tab: ", e);
    return [];
  }
}
